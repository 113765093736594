import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dashboardFilterActions } from '../../../../dashboard/actions';
import { FilterComponents } from '../components';

export const FiltterContainers = (props) => {
  const dispatch = useDispatch();
  // const [filter, setFilter] = useState(['vc', 'vd', 'oc', 'od', 'oo']);
  const [checked, setChecked] = useState({
    vc: true,
    vd: true,
    oc: true,
    od: true,
    oo: true,
    non: true,
    iot: true,
    leakage: true,
    predictive: true,
  });

  useMemo(() => {
    dispatch(dashboardFilterActions.changeFilterRoomStatus(checked));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const onChange = (e) => {
    let name = e.target.id;
    if (name === 'vc') {
      setChecked({
        ...checked,
        vc: !checked.vc,
      });
    }
    if (name === 'vd') {
      setChecked({
        ...checked,
        vd: !checked.vd,
      });
    }
    if (name === 'oc') {
      setChecked({
        ...checked,
        oc: !checked.oc,
      });
    }
    if (name === 'ac') {
      setChecked({
        ...checked,
        ac: !checked.ac,
      });
    }
    if (name === 'od') {
      setChecked({
        ...checked,
        od: !checked.od,
      });
    }
    if (name === 'oo') {
      setChecked({
        ...checked,
        oo: !checked.oo,
      });
    }
    if (name === 'non') {
      setChecked({
        ...checked,
        non: !checked.non,
      });
    }
    if (name === 'iot') {
      setChecked({
        ...checked,
        iot: !checked.iot,
      });
    }
    if (name === 'leakage') {
      setChecked({
        ...checked,
        leakage: !checked.leakage,
      });
    }
    if (name === 'predictive') {
      setChecked({
        ...checked,
        predictive: !checked.predictive,
      });
    }
  };
  return (
    <>
      <FilterComponents onChange={onChange} checked={checked} />
    </>
  );
};
