import React from 'react';
import { CostBreakDownComponent } from '../../../components/Energy';

// import { Tabs, Tab } from '../../components/EnergyCostBreakdown/Tab/Tab';

const plot_1 = {
  series: [62, 38],
  options: {
    labels: ['Common Space', 'Guest Room'],
  },
};

const plot_2 = {
  series: [38, 25, 14, 15, 8],
  options: {
    labels: ['Floor1', 'Floor2', 'Floor3', 'Floor4', 'Floor5'],
  },
};

const plot_3 = {
  series: [33, 15, 47, 5],
  options: {
    labels: ['Lobby', 'Restaurant', 'Conference Room', 'Gallery'],
  },
};

const EnergyCostBreakdownContainer = () => {
  return (
    <div>
      <CostBreakDownComponent plot_1={plot_1} plot_2={plot_2} plot_3={plot_3} />
    </div>
  );
};

export { EnergyCostBreakdownContainer };
