import { AUTH } from '../types';

const INIT_STATE = {
  error: [],
  isLogin: false,
  token: null,
};

const auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH.LOGIN_REQUEST:
      return {
        ...state,
        error: [],
        isLogin: false,
        token: null,
      };
    case AUTH.LOGIN_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
        isLogin: false,
        token: null,
      };
    case AUTH.LOGIN_SUCCESS:
      return {
        ...state,
        error: [],
        isLogin: true,
        token: action.token,
      };

    //##------------------------------------------------------------------
    case AUTH.LOGOUT_REQUEST:
      return {
        ...state,
        error: [],
      };
    case AUTH.LOGOUT_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    case AUTH.LOGOUT_SUCCESS:
      return {
        ...state,
        error: [],
        isLogin: false,
        token: null,
      };
    //##------------------------------------------------------------------
    default:
      return state;
  }
};

export { auth };
