import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { hotelActions } from '../../../../hotel/actions';

import { FirebaseContext2 } from '../../../../../firebase';
import { Card, CardDeck } from 'react-bootstrap';
import { Info, HistoryData } from '../../../components/Tabs';
import { List } from '../../../../../components/List/TabList';
import { energyActions } from '../../../../energy/actions';
import moment from 'moment';
import { roomActivitiesHistories } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';

function EnvSensor(props) {
  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext2);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);

  const d = new Date();

  const Temp = useSelector((state) => state.hotel.temp);
  const Humid = useSelector((state) => state.hotel.humid);

  const dropdown = useSelector((state) => state.hotel.dropdown);
  const [plotTemp, setPlotTemp] = useState([]);
  const [plotHumi, setPlotHumi] = useState([]);

  const [tmpHist, settmpHist] = useState(null);

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;

  const [currentTab, setCurrentTab] = useState('real-time-graph');
  const [environment, setEnvironment] = useState({
    env: {
      temperature: null,
      humidity: null,
      luminosity: null,
      noise: null,
      timestamp: null,
      air_quality_sub: null,
    },
  });

  // console.log({ tmpHist });

  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  async function histData(dev_id, nick_name) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        dropdown,
        nick_name,
        false,
        props.startDate,
        props.stopDate
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##--------------------------Fetch Data----------------------------------
  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/broadlinkenv/environment/envsensor';

    if (didMounted) {
      console.log('Environmetn did mounted');
      await firebase.db.ref(fbpath).off('value');
    } else {
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        // console.log({ capt });
        if (capt !== null && capt !== undefined) {
          setEnvironment({
            env: {
              temperature: capt.temperature,
              humidity: capt.humidity,
              luminosity: capt.luminosity_sub,
              noise: capt.noise_sub,
              timestamp: capt.timestamp,
              air_quality_sub: capt.air_quality_sub,
            },
          });
        } else {
          setEnvironment({
            env: {
              temperature: 'Loading',
              humidity: 'Loading',
              luminosity: 'Loading',
              noise: 'Loading',
              timestamp: 'Loading',
              air_quality_sub: capt.air_quality_sub,
            },
          });
        }
      });
    }
  }

  //##---------------------fetch data-----------------------------
  useEffect(() => {
    if (tmpHist !== null) {
      let temp = [];
      let humi = [];
      tmpHist.forEach((item) => {
        if (item.state !== null && item.timestamp !== null) {
          temp.push([Number(moment(item.timestamp).format('x')), item.temperature]);
          humi.push([Number(moment(item.timestamp).format('x')), item.humidity]);
        }

        setPlotTemp(temp);
        setPlotHumi(humi);
      });
    }
  }, [tmpHist]);

  useEffect(() => {
    devices.forEach((element) => {
      if (element['device_name'] === 'broadlinkenv') {
        try {
          histData(element['device_id'], 'broadlinkenv').then((results) => {
            return settmpHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown, currentRoomNo, props.startDate, props.stopDate]);

  useEffect(() => {
    fetchData(firebase, currentRoomNo, false);

    return () => {
      fetchData(firebase, currentRoomNo, true);
      //dispatch(hotelActions.plotFirebase({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##---------------------update graph-----------------------------
  useEffect(() => {
    if (
      environment.env.humidity !== null &&
      environment.env.timestamp !== null &&
      environment.env.humidity !== undefined &&
      environment.env.timestamp !== undefined &&
      environment.env.temperature !== undefined &&
      environment.env.temperature !== null
    ) {
      // console.log('ENV ============ ', environment.env.humidity, environment.env.temperature);
      dispatch(
        hotelActions.plotFirebase({
          temp: [
            Number(moment(environment.env.timestamp).format('x')),
            environment.env.temperature,
          ],
          humid: [Number(moment(environment.env.timestamp).format('x')), environment.env.humidity],
          // time: environment.env.timestamp,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment]);

  //##-------------------- Event handler  -----------------------
  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(dashboardControlActions.showIotModal(false));
    dispatch(energyActions.clearHistoryData());
    dispatch(hotelActions.clearPlot());
  };

  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
    console.log({ currentTab });
  };

  const image_link =
    'https://urbanportal.sk/review/wp-content/uploads/2017/02/BroadLink-A1-White-WiFi-Intelligent-Smart-Home-Air-Detector-Sensor-Purifier-Remote-Control-Smart-Home-Sensors.jpg';

  const Temp_plot = {
    series: [
      {
        name: 'Temperature',
        data: Temp !== undefined && Temp !== null ? plotTemp.concat(Temp) : [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      title: {
        text: 'Temperature',
        align: 'left',
      },
      yaxis: {
        title: {
          text: '°C',
        },
      },

      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  const Humid_plot = {
    series: [
      {
        name: 'Humidity',
        data: Humid !== undefined && Humid !== null ? plotHumi.concat(Humid) : [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        // style: 'hollow',
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      title: {
        text: 'Humidity',
        align: 'left',
      },
      yaxis: {
        title: {
          text: '(%)',
        },
      },

      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  //##-------------------- Render -----------------------

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Environment Sensor
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="uncont-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'real-time-graph'}
                        onClick={(e) => onClickTabMenu(e, 'real-time-graph')}
                      >
                        REAL TIME
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        onClick={(e) => onClickTabMenu(e, 'info')}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'activity'}
                        // onClick={(e) => onClickTabMenu(e, 'activity')}
                        disabled={true}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'history'}
                        onClick={(e) => onClickTabMenu(e, 'history')}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>

                  {/* real time graph */}
                  {currentTab === 'real-time-graph' && (
                    <div className="tab-content column cgroup-3 cfirst-elem">
                      <div id="gl-cards">
                        <CardDeck>
                          <Card
                            bg="secondary"
                            text="light"
                            style={{ width: '18rem' }}
                            className="mb-2"
                          >
                            <Card.Header>Temperature</Card.Header>
                            <Card.Body>
                              <Card.Title>{environment.env.temperature} &deg;C </Card.Title>
                              {/* <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text> */}
                            </Card.Body>
                          </Card>
                          <Card
                            bg="secondary"
                            text="light"
                            style={{ width: '18rem' }}
                            className="mb-2"
                          >
                            <Card.Header>Air Quality</Card.Header>
                            <Card.Body>
                              <Card.Title>{environment.env.air_quality_sub}</Card.Title>
                              {/* <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text> */}
                            </Card.Body>
                          </Card>

                          <Card
                            bg="success"
                            text="light"
                            style={{ width: '18rem' }}
                            className="mb-2"
                          >
                            <Card.Header>Humidity</Card.Header>
                            <Card.Body>
                              <Card.Title>{environment.env.humidity} %</Card.Title>
                              {/* <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text> */}
                            </Card.Body>
                          </Card>
                        </CardDeck>
                      </div>
                      <div id="gl-cards">
                        <CardDeck>
                          <Card
                            bg="primary"
                            text="light"
                            style={{ width: '18rem' }}
                            className="mb-2"
                          >
                            <Card.Header>Luminosity</Card.Header>
                            <Card.Body>
                              <Card.Title>{environment.env.luminosity}</Card.Title>
                              {/* <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text> */}
                            </Card.Body>
                          </Card>
                          <Card
                            bg="success"
                            text="light"
                            style={{ width: '18rem' }}
                            className="mb-2"
                          >
                            <Card.Header>Noise</Card.Header>
                            <Card.Body>
                              <Card.Title>{environment.env.noise}</Card.Title>
                              {/* <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text> */}
                            </Card.Body>
                          </Card>
                          <Card
                            bg="success"
                            text="light"
                            style={{ width: '18rem' }}
                            className="mb-2"
                          >
                            <Card.Header>Last Update</Card.Header>
                            <Card.Body>
                              <Card.Title>
                                {String(environment.env.timestamp).split('.')[0]}
                              </Card.Title>
                              {/* <Card.Text>
                                    Some quick example text to build on the card title and make up
                                    the bulk of the card's content.
                                  </Card.Text> */}
                            </Card.Body>
                          </Card>
                        </CardDeck>
                      </div>

                      <div className="iot-element" id="iot-realtime-meter">
                        <div id="gl-voltage"></div>
                        <div id="gl-current"></div>
                        <div id="gl-active-power"></div>
                        <div id="gl-reactive-power"></div>
                        <div id="gl-apparent-power"></div>
                        <div id="gl-positive-energy"></div>
                      </div>
                    </div>
                  )}

                  {/* info */}
                  {currentTab === 'info' && (
                    <Info image={image_link} detail={props.detail} header={props.header} />
                  )}
                  {/*activity */}
                  {/* {currentTab === 'activity' && <ActivityData onClick={onchildClickActivity} />} */}

                  {currentTab === 'history' && (
                    <HistoryData
                      data={{ temp: Temp_plot, humid: Humid_plot }}
                      startDate={props.startDate}
                      stopDate={props.stopDate}
                      handleStartDate={props.handleStartDate}
                      handleStopDate={props.handleStopDate}
                    />
                  )}
                </nav>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export { EnvSensor };
