import { LINE } from '../types';

const INIT_STATE = {
  error: [],
};

const line = (state = INIT_STATE, action) => {
  switch (action.type) {
    //##------------------------------------------------------------------
    case LINE.MSG_USER_BY_LINE_ID_REQUEST:
      return {
        ...state,
        error: [],
      };
    case LINE.MSG_USER_BY_LINE_ID_SUCCESS:
      return {
        ...state,
        error: [],
      };
    case LINE.MSG_USER_BY_LINE_ID_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    default:
      return state;
  }
};

export { line };
