import { USER } from '../types';

const INIT_STATE = {
  error: [],
  userList: [],
  pendingUserList: [],
};

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER.GET_USER_LIST_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        error: [],
        userList: action.userList,
      };
    case USER.GET_USER_LIST_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };

    //##------------------------------------------------------------------
    case USER.RESET_USER_STATE_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.RESET_USER_STATE_SUCCESS:
      return {
        ...state,
        error: [],
      };
    case USER.RESET_USER_STATE_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    case USER.DELETE_USER_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.DELETE_USER_SUCCESS:
      return {
        ...state,
        error: [],
      };
    case USER.DELETE_USER_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    case USER.CHANGE_GUEST_ROOM_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.CHANGE_GUEST_ROOM_SUCCESS:
      return {
        ...state,
        error: [],
      };
    case USER.CHANGE_GUEST_ROOM_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    case USER.GET_PENDING_USER_LIST_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.GET_PENDING_USER_LIST_SUCCESS:
      return {
        ...state,
        error: [],
        pendingUserList: action.pendingUserList,
      };
    case USER.GET_PENDING_USER_LIST_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    case USER.ACCEPT_PENDING_USER_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.ACCEPT_PENDING_USER_SUCCESS:
      return {
        ...state,
        error: [],
      };
    case USER.ACCEPT_PENDING_USER_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    case USER.DISCARD_PENDING_USER_REQUEST:
      return {
        ...state,
        error: [],
      };
    case USER.DISCARD_PENDING_USER_SUCCESS:
      return {
        ...state,
        error: [],
      };
    case USER.DISCARD_PENDING_USER_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };
    //##------------------------------------------------------------------
    default:
      return state;
  }
};

export { user };
