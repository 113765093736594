import React, { useState, useContext, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { FirebaseContext2 } from '../../../../../../firebase';
import { TdpkElectricityByHoursComponents } from '../../../../components/TDPK';

const TdpkElectricityByHoursContainer = () => {
  const firebase = useContext(FirebaseContext2);

  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

  const [electricity, setElectricity] = useState(null);

  async function fetchData(didMount) {
    const main_path = 'hotel/mintel/energy/electricity_by_hour';
    if (didMount) {
      await firebase.db.ref(main_path).off('value');
    } else {
      await firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        setElectricity(capt);
      });
    }
  }

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  useEffect(() => {
    fetchData(false);

    return () => fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plotData = {
    series: [
      {
        name: 'kWh',
        data: electricity !== null ? Object.values(electricity) : [],
      },
    ],

    options: {
      colors: ['#A9ECA9'],
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '12px',
          colors: ['#808080'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: electricity !== null ? Object.keys(electricity) : [],
      },
      yaxis: {
        // tickAmount: 9,
        title: {
          text: '(kWh)',
        },
      },
    },
  };

  return (
    <div>
      <TdpkElectricityByHoursComponents
        plotData={plotData}
        handleStartDate={handleStartDate}
        handleStopDate={handleStopDate}
        startDate={startDate}
        stopDate={stopDate}
      />
    </div>
  );
};

export { TdpkElectricityByHoursContainer };
