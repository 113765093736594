import React from 'react';
// import { HistoryData, Info, ActivityData } from '../';

export const FanComponents = (props) => {
  const currentTab = props.currentTab;
  const openFan = true;

  function onClick1() {}
  const imagesPath = {
    open: '/assets/images/devices/open_fan.gif',
    close: '/assets/images/devices/close_fan.png',
  };
  return (
    <>
      {props.menu}
      {/* Control tab */}
      {currentTab === 'control' && (
        <div className="iot-element" id="iot-control-fan">
          <div className="columns">
            <div className="column">
              <div className="column">
                <img
                  alt="fan-icon"
                  id="icon-fan-close"
                  style={{
                    width: '150px',
                    height: '150px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '5%',
                    cursor: 'pointer',
                  }}
                  // src="/assets/images/devices/icon-fan-close.png"
                  src={openFan ? imagesPath.open : imagesPath.close}
                  onClick={(e) => onClick1(e)}
                />
                <hr />
              </div>
              <div className="column">
                <button
                  className={
                    // status.exhaust_fan_switch.status === 'on'
                    openFan
                      ? 'button is-success device-controller'
                      : 'button is-unsuccess device-controller'
                  }
                  // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_711c11/data/gang 0", "type": "on-off",
                  //                                       "deviceid": "tasmota_711C11", "gang": 0, "icons":["icon-fan-close","icon-fan-open"]}'
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={(e) => onClick1(e)}
                >
                  {/* {status.exhaust_fan_switch.status === 'on' ? 'ON' : 'OFF'} */}
                  {openFan ? 'ON' : 'OFF'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <Info image={image_link} detail={props.detail} header={props.header} />
      )}
      {currentTab === 'activity' && <ActivityData onClick={onchildClickActivity} />}
      {currentTab === 'history' && (
        <HistoryData
          data={hist_plot}
          startDate={props.startDate}
          stopDate={props.stopDate}
          handleStartDate={props.handleStartDate}
          handleStopDate={props.handleStopDate}
        />
      )} */}
    </>
  );
};
