import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ToysIcon from '@material-ui/icons/Toys';
import KitchenIcon from '@material-ui/icons/Kitchen';
import TvIcon from '@material-ui/icons/Tv';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Container = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  min-height: 150px;
  margin: 10px 0px 10px 0px;
  padding: 15px;
  padding-bottom: 0px;
  padding-top: 5px;
  border-bottom: 0.5px solid #e3e3e3;
`;

const DayConvert = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

const ConvertTime = (datetime) => {
  let year = datetime[5];
  let month = Number(datetime[3]) < 10 ? `0${datetime[3]}` : datetime[3];
  let date = Number(datetime[2]) < 10 ? `0${datetime[2]}` : datetime[2];
  let hour = Number(datetime[1]) < 10 ? `0${datetime[1]}` : datetime[1];
  let minute = Number(datetime[0]) < 10 ? `0${datetime[0]}` : datetime[0];
  return `${year}-${month}-${date}T${hour}:${minute}`;
};

const ConvertSchedule = (datetime) => {
  let hour = Number(datetime[1]) < 10 ? `0${datetime[1]}` : datetime[1];
  let minute = Number(datetime[0]) < 10 ? `0${datetime[0]}` : datetime[0];
  return `${hour}:${minute}`;
};

const ConvertIcon = (props) => {
  return [
    'Toilet Switch',
    'Corridor Switch',
    'Main Switch',
    'Window Switch',
    'Wardrobe Switch',
    'Bed Switch',
  ].includes(props.device) ? (
    <EmojiObjectsIcon style={{ color: props.state ? '#ffe998' : '#e4e4e4' }} />
  ) : ['Smart TV'].includes(props.device) ? (
    <TvIcon style={{ color: props.state ? '#b9eae6' : '#e4e4e4' }} />
  ) : ['Refrigerator'].includes(props.device) ? (
    <KitchenIcon style={{ color: props.state ? '#b1e5ff' : '#e4e4e4' }} />
  ) : ['Air Conditioner'].includes(props.device) ? (
    <AcUnitIcon style={{ color: props.state ? '#b1e5ff' : '#e4e4e4' }} />
  ) : ['Exhaust Fan'].includes(props.device) ? (
    <ToysIcon style={{ color: props.state ? '#b9eae6' : '#e4e4e4' }} />
  ) : null;
};

export const CurrentScheduleTab = (props) => {
  const classes = useStyles();
  const remove = () => {
    props.toggleDelete(props.automationId);
  };
  return (
    <Container>
      <Wrapper>
        <Row style={{ margin: '0px' }}>
          <Col lg="10" md="10" style={{ padding: '0px' }}>
            {props.triggerTime[4] === '*' ? (
              <form className={classes.container} noValidate>
                <TextField
                  id="datetime-local"
                  label="Schedule"
                  type="datetime-local"
                  defaultValue={ConvertTime(props.triggerTime)}
                  className={classes.textField}
                />
              </form>
            ) : (
              <div>
                <form className={classes.container} noValidate>
                  <TextField
                    id="time"
                    label="Schedule"
                    type="time"
                    defaultValue={ConvertSchedule(props.triggerTime)}
                    className={classes.textField}
                  />
                </form>
                <p style={{ margin: '0px 0px 0px 5px', paddingTop: '10px' }}>
                  <strong>Repeat every: {DayConvert[props.triggerTime[4]]}</strong>
                </p>
              </div>
            )}
          </Col>
          <Col lg="2" md="2" style={{ padding: '22px 3px 3px 10px'}}>
            <DeleteForeverIcon
              style={{ fontSize: '25px', color: '#ff6262', cursor:'pointer' }}
              onClick={() => remove()}
            />
          </Col>
        </Row>
        <Row>
          <Col
            lg="12"
            md="12"
            style={{
              justifyContent: 'center',
              fontSize: '11px',
              padding: '0',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            {Object.keys(props.action).map((room, index) => (
              <Col lg="12" key={index}>
                <Row style={{ margin: '0px', marginTop: '5px', marginBottom: '10px' }}>
                  <div style={{ fontSize: '20px', marginLeft: '5px' }}>
                    <strong>{room}</strong>
                  </div>
                </Row>
                <Row style={{ margin: '0px' }}>
                  {Object.keys(props.action[room]).map((device, index) => (
                    <Col lg="12" key={index}>
                      <FormControlLabel
                        control={
                          <div style={{ margin: '0px 5px 0px 0px' }}>
                            <ConvertIcon
                              device={device}
                              state={props.action[room][device].command.state === 'on'}
                            />
                          </div>
                        }
                        label={device}
                        key={index}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
          </Col>
        </Row>
      </Wrapper>
    </Container>
  );
};
