import React, { useState } from 'react';
import { TdpkReportComponents } from '../../components';

const TdpkReportContainers = () => {
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleType = (event) => {
    setType(event.target.value);
  };

  const handleStart = (event) => {
    setStartDate(event.target.value);
  };

  const handleEnd = (event) => {
    setEndDate(event.target.value);
  };

  return (
    <div>
      <TdpkReportComponents
        type={type}
        startDate={startDate}
        endDate={endDate}
        handleType={handleType}
        handleStart={handleStart}
        handleEnd={handleEnd}
      />
    </div>
  );
};

export { TdpkReportContainers };
