import React from 'react';
import { Card, CardHeader, Col } from 'reactstrap';
import Chart from 'react-apexcharts';

const EnergyUseRatio = (props) => {
  return (
    <Col md="4">
      <Card>
        <div style={{ height: '350px' }}>
          <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
            <h5 className="card-title">{props.name}</h5>
          </CardHeader>
          <div
            style={{
              height: '80%',
              width: '100%',
              margin: '0',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Chart
              options={props.data.options}
              series={props.data.series}
              type="donut"
              height="300"
            />
          </div>
        </div>
      </Card>
    </Col>
  );
};

export { EnergyUseRatio };
