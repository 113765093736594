import React from 'react';
// import { Button, PopoverHeader, PopoverBody, Popover } from 'reactstrap';
import Tooltip from 'rc-tooltip';

const Popup = (props) => {
  return (
    <div>
      {/* <Button id="Popover1" type="button">
        Launch Popover
      </Button> */}
      {/* <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>      */}
      {/* <Popover className="max-v-100" placement="left" isOpen={props.isOpen} target="floorPlan" hideArrow={true}>
        <PopoverBody className="max-v-100">
          <img src={props.images} alt="cctv" width="700px" height="550px" />
        </PopoverBody>
      </Popover> */}
      <Tooltip placement="left" visible={props.isOpen}>
        <img style={{position:'fixed', bottom:'0', right:'0'}} src={props.images} alt="cctv" width="500px" height="350px" />
      </Tooltip>
    </div>
  );
};

export { Popup };
