import { NAV } from '../types';

const INIT_STATE = {
  starterUrl: null,
  focusPage: '/login',
  navigateToPage: null,
  previousPage: null,
};

const nav = (state = INIT_STATE, action) => {
  switch (action.type) {
    //##-----------------------------------------------------
    case NAV.NAV_PAGE_START:
      return {
        ...state,
        previousPage: action.currentPage,
        navigateToPage: action.targetPage,
      };
    case NAV.NAV_PAGE_FOCUS:
      return {
        ...state,
        focusPage: action.focusPage,
      };
    //##-----------------------------------------------------
    default:
      return state;
  }
};

export { nav };
