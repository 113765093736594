import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { RoomSetting } from './RoomSetting';
import { TimeSetting } from './TimeSetting';
import { DeviceSetting } from './DeviceSetting';
import { DeviceList } from './DeviceList';
import Select from 'react-select';
import { Row, Col, Card, CardTitle, CardBody, CardHeader } from 'reactstrap';
import { CurrentScheduleTab } from './CurrentScheduleTab';
import { ScheduleRooms } from '../../List/fileLists.component';
import { hotelActions } from '../../../../hotel/actions';
import { useDispatch, useSelector } from 'react-redux';
import { automation } from 'api/services';
import toast, { Toaster } from 'react-hot-toast';

const Space = styled.div`
  margin-top: 0px;
  height: 100vh;
  margin-left: 0;
  margin-right: 0;
  font-family: Montserrat;
`;

const notify = (text) => toast(text);

export const ScheduleSetting = () => {
  const [settingRoom, setSettingRoom] = useState(null);
  const [datetime, setDatetime] = useState(null);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.hotel.allDevices);
  const [scheduleDevices, setScheduleDevices] = useState(null);
  const [selectedRoomNameOfSchedule, setSelectedRoomNameOfSchedule] = useState('201');
  const [selectedDeviceList, setSelectedDeviceList] = useState([]);
  const [repeatWeek, setRepeatWeek] = useState(false);
  const [deviceStates, setDeviceStates] = useState(null);

  const noOfSubdevices = {
    front_switch: 3,
    back_switch: 3,
    ac: 1,
    refrigerator: 1,
    smart_tv: 1,
    fan: 1,
    broadlinkenv: 0,
    smart_meter: 0,
  };

  const selectRoom = (e) => {
    setSettingRoom(e);
    setSelectedDeviceList([]);
  };

  // Convert cron by Calendar picker
  const cronconversion = (datestring, repeat) => {
    let datetime = new Date(Date.parse(datestring));
    let minute = String(datetime.getMinutes()); // 28
    let hour = String(datetime.getHours()); // 14
    let date = String(datetime.getDate());
    let day = String(datetime.getDay());
    let month = String(datetime.getMonth() + 1);
    let year = String(datetime.getFullYear());
    if (!repeat) {
      return { cron: [minute, hour, date, month, '*', year] };
    } else {
      return { cron: [minute, hour, '*', '*', day, '*'] };
    }
  };

  // Wrap state into room
  const addStateToRoom = (stateData) => {
    return { [`room_${settingRoom.value}`]: stateData };
  };

  // Post automation
  const postNewAutomation = async () => {
    if (datetime) {
      automation
        .postAutomation(
          'schedule',
          cronconversion(datetime, repeatWeek),
          { condition_event: '', condition_value: '' },
          addStateToRoom(
            Object.keys(deviceStates)
              .filter((key) => selectedDeviceList.includes(key))
              .reduce((obj, key) => {
                obj[key] = deviceStates[key];
                return obj;
              }, {})
          )
        )
        .then(() => {
          notify(`Set schedule configuration successfully`);
          queryAutomation();
        });
    } else {
      notify('Please complete the form.');
    }
  };

  // Select device which we want to set the schedule
  const selectDevice = (data) => {
    let itemToBeRemoved = Array(data);
    var filteredArray = scheduleDevices.filter((item) => !itemToBeRemoved.includes(item));
    setScheduleDevices(filteredArray);
    setSelectedDeviceList([...selectedDeviceList, data]);
  };

  // Cancel selecting devices
  const removeDevice = (data) => {
    let itemToBeRemoved = Array(data);
    var filteredArray = selectedDeviceList.filter((item) => !itemToBeRemoved.includes(item));
    setSelectedDeviceList(filteredArray);
    setScheduleDevices([...scheduleDevices, data]);
  };

  // Add command into selected subdevices
  const assignCheckState = (ListOfDevices, checkState) => {
    let assignedCheckState = {};
    ListOfDevices.forEach((device) => {
      if (checkState === 'check_in' || checkState === 'schedule') {
        if (device !== 'Air Conditioner') {
          assignedCheckState[device] = { command: { state: 'on' } };
        } else {
          assignedCheckState[device] = {
            command: { state: 'on', mode: 'cool', temp: 25, fan: 'medium' },
          };
        }
      } else {
        if (device !== 'Air Conditioner') {
          assignedCheckState[device] = { command: { state: 'off' } };
        } else {
          assignedCheckState[device] = { command: { state: 'off', mode: 'off' } };
        }
      }
    });
    return assignedCheckState;
  };

  const queryAutomation = async () => {
    automation.getAutomation('trigger_type', 'schedule').then((array) => {
      if (array) {
        setCurrentSchedule(array.data.automations);
      }
    });
  };

  const toggleDelete = async (id) => {
    let response = await automation.deleteAutomation(id);
    await console.log(response);
    await notify(`Delete successfully`);
    await queryAutomation();
  };

  //1. Get and Display current automation settings
  useEffect(() => {
    queryAutomation();
  }, []);

  //2. Get devices from GET: Devices by roomNO
  useMemo(() => {
    if (settingRoom) {
      dispatch(hotelActions.getAllDevices(settingRoom.value, 'mintel'));
    }
    // eslint-disable-next-line
  }, [settingRoom]);

  //3. Assign value into state by available subdevices in room
  useMemo(() => {
    let ListOfSubdevices = [];
    if (devices) {
      devices.devices.forEach((device) => {
        for (let i = 0; i < noOfSubdevices[device.device_name]; i++) {
          if (device.subdevices) {
            ListOfSubdevices.push(device.subdevices[i].nickname);
          }
        }
      });
      setScheduleDevices(ListOfSubdevices);
    }
    setDeviceStates(assignCheckState(ListOfSubdevices, 'schedule'));
    // eslint-disable-next-line
  }, [devices]);

  return (
    <div style={{ margin: '25px 0px 0px 0px' }}>
      <Row style={{ margin: '0px' }}>
        <Col lg="12" md="12" sm="12" style={{ marginLeft: '0px', padding: '0' }}>
          <Space>
            <Row style={{ margin: '0px' }}>
              <Col lg="8" style={{ padding: '0' }}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h3" style={{ marginLeft: '15px' }}>
                      Setting Schedule
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="overflow-auto">
                    <div style={{ minHeight: '500px', width: '100%' }}>
                      <RoomSetting
                        settingRoom={settingRoom}
                        setSettingRoom={(e) => selectRoom(e)}
                      />

                      <TimeSetting
                        datetime={datetime}
                        changeDatetime={(e) => setDatetime(e)}
                        repeatValue={repeatWeek}
                        setRepeatWeek={() => setRepeatWeek(!repeatWeek)}
                      />
                      {scheduleDevices ? (
                        <Row>
                          <Col lg="4">
                            <DeviceList
                              scheduleDevices={scheduleDevices}
                              selectDevice={(data) => selectDevice(data)}
                            />
                          </Col>
                          {selectedDeviceList.length !== 0 ? (
                            <Col lg="7" style={{ paddingTop: '50px' }}>
                              <DeviceSetting
                                selectedDeviceList={selectedDeviceList}
                                deviceStates={deviceStates}
                                setDeviceStates={(data) => setDeviceStates(data)}
                                sendRequest={() => postNewAutomation()}
                                removeDevice={(data) => removeDevice(data)}
                              />
                            </Col>
                          ) : null}
                        </Row>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" style={{ paddingRight: '0px' }}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Current Schedule{' '}
                      {currentSchedule ? `(${Object.keys(currentSchedule).length})` : null}
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="overflow-auto">
                    <Row style={{ margin: '0px', minHeight: '300px', maxHeight: '500px' }}>
                      <div style={{ marginLeft: '5px', marginTop: '5px', width: '40%' }}>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="repeatSelect"
                          closeMenuOnSelect={false}
                          value={selectedRoomNameOfSchedule}
                          onChange={(value) => setSelectedRoomNameOfSchedule(value.value)}
                          options={ScheduleRooms}
                          placeholder={selectedRoomNameOfSchedule}
                        />
                      </div>
                      <Col lg="12" md="12" style={{ padding: '0px' }}>
                        {currentSchedule &&
                          (currentSchedule.length !== 0 ? (
                            currentSchedule.map((schedule, index) => (
                              <div key={index}>
                                {Object.keys(schedule.action)[0] ===
                                  `room_${selectedRoomNameOfSchedule}` && (
                                  <CurrentScheduleTab
                                    key={index}
                                    triggerTime={schedule.trigger.trigger_time.cron}
                                    action={schedule.action}
                                    automationId={schedule.automation_id}
                                    toggleDelete={(id) => toggleDelete(id)}
                                  />
                                )}
                              </div>
                            ))
                          ) : (
                            <div style={{ margin: '10px' }}>
                              No configuration in room {selectedRoomNameOfSchedule}
                            </div>
                          ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Space>
        </Col>
        <Toaster />
      </Row>
    </div>
  );
};
