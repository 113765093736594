import { combineReducers } from 'redux';

// import { authentication } from './authentication.reducer';
// import { userData } from './user-data.reducer';
// import { alert } from './alert.reducer';
// import { meterData } from './meter-data.reducer';

import { root } from '../features/root/reducers';
import { auth } from '../features/auth/reducers';
import { nav } from '../features/nav/reducers';
import { user } from '../features/user/reducers';
import { line } from '../features/line/reducers';
import { hotel } from '../features/hotel/reducers';
import { dashboard } from '../features/dashboard/reducers';
import { energy, historyEnergy, energyTabSelect, testEnergy } from '../features/energy/reducers';
import { tdpk } from '../features/dashboard/containers/TDPK/reducers';

const rootReducer = combineReducers({
  auth,
  root,
  nav,
  user,
  line,
  hotel,
  dashboard,
  energy,
  tdpk,
  historyEnergy,
  energyTabSelect,
  testEnergy,
});

export { rootReducer };
