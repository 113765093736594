import React from 'react';
import { Card, Row, Col, Table } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { DatePickers } from '../../../components';

export const EnergyComponent = (props) => {
  return (
    <div className="content">
      <Row>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td colSpan="2">
                          <b>This month E-bill</b>{' '}
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>158,000</td>
                        <td>Bath</td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <Col md="12">ON PEAK</Col>
                          <Col md="12">
                            <b style={{ color: 'red' }}>94,800</b> Bath
                          </Col>
                        </td>
                        <td>
                          <Col md="12">OFF PEAK</Col>
                          <Col md="12">
                            <b style={{ color: 'blue' }}>63,200</b> Bath
                          </Col>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b>Electricity Rate </b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b style={{ color: 'red' }}>On peak: 4.2097 </b>
                          <br />
                          (M-F 9am - 10pm)
                          <br />
                          <br />
                          <b style={{ color: 'blue' }}>On peak: 4.2097 </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b>This month E-bill forecast</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <br />
                          <h4 style={{ color: 'orange' }}>214,200 Baht</h4>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Chart
                options={props.main_graph.options}
                series={props.main_graph.series}
                type="line"
                width="100%"
                height="300px"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {/* <Col lg="4" sm="6">
          From <DatePicker selected={daySelectStart} onChange={(date) => setDaySelectStart(date)} />
        </Col>
        <Col lg="4" sm="6">
          To <DatePicker selected={daySelectStop} onChange={(date) => setDaySelectStop(date)} />
        </Col> */}

        <Col md={12} style={{ padding: 5, textAlign: 'center' }}>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="1" md="6" sm="6" style={{ textAlign: 'center', alignContent: 'center' }}>
          <div style={{ textAlign: 'center', alignContent: 'center' }}>Baseline (Monthly)</div>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b>kWh per room-night</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b style={{ color: 'gray' }}>34</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>(15% above avg)</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="2" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b>
                            kWh per m<sup>2</sup>
                          </b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b style={{ color: 'gray' }}>38.42</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>(20% above avg)</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b>Peak Power (kW)</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b style={{ color: 'gray' }}>85</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>(8% above avg)</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="3" md="6" sm="6">
          <Card className="card-stats" style={{ borderRadius: '0' }}>
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b>
                            CO<sub>2</sub> Emission (kgCO<sub>2</sub>e)
                          </b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b style={{ color: 'gray' }}>39,604</b>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>(10% above avg)</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
