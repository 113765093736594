import React from 'react';
import Sketch from 'react-p5';

const MockUpRoomServiceTabComponent = () => {
  /* p5.js used in room service part */
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(21, 21).parent(canvasParentRef);
  };

  const draw = (p5) => {
    p5.rect(20, 20, 20, 20);
    p5.background('#62B76F');
    p5.text('1', 7, 15).fill('white');
  };

  return (
    <div className="tab-content column cgroup-1" id="room-service">
      <center>
        <table
          className="table"
          style={{
            borderCollapse: 'collapse',
            width: '80%',
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th>รายการอาหาร</th>
              <th
                style={{
                  textAlign: 'right',
                }}
              >
                ราคา
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Sketch
                  setup={setup}
                  draw={draw}
                  style={{
                    marginTop: '5%',
                  }}
                />
              </td>
              <td>กระเพราหมูสับ</td>
              <td
                style={{
                  textAlign: 'right',
                }}
              >
                100 บาท
              </td>
            </tr>
            <tr>
              <td>
                <Sketch
                  setup={setup}
                  draw={draw}
                  style={{
                    marginTop: '5%',
                  }}
                />
              </td>
              <td>ข้าวหมูกระเทียม</td>
              <td
                style={{
                  textAlign: 'right',
                }}
              >
                80 บาท
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th />
              <th
                style={{
                  fontWeight: 'bold',
                }}
              >
                ทั้งหมด (2 รายการ)
              </th>
              <th
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                }}
              >
                180 บาท
              </th>
            </tr>
          </tfoot>
        </table>
      </center>
    </div>
  );
};

export { MockUpRoomServiceTabComponent };
