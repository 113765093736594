import axios from 'axios';
import { API_CONSTANTS } from '../';

async function tdpkAirAPI(device_name, temp, mode, fan) {
  //##-------------------------Payload-----------------------------------

  const token = localStorage.getItem('token');

  const payload = {
    topic: 'devicecontrol',
    action: {
      '6C1': {
        [device_name]: {
          command: {
            mode: mode,
            fan: fan,
            temperature: temp,
          },
        },
      },
    },
    RequestId: '12345',
  };

  // console.log(payload.message, payload.device);

  //##------------------------request to API---------------------------
  const requestOptions = {
    url: API_CONSTANTS.DEVICES_CONTROL_URL,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: JSON.stringify(payload),
  };
  console.log(requestOptions)
  // API
  try {
    let responseJson = await axios.request(requestOptions);
    console.log(responseJson.data);
    console.log('AC control API ########');
  } catch {
    console.log('Request API error');
  }
}

export const TDPK_API = {
  tdpkAirAPI,
};
