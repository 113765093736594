import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { FirebaseContext2 } from '../../../../../firebase';
import { List } from '../../../../../components/List/TabList';
import { Info } from '../../../../hotel/components/Tabs';
import { SYN_API } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';

export const AcControl = () => {
  const dispatch = useDispatch();
  const synDeviceControl = useSelector((state) => state.dashboard.syn_devices_control);

  const [currentTab, setCurrentTab] = useState('control');

  const timeoutid = useRef();
  //##----------------------Close Modal------------------------------
  const onCloseModal = (event) => {
    event.preventDefault();

    dispatch(dashboardControlActions.hideSynACControlModal());
  };

  const firebase = useContext(FirebaseContext2);
  const triggerAPI = SYN_API.SYNAirAPI;

  //##----------------Firebase PATH----------------------------
  const main_path = API_CONSTANTS.FIREBASE_SYN_REAL_TIME_PATH;

  //#--------------------Constant------------------------------
  const [temp, setTemp] = useState(null);
  const [envTemp, setEnvTemp] = useState(null);
  const [mainSwitch, setMainSwitch] = useState(null);

  const [mode, setMode] = useState(null);
  const [settemp, setSettemp] = useState(null);
  const [status, setStatus] = useState(null);
  const [fanMode, setFanMode] = useState(null);

  const [info, setInfo] = useState({
    info: {
      ip: null,
      port: null,
      presence: null,
      date_time: null,
      error: null,
    },
  });

  const [currentMode, setCurrentMode] = useState(null);

  //##-------------------Fetch data from firebase----------------
  async function fetchData(firebase, currentDevice, didMounted) {
    let fbpath =
      main_path + 'syn_lobby/iot_devices/airconet_' + currentDevice + '/data/status/data/';

    let fan_path = fbpath + 'fan';
    let mode_path = fbpath + 'mode';
    let status_path = fbpath + 'status';
    let room_temp_path = fbpath + 'temperature';
    let settemp_path = fbpath + 'settemp';

    if (didMounted) {
      console.log('SYN AC did mounted');
      await firebase.db.ref(fan_path).off('value');
      await firebase.db.ref(mode_path).off('value');
      await firebase.db.ref(status_path).off('value');
      await firebase.db.ref(room_temp_path).off('value');
      await firebase.db.ref(settemp_path).off('value');
      await firebase.db.ref(fbpath).off('value');
    } else {
      await firebase.db.ref(fan_path).on('value', function (snap) {
        let capt = snap.val();
        setFanMode(capt);
      });
      await firebase.db.ref(mode_path).on('value', function (snap) {
        let capt = snap.val();
        setMode(capt);
      });
      await firebase.db.ref(status_path).on('value', function (snap) {
        let capt = snap.val();
        setStatus(capt);
      });
      await firebase.db.ref(room_temp_path).on('value', function (snap) {
        let capt = snap.val();
        setEnvTemp(capt);
      });
      await firebase.db.ref(settemp_path).on('value', function (snap) {
        let capt = snap.val();
        setSettemp(capt);
      });
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        setInfo({
          info: {
            ip: capt.ip,
            port: capt.port,
            presence: capt.presence,
            date_time: capt.datetime,
            error: capt.error,
          },
        });
      });
    }
  }

  //##-----------------------Sync Firebase only-----------------
  useEffect(() => {
    fetchData(firebase, synDeviceControl[0], false);

    return () => fetchData(firebase, synDeviceControl[0], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##----------------------Set status value-----------------------
  useEffect(() => {
    initState(mode, fanMode, settemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, fanMode, settemp]);

  //##-----------------Sync status from firebase------------------
  const initState = (mode, fan, temp) => {
    if (status === 'ON') {
      setMainSwitch(true);
    } else {
      setMainSwitch(false);
    }
    setCurrentMode(mode);
    setTemp(temp);
  };

  //##--------------------ON / OFF--------------------------------
  const onClick = (event, value) => {
    event.preventDefault();
    setMainSwitch((prev) => !prev);
    if (value === 'on/off') {
      triggerAPI(synDeviceControl[1], temp, !mainSwitch ? 'cool' : 'off', fanMode);
    }
  };

  //##----------------------Set Temp---------------------------
  const onSettemp = (event, value) => {
    event.preventDefault();
    if (value === 'increment') {
      setTemp(temp + 1);
      clearTimeout(timeoutid.current);
      if (currentMode !== null && fanMode !== null && temp !== null) {
        timeoutid.current = setTimeout(
          () =>
            triggerAPI(
              synDeviceControl[1],
              temp + 1,
              currentMode,
              fanMode,
              mainSwitch ? 'ON' : 'OFF'
            ),
          700
        );
      }
      return () => clearTimeout(timeoutid.current);
    }
    if (value === 'decrement') {
      setTemp(temp - 1);
      clearTimeout(timeoutid.current);
      if (currentMode !== null && fanMode !== null && temp !== null) {
        timeoutid.current = setTimeout(
          () =>
            triggerAPI(
              synDeviceControl[1],
              temp - 1,
              currentMode,
              fanMode,
              mainSwitch ? 'ON' : 'OFF'
            ),
          700
        );
      }
      return () => clearTimeout(timeoutid.current);
    }
  };

  //##-----------------------Fan Mode--------------------------
  const onClickFanMode = (event, value) => {
    event.preventDefault();
    console.log({ value });
    setFanMode(value);

    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(synDeviceControl[1], temp, currentMode, value, mainSwitch ? 'ON' : 'OFF'),
        1000
      );
    }

    return () => clearTimeout(timeoutid.current);
  };

  //##------------------------Mode Control---------------------
  const onClickControlMode = (event, value) => {
    event.preventDefault();
    if (value !== 'fan') {
      setMode(value);
      clearTimeout(timeoutid.current);
      if (currentMode !== null && fanMode !== null && temp !== null) {
        timeoutid.current = setTimeout(
          () => triggerAPI(synDeviceControl[1], temp, value, fanMode, mainSwitch ? 'ON' : 'OFF'),
          1500
        );
      }

      return () => clearTimeout(timeoutid.current);
    } else {
      setMode(value);
      setFanMode('medium');
      clearTimeout(timeoutid.current);
      if (currentMode !== null && fanMode !== null && temp !== null) {
        timeoutid.current = setTimeout(
          () => triggerAPI(synDeviceControl[1], temp, value, fanMode, mainSwitch ? 'ON' : 'OFF'),
          1500
        );
      }

      return () => clearTimeout(timeoutid.current);
    }
  };

  //##-------------------Tab menu------------------------------
  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };

  const image_link =
    'https://www.wolseley.co.uk/wcsstore7.00.1066.986/ExtendedSitesCatalogAssetStore/images/products/AssetPush/wol-web-proof-pdp-w/std.lang.all/79/47/611099_697947_wpdp.jpg';

  const header = ['IP', 'Port', 'Presence', 'Date Time', 'Error'];

  const detail = [
    info.info.ip,
    info.info.port,
    info.info.presence,
    info.info.date_time,
    info.info.error,
  ];

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title" id="iot-modal-title">
            Air Condition Control
          </p>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          <div id="ucount-w">
            <div id="modal-menu">
              <nav className="panel">
                <div className="tabs is-toggle is-fullwidth">
                  <ul style={{ margin: 0, padding: 10 }}>
                    <List
                      isSelected={currentTab === 'control'}
                      onClick={(e) => onClickTabMenu(e, 'control')}
                    >
                      CONTROL
                    </List>
                    <List
                      isSelected={currentTab === 'info'}
                      onClick={(e) => onClickTabMenu(e, 'info')}
                    >
                      INFO
                    </List>
                    <List
                      isSelected={currentTab === 'activity'}
                      onClick={(e) => onClickTabMenu(e, 'activity')}
                    >
                      ACTIVITY
                    </List>
                    <List
                      isSelected={currentTab === 'history'}
                      onClick={(e) => onClickTabMenu(e, 'history')}
                    >
                      HISTORY
                    </List>
                  </ul>
                </div>
                {currentTab === 'control' && (
                  <div className="iot-element" id="iot-control-air">
                    <div className="columns">
                      <div
                        className="column"
                        style={{
                          border: '1px #aaa solid',
                          borderRadius: '5px',
                          margin: '5px',
                        }}
                      >
                        <div
                          className="column"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Status
                        </div>
                        <div className="column">
                          <img
                            alt="remote"
                            id="iot-device-icon"
                            src="/assets/images/devices/air-conditioner.png"
                            style={{
                              width: '100px',
                              height: 'auto',
                              display: 'block',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                          />
                        </div>
                        <div className="column">
                          <button
                            className={
                              mainSwitch
                                ? 'button is-success device-controller'
                                : 'button is-unsuccess device-controller'
                            }
                            style={{
                              width: '100px',
                              height: 'auto',
                              display: 'block',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                            }}
                            onClick={(e) => onClick(e, 'on/off')}
                          >
                            {!mainSwitch ? 'OFF' : 'ON'}
                          </button>
                        </div>
                      </div>
                      <div
                        className="column"
                        style={{
                          border: '1px #aaa solid',
                          borderRadius: '5px',
                          margin: '5px',
                        }}
                      >
                        <div
                          className="column"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Set Temp
                        </div>
                        {/* increte button */}
                        <button
                          className="button is-info"
                          style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            paddingTop: '0px',
                          }}
                          // onClick={() => setTemp(temp + 1)}
                          onClick={(e) => onSettemp(e, 'increment')}
                        >
                          <i
                            className="fa fa-caret-up"
                            style={{
                              color: 'white',
                              fontSize: '44px',
                              lineHeight: '40px',
                            }}
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div className="column">
                          <span
                            style={{
                              fontSize: '44px',
                              color: '#0af',
                              fontWeight: 'bold',
                            }}
                          >
                            {/* {status.air_con.set_temp } */}
                            {temp}
                          </span>
                          <span
                            style={{
                              fontSize: '26px',
                              color: '#aaa',
                            }}
                          >
                            ºC
                          </span>
                        </div>
                        {/* decrete button */}
                        <button
                          className="button is-info"
                          style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            paddingTop: '0px',
                          }}
                          // onClick={() => setTemp(temp - 1)}
                          onClick={(e) => onSettemp(e, 'decrement')}
                        >
                          <i
                            className="fa fa-caret-down"
                            style={{
                              color: 'white',
                              fontSize: '44px',
                              lineHeight: '40px',
                            }}
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                      <div
                        className="column"
                        style={{
                          border: '1px #aaa solid',
                          borderRadius: '5px',
                          margin: '5px',
                        }}
                      >
                        <div
                          className="column"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Room Temp
                        </div>
                        <div
                          className="column"
                          style={{
                            marginTop: '40px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '46px',
                              color: '#0af',
                              fontWeight: 'bold',
                            }}
                          >
                            {envTemp}
                          </span>{' '}
                          <span
                            style={{
                              fontSize: '26px',
                              color: '#aaa',
                            }}
                          >
                            ºC
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div
                        className="column"
                        style={{
                          border: '1px #aaa solid',
                          borderRadius: '5px',
                          margin: '5px',
                        }}
                      >
                        <div
                          className="column"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Mode Control
                        </div>
                        <div className="columns">
                          <div className="column">
                            <button
                              value="auto"
                              className={currentMode === 'auto' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickControlMode(e, 'auto')}
                            >
                              Auto
                            </button>
                            <button
                              value="cool"
                              className={currentMode === 'cool' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickControlMode(e, 'cool')}
                            >
                              Cool
                            </button>
                            <button
                              value="dry"
                              className={currentMode === 'dry' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickControlMode(e, 'dry')}
                            >
                              Dry
                            </button>
                            <button
                              value="fan"
                              className={currentMode === 'fan' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickControlMode(e, 'fan')}
                            >
                              Fan
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="column"
                        style={{
                          border: '1px #aaa solid',
                          borderRadius: '5px',
                          margin: '5px',
                        }}
                      >
                        <div
                          className="column"
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Fan Speed
                        </div>
                        <div className="columns">
                          <div className="column">
                            {currentMode === 'fan' ? (
                              ''
                            ) : (
                              <button
                                value="auto"
                                className={fanMode === 'auto' ? 'button is-info' : 'button'}
                                onClick={(e) => onClickFanMode(e, 'auto')}
                              >
                                Auto
                              </button>
                            )}
                            <button
                              value="low"
                              className={fanMode === 'low' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickFanMode(e, 'low')}
                            >
                              Low
                            </button>
                            <button
                              value="medium"
                              className={fanMode === 'medium' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickFanMode(e, 'medium')}
                            >
                              Medium
                            </button>
                            <button
                              value="high"
                              className={fanMode === 'high' ? 'button is-info' : 'button'}
                              onClick={(e) => onClickFanMode(e, 'high')}
                            >
                              High
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentTab === 'info' && (
                  <Info image={image_link} detail={detail} header={header} />
                )}
                {/* {currentTab === 'activity' && <Activity />} */}
                {currentTab === 'activity' && <h1>" To be developed"</h1>}
                {/* {currentTab === 'history' && <HistoryData />} */}
                {currentTab === 'history' && <h1>" To be developed"</h1>}
              </nav>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <div
            className="column is-fullwidth"
            style={{ padding: 0, textAlign: 'right', margin: 0 }}
          >
            <button onClick={(e) => onCloseModal(e)} className="btn btn-primary">
              Done
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};
