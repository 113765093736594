export const MapCam = [
  // [type, mac, x1, y1, x2, y2]
  {
    name: 'cam1',
    x1: 0.05786163522012579,
    y1: 0.16275167785234898,
    x2: 0.09559748427672957,
    y2: 0.21140939597315436,
    triggers: ['C0601', 'cam_1', 'cam'],
  },
  {
    name: 'cam2',
    x1: 0.2893081761006289,
    y1: 0.1593959731543624,
    x2: 0.32955974842767294,
    y2: 0.21308724832214765,
    triggers: ['C0612', 'cam_2', 'cam'],
  },
  {
    name: 'cam3',
    x1: 0.8805031446540881,
    y1: 0.1610738255033557,
    x2: 0.919496855345912,
    y2: 0.21476510067114093,
    triggers: ['C0611', 'cam_3', 'cam'],
  },
  {
    name: 'cam4',
    x1: 0.0779874213836478,
    y1: 0.4513422818791946,
    x2: 0.11572327044025157,
    y2: 0.511744966442953,
    triggers: ['C0603', 'cam_4', 'cam'],
  },
  {
    name: 'cam5',
    x1: 0.8591194968553459,
    y1: 0.5335570469798657,
    x2: 0.8955974842767296,
    y2: 0.5906040268456376,
    triggers: ['C0604', 'cam_5', 'cam'],
  },
  {
    name: 'cam6',
    x1: 0.6497134670487106,
    y1: 0.8336520076481836,
    x2: 0.6755014326647565,
    y2: 0.8680688336520076,
    triggers: ['C0602', 'cam_6', 'cam'],
  },
];
