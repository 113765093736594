export const MapAir = [
  //Map Done
  {
    name: 'air1',
    x1: 0.13962264150943396,
    y1: 0.09228187919463088,
    x2: 0.17484276729559747,
    y2: 0.14429530201342283,
    triggers: ['T-6C1F-01', 'airconditioning_1', 'air'],
  },
  //Map Done
  {
    name: 'air2',
    x1: 0.2339622641509434,
    y1: 0.3187919463087248,
    x2: 0.26163522012578616,
    y2: 0.35906040268456374,
    triggers: ['T-6C1F-02', 'airconditioning_2', 'air'],
  },
  //Map Done
  {
    name: 'air3',
    x1: 0.38113207547169814,
    y1: 0.313758389261745,
    x2: 0.41132075471698115,
    y2: 0.3573825503355705,
    triggers: ['T-6C1F-03', 'airconditioning_3', 'air'],
  },
  //Map Done
  {
    name: 'air4',
    x1: 0.5446540880503145,
    y1: 0.313758389261745,
    x2: 0.5723270440251572,
    y2: 0.3573825503355705,
    triggers: ['T-6C1F-04', 'airconditioning_4', 'air'],
  },
  //Map Done
  {
    name: 'air5',
    x1: 0.710691823899371,
    y1: 0.31208053691275167,
    x2: 0.740880503144654,
    y2: 0.35570469798657717,
    triggers: ['T-6C1F-05', 'airconditioning_5', 'air'],
  },
  //Map Done
  {
    name: 'air6',
    x1: 0.8477987421383648,
    y1: 0.313758389261745,
    x2: 0.8767295597484277,
    y2: 0.35570469798657717,
    triggers: ['T-6C1F-06', 'airconditioning_6', 'air'],
  },
  //Map Done
  {
    name: 'air7',
    x1: 0.9308176100628931,
    y1: 0.5486577181208053,
    x2: 0.9723270440251572,
    y2: 0.6073825503355704,
    triggers: ['T-6C1F-07', 'airconditioning_7', 'air'],
  },
];
