import React, { useState, useMemo, useRef, useContext } from 'react';
import { Menu } from './Tabs/Menu';
import { Row, Col, Card } from 'react-bootstrap';
import { TDPK_API } from '../../../../../../api/services';
import { FirebaseContext2 } from '../../../../../../firebase';

export const Ac = (props) => {
  //##---------------------Constant-------------------------------
  const timeoutid = useRef();
  const firebase = useContext(FirebaseContext2);

  const [mode, setMode] = useState(null);
  const [fan, setFan] = useState(null);
  const [tempValue, setTempValue] = useState(null);
  // const [timeStamp, setTimeStamp] = useState(null);

  const [currentMode, setCurrentMode] = useState(null);
  const [fanMode, setFanMode] = useState(null);
  const [temp, setTemp] = useState(null);
  const [envTemp, setEnvTemp] = useState(null);
  const [currentTab, setCurrentTab] = useState('control');

  async function fetchData(firebase, device_id, didMounted) {
    let main_path =
      'publicspace/tdpk/pegasus/lobby_61/iot_devices/TDPK_' + device_id + '/status/subdev_0/';

    const room_temp = main_path + 'room_temperature';
    const set_temp = main_path + 'temperature';
    const mode = main_path + 'mode';
    const fan = main_path + 'fan';
    const timestamp = main_path + 'timestamp';

    if (didMounted) {
      console.log('TDPK AC did mounted');
      await firebase.db.ref(room_temp).off('value');
      await firebase.db.ref(set_temp).off('value');
      await firebase.db.ref(mode).off('value');
      await firebase.db.ref(fan).off('value');
      await firebase.db.ref(timestamp).off('value');
    } else {
      console.log('Begin to initial data');
      await firebase.db.ref(mode).on('value', function (snap) {
        let capt = snap.val();
        setMode(capt);
      });
      await firebase.db.ref(fan).on('value', function (snap) {
        let capt = snap.val();
        setFan(capt);
      });
      await firebase.db.ref(set_temp).on('value', function (snap) {
        let capt = snap.val();
        setTempValue(capt);
      });
      await firebase.db.ref(room_temp).on('value', function (snap) {
        let capt = snap.val();
        setEnvTemp(capt);
      });
      // await firebase.db.ref(timestamp).on('value', function (snap) {
      //   let capt = snap.val();
      //   setTimeStamp(capt);
      // });
    }
  }

  function initState(mode, fan, temp) {
    setCurrentMode(mode);
    setFanMode(fan);
    setTemp(temp);
  }

  // console.log(props.airNum);
  //##----------------------------Fetch Firebase--------------------------
  useMemo(() => {
    fetchData(firebase, props.airNum, false);
    return () => fetchData(firebase, props.airNum, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##------------------------Initial Button state------------------------
  useMemo(() => {
    initState(mode, fan, tempValue);
    console.log(mode, fan, tempValue);
  }, [mode, fan, tempValue]);

  //##------------------------Mode---------------------------------------
  const onClickControlMode = (e, type) => {
    e.preventDefault();
    setCurrentMode(type);
    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(
        () => TDPK_API.tdpkAirAPI(props.airNum, temp, type, fanMode),
        1000
      );
    }

    return () => clearTimeout(timeoutid.current);
  };

  //##------------------------Fan----------------------------------------
  const onClickFanMode = (e, type) => {
    e.preventDefault();
    setFanMode(type);
    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(
        () => TDPK_API.tdpkAirAPI(props.airNum, temp, currentMode, type),
        1000
      );
    }

    return () => clearTimeout(timeoutid.current);
  };

  //##-----------------------Set temp-----------------------------------
  const onSettemp = (e, type) => {
    e.preventDefault();
    switch (type) {
      case 'increment':
        setTemp(temp + 0.5);
        clearTimeout(timeoutid.current);
        if (currentMode !== null && fanMode !== null && temp !== null) {
          timeoutid.current = setTimeout(
            () => TDPK_API.tdpkAirAPI(props.airNum, temp + 0.5, currentMode, fanMode),
            1000
          );
        }

        return () => clearTimeout(timeoutid.current);

      case 'decrement':
        setTemp(temp - 0.5);
        clearTimeout(timeoutid.current);
        if (currentMode !== null && fanMode !== null && temp !== null) {
          timeoutid.current = setTimeout(
            () => TDPK_API.tdpkAirAPI(props.airNum, temp - 0.5, currentMode, fanMode),
            1000
          );
        }
        return () => clearTimeout(timeoutid.current);
      default:
        return temp;
    }
  };

  return (
    <div>
      <Menu currentTab={(e) => setCurrentTab(e)} />

      {currentTab === 'control' && (
        <>
          <div className="columns">
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Status
              </div>
              <div className="column">
                <img
                  alt="remote"
                  id="iot-device-icon"
                  src="https://www.carrier.co.th/products/carrier/images/40vct.jpg"
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              </div>
              <div className="column" style={{ textAlign: 'center' }}>
                {currentMode === 'off' ? (
                  <h1 style={{ color: 'gray' }}>OFF</h1>
                ) : (
                  <h1 style={{ color: 'green' }}>ON</h1>
                )}
              </div>
            </div>
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              {currentMode === 'fan' ? (
                <>
                  <div
                    className="column"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Fan Mode
                  </div>
                  <br />
                  <br /> <p style={{ textAlign: 'center' }}>Can not control temperature</p>{' '}
                </>
              ) : (
                <>
                  {' '}
                  <div
                    className="column"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Set Temp
                  </div>
                  {/* increte button */}
                  {currentMode === 'off' ? (
                    ''
                  ) : (
                    <button
                      className="button is-info"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0px',
                      }}
                      // onClick={() => setTemp(temp + 1)}
                      onClick={(e) => onSettemp(e, 'increment')}
                    >
                      <i
                        className="fa fa-caret-up"
                        style={{
                          color: 'white',
                          fontSize: '44px',
                          lineHeight: '40px',
                        }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  <div className="column">
                    <span
                      style={{
                        fontSize: '44px',
                        color: '#0af',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* {status.air_con.set_temp } */}
                      {currentMode === 'off' ? '' : parseFloat(temp).toFixed(1)}
                    </span>{' '}
                    <span
                      style={{
                        fontSize: '26px',
                        color: '#aaa',
                      }}
                    >
                      {currentMode === 'off' ? '' : 'ºC'}
                    </span>
                  </div>
                  {/* decrete button */}
                  {currentMode === 'off' ? (
                    ''
                  ) : (
                    <button
                      className="button is-info"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0px',
                      }}
                      // onClick={() => setTemp(temp - 1)}
                      onClick={(e) => onSettemp(e, 'decrement')}
                    >
                      <i
                        className="fa fa-caret-down"
                        style={{
                          color: 'white',
                          fontSize: '44px',
                          lineHeight: '40px',
                        }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </>
              )}
            </div>

            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Room Temp
              </div>
              <div
                className="column"
                style={{
                  marginTop: '40px',
                }}
              >
                <span
                  style={{
                    fontSize: '46px',
                    color: '#0af',
                    fontWeight: 'bold',
                  }}
                >
                  {envTemp}
                </span>{' '}
                <span
                  style={{
                    fontSize: '26px',
                    color: '#aaa',
                  }}
                >
                  ºC
                </span>
              </div>
            </div>
          </div>
          <div className="columns">
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Mode Control
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    <button
                      value="off"
                      // className={status.air_con.mode === 'off' ? 'button is-info' : 'button'}
                      className={currentMode === 'off' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'off')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Off
                    </button>
                    <button
                      value="cool"
                      className={currentMode === 'cool' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'cool')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Cool
                    </button>

                    <button
                      value="dry"
                      className={currentMode === 'dry' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'dry')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Dry
                    </button>
                    <button
                      value="fan"
                      className={currentMode === 'fan' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'fan')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Fan
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Fan Speed
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    {currentMode === 'fan' ? (
                      ''
                    ) : (
                      <button
                        value="auto"
                        className={
                          currentMode === 'off'
                            ? 'button'
                            : fanMode === 'auto'
                            ? 'button is-info'
                            : 'button'
                        }
                        onClick={(e) => onClickFanMode(e, 'auto')}
                        style={{
                          margin: '10px 1px 20px 1px',

                          boxSizing: 'border-box',
                          width: '65px',
                          font: '15px',
                        }}
                      >
                        Auto
                      </button>
                    )}
                    <button
                      value="low"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'low'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'low')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '65px',
                        font: '15px',
                      }}
                    >
                      Low
                    </button>

                    <button
                      value="medium"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'medium'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'medium')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '85px',
                        font: '15px',
                      }}
                    >
                      Medium
                    </button>
                    <button
                      value="high"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'high'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'high')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '65px',
                        font: '15px',
                      }}
                    >
                      High
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
          </div>
        </>
      )}
      {currentTab === 'info' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {currentTab === 'activity' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {currentTab === 'history' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
