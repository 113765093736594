import React, { useEffect, useState, useContext } from 'react';
import classnames from 'classnames';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { BuildingPerformance } from './PowerScalse';
import { FirebaseContext2 } from '../../firebase';

const PowerScale = '/assets/images/powerscale/power-scale.svg';
const logo = '/assets/images/dashbard/LogoAltoTech-03.png';
const hotelFullName = localStorage.getItem('hotel_hotel_name');

const role = localStorage.getItem('role');
const hotelName = localStorage.getItem('hotel_hotel_code');

const NavBar = (props) => {
  const firebase = useContext(FirebaseContext2);
  let total_consumption_url = '/hotel/mintel/energy/electricity_bill/this_month_baht';
  const [bahtSum, setBahtSum] = useState(null);
  const state = {
    collapseOpen: false,
    color: 'navbar-transparent',
  };

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };

  const refreshCacheAndReload = () => {
    localStorage.clear();
  };

  useEffect(() => {
    firebase.db.ref(total_consumption_url).on('value', function (snap) {
      let totalConsumption = snap.val();

      setBahtSum(totalConsumption.toFixed(1));
    });
    return () => {
      firebase.db.ref(total_consumption_url).off('value');
    };
  });

  return (
    <Navbar className={classnames('navbar-absolute fixed-top', state.color)} expand="lg">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize">
            <Button
              className="btn-icon btn-round"
              color="default"
              id="minimizeSidebar"
              onClick={props.handleMiniClick}
            >
              <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
              <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
            </Button>
          </div>
          <div
            className={classnames('navbar-toggle', {
              toggled: state.sidebarOpen,
            })}
          >
            <button className="navbar-toggler" type="button" onClick={() => toggleSidebar()}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand
            href="#"
            onClick={(e) => e.preventDefault()}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {/* <span className="d-none d-md-block">{hotelFullName}</span> */}
            <span className="d-block d-md-none">
              <p>
                <img src={logo} alt="logo" width="30px" height="30px" />
              </p>
            </span>
          </NavbarBrand>
        </div>

        {hotelName === 'MIN' && <BuildingPerformance />}

        <Collapse className="justify-content-end" navbar isOpen={state.collapseOpen}>
          <Nav navbar>
            <UncontrolledDropdown className="btn-rotate" nav>
              {hotelName === 'MIN' && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {/* <div>
                  Last Energy : <strong>190293 </strong> kWh
                </div>
                &nbsp;&nbsp;/&nbsp;&nbsp; */}
                  <div>
                    This month : <strong>{bahtSum}</strong> Baht
                  </div>
                </div>
              )}

              <DropdownToggle
                aria-haspopup={true}
                caret
                color="default"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                nav
              >
                <i className="nc-icon nc-bell-55" />
                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </DropdownToggle>
              <DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  Help
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  Tutorial
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  About US
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <div className="navbar-wrapper">
            <NavLink
              to={'/login'}
              activeClassName=""
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  onClick={() => refreshCacheAndReload()}
                  style={{ fontSize: '18px', margin: '2px', height: '22px' }}
                >
                  <span className="d-none d-md-block">{hotelFullName}</span>
                </p>
                <p style={{ fontSize: '12px', margin: '2px', textTransform: 'uppercase' }}>
                  <span className="d-none d-md-block">Role: {role}</span>
                </p>
              </div>

              <i
                className={'fas fa-sign-out '}
                onClick={() => refreshCacheAndReload()}
                style={{ opacity: '0.7', marginLeft: '15px', fontSize: '22px', color: '#5bc9bd' }}
              />
            </NavLink>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export { NavBar };
