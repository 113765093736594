import React from 'react';
import { CardHeader, CardBody, CardTitle } from 'reactstrap';
import Chart from 'react-apexcharts'

const ElectricityForcastedGraph = (props) => (
  <div>
    <CardHeader style={{boxShadow:'none'}}>
      <CardTitle tag="h4">{props.title}</CardTitle>
    </CardHeader>
    <CardBody>
      <Chart
        options={props.options}
        series={props.series}
        type='bar'
        height='300'
      />
    </CardBody>
  </div>
);
export { ElectricityForcastedGraph };