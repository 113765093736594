import { Automation } from './features/automation/containers';
import { ViewerCanvas, MinttowerDashboard } from './features/dashboard/containers';
import { EnergyTabLayout } from './features/energy/containers/LayoutTabs';
import { SynFloorPlan } from './features/dashboard/containers';
import { SynSchedule } from './features/dashboard/containers/SYN/SynContainers/SynSchedule.containers';
import { Store } from './features/store/containers';
import { Analytics } from './features/analytics/containers';
import {
  Reports,
  TdpkReportContainers,
  MinttowerReportContainers,
} from './features/reports/containers';
import { TDPKFloorPlan } from './features/dashboard/containers/TDPK/TDPKContainers/TDPKFloorPlan.containers';
import { Schedule } from './features/dashboard/containers/TDPK/TDPKContainers/Schedule.containers';
import { TdpkEnergyTabLayout } from './features/energy/containers';
import {
  DashboardContainer,
  EnergyContainers,
} from './features/dashboard/containers/AGS/containers';
import { NAPFloorPlan } from './features/dashboard/containers/NAP/NAPContainers/NAPFloorPlan.containers';
import { NAPEnergyContainer } from './features/energy/containers';
import { SettingLayouTabs } from './features/setting/containers/LayoutTabs';
import { KlubEnergycontainer } from './features/energy/containers/KLUB/Energy';
// From Main containers
import { MaidContainer } from './features/housekeeper';

import { graphCompere } from './features/bills/containers/MINTEL/graphCompere.container';

const hotelCode = localStorage.getItem('hotel_hotel_code');

const routes =
  (hotelCode === 'MINT' && [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: 'fas fa-tachometer',
      component: MinttowerDashboard,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/reports',
      name: 'Reports',
      icon: 'nc-icon nc-single-copy-04',
      component: MinttowerReportContainers,
      layout: '/admin',
      disabled: false,
    },
  ]) ||
  (hotelCode === 'MIN' && [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: 'fas fa-tachometer',
      component: ViewerCanvas,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/energy',
      name: 'Energy',
      icon: 'fas fa-lightbulb-o',
      component: EnergyTabLayout,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/automation',
      name: 'Automation',
      icon: 'fas fa-magic',
      component: Automation,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/store',
      name: 'Store',
      icon: 'nc-icon nc-box-2',
      component: Store,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/housekeeper',
      name: 'Housekeeper',
      icon: 'fas fa-female',
      component: MaidContainer,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/setting',
      name: 'Settings',
      icon: 'nc-icon nc-settings-gear-65',
      component: SettingLayouTabs,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/analytics',
      name: 'Analytics',
      icon: 'nc-icon nc-chart-bar-32',
      component: Analytics,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/reports',
      name: 'Reports',
      icon: 'nc-icon nc-single-copy-04',
      component: Reports,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/bills',
      name: 'Bills',
      icon: 'nc-icon nc-shop',
      component: graphCompere,
      layout: '/admin',
      disabled: false,
    },
    {
      path: '/login',
      name: 'Technician',
      icon: 'nc-icon nc-settings',
      component: '',
      layout: '',
      disabled: true,
    },
    {
      path: '/login',
      name: 'Roomservice',
      icon: 'nc-icon nc-shop',
      component: '',
      layout: '',
      disabled: true,
    },

    // {
    //   collapse: true,
    //   name: 'GM',
    //   // icon: 'nc-icon nc-single-02',
    //   // icon: <MdAccountCircle />,

    //   state: 'dashCollapse',
    //   views: [
    //     {
    //       path: '/dashboard',
    //       name: 'Dashboard',
    //       icon: 'fas fa-tachometer text-info',
    //       component: ViewerCanvas,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //     {
    //       path: '/energy',
    //       name: 'Energy',
    //       icon: 'fas fa-lightbulb-o text-info',
    //       component: EnergyTabLayout,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //     {
    //       path: '/automation',
    //       name: 'Automation',
    //       icon: 'fas fa-magic text-info',
    //       component: Automation,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //   ],
    // },
    // {
    //   collapse: true,
    //   name: 'Head of Staffs',
    //   // icon: 'nc-icon nc-ruler-pencil',
    //   mini: 'SF',
    //   state: 'HeadOfStaffsCollapse',
    //   views: [
    //     {
    //       path: '/store',
    //       name: 'Store',
    //       icon: 'nc-icon nc-box-2 text-info',
    //       component: Store,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //     {
    //       path: '/housekeeper',
    //       name: 'Housekeeper',
    //       icon: 'fas fa-female text-info',
    //       component: MaidContainer,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //     {
    //       path: '/setting',
    //       name: 'Settings',
    //       icon: 'nc-icon nc-settings-gear-65 text-info',
    //       component: SettingLayouTabs,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //   ],
    // },
    // {
    //   collapse: true,
    //   name: 'Technician',
    //   // icon: 'nc-icon nc-ruler-pencil',
    //   state: 'TechnicianCollapse',
    //   views: [
    //     {
    //       path: '/analytics',
    //       name: 'Analytics',
    //       icon: 'nc-icon nc-chart-bar-32 text-info',
    //       component: Analytics,
    //       layout: '/admin',
    //       disabled: false,
    //     },
    //     {
    //       path: '/login',
    //       name: 'Technician',
    //       icon: 'nc-icon nc-settings text-info',
    //       component: '',
    //       layout: '',
    //       disabled: true,
    //     },
    //     {
    //       path: '/login',
    //       name: 'Roomservice',
    //       icon: 'nc-icon nc-shop text-info',
    //       component: '',
    //       layout: '',
    //       disabled: true,
    //     },
    //   ],
    // },

    // {
    //   path: '/login',
    //   name: 'Logout',
    //   icon: 'fas fa-sign-out text-info',
    //   component: '',
    //   layout: '',
    //   disabled: false,
    // },
  ]) ||
  (hotelCode === 'SYN' && [
    {
      path: '/dashboard',
      name: 'Room',
      icon: 'fas fa-bed text-info',
      component: ViewerCanvas,
      layout: '/admin',
    },
    {
      path: '/lobby',
      name: 'Lobby',
      icon: 'fas fa-th text-info',
      component: SynFloorPlan,
      layout: '/admin',
    },
    {
      path: '/energy',
      name: 'Energy',
      icon: 'fas fa-lightbulb-o text-info',
      // component: EnergyTabLayout,
      component: EnergyContainers,
      layout: '/admin',
    },
    {
      path: '/automation',
      name: 'Automation',
      icon: 'fas fa-magic text-info',
      component: SynSchedule,
      layout: '/admin',
    },
    // {
    //   path: '/login',
    //   name: 'Logout',
    //   icon: 'fas fa-sign-out text-info',
    //   component: '',
    //   layout: '',
    // },
  ]) ||
  (hotelCode === 'TDPK' && [
    {
      path: '/dashboard',
      name: 'Building',
      icon: 'fas fa-building text-info',
      component: TDPKFloorPlan,
      layout: '/admin',
    },
    {
      path: '/energy',
      name: 'Energy',
      icon: 'fas fa-lightbulb-o text-info',
      component: TdpkEnergyTabLayout,
      layout: '/admin',
    },
    {
      path: '/schedule',
      name: 'Schedule',
      icon: 'fas fa-calendar text-info',
      component: Schedule,
      layout: '/admin',
    },
    {
      path: '/reports',
      name: 'Reports',
      icon: 'fas fa-file-text text-info',
      component: TdpkReportContainers,
      layout: '/admin',
    },
  ]) ||
  (hotelCode === 'AGS' && [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: 'fas fa-tachometer text-info',
      component: DashboardContainer,
      // component: ViewerCanvas,
      layout: '/admin',
    },
    {
      path: '/energy',
      name: 'Energy',
      icon: 'fas fa-lightbulb-o text-info',
      component: EnergyContainers,
      layout: '/admin',
    },
    {
      path: '/automation',
      name: 'Automation',
      icon: 'fas fa-magic text-info',
      component: Automation,
      layout: '/admin',
    },
    {
      path: '/login',
      name: 'Housekeeper',
      icon: 'fas fa-female text-info',
      component: '',
      layout: '',
    },
    {
      path: '/login',
      name: 'Technician',
      icon: 'nc-icon nc-settings text-info',
      component: '',
      layout: '',
    },
    {
      path: '/login',
      name: 'Roomservice',
      icon: 'nc-icon nc-shop text-info',
      component: '',
      layout: '',
    },
    {
      path: '/store',
      name: 'Store',
      icon: 'nc-icon nc-box-2 text-info',
      component: Store,
      layout: '/admin',
    },
    {
      path: '/login',
      name: 'Analytics',
      icon: 'nc-icon nc-chart-bar-32 text-info',
      component: '',
      layout: '',
    },
    {
      path: '/login',
      name: 'Settings',
      icon: 'nc-icon nc-settings-gear-65 text-info',
      component: '',
      layout: '',
    },
    // {
    //   path: '/login',
    //   name: 'Logout',
    //   icon: 'fas fa-sign-out text-info',
    //   component: '',
    //   layout: '',
    // },
  ]) ||
  (hotelCode === 'NAP' && [
    {
      path: '/dashboard',
      name: 'Building',
      icon: 'fas fa-bed text-info',
      component: NAPFloorPlan,
      layout: '/admin',
    },
    {
      path: '/energy',
      name: 'Energy',
      icon: 'fas fa-lightbulb-o text-info',
      component: NAPEnergyContainer,
      layout: '/admin',
    },
    {
      path: '/schedule',
      name: 'Schedule',
      icon: 'fas fa-magic text-info',
      component: Schedule,
      layout: '/admin',
    },
  ]) ||
  (hotelCode === 'KLUB' && [
    {
      path: '/dashboard',
      name: 'Energy',
      icon: 'fas fa-lightbulb-o text-info',
      component: KlubEnergycontainer,
      layout: '/admin',
      disabled: false,
    },
  ]);

export default routes;
