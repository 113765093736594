import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { GiElectric } from 'react-icons/gi';
import { FaTachometerAlt } from 'react-icons/fa';
import { ModalDialog } from '../';

//##--------------------------Floor Labels-----------------------------------
const lvl_1 = ['101', '102', '103', '104', '105', '106', '107', '108', '109'];
const lvl_m = ['M02', 'M03', 'M04', 'M05'];
const lvl_5 = ['501', '502', '503', '504', '505', '506', '507', '508', '509', '510'];
const lvl_6 = ['601', '602', '603', '604', '605', '606'];
const floor = ['7 Floor', '8 Floor', '9 Floor', 'Gen'];
const lvl_10 = ['1001', '1002', '1003', '1004', '1005', '1006', '1007'];

const floor_fb = ['701', '801', '901', '1001'];

//##-----------------------------Styles-------------------------------------

const LV_1 = styled.div`
  /* position: absolute; */
  width: 8rem;
  height: 6rem;
  padding: 10px;
  background-color: #d9ede7;
  /* border-radius: 10px; */
  font-size: 14px;
  color: #f86871;
  font-weight: bold;

  :hover {
    color: #ed1212;
    background-color: #b2d9cd;
    cursor: pointer;
  }
`;
const FR = styled.div`
  /* position: absolute; */
  width: 11rem;
  height: 6rem;
  padding: 10px;
  background-color: #d9ede7;
  /* border-radius: 10px; */
  font-size: 14px;
  color: #f86871;
  font-weight: bold;

  :hover {
    color: #ed1212;
    background-color: #b2d9cd;
    cursor: pointer;
  }
`;

const MainMeter = styled.div`
  width: 24rem;
  height: 15rem;
  padding: 10px;
  background-color: #d9ede7;
  /* border-radius: 10px; */
  font-size: 14px;
  color: #f86871;
  font-weight: bold;

  :hover {
    color: #ed1212;
    background-color: #b2d9cd;
    cursor: pointer;
  }
`;

const Containers = styled.div`
  display: inline-flex;
  margin: 5px;
`;

const Roomcomponents = (props) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');

  const handleClickOpen = (e) => {
    e.preventDefault();
    setTitle(e.target.id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Row>
        <Col md={12}>
          {lvl_1.map((data, i) => {
            return (
              <Containers key={data}>
                <div style={{ position: 'relative' }}>
                  <strong style={{ fontSize: 30 }}>{data}</strong>
                  <LV_1 onClick={handleClickOpen} id={data}>
                    <div style={{ textAlign: 'center', fontSize: 19, color: '#26697d' }}>
                      ฿ 0
                      {/* ฿ {props.data !== null
                        ? Number(props.data[lvl_1m[i]].electric.subdev_3.energy).toFixed(1)
                        : ''}{' '} */}
                    </div>
                    <FaTachometerAlt></FaTachometerAlt> 0
                    {/* {props.data !== null
                      ? Number(props.data[lvl_1m[i]].electric.subdev_3.energy).toFixed(1)
                      : ''} */}
                    <br />
                    <GiElectric></GiElectric> 0
                    {/* {props.data !== null
                      ? Number(props.data[lvl_1m[i]].electric.subdev_3.energy).toFixed(1)
                      : ''} */}
                    <br />
                  </LV_1>
                </div>
              </Containers>
            );
          })}
        </Col>

        <Col md={12}>
          {lvl_m.map((data, i) => {
            return (
              <Containers key={data}>
                <div style={{ position: 'relative' }}>
                  <strong style={{ fontSize: 30 }}>{data}</strong>
                  <LV_1 onClick={handleClickOpen} id={data}>
                    <div style={{ textAlign: 'center', fontSize: 19, color: '#26697d' }}>
                      ฿ 0
                      {/* ฿ {props.data !== null
                        ? Number(props.data[lvl_mf[i]].electric.subdev_3.energy).toFixed(1)
                        : ''}{' '} */}
                    </div>
                    <FaTachometerAlt></FaTachometerAlt> 0
                    {/* {props.data !== null
                      ? Number(props.data[lvl_mf[i]].electric.subdev_3.energy).toFixed(1)
                      : ''} */}
                    <br />
                    <GiElectric></GiElectric> 0
                    {/* {props.data !== null
                      ? Number(props.data[lvl_mf[i]].electric.subdev_3.energy).toFixed(1)
                      : ''} */}
                    <br />
                  </LV_1>
                </div>
              </Containers>
            );
          })}
        </Col>

        <Col md={12}>
          {lvl_5.map((data, i) => {
            return (
              <Containers key={data}>
                <div style={{ position: 'relative' }}>
                  <strong style={{ fontSize: 30 }}>{data}</strong>
                  <LV_1 onClick={handleClickOpen} id={data}>
                    <div style={{ textAlign: 'center', fontSize: 19, color: '#26697d' }}>
                      ฿{' '}
                      {props.data !== null && i !== 0
                        ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                        : 0}{' '}
                    </div>
                    <FaTachometerAlt></FaTachometerAlt>{' '}
                    {props.data !== null && i !== 0
                      ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                      : 0}
                    <br />
                    <GiElectric></GiElectric>{' '}
                    {props.data !== null && i !== 0
                      ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                      : 0}
                    <br />
                  </LV_1>
                </div>
              </Containers>
            );
          })}
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {lvl_6.map((data) => {
            return (
              <Containers key={data}>
                <div style={{ position: 'relative' }}>
                  <strong style={{ fontSize: 30 }}>{data}</strong>
                  <LV_1 onClick={handleClickOpen} id={data}>
                    <div style={{ textAlign: 'center', fontSize: 19, color: '#26697d' }}>
                      ฿{' '}
                      {props.data !== null
                        ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                        : ''}{' '}
                    </div>
                    <FaTachometerAlt></FaTachometerAlt>{' '}
                    {props.data !== null
                      ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                      : ''}
                    <br />
                    <GiElectric></GiElectric>{' '}
                    {props.data !== null
                      ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                      : ''}
                    <br />
                  </LV_1>
                </div>
              </Containers>
            );
          })}
          <br />
          {floor.map((data, i) => {
            return (
              <Containers key={data}>
                <div style={{ position: 'relative' }}>
                  <strong style={{ fontSize: 30 }}>{data}</strong>
                  <FR onClick={handleClickOpen} id={data}>
                    <div style={{ textAlign: 'center', fontSize: 19, color: '#26697d' }}>
                      ฿{' '}
                      {props.data !== null && i !== 3
                        ? Number(props.data[floor_fb[i]].electric.subdev_3.energy).toFixed(1)
                        : 0}{' '}
                    </div>
                    <FaTachometerAlt></FaTachometerAlt>{' '}
                    {props.data !== null && i !== 3
                      ? Number(props.data[floor_fb[i]].electric.subdev_3.energy).toFixed(1)
                      : 0}
                    <br />
                    <GiElectric></GiElectric>{' '}
                    {props.data !== null && i !== 3
                      ? Number(props.data[floor_fb[i]].electric.subdev_3.energy).toFixed(1)
                      : 0}
                    <br />
                  </FR>
                </div>
              </Containers>
            );
          })}
        </Col>
        <Col md={4}>
          <Containers>
            <div style={{ position: 'relative', textAlign: 'center' }}>
              <strong style={{ fontSize: 30 }}>MAIN METER</strong>
              <MainMeter onClick={handleClickOpen} id="Main Meter">
                <div style={{ marginTop: 20, fontSize: 20, textAlign: 'left', marginLeft: 50 }}>
                  Energy :{' '}
                  <strong style={{ color: '#26697d' }}>
                    {props.data !== null
                      ? Number(props.data['1001'].electric.subdev_3.energy).toFixed(0)
                      : ''}
                  </strong>{' '}
                  kWh
                </div>
                <div style={{ marginTop: 20, fontSize: 20, textAlign: 'left', marginLeft: 50 }}>
                  Power :{' '}
                  <strong style={{ color: '#26697d' }}>
                    {props.data !== null
                      ? Number(props.data['1001'].electric.subdev_3.power).toFixed(2)
                      : ''}
                  </strong>{' '}
                  kW
                </div>
                <div style={{ marginTop: 20, fontSize: 20, textAlign: 'left', marginLeft: 50 }}>
                  Current :{' '}
                  <strong style={{ color: '#26697d' }}>
                    {props.data !== null
                      ? Number(props.data['1001'].electric.subdev_3.current).toFixed(2)
                      : ''}
                  </strong>{' '}
                  A
                </div>
                <div style={{ marginTop: 20, fontSize: 20, textAlign: 'left', marginLeft: 50 }}>
                  Voltage :{' '}
                  <strong style={{ color: '#26697d' }}>
                    {props.data !== null
                      ? Number(props.data['1001'].electric.subdev_3.voltage).toFixed(2)
                      : ''}
                  </strong>{' '}
                  V
                </div>
              </MainMeter>
            </div>
          </Containers>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          {lvl_10.map((data) => {
            return (
              <Containers key={data}>
                <div style={{ position: 'relative' }}>
                  <strong style={{ fontSize: 30 }}>{data}</strong>
                  <LV_1 onClick={handleClickOpen}>
                    <div style={{ textAlign: 'center', fontSize: 19, color: '#26697d' }}>
                      ฿{' '}
                      {props.data !== null
                        ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                        : ''}{' '}
                    </div>
                    <FaTachometerAlt></FaTachometerAlt>{' '}
                    {props.data !== null
                      ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                      : ''}
                    <br />
                    <GiElectric></GiElectric>{' '}
                    {props.data !== null
                      ? Number(props.data[data].electric.subdev_3.energy).toFixed(1)
                      : ''}
                    <br />
                  </LV_1>
                </div>
              </Containers>
            );
          })}
        </Col>
        <Col md={3}>
          <div style={{ fontSize: 50, padding: 0, margin: 0 }}>
            <strong style={{ marginLeft: 25, color: '#808080' }}>MINT</strong>
            <br />
            <strong style={{ color: '#808080' }}>TOWER</strong>
          </div>
        </Col>
      </Row>

      <ModalDialog open={open} handleClose={handleClose} title={title} />
    </div>
  );
};

export { Roomcomponents };
