import React, { useState, useEffect } from 'react';
import { Col, Card, CardBody, Label, FormGroup, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { dashboardFilterActions } from '../../dashboard/actions';
import { Scrollbars } from 'react-custom-scrollbars';

const Filter = () => {
  const dispatch = useDispatch();
  // const [filter, setFilter] = useState(['vc', 'vd', 'oc', 'od', 'oo']);
  const [checked, setChecked] = useState({
    vc: true,
    vd: true,
    oc: true,
    od: true,
    oo: true,
    ac: true,
    non: true,
    iot: true,
    leakage: true,
    maintenance: true,
    maid: true,
  });

  useEffect(() => {
    dispatch(dashboardFilterActions.changeFilterRoomStatus(checked));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const onChange = (e) => {
    let name = e.target.id;
    // let newFilter = [...filter, name];
    // if (filter.includes(name)) {
    //   newFilter = newFilter.filter((ss) => ss !== e.target.id);
    // }
    // setFilter(newFilter);
    if (name === 'vc') {
      setChecked({
        ...checked,
        vc: !checked.vc,
      });
    }
    if (name === 'vd') {
      setChecked({
        ...checked,
        vd: !checked.vd,
      });
    }
    if (name === 'oc') {
      setChecked({
        ...checked,
        oc: !checked.oc,
      });
    }
    if (name === 'od') {
      setChecked({
        ...checked,
        od: !checked.od,
      });
    }
    if (name === 'oo') {
      setChecked({
        ...checked,
        oo: !checked.oo,
      });
    }
    if (name === 'ac') {
      setChecked({
        ...checked,
        ac: !checked.ac,
      });
    }
    if (name === 'non') {
      setChecked({
        ...checked,
        non: !checked.non,
      });
    }
    if (name === 'maid') {
      setChecked({
        ...checked,
        maid: !checked.maid,
      });
    }
    if (name === 'iot') {
      setChecked({
        ...checked,
        iot: !checked.iot,
      });
    }
    if (name === 'leakage') {
      setChecked({
        ...checked,
        leakage: !checked.leakage,
      });
    }
    if (name === 'maintenance') {
      setChecked({
        ...checked,
        maintenance: !checked.maintenance,
      });
    }
  };

  return (
    <Col md="2">
      {/* <Card style={{ height: 510, overflowY: 'auto' }}> */}
      <Card>
        {/* <CardHeader>
              <CardTitle tag="h4">Symbol here </CardTitle>
            </CardHeader> */}
        <Scrollbars style={{ width: '100%', height: '480px' }} autoHide>
          <CardBody>
            <b>Room Status</b>
            <hr />

            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="vc" checked={checked.vc} onChange={onChange} />
                <span className="form-check-sign" />
                VC
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="vd" checked={checked.vd} onChange={onChange} />
                <span className="form-check-sign" />
                VD
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="oc" checked={checked.oc} onChange={onChange} />
                <span className="form-check-sign" />
                OC
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="od" checked={checked.od} onChange={onChange} />
                <span className="form-check-sign" />
                OD
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="oo" checked={checked.oo} onChange={onChange} />
                <span className="form-check-sign" />
                OO
              </Label>
            </FormGroup>

            <hr />
            <b>Housekeeper</b>
            <hr />
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="maid" checked={checked.maid} onChange={onChange} />
                <span className="form-check-sign" />
                Housekeeper
              </Label>
            </FormGroup>

            <hr />
            <b>Room Alert</b>
            <hr />

            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="non" checked={checked.non} onChange={onChange} />
                <span className="form-check-sign" />
                Non Permit room
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="leakage" checked={checked.leakage} onChange={onChange} />
                <span className="form-check-sign" />
                Electric leakage
              </Label>
            </FormGroup>

            <hr />

            <b>Device Alert</b>
            <hr />

            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="iot" checked={checked.iot} onChange={onChange} />
                <span className="form-check-sign" />
                IoT Disconnected
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="maintenance"
                  checked={checked.maintenance}
                  onChange={onChange}
                />
                <span className="form-check-sign" />
                Require Maintenance
              </Label>
            </FormGroup>
          </CardBody>
        </Scrollbars>
      </Card>
    </Col>
  );
};

export default Filter;
