import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { LoginForm } from '../containers/LoginForm.containers';

import { navActions } from '../../nav/actions';

import '../styles/LoginPage.scss';

function LoginPage() {
  const dispatch = useDispatch();

  const mounted = () => {
    dispatch(navActions.focusPage('LoginPage'));
  };
  useEffect(mounted, []);
  useEffect(() => {
    return () => {
      window.location.reload(false);
    };
  });

  return (
    <div>
      <LoginForm />
    </div>
  );
}

export { LoginPage };
