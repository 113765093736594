import React, { useState, useMemo, useContext, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Card, CardBody, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { FirebaseContext2 } from '../../../firebase';
import { IoMdRefresh } from 'react-icons/io';
import { DisappearedLoading } from 'react-loadingg';
import moment from 'moment';

import { HotelFloorPlanMapCanvas } from '../../hotel/containers/HotelFloorPlanMapCanvas.containers';
import { HotelRoomDetailsModal } from '../../hotel/containers/HotelRoomDetailsModal.containers';
import Filter from './Filter.containers';

import { dashboardControlActions } from '../actions';
import { hotelActions } from '../../hotel/actions';
import { EnergyReport } from './EnergyReport.containers';
import { SummaryBoxes } from './SummaryBoxes.containers';

const RefreshButton = styled.div`
  position: absolute;
  display: flex;
  flex-direction: space-between;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: 37px;
  font-size: 12px;
  width: 90px;
  height: 28px;
  border-radius: 14px;
  background-color: transparent;
  color: black;
  border: 1px solid #2b5dc6;
  cursor: pointer;
  :hover {
    background-color: #2b5dc640;
  }
`;

function ViewerCanvas() {
  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext2);
  const filterRoomStatusList = useSelector((state) => state.dashboard.filterCountryList);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const isShowOneRoomDetailsModal = useSelector(
    (state) => state.dashboard.isShowOneRoomDetailsModal
  );
  const [maidUrl, setMaidUrl] = useState(null);
  let maid_url = 'hotel/mintel/user_info/room_status';

  const canvasRef = useRef(null);
  const hotelCode = localStorage.getItem('hotel_hotel_code');
  const [mapData, setMapData] = useState({
    hotel: null, // name of hotel
    name: 'main', // name of showing map
    xgrid: 1, // number of grids in x axis
    ygrid: 1, // number of grids in y axis
    map: {}, // data of hotel rooms, call from backend
    user_is_update: false, // communicate with p5js to draw bell when new user are registered
    showing_room_modal: null, // room number of showing modal
    show_filter_tab: true, // state of show or hide filter tab
    filter_case: filterRoomStatusList, // filter case that show rooms that satisfy this condition
  });

  const [roomStatus, setRoomStatus] = useState({
    vc: '...',
    vd: '...',
    oc: '...',
    od: '...',
    ad: '...',
    ac: '...',
  });

  const [deviceStatus, setDeviceStatus] = useState({
    '...': '...',
  });
  const [devicesUpdated_at, setDevicesUpdated_at] = useState('...');
  const [sumDeviceStatus, setSumDeviceStatus] = useState({ online: '...', offline: '...' });
  const [maidStatus, setMaidStatus] = useState({
    '...': '...',
  });
  const [loading, setLoading] = useState(true);

  //##--------------------- Prefetch Room Status ----------------------------
  const countMaidStatus = async (didMounted) => {
    if (didMounted) {
      console.log('Viewer Canvas Unsubscribe firebase');
      //## Unsubscribe firebase
      firebase.db.ref('hotel/mintel/user_info/room_status').off('value');
      await firebase.db.ref(maid_url).off('value');
    } else {
      await firebase.db.ref('hotel/mintel/dashboard/maid_status/').on('value', function (capture) {
        if (capture.val()) {
          let state = capture.val();
          setMaidStatus(state);
        }
      });
    }
  };

  //##--------------------- Prefetch Room Status ----------------------------
  const countDeviceStatus = async (didMounted) => {
    if (didMounted) {
      console.log('Viewer Canvas Unsubscribe firebase');
      //## Unsubscribe firebase
      firebase.db.ref('hotel/mintel/user_info/room_status').off('value');
      await firebase.db.ref(maid_url).off('value');
    } else {
      await firebase.db
        .ref('hotel/mintel/dashboard/devices_status/items')
        .on('value', function (capture) {
          if (capture.val()) {
            let state = capture.val();
            setDeviceStatus(state);
            setDevicesUpdated_at(moment(state.updated_at).format('llll'));
            let sumDeviceOnline = 0;
            let sumDeviceOffline = 0;
            for (let device in state) {
              if (state[device]) {
                sumDeviceOnline = sumDeviceOnline + state[device]['on'];
                sumDeviceOffline = sumDeviceOffline + state[device]['off'];
              }
            }

            setSumDeviceStatus({ on: sumDeviceOnline, off: sumDeviceOffline });
          }
        });
    }
  };

  //##--------------------- Prefetch Room Status ----------------------------
  const countRoomStatus = async () => {
    let vc = 0;
    let vd = 0;
    let oc = 0;
    let od = 0;
    let oo = 0;
    let ad = 0;
    let ac = 0;
    await firebase.db.ref('hotel/mintel/user_info/room_status/').once('value', function (capture) {
      if (capture.val()) {
        let state = capture.val();
        Object.keys(state).forEach((room) => {
          if (state[room]['clean_status']) {
            if (state[room]['clean_status'] === 'vc') {
              vc = vc + 1;
            } else if (state[room]['clean_status'] === 'vd') {
              vd = vd + 1;
            } else if (state[room]['clean_status'] === 'oc') {
              oc = oc + 1;
            } else if (state[room]['clean_status'] === 'od') {
              od = od + 1;
            } else if (state[room]['clean_status'] === 'ad') {
              ad = ad + 1;
            } else if (state[room]['clean_status'] === 'ac') {
              ac = ac + 1;
            } else if (state[room]['clean_status'] === 'oo') {
              oo = oo + 1;
            }
          }
        });
      }
    });
    setRoomStatus({ vc: vc, vd: vd, oc: oc, od: od, ad: ad, ac: ac, oo:oo });
  };

  //##--------------------- Prefetch Data ----------------------------
  const onPrefetchMapData = async (firebase, didMounted) => {
    let tempMapData = await dispatch(hotelActions.getHotelMapData(hotelCode));

    if (didMounted) {
      console.log('Viewer Canvas Unsubscribe firebase');
      //## Unsubscribe firebase
      firebase.db.ref('hotel/mintel/user_info/room_status').off('value');
      await firebase.db.ref(maid_url).off('value');
    } else {
      if (tempMapData) {
        let xGrid = parseInt(tempMapData['xGrid'], 10);
        let yGrid = parseInt(tempMapData['yGrid'], 12);
        let drawSize = parseInt(tempMapData['drawSize']);

        let roomList = tempMapData['rooms'];
        let _map = {};

        let newMapData = { ...tempMapData };

        for (let i in roomList) {
          let d = roomList[i];
          let roomNo = d.text;

          _map[roomNo] = {
            posx: parseInt(d.x) / drawSize,
            posy: parseInt(d.y) / drawSize,
            room_number: roomNo,
          };

          //## Subscribe firebase pending user has update
          await firebase.db
            .ref('hotel/mintel/user_info/room_status/' + roomNo + '/')
            .on('value', function (capture) {
              if (capture.val()) {
                let state = capture.val();

                for (let k in state) {
                  _map[roomNo][k] = state[k];
                }

                newMapData.map = { ..._map };
                newMapData['xgrid'] = xGrid;
                newMapData['ygrid'] = yGrid;
                newMapData['filter_case'] = ['vc', 'od', 'vd', 'oc', 'oo', 'ac'];
                setMapData(newMapData);
                if (!loading) {
                  countRoomStatus();
                }
              } else {
                console.log('disconnect');
              }
            });
        }

        await firebase.db.ref(maid_url).on('value', function (snap) {
          let capt = snap.val();
          fetchMaidUrl(capt);
        });

        await setLoading(false);

        countRoomStatus();
        countDeviceStatus(false);
        countMaidStatus(false);
      }
    }
  };

  const refresh = async () => {
    await setLoading(true);
    await onPrefetchMapData(firebase, true);
    await onPrefetchMapData(firebase, false);
    await setLoading(false);
  };

  const fetchMaidUrl = async (roomInfo) => {
    if (roomInfo !== null) {
      let tmp = [];
      for (const i in roomInfo) {
        if (roomInfo[i].maid_img_url !== undefined && roomInfo[i].maid_img_url !== '')
          tmp.push({ [i]: roomInfo[i].maid_img_url });
      }
      setMaidUrl(tmp);
    }
  };

  //##--------------------- Mounted ----------------------------
  useEffect(() => {
    onPrefetchMapData(firebase, false);
    return () => {
      onPrefetchMapData(firebase, true);
      countDeviceStatus(true);
      countMaidStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##--------------------- Child Event Handler ----------------------------
  const onEventUpdateMapData = (newMapData) => {
    //##[DEBUG]
    console.log('!! [EVENT][onEventUpdateMapData] Fired !!');
  };

  useMemo(() => {
    if (currentRoomNo) {
      dispatch(dashboardControlActions.showOneRoomDetailsModal());
      dispatch(hotelActions.getAllDevices(currentRoomNo, 'mintel'));
    } else {
      dispatch(dashboardControlActions.resetSelectedRoom());
      dispatch(dashboardControlActions.hideOneRoomDetailsModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomNo]);

  return (
    <div ref={canvasRef} className="content">
      <Row>
        <Col md="10">
          <Card>
            <Scrollbars style={{ width: '100%', height: '480px' }} autoHide>
              <CardBody>
                <Row>
                  <Col md="12">
                    <HotelFloorPlanMapCanvas
                      mapData={mapData}
                      canvasRef={canvasRef}
                      onEventUpdateMapData={onEventUpdateMapData}
                    />

                    <RefreshButton onClick={() => refresh()}>
                      {!loading ? (
                        <>
                          <IoMdRefresh
                            style={{
                              width: '24px',
                              height: '24px',
                              marginRight: '4px',
                              color: '#2b5dc6',
                            }}
                          />
                          Refresh
                        </>
                      ) : (
                        <>
                          <DisappearedLoading
                            style={{ width: '40px', height: '10px', color: 'mint' }}
                          />
                        </>
                      )}
                    </RefreshButton>
                  </Col>
                </Row>
              </CardBody>
            </Scrollbars>
          </Card>
        </Col>
        <Filter />
      </Row>
      <SummaryBoxes
        roomStatus={roomStatus}
        deviceStatus={deviceStatus}
        sumDeviceStatus={sumDeviceStatus}
        maidStatus={maidStatus}
        devicesUpdated_at={devicesUpdated_at}
        maidUrl={maidUrl}
      />
      <EnergyReport />
      {isShowOneRoomDetailsModal && (hotelCode !== 'SYN' ? <HotelRoomDetailsModal /> : '')}
    </div>
  );
}

export { ViewerCanvas };
