import { GLOBAL_CONSTANTS, TO_STAGE_CONSTANTS } from '../../../constants';
import { AUTH_CONSTANTS } from '../constants';
import { AUTH } from '../types';
import { loginServices } from '../../../api/services';

// import { createErrorMessageFromJsonResponse } from '../../../utilities';

function requestLogin() {
  return {
    type: AUTH.LOGIN_REQUEST,
  };
}

function successLogin(token) {
  return {
    type: AUTH.LOGIN_SUCCESS,
    token,
  };
}

function failureLogin(error) {
  return {
    type: AUTH.LOGIN_FAILURE,
    error,
  };
}

function login(payload) {
  return async (dispatch, getState) => {
    await dispatch(requestLogin());

    try {
      const STAGE = process.env.NODE_ENV;

      let responseJson = await loginServices.login(payload);

      if (responseJson) {
        if (responseJson['non_field_errors']) {
          // [DEBUG]
          let errorMsg = 'Username or password are invalid.';
          alert(errorMsg);
          console.error(errorMsg);

          return null;
        } else {
          if (responseJson.token) {
            let token = responseJson.token;
            let role = responseJson.role ? responseJson.role : null;
            let credentialList = responseJson.credentials ? responseJson.credentials : [];
            let hotelList = responseJson.hotel ? responseJson.hotel : [];
            let firebaseConfigList = responseJson.firebase_config
              ? responseJson.firebase_config
              : [];

            //## [DEBUG]
            // console.log('[DEBUG] responseJson');
            // console.log(hotelList);

            //## Save and Set State
            localStorage.setItem('token', token);

            if (role) {
              localStorage.setItem('role', role);
            }

            for (var i in credentialList[TO_STAGE_CONSTANTS[STAGE]]) {
              localStorage.setItem('creds_' + i, credentialList[TO_STAGE_CONSTANTS[STAGE]][i]);
            }

            for (var j in hotelList) {
              localStorage.setItem('hotel_' + j, hotelList[j]);
            }

            for (var k in firebaseConfigList) {
              localStorage.setItem('firebase_config_' + k, firebaseConfigList[k]);
            }

            await dispatch(successLogin(token));
            return token;
          } else {
            await dispatch(failureLogin(AUTH_CONSTANTS.ERROR_EMPTY_LOGIN_USER_DATA));
            return null;
          }
        }
      } else {
        await dispatch(failureLogin(GLOBAL_CONSTANTS.ERROR_JSON_DATA_NOT_FOUND));
        return null;
      }
    } catch (error) {
      await dispatch(failureLogin(error.message));
      return null;
    }
  };
}

export const loginActions = {
  requestLogin,
  successLogin,
  failureLogin,
  login,
};
