import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { FirebaseContext2 } from '../../../../../firebase';
import { List } from '../../../../../components/List/TabList';
import { Info, ActivityData, HistoryData } from '../../../components/Tabs';
import { energyActions } from '../../../../energy/actions';
import moment from 'moment';
import { roomActivitiesHistories } from '../../../../../api/services';
import { iotControlServices } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';

const LightSwitchBackControl = (props) => {
  const d = new Date();
  const firebase = useContext(FirebaseContext2);
  const timeoutid = useRef();

  //##------------------------Global Variables Redux-------------------------
  const dispatch = useDispatch();
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const InitPlotData = useSelector((state) => state.historyEnergy.hist);
  // const dropdown = useSelector((state) => state.hotel.dropdown);
  const getDevices = useSelector((state) => state.hotel.allDevices);

  const [currentTab, setCurrentTab] = useState('control');
  const devices = getDevices ? getDevices.devices : null;

  //##-------------------Switch--------------------------------
  const [openlight1, setOpenlight1] = useState(null);
  const [openlight2, setOpenlight2] = useState(null);
  const [openlight3, setOpenlight3] = useState(null);
  //##-------------------Hist Data-----------------------------
  // const [plotHistData, setPlotHistData] = useState([]);
  const [plotHistWindow, setPlotHistWindow] = useState([]);
  const [plotHistWardrobe, setPlotHistWardrobe] = useState([]);
  const [plotHistBed, setPlotHistBed] = useState([]);

  // const [tmpHist, settmpHist] = useState(null);

  const [windowHist, setWindowHist] = useState(null);
  const [wardrobeHist, setWardrobeHist] = useState(null);
  const [bedHist, setBedHist] = useState(null);

  //##-----------------Firebase init---------------------------
  const [window, setWindow] = useState({
    state: null,
    time: null,
  });
  const [wardrobe, setWardrobe] = useState({
    state: null,
    time: null,
  });
  const [bed, setBed] = useState({
    state: null,
    time: null,
  });

  //##---------------------Close Modal------------------------------
  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(energyActions.clearHistoryData());
    dispatch(dashboardControlActions.showIotModal(false));
  };

  //##--------------------Get Month-------------------------------
  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  //##--------------------Dropdown Activity-----------------------
  const onchildClickActivity = (event) => {
    console.log({ event });
    props.onchildClickActivity(event);
  };
  //##-------------------------Init Fucntion Switch--------------------------------
  const initSwitch = (status1, status2, status3) => {
    if (status1 === 'on') {
      setOpenlight1(true);
    } else {
      setOpenlight1(false);
    }
    if (status2 === 'on') {
      setOpenlight2(true);
    } else {
      setOpenlight2(false);
    }
    if (status3 === 'on') {
      setOpenlight3(true);
    } else {
      setOpenlight3(false);
    }
  };

  //##-------------------Fetch History Function--------------------
  async function histData(dev_id, nick_name, sub_dev) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        sub_dev,
        nick_name,
        false,
        props.startDate,
        props.stopDate
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##-------------------Fetch Firebase Function-------------------
  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/back_switch/relay';

    let windowPath = fbpath + '/window_switch';
    let wardrobePath = fbpath + '/wardrobe_switch';
    let bedPath = fbpath + '/bed_switch';

    if (didMounted) {
      console.log('Front Light switch did mounted');
      await firebase.db.ref(windowPath).off('value');
      await firebase.db.ref(wardrobePath).off('value');
      await firebase.db.ref(bedPath).off('value');
    } else {
      await firebase.db.ref(windowPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setWindow({
            state: capt.state,
            time: capt.timestamp,
          });
        }
      });
      await firebase.db.ref(wardrobePath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setWardrobe({
            state: capt.state,
            time: capt.timestamp,
          });
        }
      });
      await firebase.db.ref(bedPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setBed({
            state: capt.state,
            time: capt.timestamp,
          });
        }
      });
    }
  }

  //##------------------Call REST API--------------------------------
  async function triggerAPI(currentStatus, swLocation, currentRoomNo) {
    //##-------------------------Payload-----------------------------------
    const payload = {
      topic: 'devicecontrol',
      action: {
        [currentRoomNo]: {
          [swLocation]: {
            command: {
              state: currentStatus,
            },
          },
        },
      },
      RequestId: '12345',
    };
    console.log(payload.message, payload.device);
    iotControlServices.postIotApi(payload);
  }

  //##--------------------------sync data from firebase only-----------------------
  useEffect(() => {
    fetchData(firebase, currentRoomNo, false);
    return () => {
      fetchData(firebase, currentRoomNo, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##--------------------------Prepaering History Plot Data-----------------------
  useMemo(() => {
    if (windowHist && wardrobeHist && bedHist !== null) {
      let windowTmp = [];
      let wardrobeTmp = [];
      let bedTmp = [];

      windowHist.forEach((item) => {
        if (item.state && item.timestamp !== null) {
          windowTmp.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        return setPlotHistWindow(windowTmp);
      });

      wardrobeHist.forEach((item) => {
        if (item.state && item.timestamp !== null) {
          wardrobeTmp.push([
            Number(moment(item.timestamp).format('x')),
            item.state === 'on' ? 1 : 0,
          ]);
        }
        return setPlotHistWardrobe(wardrobeTmp);
      });

      bedHist.forEach((item) => {
        if (item.state && item.timestamp !== null) {
          bedTmp.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        return setPlotHistBed;
      });
    }
  }, [windowHist, wardrobeHist, bedHist]);

  //##--------------------------------init switch----------------------------------
  useMemo(() => {
    initSwitch(window.state, wardrobe.state, bed.state);
  }, [window, wardrobe, bed]);

  //##----------------------Hoding real-time data to plot--------------------------
  useMemo(() => {
    clearTimeout(timeoutid.current);
    devices.forEach((element) => {
      if (element['device_name'] === 'back_switch') {
        try {
          histData(element.device_id, 'back_switch', 0).then((results) => {
            return setWindowHist(results);
          });

          histData(element.device_id, 'back_switch', 1).then((results) => {
            return setWardrobeHist(results);
          });

          histData(element.device_id, 'back_switch', 2).then((results) => {
            return setBedHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });
    //##-------------------Tmp Data----------------
    let windowTmp = [];
    let wardrobeTmp = [];
    let bedTmp = [];

    timeoutid.current = setTimeout(() => {
      if (window.time !== null && bed.time !== null && wardrobe.time !== null) {
        windowTmp.push(Number(moment(window.time).format('x')), window.state === 'on' ? 1 : 0);
        wardrobeTmp.push(
          Number(moment(wardrobe.time).format('x')),
          wardrobe.state === 'on' ? 1 : 0
        );
        bedTmp.push(Number(moment(bed.time).format('x')), bed.state === 'on' ? 1 : 0);
        dispatch(
          energyActions.mintelHistoryData({
            hist: {
              front_switch: [],
              back_switch: {
                window: windowTmp,
                wardrobe: wardrobeTmp,
                bed: bedTmp,
              },
              ac: [],
              fan: [],
              fridge: { state: [], power: [] },
              smart_meter: { energy: [], power: [] },
              smart_tv: [],
            },
          })
        );
      }
    }, 2000);

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomNo, props.startDate, props.stopDate]);

  //##------------------------onClick Window Switch--------------------------------
  const windowSW = (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenlight1((prev) => !prev);
    const location = 'Window Switch';
    if (openlight1 !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openlight1 ? 'on' : 'off', location, currentRoomNo),
        200
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##------------------------onClick Wardrobe Switch------------------------------
  const wardrobeSW = (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenlight2((prev) => !prev);
    const location = 'Wardrobe Switch';

    if (openlight2 !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openlight2 ? 'on' : 'off', location, currentRoomNo),
        200
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##--------------------------onClick Bed Switch---------------------------------
  const bedSW = (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenlight3((prev) => !prev);
    const location = 'Bed Switch';

    if (openlight3 !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openlight3 ? 'on' : 'off', location, currentRoomNo),
        200
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##------------------------onClick Tab menu-------------------------------------
  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };

  //##---------------------------Image Path----------------------------------------
  const imagesPath = {
    open: '/assets/images/devices/icon-light-open.png',
    close: '/assets/images/devices/icon-light-close.png',
  };

  const image_link = 'https://images-na.ssl-images-amazon.com/images/I/41C6YYGB7IL._SL1000_.jpg';

  const hist_plot_window = {
    series: [
      {
        name: 'Window Switch',
        data:
          plotHistWindow !== undefined &&
          plotHistWindow !== null &&
          InitPlotData.back_switch.window !== undefined
            ? plotHistWindow.concat(InitPlotData.back_switch.window)
            : [],
      },
    ],
    options: {
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },

      title: {
        text: 'Activity in 24 hrs of window switch',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  const hist_plot_wardrobe = {
    series: [
      {
        name: 'Wardrobe Switch',
        data:
          plotHistWardrobe !== undefined &&
          plotHistWardrobe !== null &&
          InitPlotData.back_switch.wardrobe !== undefined
            ? plotHistWardrobe.concat(InitPlotData.back_switch.wardrobe)
            : [],
      },
    ],
    options: {
      noData: {
        text: 'No activity',
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },

      title: {
        text: 'Activity in 24 hrs of wardrobe switch',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  const hist_plot_bed = {
    series: [
      {
        name: 'Bed Switch',
        data:
          plotHistBed !== undefined &&
          plotHistBed !== null &&
          InitPlotData.back_switch.bed !== undefined
            ? plotHistBed.concat(InitPlotData.back_switch.bed)
            : [],
      },
    ],
    options: {
      noData: {
        text: 'No activity',
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },

      title: {
        text: 'Activity in 24 hrs of bed switch',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Back Switch
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="ucount-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'control'}
                        onClick={(e) => onClickTabMenu(e, 'control')}
                      >
                        CONTROL
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        onClick={(e) => onClickTabMenu(e, 'info')}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'activity'}
                        onClick={(e) => onClickTabMenu(e, 'activity')}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'history'}
                        onClick={(e) => onClickTabMenu(e, 'history')}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>

                  {/* Control tab */}
                  {currentTab === 'control' && (
                    <div className="iot-element" id="iot-control-light2">
                      <div className="columns">
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                          >
                            Window Switch
                          </div>
                          <div className="column">
                            <img
                              alt="back-switch-3"
                              id="icon-light-close3"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '17%',
                                cursor: 'pointer',
                              }}
                              src={openlight1 ? imagesPath.open : imagesPath.close}
                              onClick={windowSW}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            <button
                              className={
                                // status.window_switch.status === 'on'
                                openlight1
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={windowSW}
                            >
                              {/* {status.window_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openlight1 ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                          >
                            Wardrobe Switch
                          </div>
                          <div className="column">
                            <img
                              alt="back-switch-4"
                              id="icon-light-close4"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '17%',
                                cursor: 'pointer',
                              }}
                              src={openlight2 ? imagesPath.open : imagesPath.close}
                              onClick={wardrobeSW}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            <button
                              className={
                                // status.wardrobe_switch.status === 'on'
                                openlight2
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={wardrobeSW}
                            >
                              {/* {status.wardrobe_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openlight2 ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                          >
                            Bed Switch
                          </div>
                          <div className="column">
                            <img
                              alt="back-switch5"
                              id="icon-light-close5"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '17%',
                                cursor: 'pointer',
                              }}
                              src={openlight3 ? imagesPath.open : imagesPath.close}
                              onClick={bedSW}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            <button
                              className={
                                // status.bed_switch.status === 'on'
                                openlight3
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={bedSW}
                            >
                              {/* {status.bed_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openlight3 ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentTab === 'info' && (
                    <Info image={image_link} header={props.header} detail={props.detail} />
                  )}
                  {currentTab === 'activity' && (
                    <ActivityData onClick={onchildClickActivity} subDevices={props.subDevices} />
                  )}
                  {currentTab === 'history' && (
                    <HistoryData
                      // data={hist_plot}
                      windowPlot={hist_plot_window}
                      wardrobePlot={hist_plot_wardrobe}
                      bedPlot={hist_plot_bed}
                      onClick={onchildClickActivity}
                      startDate={props.startDate}
                      stopDate={props.stopDate}
                      handleStartDate={props.handleStartDate}
                      handleStopDate={props.handleStopDate}
                    />
                  )}
                </nav>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export { LightSwitchBackControl };
