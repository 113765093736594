import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import AltoSupportQR from 'assets/img/AltoSupportQR.png'
import { c2d } from 'api/services/c2d'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
import { FirebaseContext2 } from '../../firebase';

const Container = styled.div`
  position: fixed;
  bottom: 39px;
  right: 120px;
`

const Wrapper = styled.div`
  position:relative;
`

const Button = styled.button`
  width: fit-content;
  height: 36px;
  font-size: 14px;
  padding: 8px 16px;
  color: white;
  border: 0px;
  font-weight: medium;
  border-radius: 8px;
  background: ${(props) => props.bgColor};
  font-weight: bold;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
  :hover{
    opacity: 0.95
  }
`

const TooltipContainer = styled.div`
  position: absolute;
  display:grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 400px;
  height: 320px;
  padding:16px;
  border-radius: 8px;
  top: -332px;
  right: 0px;
  background: white;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`

const TooltipWrap = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`

const LineQR = styled.img`
  height: 140px;
`

const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
`

const Text = styled.p`
  margin: 0px;
`

const WrapIntruder = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WrapStatus = styled.div`
  display: flex;
  align-items: center;
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
  background: ${(props) => props.online ? '#56f000' : '#ff3838'};
`

export const TroubleshootButton = (prop) => {
  const firebase = useContext(FirebaseContext2);
  const [isOpen, setIsOpen] = useState(false)
  const [intruderStatus, setIntruderStatus] = useState()
  const [intruderEvent, setIntruderEvent] = useState()

  const onChangeIntruderStatus = () => {
    if (window.confirm(`Are you sure to turn ${intruderStatus ? 'off' : 'on'}`)) {
      postC2D(intruderStatus ? 'stop' : 'start')
      setIntruderStatus(!intruderStatus)
    }
  }

  const postC2D = async (command) => {
    const resMessage = {
      success: command ? 'Start successful!' : 'Stop successful!',
      failed: command ? 'Failed to start agent' : 'Failed to stop agent'
    }
    const payload = {
      "RequestId": "12345",
      "topic": "manage_agent",
      "agent_id": "lifecycle",
      "schema": "platform",
      "action": {
        "target_agent": "action_room",
        "command": command //'stop', 'start'
      }
    }
    await toast.promise(c2d(payload), {
      loading: 'Sending request...',
      success: <b>{resMessage.success}</b>,
      error: error => {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          return <b>{resMessage.failed}</b>
        }
        return <b>{resMessage.failed}</b>
      }
    })
  }

  const checkStatus = async (didMounted) => {
    let intruder_url = 'hotel/mintel/agents/action_room';
    if (didMounted) {
      await firebase.db.ref(intruder_url).off('value');
    } else {
      await firebase.db.ref(intruder_url).on('value', function (capture) {
        if (capture) {
          let state = capture.val();
          setIntruderStatus(state.agent_status);
          setIntruderEvent(state.event_status)
        }
      });
    }
  };

  useEffect(() => {
    checkStatus(false)
    return () => {
      checkStatus(true)
    }
  }, [])

  if (window.location.pathname === "/" || window.location.pathname.includes('login')) {
    return null
  }

  return (
    <>
      <Container>
        <Wrapper>
          <Button onClick={() => setIsOpen(!isOpen)} bgColor={"#EF4337"}>
            TROUBLESHOOT
          </Button>
          <TooltipContainer style={{ display: isOpen ? 'flex' : 'none' }}>
            <TooltipWrap>
              <Title>Contact Us</Title>
              <Text>
                Add our line
              </Text>
              <LineQR src={AltoSupportQR} />
              <p>
                or
              </p>
              <Button bgColor={"#00B900"}>
                <a href="line://ti/p/@170gcigy" style={{ padding: '0px', color: 'white', fontWeight: 'bold' }} target="_blank" >CHAT WITH US</a>
              </Button>
            </TooltipWrap>
            <TooltipWrap>
              <Title>Intruder</Title>
              <WrapIntruder>
                <WrapStatus>
                  <Dot online={intruderStatus}/>
                  <p style={{marginBottom: '0px'}}>STATUS: {intruderStatus ? 'ON' : 'OFF'}</p>
                </WrapStatus>
                <Button
                  bgColor={intruderStatus ? "gray" : "red"}
                  style={{ margin: '20px', fontWeight: 'bold' }}
                  onClick={() => onChangeIntruderStatus()}>
                  {intruderStatus ? 'TURN OFF' : 'TURN ON'}
                </Button>
                {intruderEvent==="failed" && <p style={{color:'red'}}>กรุณากดอีกครั้ง</p>}
              </WrapIntruder>
            </TooltipWrap>
          </TooltipContainer>
        </Wrapper>
      </Container>
      <Toaster />
    </>
  )
}
