export const ac_position_map = [
  {
    name: 'air1',
    x1: 0.34357894094366775,
    y1: 0.16691729323308271,
    x2: 0.38989473041735195,
    y2: 0.2075187969924812,
    triggers: ['c82b962c7e5e', 'Air1'],
  },
  {
    name: 'air2',
    x1: 0.4783157830489309,
    y1: 0.2631578947368421,
    x2: 0.521473677785773,
    y2: 0.3022556390977444,
    triggers: ['c82b962c7f00', 'Air2'],
  },
  {
    name: 'air3',
    x1: 0.3656842041015625,
    y1: 0.3518796992481203,
    x2: 0.41094736199629933,
    y2: 0.39398496240601505,
    triggers: ['c82b962c7e47', 'Air3'],
  },
  {
    name: 'air4',
    x1: 0.45831578304893095,
    y1: 0.4998496234865117,
    x2: 0.5035789409436677,
    y2: 0.5404511272459102,
    triggers: ['c82b962c7d6c', 'Air4'],
  },
  {
    name: 'air5',
    x1: 0.2846315725226151,
    y1: 0.6742857137120756,
    x2: 0.32989473041735196,
    y2: 0.7178947362684666,
    triggers: ['c82b962c7e5c', 'Air5'],
  },
  {
    name: 'air6',
    x1: 0.6541052567331415,
    y1: 0.49774436090225566,
    x2: 0.6993684146278782,
    y2: 0.5383458646616541,
    triggers: ['c82b962c7eb9', 'Air6'],
  },
  {
    name: 'air7',
    x1: 0.6709473619962993,
    y1: 0.26165413533834586,
    x2: 0.7162105198910362,
    y2: 0.3037593984962406,
    triggers: ['c82b962c7cf5', 'Air7'],
  },

  {
    name: 'air8',
    x1: 0.7825263093647203,
    y1: 0.24962406015037594,
    x2: 0.8277894672594572,
    y2: 0.2932330827067669,
    triggers: ['c82b962c7fc3', 'Air8'],
  },
  {
    name: 'air9',
    x1: 0.8372631514699835,
    y1: 0.362406015037594,
    x2: 0.8825263093647204,
    y2: 0.40150375939849625,
    triggers: ['c82b962c7f16', 'Air9'],
  },

  {
    name: 'air10',
    x1: 0.8362105198910362,
    y1: 0.5007518796992482,
    x2: 0.881473677785773,
    y2: 0.5383458646616541,
    triggers: ['c82b962c7e1e', 'Air10'],
  },
  {
    name: 'air11',
    x1: 0.8351578883120888,
    y1: 0.6406015037593985,
    x2: 0.8825263093647204,
    y2: 0.6796992481203008,
    triggers: ['c82b962c8013', 'Air11'],
  },
  {
    name: 'air21',
    x1: 0.39410525673314145,
    y1: 0.7434586460429027,
    x2: 0.44989473041735195,
    y2: 0.7825563904038049,
    triggers: ['c82b962c7d77', 'Air12'],
  },
  {
    name: 'air22',
    x1: 0.5172631514699836,
    y1: 0.7449624054413989,
    x2: 0.5730526251541941,
    y2: 0.7825563904038049,
    triggers: ['c82b962c800f', 'Air13'],
  },
  {
    name: 'air23',
    x1: 0.6308623298033282,
    y1: 0.7606263982102909,
    x2: 0.6883509833585476,
    y2: 0.8031319910514542,
    triggers: ['c82b962c7e56', 'Air14'],
  },
];
