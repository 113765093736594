import React, { useRef } from 'react';
import styled from 'styled-components';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

const Container = styled.div`
  position: absolute;
  height: 70px;
  width: 90%;
  margin: 5px;
  flex-direction: row;
  overflow: scroll;
`;

const Wrapper = styled.div`
  position: absolute;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 0px;
  background-color: #ffffff;
  flex-wrap: nowrap;
  height: 70px;
  justify-items: center;
`;

const Box = styled.div`
  position: relative;
  margin: 5px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const StyledRow = styled(Row)`
  && {
    margin: 0px;
    height: 27px;
    padding: 0px;
  }
`;

const StyledCol = styled(Col)`
  && {
    margin: 0px;
    padding: 0px;
  }
`;

const Topic = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const MaidStatus = (props) => {
  const scrollRef = useRef();

  const scroll = (e) => {
    scrollRef.current.scrollLeft += e.deltaY * -0.3;
  };

  return (
    <>
      <Col lg="3" md="6">
        <Card>
          <div style={{ height: '300px' }}>
            <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
              <h5 className="card-title">{props.name}</h5>
            </CardHeader>
            <CardBody style={{ padding: '10px' }}>
              <StyledRow style={{ height: '100px' }}>
                <Container style={{ height: '90px', marginTop: '-8px' }}>
                  <Wrapper ref={scrollRef} onWheel={scroll} style={{ height: '90px' }}>
                    {props.maidUrl
                      ? props.maidUrl.map((room, index) => (
                          <StyledCol
                            style={{ margin: '0px 10px 0px 10px', padding: '2px' }}
                            key={index}
                          >
                            <p style={{ margin: '0px', textAlign: 'center' }}>
                              {Object.keys(room)[0]}
                            </p>
                            <Box url={Object.values(room)[0]} key={index} />
                          </StyledCol>
                        ))
                      : '0 Maid'}
                  </Wrapper>
                </Container>
              </StyledRow>
              <StyledRow style={{ marginTop: '0px' }}>
                <StyledCol lg="8">
                  <Topic>กำลังทำความสะอาดอยู่</Topic>
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  {props.maidStatus.is_cleaning}
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  คน
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledCol lg="8">
                  <Topic>ทำความสะอาดเสร็จ</Topic>
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  {props.maidStatus.done_cleaning}
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  ห้อง
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledCol lg="8">
                  <Topic>ห้ามรบกวน/ไม่ให้ทำ</Topic>
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  {props.maidStatus.do_not_disturb}
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  ห้อง
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledCol lg="8">
                  <Topic>ห้องที่ตรวจสอบแล้ว</Topic>
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  {props.maidStatus.done_inspecting}
                </StyledCol>
                <StyledCol lg="2" style={{ textAlign: 'center' }}>
                  ห้อง
                </StyledCol>
              </StyledRow>
            </CardBody>
          </div>
        </Card>
      </Col>
    </>
  );
};
