export const HOTEL = {
  GET_MAP_DATA_REQUEST: 'GET_MAP_DATA_REQUEST',
  GET_MAP_DATA_SUCCESS: 'GET_MAP_DATA_SUCCESS',
  GET_MAP_DATA_FAILURE: 'GET_MAP_DATA_FAILURE',
  GET_ALL_DEVICES_REQUEST: 'GET_ALL_DEVICES_REQUEST',
  GET_ALL_DEVICES_SUCCESS: 'GET_ALL_DEVICES_SUCCESS',
  GET_ALL_DEVICES_FAILURE: 'GET_ALL_DEVICES_FAILURE',
  GET_ALL_ACTIVITIES_REQUEST: 'GET_ALL_ACTIVITIES_REQUEST',
  GET_ALL_ACTIVITIES_SUCCESS: 'GET_ALL_ACTIVITIES_SUCCESS',
  GET_ALL_ACTIVITIES_FAILURE: 'GET_ALL_ACTIVITIES_FAILURE',
  PLOT_FIREBASE: 'PLOT_FIREBASE',
  CLEAR_PLOT: 'CLEAR_PLOT',
  DROP_DOWN_ACCTIVITY: 'DROP_DOWN_ACCTIVITY',
  AC_ACTIVITY_PLOT: 'AC_ACTIVITY_PLOT',
  AC_CLEAR_ACTIVITY_PLOT: 'AC_CLEAR_ACTIVITY_PLOT',
};
