import React from 'react';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

// import { PrivateRoute } from '../../nav/components';

import { history } from '../../../history';

// import { ErrorPage } from '../../root/pages';
import { LoginPage } from '../../auth/pages';
// import { UserSettingsPage } from '../../user/pages';
// import { DashboardPage } from '../../dashboard/pages';
// import { EnergyContainer } from '../../energy/containers';
// import { Automation } from '../../automation/containers';
import Admin from '../../../layout/Admin';

function MainNavigationPage(props) {
  return (
    <Router history={history}>
      <Switch>
        {/* Private Route */}
        {/* <PrivateRoute path="/splash" component={SplashPage} /> */}

        {/* <Redirect to="admin/dashboard" /> */}

        <Route path="/admin" render={(props) => <Admin {...props} />} />

        {/* <PrivateRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <PrivateRoute exact path="/" component={UserSettingsPage} /> */}
        {/* Normal Route */}
        <Route path="/login" component={LoginPage} />
        <Route path="/" component={LoginPage} />
        <Redirect to="/admin/dashboard" />
        {/* <Route path="/energy" component={EnergyContainer} /> */}

        {/* <Route path="/automation" component={Automation} /> */}
        {/* <Route path="/error" component={ErrorPage} /> */}
      </Switch>
    </Router>
  );
}

export { MainNavigationPage };
