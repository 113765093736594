// import { GLOBAL_CONSTANTS } from '../../../constants';

import { DASHBOARD } from '../types';

function selectedRoom(roomNo, roomData) {
  return {
    type: DASHBOARD.SELECTED_ROOM,
    roomNo,
    roomData,
  };
}

function resetSelectedRoom() {
  return {
    type: DASHBOARD.RESET_SELECTED_ROOM,
  };
}

//##---------------- Room Details Modal Control -----------
function showOneRoomDetailsModal() {
  return {
    type: DASHBOARD.SHOW_ONE_ROOM_DETAILS_MODAL,
  };
}

function hideOneRoomDetailsModal() {
  return {
    type: DASHBOARD.HIDE_ONE_ROOM_DETAILS_MODAL,
  };
}

//##---------------- Room Iot Modal Control -----------
function showOneRoomIotModal() {
  return {
    type: DASHBOARD.SHOW_ONE_ROOM_IOT_MODAL,
  };
}

function hideOneRoomIotModal() {
  return {
    type: DASHBOARD.HIDE_ONE_ROOM_IOT_MODAL,
  };
}

//##----------------Room Device Control-----------------
function isControlIot() {
  return {
    type: DASHBOARD.IS_CONTROL_IOT,
  };
}

function mouseHover(payload) {
  return {
    type: DASHBOARD.MOUSE_HOVER,
    payload: payload,
  };
}

function synDeviesControl(payload) {
  return {
    type: DASHBOARD.SYN_DEIVIES_CONTROL,
    payload: payload,
  };
}

function showSynACControlModal() {
  return {
    type: DASHBOARD.SHOW_SYN_AC_CONTROL,
  };
}

function hideSynACControlModal() {
  return {
    type: DASHBOARD.HIDE_SYN_AC_CONTROL,
  };
}

function plotSynEnergy(data) {
  return {
    type: DASHBOARD.PLOT_SYN_ENERGY,
    payload: data,
  };
}
function showIotModal(data) {
  return {
    type: DASHBOARD.SHOW_IOT_MODAL,
    payload: data,
  };
}

function modalName(data) {
  return {
    type: DASHBOARD.MODAL_NAME,
    payload: data,
  };
}

export const dashboardControlActions = {
  selectedRoom,
  resetSelectedRoom,
  showOneRoomDetailsModal,
  hideOneRoomDetailsModal,
  showOneRoomIotModal,
  hideOneRoomIotModal,
  isControlIot,
  showIotModal,
  mouseHover,
  synDeviesControl,
  showSynACControlModal,
  hideSynACControlModal,
  plotSynEnergy,
  modalName,
};
