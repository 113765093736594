import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './store';

import { FirebaseProvider2 } from './firebase';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { TroubleshootButton } from './components/TroubleshootButton'

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.2.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <FirebaseProvider2>
      <App />
      <TroubleshootButton />
    </FirebaseProvider2>
    <MessengerCustomerChat pageId={'685983694926359'} appId={'971858410399697'} />
  </Provider>,
  /* </React.StrictMode>, */
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
