export const USER = {
  GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

  RESET_USER_STATE_REQUEST: 'RESET_USER_STATE_REQUEST',
  RESET_USER_STATE_SUCCESS: 'RESET_USER_STATE_SUCCESS',
  RESET_USER_STATE_FAILURE: 'RESET_USER_STATE_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  CHANGE_GUEST_ROOM_REQUEST: 'CHANGE_GUEST_ROOM_REQUEST',
  CHANGE_GUEST_ROOM_SUCCESS: 'CHANGE_GUEST_ROOM_SUCCESS',
  CHANGE_GUEST_ROOM_FAILURE: 'CHANGE_GUEST_ROOM_FAILURE',

  GET_PENDING_USER_LIST_REQUEST: 'GET_PENDING_USER_LIST_REQUEST',
  GET_PENDING_USER_LIST_SUCCESS: 'GET_PENDING_USER_LIST_SUCCESS',
  GET_PENDING_USER_LIST_FAILURE: 'GET_PENDING_USER_LIST_FAILURE',

  ACCEPT_PENDING_USER_REQUEST: 'ACCEPT_PENDING_USER_REQUEST',
  ACCEPT_PENDING_USER_SUCCESS: 'ACCEPT_PENDING_USER_SUCCESS',
  ACCEPT_PENDING_USER_FAILURE: 'ACCEPT_PENDING_USER_FAILURE',

  DISCARD_PENDING_USER_REQUEST: 'DISCARD_PENDING_USER_REQUEST',
  DISCARD_PENDING_USER_SUCCESS: 'DISCARD_PENDING_USER_SUCCESS',
  DISCARD_PENDING_USER_FAILURE: 'DISCARD_PENDING_USER_FAILURE',
};
