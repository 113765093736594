import styled from 'styled-components';

export const UpdatedAt = styled.div`
  font-size: 9px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 10px;
  margin: 2px 4px 2px 2px;
  background-color: #dddddd;
`;
