import React, { Fragment, Children, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { DevicesTab, UsersTabContainer } from '../Tabs';
import styled from 'styled-components';

export const ComingSoon = styled.div`
  padding-top: 10px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  vertical-align: center;
  font-size: 30px;
  color: black;
  font-weight: bold;
  z-index: 999;
`;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(0.5),
  },
});

function TabContainer({ children, value: valueProp }) {
  const [value, setValue] = useState();

  const onChange = (e, value) => {
    setValue(value);
  };

  if (value === undefined) {
    setValue(valueProp);
  }

  return (
    <Fragment>
      <Tabs value={value} onChange={onChange} indicatorColor="primary">
        {Children.map(children, (child) => (
          <Tab label={child.props.label} style={{ outline: 'none', width: '100px' }} />
        ))}
      </Tabs>
      {Children.map(children, (child, index) => (index === value ? child : null))}
    </Fragment>
  );
}

TabContainer.defaultProps = {
  value: 0,
};

const TabContent = withStyles(styles)(({ classes, children }) => (
  <Typography component="div" className={classes.tabContent}>
    {children}
  </Typography>
));

const SettingLayouTabs = withStyles(styles)(() => {
  const hotelCode = localStorage.getItem('hotel_hotel_code');
  return (
    <div className="content" style={{ fontFamily: 'Montserrat' }}>
      {hotelCode === 'MIN' && (
        <TabContainer value={0}>
          <TabContent
            label={
              <div style={{ fontSize: '15px', fontFamily: 'Montserrat' }}>
                <i className="nc-icon nc-globe text-warning" style={{ verticalAlign: 'middle' }} />{' '}
                Devices
              </div>
            }
          >
            <DevicesTab />
          </TabContent>
          <TabContent
            label={
              <div style={{ fontSize: '15px', fontFamily: 'Montserrat' }}>
                <i className="nc-icon nc-globe text-warning" style={{ verticalAlign: 'middle' }} />{' '}
                User
              </div>
            }
          >
            <UsersTabContainer />
          </TabContent>
        </TabContainer>
      )}
    </div>
  );
});

export { SettingLayouTabs };
