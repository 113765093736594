import React, { useState } from 'react';
import { Card, CardDeck, Row, Col } from 'react-bootstrap';
import { Menu } from './Tabs/Menu';

export const Env = () => {
  const [currentTab, setCurrentTab] = useState('control');
  // console.log({ currentTab });

  const DateTime = new Date().toLocaleString();

  return (
    <div>
      <Menu currentTab={(e) => setCurrentTab(e)} />
      {currentTab === 'control' && (
        <>
          <CardDeck>
            <Card bg="secondary" text="light" style={{ width: '18rem' }} className="mb-2">
              <Card.Header>Temperature</Card.Header>
              <Card.Body>
                <Card.Title>25 &deg;C </Card.Title>
              </Card.Body>
            </Card>
            <Card bg="secondary" text="light" style={{ width: '18rem' }} className="mb-2">
              <Card.Header>Air Quality</Card.Header>
              <Card.Body>
                <Card.Title>Good</Card.Title>
              </Card.Body>
            </Card>

            <Card bg="success" text="light" style={{ width: '18rem' }} className="mb-2">
              <Card.Header>Humidity</Card.Header>
              <Card.Body>
                <Card.Title>56 %</Card.Title>
              </Card.Body>
            </Card>
          </CardDeck>
          <CardDeck>
            <Card bg="primary" text="light" style={{ width: '18rem' }} className="mb-2">
              <Card.Header>Luminosity</Card.Header>
              <Card.Body>
                <Card.Title>normal</Card.Title>
              </Card.Body>
            </Card>
            <Card bg="success" text="light" style={{ width: '18rem' }} className="mb-2">
              <Card.Header>Noise</Card.Header>
              <Card.Body>
                <Card.Title>normal</Card.Title>
              </Card.Body>
            </Card>
            <Card bg="success" text="light" style={{ width: '18rem' }} className="mb-2">
              <Card.Header>Last Update</Card.Header>
              <Card.Body>
                <Card.Title>{DateTime}</Card.Title>
              </Card.Body>
            </Card>
          </CardDeck>
        </>
      )}
      {currentTab === 'info' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {currentTab === 'activity' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {currentTab === 'history' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
