import React from 'react';
import { MaidLayout } from 'components/Housekeeper';

const MaidContainer = () => {
  return (
    <div className="content">
      <MaidLayout />
    </div>
  );
};

export { MaidContainer };
