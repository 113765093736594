import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => (props.disabled ? '#b5b5b5' : 'white')};
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  background-image: url(${(props) => props.url});
  background-size: cover;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

export const CardTextHeader = styled.div`
  height: 100%;
  padding-top: 10px;
  vertical-align: center;
  font-size: 30px;
  color: white;
  font-weight: bold;
`;

export const AppBox = styled.div`
  height: 120px;
  width: 100%;
  margin: 10px;
  padding: 10px;
  z-index: ${(props) => (props.disabled ? '1' : '999')};
`;

export const ComingSoon = styled.div`
  padding-top: 10px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  vertical-align: center;
  font-size: 30px;
  color: black;
  font-weight: bold;
  z-index: 999;
`;

export const Logo = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 5px;
  background-color: black;
  background-image: url(${(props) => props.url});
  background-size: cover;
`;
