import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ToysIcon from '@material-ui/icons/Toys';
import KitchenIcon from '@material-ui/icons/Kitchen';
import TvIcon from '@material-ui/icons/Tv';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SingleBedIcon from '@material-ui/icons/SingleBed';
import InputIcon from '@material-ui/icons/Input';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100px;
  margin: 10px 0px 10px 0px;
  padding: 5px;
  padding-bottom: 0px;
  padding-top: 5px;
  /* border-bottom: 0.5px solid #e3e3e3; */
`;

const DeleteButton = styled.button`
  margin-top: 15px 15px 0 0;
  padding: 3px;
  border: 0;
  background-color: transparent;
`;

const ConvertIcon = (props) => {
  return [
    'Toilet Switch',
    'Corridor Switch',
    'Main Switch',
    'Window Switch',
    'Wardrobe Switch',
    'Bed Switch',
  ].includes(props.device) ? (
    <EmojiObjectsIcon style={{ color: props.state ? '#ffe998' : '#e4e4e4' }} />
  ) : ['Smart TV'].includes(props.device) ? (
    <TvIcon style={{ color: props.state ? '#b9eae6' : '#e4e4e4' }} />
  ) : ['Refrigerator'].includes(props.device) ? (
    <KitchenIcon style={{ color: props.state ? '#b1e5ff' : '#e4e4e4' }} />
  ) : ['Air Conditioner'].includes(props.device) ? (
    <AcUnitIcon style={{ color: props.state ? '#b1e5ff' : '#e4e4e4' }} />
  ) : ['Exhaust Fan'].includes(props.device) ? (
    <ToysIcon style={{ color: props.state ? '#b9eae6' : '#e4e4e4' }} />
  ) : null;
};

export const CurrentAutomationTab = (props) => {
  const remove = () => {
    props.toggleDelete(props.automationId);
  };
  return (
    <Wrapper>
      <Row style={{ margin: '0px' }}>
        <Col lg="2" md="5" style={{ padding: '0px' }}>
          <div style={{ marginLeft: '8px', padding: '0', fontSize: '11px', overflowX: 'auto' }}>
            <strong style={{ fontSize: '20px' }}>
              <SingleBedIcon /> {props.roomNumber}
            </strong>
            <br />
          </div>
        </Col>
        <Col lg="2" md="12" style={{ padding: '0px' }}>
          <div style={{ marginLeft: '0px', padding: '0', fontSize: '11px', overflowX: 'auto' }}>
            <strong style={{ fontSize: '20px' }}>
              {props.triggerEvent === 'check_in' ? <InputIcon /> : <CancelPresentationIcon />}{' '}
              {props.triggerEvent}
            </strong>
            <br />
          </div>
        </Col>

        <Col lg="8" md="8" style={{ padding: '0', display:'flex', alignItems:'center', justifyContent:'flex-end' }}>
          <div style={{ marginLeft: '0px',marginRight: '20px', padding: '0', fontSize: '15px', overflowX: 'auto' }}>
            updated on {props.updatedtime ? props.updatedtime : 'none'}
            <br />
          </div>
          <DeleteButton type="button" onClick={() => remove()}>
            <DeleteForeverIcon style={{ color: '#ff6262' }} />
          </DeleteButton>
        </Col>
      </Row>

      <Row style={{ margin: '0px' }}>
        <Col
          lg="12"
          md="12"
          style={{
            justifyContent: 'center',
            fontSize: '11px',
            padding: '0',
            marginTop: '15px',
            minHeight: '100px',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              fontSize: '11px',
              padding: '0',
              minHeight: '100px',
              paddingBottom: '15px',
            }}
          >
            {Object.keys(props.action).map((room, index) => (
              <Col lg="12" key={index}>
                <Row style={{ margin: '0px' }}>
                  {Object.keys(props.action[room]).map((device, index) => (
                    <Col lg="3" key={index}>
                      <FormControlLabel
                        control={
                          <div style={{ margin: '0px 5px 0px 0px' }}>
                            <ConvertIcon
                              device={device}
                              state={props.action[room][device].command.state === 'on'}
                            />
                          </div>
                        }
                        label={device}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
