import React, { useState, useContext, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { List } from '../../../../../components/List/TabList';
import { Col, Row } from 'reactstrap';
import { FirebaseContext2 } from '../../../../../firebase';
import { Info, ActivityData, HistoryData } from '../../../components/Tabs';
import { energyActions } from '../../../../energy/actions';
import moment from 'moment';
import { roomActivitiesHistories } from '../../../../../api/services';
import { hotelActions } from '../../../actions';
import { API_CONSTANTS } from '../../../../../api';
import { iotControlServices } from '../../../../../api/services';
import Swal from 'sweetalert2';

const AcControl = (props) => {
  const dispatch = useDispatch();
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const firebase = useContext(FirebaseContext2);

  const dropdown = useSelector((state) => state.hotel.dropdown);
  const acPlotRealTime = useSelector((state) => state.hotel.ac);

  const [tmpHist, settmpHist] = useState(null);
  const [plotMode, setPlotMode] = useState([]);
  const [plotRoomTemp, setPlotRoomTemp] = useState([]);
  const [plotTemp, setPlotTemp] = useState([]);

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;

  const [currentTab, setCurrentTab] = useState('control');
  const timeoutid = useRef();

  //##-------------------------------Close Modal----------------------------------
  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(energyActions.clearHistoryData());
    dispatch(hotelActions.clearPlot());
    dispatch(dashboardControlActions.showIotModal(false));
  };

  const onchildClickActivity = (event) => {
    props.onchildClickActivity(event);
  };

  //#---------------------Constant Currente State---------------
  const [mainSwitch, setMainSwitch] = useState(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [temp, setTemp] = useState(null);
  const [fanMode, setFanMode] = useState(null);
  const [envTemp, setEnvTemp] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [buttonState, setButtonState] = useState({
    mode: null,
    fan: null,
    temp: null,
  });

  const [modeFromFire, setModeFromFire] = useState(null);
  const [fanFromFire, setFanFromFire] = useState(null);
  const [tempFromFire, setTempFromFire] = useState(null);

  //##------------------Constant Previouse Mode------------------
  const [tmpWaiting, settmpWaiting] = useState(false);

  const d = new Date();

  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  //##-----------------------Fetch data from firebase-------------------
  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/ac_' +
      currentRoomNo +
      '/ac/ac/';

    const fanPath = fbpath + 'fan';
    const modePath = fbpath + 'mode';
    const setTempPath = fbpath + 'temperature';
    const envTempPath = fbpath + 'room_temperature';
    const timestampPath = fbpath + 'timestamp';

    if (didMounted) {
      console.log('AC did mounted');
      await firebase.db.ref(fanPath).off('value');
      await firebase.db.ref(modePath).off('value');
      await firebase.db.ref(setTempPath).off('value');
      await firebase.db.ref(envTempPath).off('value');
      await firebase.db.ref(timestampPath).off('value');
    } else {
      await firebase.db.ref(fanPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setFanMode(capt);
          setFanFromFire(capt);
        }
      });

      await firebase.db.ref(modePath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setCurrentMode(capt);
          setModeFromFire(capt);
        }
      });

      await firebase.db.ref(setTempPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setTemp(parseInt(capt));
          setTempFromFire(parseInt(capt));
        }
      });

      await firebase.db.ref(envTempPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setEnvTemp(capt);
        }
      });

      await firebase.db.ref(timestampPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setTimeStamp(capt);
        }
      });
    }
  }

  //##------------------------Fetch History data--------------------------
  async function histData(dev_id, nick_name) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        dropdown,
        nick_name,
        false,
        props.startDate,
        props.stopDate
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##----------------------Trigger API------------------------------------
  async function triggerAPI(currentRoomNo, temp, mode, fan) {
    //##-------------------------Payload-----------------------------------
    const payload = {
      topic: 'devicecontrol',
      action: {
        [currentRoomNo]: {
          'Air Conditioner': {
            command: {
              mode: mode,
              fan: fan,
              temperature: temp,
              // state: mainSwitch ? 'off' : 'on',
            },
          },
        },
      },
      RequestId: '12345',
    };

    console.log(payload.message, payload);
    iotControlServices.postIotApi(payload);
  }

  // console.log(currentRoomNo);

  //##-----------------------Sync Firebase only-----------------
  useMemo(() => {
    fetchData(firebase, currentRoomNo, false);
    return () => {
      fetchData(firebase, currentRoomNo, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##-----------------------Initial Button---------------------
  const initState = (mode) => {
    if (mode !== 'off') {
      setMainSwitch(true);
      // dispatch(dashboardControlActions.acPrevStatus('on'));
    } else {
      setMainSwitch(false);
      // dispatch(dashboardControlActions.acPrevStatus('off'));
    }
    // setCurrentMode(mode);
    // setFanMode(fan);
    // setTemp(temp);
  };

  //##-------------------History Data for plotting-------------
  useMemo(() => {
    if (tmpHist !== null) {
      let temp = [];
      let room_temp = [];
      let mode = [];
      tmpHist.forEach((item) => {
        if (
          item.temperature !== undefined &&
          item.timestamp !== undefined &&
          item.room_temperature !== undefined &&
          item.mode !== undefined &&
          item.fan !== undefined
        ) {
          temp.push([Number(moment(item.timestamp).format('x')), item.temperature]);
          room_temp.push([Number(moment(item.timestamp).format('x')), item.room_temperature]);
          mode.push([Number(moment(item.timestamp).format('x')), item.mode === 'off' ? 0 : 1]);
          // fan.push([Number(moment(item.timestamp).format('x')), item.temperature]);
        }
        setPlotTemp(temp);
        setPlotMode(mode);
        setPlotRoomTemp(room_temp);

        // setPlotHumi(humi);
      });
    }
  }, [tmpHist]);

  //##---------------------Fetch History Data-------------------
  useMemo(() => {
    devices.forEach((element) => {
      if (element['device_name'] === 'ac') {
        try {
          histData(element.device_id, 'ac').then((results) => {
            return settmpHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown, currentRoomNo, props.startDate, props.stopDate]);

  //##----------------------Set status value-----------------------
  useMemo(() => {
    initState(currentMode);
  }, [currentMode]);

  //##--------------------Mode Changing--------------------------
  useMemo(() => {
    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(currentRoomNo, parseInt(temp), currentMode, fanMode),
        1000
      );
    }
    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode, fanMode]);

  //##-------------------Dispath plot Data-----------------------
  useMemo(() => {
    if (currentMode !== null && envTemp !== null && temp !== null && timeStamp !== null) {
      dispatch(
        hotelActions.acPlotActivity({
          ac: {
            room_temp: [Number(moment(timeStamp).format('x')), envTemp],
            temp: [Number(moment(timeStamp).format('x')), temp],
            mode: [Number(moment(timeStamp).format('x')), currentMode === 'off' ? 0 : 1],
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode, envTemp, temp, timeStamp]);

  //##----------------------Set Temp value-----------------------
  useMemo(() => {
    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(() => {
        console.log(currentRoomNo);
        triggerAPI(currentRoomNo, buttonState.temp, currentMode, fanMode);
        Swal.fire({
          title: 'Please wait, Temperature is changing',
          didOpen: () => {
            if (parseInt(modeFromFire) !== parseInt(buttonState.temp)) {
              Swal.showLoading();
            }
          },
        });
      }, 700);
      console.log(buttonState.temp);
    }
    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonState.temp]);

  useMemo(() => {
    clearTimeout(timeoutid.current);

    timeoutid.current = setTimeout(() => {
      if (tmpWaiting) {
      } else {
        //##--> STEP 3 : If equal => Do nothing.
        console.log('complete...');
        Swal.close();
        settmpWaiting(false);
      }
    }, 700);
  }, [tmpWaiting]);

  useMemo(() => {
    //##--> STEP 1 : Check button state === state from firebase ?
    if (tmpWaiting) {
      if (modeFromFire !== buttonState.mode && buttonState.mode !== null) {
        setCurrentMode(buttonState.mode);
        console.log('waiting....');
        //##--> STEP 2 : Show Modal wating process.
        Swal.fire({
          title: 'Please wait, Mode is changing',
          didOpen: () => {
            if (modeFromFire !== buttonState.mode) {
              Swal.showLoading();
            }
          },
        });
      } else if (fanFromFire !== buttonState.fan && buttonState.fan !== null) {
        setFanMode(buttonState.fan);
        Swal.fire({
          title: 'Please wait, Fan mode is changing',
          didOpen: () => {
            if (fanFromFire !== buttonState.fan) {
              Swal.showLoading();
            }
          },
        });
      } else if (
        parseInt(tempFromFire) !== parseInt(buttonState.temp) &&
        buttonState.temp !== null
      ) {
        setTemp(parseInt(buttonState.temp));
      } else {
        //##--> STEP 3 : If equal => Do nothing.
        console.log('complete...');
        Swal.close();
        settmpWaiting(false);
      }
    }
  }, [tmpWaiting, modeFromFire, buttonState, fanFromFire, tempFromFire]);

  //##----------------------Set Temp-------------------------------------
  const onSettemp = (event, value) => {
    event.preventDefault();
    if (value === 'increment') {
      // setTemp(parseInt(temp) + 1);
      settmpWaiting(true);
      setButtonState({
        ...buttonState,
        temp: parseInt(temp) + 1,
      });
    }
    if (value === 'decrement') {
      // setTemp(parseInt(temp) - 1);
      settmpWaiting(true);
      setButtonState({
        ...buttonState,
        temp: parseInt(temp) - 1,
      });
    }
  };

  //##-----------------------Fan Mode------------------------------------
  const onClickFanMode = (event, value) => {
    event.preventDefault();
    console.log({ value });
    // setFanMode(value);
    settmpWaiting(true);
    setButtonState({
      ...buttonState,
      fan: value,
    });
  };

  //##------------------------Mode Control-------------------------------
  const onClickControlMode = (event, value) => {
    event.preventDefault();
    settmpWaiting(true);
    setButtonState({
      ...buttonState,
      mode: value,
    });
  };

  //##-------------------Tab menu------------------------------
  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };

  const image_link =
    'https://www.wolseley.co.uk/wcsstore7.00.1066.986/ExtendedSitesCatalogAssetStore/images/products/AssetPush/wol-web-proof-pdp-w/std.lang.all/79/47/611099_697947_wpdp.jpg';

  const Temp_plot = {
    series: [
      {
        name: 'Room Temperature',
        // data: Temp !== undefined ? plotTemp.concat(Temp) : [],

        data: plotRoomTemp.concat(acPlotRealTime.room_temp),
        // data: acPlotRealTime.room_temp.concat(plotRoomTemp),
        // data: acPlotRealTime.room_temp,
      },
      {
        name: 'Set Temperature',

        data: plotTemp.concat(acPlotRealTime.temp),
        // data: acPlotRealTime.temp.concat(plotTemp),
        // data: acPlotRealTime.temp,
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      title: {
        text: 'Temperature',
        align: 'left',
      },
      yaxis: [
        {
          seriesName: 'Room Temperature',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              color: '#008FFB',
            },
          },
          title: {
            text: '°C',
            style: {
              color: '#008FFB',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      stroke: {
        // OR provide an array
        width: 2,
        curve: ['smooth', 'smooth'],
      },
    },
  };
  const mode_plot = {
    series: [
      {
        name: 'Mode',
        data: plotMode.concat(acPlotRealTime.mode),
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      title: {
        text: 'Mode (on/off)',
        align: 'left',
      },
      yaxis: [
        {
          tickAmount: 1,
          labels: {
            formatter: function (value) {
              return value === 1 ? 'ON' : 'OFF';
            },
          },
          seriesName: 'Mode',
          title: {
            text: 'Mode (on/off)',
            style: {
              color: '#FEB019',
            },
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      stroke: {
        // OR provide an array
        width: 2,
        curve: ['stepline'],
      },
    },
  };

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Air Condition Control
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="ucount-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'control'}
                        onClick={(e) => onClickTabMenu(e, 'control')}
                      >
                        CONTROL
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        onClick={(e) => onClickTabMenu(e, 'info')}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'activity'}
                        onClick={(e) => onClickTabMenu(e, 'activity')}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'history'}
                        onClick={(e) => onClickTabMenu(e, 'history')}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>
                  {currentTab === 'control' && (
                    <div className="iot-element" id="iot-control-air" style={{ marginTop: '35px' }}>
                      <div className="columns">
                        <div
                          className="column"
                          style={{
                            border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Status
                          </div>
                          <div className="column">
                            <img
                              alt="remote"
                              id="iot-device-icon"
                              src="/assets/images/devices/air-conditioner.png"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            />
                          </div>
                          <div className="column">
                            <button
                              className={
                                currentMode !== 'off'
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={(e) =>
                                onClickControlMode(e, currentMode === 'off' ? 'cool' : 'off')
                              }
                            >
                              {!mainSwitch ? 'OFF' : 'ON'}
                            </button>
                          </div>
                        </div>
                        <div
                          className="column"
                          style={{
                            border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          {currentMode === 'fan' ? (
                            <>
                              <div
                                className="column"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Fan Mode
                              </div>
                              <br />
                              <br />{' '}
                              <p style={{ textAlign: 'center' }}>
                                Can not control temperature
                              </p>{' '}
                            </>
                          ) : (
                            <>
                              {' '}
                              <div
                                className="column"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Set Temp
                              </div>
                              {/* increte button */}
                              <button
                                className="button is-info"
                                style={{
                                  display: 'block',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  paddingTop: '0px',
                                }}
                                onClick={(e) => onSettemp(e, 'increment')}
                              >
                                <i
                                  className="fa fa-caret-up"
                                  style={{
                                    color: 'white',
                                    fontSize: '44px',
                                    lineHeight: '40px',
                                  }}
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <div className="column">
                                <span
                                  style={{
                                    fontSize: '44px',
                                    color: '#0af',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {/* {status.air_con.set_temp } */}
                                  {temp}
                                </span>
                                <span
                                  style={{
                                    fontSize: '26px',
                                    color: '#aaa',
                                  }}
                                >
                                  ºC
                                </span>
                              </div>
                              {/* decrete button */}
                              <button
                                className="button is-info"
                                style={{
                                  display: 'block',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  paddingTop: '0px',
                                }}
                                onClick={(e) => onSettemp(e, 'decrement')}
                              >
                                <i
                                  className="fa fa-caret-down"
                                  style={{
                                    color: 'white',
                                    fontSize: '44px',
                                    lineHeight: '40px',
                                  }}
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </>
                          )}
                        </div>

                        <div
                          className="column"
                          style={{
                            border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Room Temp
                          </div>
                          <div
                            className="column"
                            style={{
                              marginTop: '40px',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '46px',
                                color: '#0af',
                                fontWeight: 'bold',
                              }}
                            >
                              {envTemp}
                            </span>{' '}
                            <span
                              style={{
                                fontSize: '26px',
                                color: '#aaa',
                              }}
                            >
                              ºC
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="columns">
                        <Col
                          style={{
                            border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Mode Control
                          </div>
                          <div className="columns">
                            <div className="column">
                              <Row style={{ justifyContent: 'center' }}>
                                <button
                                  value="cool"
                                  // className={status.air_con.mode === 'off' ? 'button is-info' : 'button'}
                                  className={currentMode === 'off' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickControlMode(e, 'off')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '70px',
                                    font: '15px',
                                  }}
                                >
                                  Off
                                </button>
                                <button
                                  value="cool"
                                  className={currentMode === 'cool' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickControlMode(e, 'cool')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '70px',
                                    font: '15px',
                                  }}
                                >
                                  Cool
                                </button>

                                <button
                                  value="dry"
                                  className={currentMode === 'dry' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickControlMode(e, 'dry')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '70px',
                                    font: '15px',
                                  }}
                                >
                                  Dry
                                </button>
                                <button
                                  value="fan"
                                  className={currentMode === 'fan' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickControlMode(e, 'fan')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '70px',
                                    font: '15px',
                                  }}
                                >
                                  Fan
                                </button>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col
                          style={{
                            border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Fan Speed
                          </div>
                          <div className="columns">
                            <div className="column">
                              <Row style={{ justifyContent: 'center' }}>
                                {currentMode === 'fan' ? (
                                  ''
                                ) : (
                                  <button
                                    value="auto"
                                    className={fanMode === 'auto' ? 'button is-info' : 'button'}
                                    onClick={(e) => onClickFanMode(e, 'auto')}
                                    style={{
                                      margin: '10px 1px 20px 1px',
                                      boxSizing: 'border-box',
                                      width: '65px',
                                      font: '15px',
                                    }}
                                  >
                                    Auto
                                  </button>
                                )}
                                <button
                                  value="low"
                                  className={fanMode === 'low' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickFanMode(e, 'low')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '65px',
                                    font: '15px',
                                  }}
                                >
                                  Low
                                </button>

                                <button
                                  value="medium"
                                  className={fanMode === 'medium' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickFanMode(e, 'medium')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '85px',
                                    font: '15px',
                                  }}
                                >
                                  Medium
                                </button>
                                <button
                                  value="high"
                                  className={fanMode === 'high' ? 'button is-info' : 'button'}
                                  onClick={(e) => onClickFanMode(e, 'high')}
                                  style={{
                                    margin: '10px 1px 20px 1px',
                                    boxSizing: 'border-box',
                                    width: '65px',
                                    font: '15px',
                                  }}
                                >
                                  High
                                </button>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  )}
                  {currentTab === 'info' && (
                    <div>
                      <Info image={image_link} detail={props.detail} header={props.header} />
                    </div>
                  )}

                  {currentTab === 'activity' && (
                    <ActivityData onClick={onchildClickActivity} subDevices={props.subDevices} />
                  )}
                  {currentTab === 'history' && (
                    <HistoryData
                      data={Temp_plot}
                      mode_plot={mode_plot}
                      startDate={props.startDate}
                      stopDate={props.stopDate}
                      handleStartDate={props.handleStartDate}
                      handleStopDate={props.handleStopDate}
                    />
                  )}
                </nav>
              </div>
            </div>
          )}
        </section>

        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export { AcControl };
