import axios from 'axios';
import { API_CONSTANTS } from '../../api';

/**
 *	NAME: login
 *	DESCRIPTION: login admin to system
 *	METHOD: POST
 */
async function login(payload) {
  const requestOptions = {
    url: API_CONSTANTS.LOGIN_URL,
    method: 'POST',

    // mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Authorization: 'Bearer ' + ADMIN_JWT_KEY,
      // 'Access-Control-Allow-Origin':'*',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
      // 'Access-Control-Allow-Headers': 'Content-Type'
    },
    // credentials: 'include',
    data: JSON.stringify(payload),
  };

  try {
    let responseJson = await axios.request(requestOptions);

    return responseJson.data;
  } catch (error) {
    //## [DEBUG]
    console.error('[Services] login Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

export const loginServices = {
  login,
};
