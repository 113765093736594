export const cam_position_map = [
  {
    name: 'cctv1',
    x1: 0.2962105198910362,
    y1: 0.10827067669172932,
    x2: 0.3193684146278783,
    y2: 0.14135338345864662,
    triggers: ['cctv1'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-20.png',
  },
  {
    name: 'cctv2',
    x1: 0.33410525673314145,
    y1: 0.5022556390977444,
    x2: 0.3583157830489309,
    y2: 0.530827067669173,
    triggers: ['cctv2'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-14.png',
  },
  {
    name: 'cctv3',
    x1: 0.2993684146278783,
    y1: 0.518796992481203,
    x2: 0.3256842041015625,
    y2: 0.5488721804511278,
    triggers: ['cctv3'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-15.png',
  },
  {
    name: 'cctv4',
    x1: 0.8288420988384047,
    y1: 0.10887217987748914,
    x2: 0.8530526251541941,
    y2: 0.14195488664440642,
    triggers: ['cctv4'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-18.png',
  },
  {
    name: 'cctv5',
    x1: 0.7372631514699836,
    y1: 0.451729322734632,
    x2: 0.7635789409436677,
    y2: 0.4818045107045568,
    triggers: ['cctv5'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-16.png',
  },
  {
    name: 'cctv6',
    x1: 0.7688420988384046,
    y1: 0.4336842099526771,
    x2: 0.7930526251541941,
    y2: 0.46075187912560944,
    triggers: ['cctv6'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-17.png',
  },
  {
    name: 'cctv7',
    x1: 0.9193684146278783,
    y1: 0.5930827061932786,
    x2: 0.9446315725226151,
    y2: 0.6261654129601959,
    triggers: ['cctv7'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-12.png',
  },
  {
    name: 'cctv8',
    x1: 0.9235789409436678,
    y1: 0.7224060144639552,
    x2: 0.9488420988384046,
    y2: 0.7524812024338801,
    triggers: ['cctv8'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-11.png',
  },
  {
    name: 'cctv9',
    x1: 0.8098947304173519,
    y1: 0.7359398490504214,
    x2: 0.8341052567331414,
    y2: 0.7675187964188425,
    triggers: ['cctv9'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-13.png',
  },
  {
    name: 'cctv10',
    x1: 0.8688420988384046,
    y1: 0.8727819543135794,
    x2: 0.893052625154194,
    y2: 0.9028571422835042,
    triggers: ['cctv10'],

    img_url:
      'https://altotechpublic.s3-ap-southeast-1.amazonaws.com/syn_hotel/Photos/syn-ci-f1-13.png',
  },
];
