import React from 'react';
// import { UsersTabComponent } from '../../components';
import { Tables } from 'components/ReactTable/ReactTable';
import { Row, Col } from 'reactstrap';

export const UsersTabContainer = () => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Role',
      accessor: 'role',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
    {
      Header: 'Company',
      accessor: 'company',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
  ];

  const usersData = [
    {
      name: 'no data',
      role: 'no data',
      company: 'no data',
    },
  ];
  // const columns = [
  //   {
  //     dataField: 'id',
  //     text: 'Product ID',
  //     sort: true,
  //   },
  //   {
  //     dataField: 'name',
  //     text: 'Product Name',
  //     sort: true,
  //   },
  //   {
  //     dataField: 'price',
  //     text: 'Product Price',
  //     sort: true,
  //   },
  // ];
  return (
    // <div style={{ fontFamily: 'Montserrat' }}>
    //   <UsersTabComponent columns={columns} data={products} />
    // </div>
    <>
      <Row>
        {/* <p
          style={{
            marginTop: '25px',
            marginLeft: '40px',
            fontSize: '17px',
            fontFamily: 'Montserrat',
          }}
        >
          Select Rooms
        </p> */}
        <div style={{ marginLeft: '25px', marginTop: '18px', width: '30%' }}>
          {/* <Select
            className="basic-single"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            value={currentRoomNo}
            onChange={(value) => setCurrentRoomNo(value.value)}
            options={ScheduleRooms}
            placeholder={currentRoomNo}
          /> */}
        </div>
      </Row>
      <Row>
        <Col lg="12">
          {usersData !== null ? <Tables columns={columns} data={usersData} /> : 'Loading...'}
        </Col>
      </Row>
    </>
  );
};
