import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import moment from 'moment';
// import styled from 'styled-components';
import { DatePickers } from 'components';
import './styles.css';
import { UpdatedAt } from 'components/UpdatedAt';

const KlubRealtimeComponents = (props) => {
  const main_data = props.main_mdb;
  const total_power_usage = props.total_power_usage;
  const average_power_usage = props.average_power_usage;
  const daily_power_cost = props.daily_power_cost;
  const estimated_mounthly_power_cost = props.estimated_mounthly_power_cost;

  return (
    <div className="content">
      <Row>
        {/* Plot Real-Time graph */}
        <Col md="12">
          <Card className="card-stats">
            <Card.Body>
              <div className="in-filter">
                <DatePickers
                  daySelectStart={props.startDate}
                  daySelectStop={props.stopDate}
                  handleStartDate={props.handleStartDate}
                  handleStopDate={props.handleStopDate}
                />
                <div style={{ display: 'inline-flex', marginLeft: '0' }}>
                  <button
                    className={props.fetchSampling === 1 ? 'my-btn-clicked ' : 'my-btn'}
                    onClick={() => props.sampling(1)}
                  >
                    1M
                  </button>
                  <button
                    className={props.fetchSampling === 5 ? 'my-btn-clicked ' : 'my-btn'}
                    onClick={() => props.sampling(5)}
                  >
                    5M
                  </button>
                  <button
                    className={props.fetchSampling === 15 ? 'my-btn-clicked ' : 'my-btn'}
                    onClick={() => props.sampling(15)}
                  >
                    15M
                  </button>
                </div>
              </div>

              <Chart
                options={props.main_graph.options}
                series={props.main_graph.series}
                type="area"
                width="100%"
                height="300px"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {/* Main Energy */}
        <Col>
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>
                    <strong style={{ fontSize: 12 }}>24 hour Total Power Usage</strong>
                  </Col>
                </Row>
              </Card.Title>
              <div style={{ textAlign: 'center' }}>
                <strong style={{ fontSize: 15 }}>
                  {main_data !== null ? main_data.subdev_3.energy : 0} kWh
                </strong>
              </div>
              <Chart
                options={total_power_usage.options}
                series={total_power_usage.series}
                type="radialBar"
                width="100%"
                height="300px"
              />
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {main_data !== null ? moment(main_data.timestamp).format('DD/MM/YYYY HH:mm') : ''}
            </UpdatedAt>
          </Card>
        </Col>

        {/* Main Power */}
        <Col>
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>
                    <strong style={{ fontSize: 12 }}>Average Power Usage</strong>
                  </Col>
                </Row>
              </Card.Title>

              <div style={{ textAlign: 'center' }}>
                <strong style={{ fontSize: 15 }}>
                  {main_data !== null ? main_data.subdev_3.power : 0} kW
                </strong>
              </div>

              <Chart
                options={average_power_usage.options}
                series={average_power_usage.series}
                type="radialBar"
                width="100%"
                height="300px"
              />
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {main_data !== null ? moment(main_data.timestamp).format('DD/MM/YYYY HH:mm') : ''}
            </UpdatedAt>
          </Card>
        </Col>

        {/* Empty */}
        <Col>
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>
                    <strong style={{ fontSize: 12 }}>Daily Power Cost</strong>
                  </Col>
                </Row>
              </Card.Title>
              <div style={{ textAlign: 'center' }}>
                <strong style={{ fontSize: 15 }}>
                  {main_data !== null ? main_data.subdev_3.energy : 0} ฿
                </strong>
              </div>
              <Chart
                options={daily_power_cost.options}
                series={daily_power_cost.series}
                type="radialBar"
                width="100%"
                height="300px"
              />
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {main_data !== null ? moment(main_data.timestamp).format('DD/MM/YYYY HH:mm') : ''}
            </UpdatedAt>
          </Card>
        </Col>

        {/* Empty */}
        <Col>
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>
                    <strong style={{ fontSize: 12 }}>Estimated Mounthly Power Cost</strong>
                  </Col>
                </Row>
              </Card.Title>
              <div style={{ textAlign: 'center' }}>
                <strong style={{ fontSize: 15 }}>
                  {main_data !== null ? main_data.subdev_3.energy : 0} ฿
                </strong>
              </div>
              <Chart
                options={estimated_mounthly_power_cost.options}
                series={estimated_mounthly_power_cost.series}
                type="radialBar"
                width="100%"
                height="300px"
              />
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {main_data !== null ? moment(main_data.timestamp).format('DD/MM/YYYY HH:mm') : ''}
            </UpdatedAt>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export { KlubRealtimeComponents };
