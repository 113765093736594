import React from 'react';
import { Card, Row } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';

export const ShowIconTab = (props) => {
  const handleChangeAirIcon = (event) => {
    props.handleChangeAirIcon(event.target.checked);
  };

  const handleChangeEnvIcon = (event) => {
    props.handleChangeEnvIcon(event.target.checked);
  };

  const handleChangeSecIcon = (event) => {
    props.handleChangeSecIcon(event.target.checked);
  };

  return (
    <div>
      <Card>
        <div style={{ margin: '5px', paddingLeft: '25px', paddingTop: '5px' }}>
          <Row>
            <strong>Show Icon</strong>
          </Row>
          <Row style={{ paddingLeft: '3px' }}>

            <Row style={{ width: '100%' }}>

              <Checkbox
                checked={props.airIconChecked}
                onChange={handleChangeAirIcon}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: '3px' }}
              />
              <p style={{ margin: '0', paddingTop: '5px' }}>Air conditioners</p>
            </Row>

            <Row style={{ width: '100%' }}>

              <Checkbox
                checked={props.envIconChecked}
                onChange={handleChangeEnvIcon}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: '3px' }}
              />
              <p style={{ margin: '0', paddingTop: '5px' }}>Environment sensors</p>
            </Row>

            <Row style={{ width: '100%' }}>

              <Checkbox
                checked={props.secIconChecked}
                onChange={handleChangeSecIcon}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: '3px' }}
              />
              <p style={{ margin: '0', paddingTop: '5px' }}>Security cameras</p>
            </Row>
          </Row>
        </div>
      </Card>
    </div>
  );
};
