import axios from 'axios';
import { API_CONSTANTS } from '..';

const ConvertTriggerType = {
  device: 'device',
  event: 'event',
  schedule: 'time',
};

const getAutomation = async (type, object) => {
  const token = localStorage.getItem('token');
  console.log(type, object);

  const requestOptions = {
    url:
      type !== 'all'
        ? `${API_CONSTANTS.AUTOMATION}?by=${type}&query=${object}`
        : `${API_CONSTANTS.AUTOMATION}?by=all`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
  };

  try {
    let responseJson = await axios.request(requestOptions);
    return responseJson;
  } catch (error) {
    console.error('[Services] get automation Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
};

const postAutomation = async (
  trigger_type,
  trigger_object,
  condition_type, // condition_type={} when check in
  action
) => {
  const token = localStorage.getItem('token');

  const requestBody = {
    automation_image: '',
    automation_name: 'automation_mintel',
    topic: 'automation',
    trigger: {
      trigger_type: trigger_type,
      [`trigger_${ConvertTriggerType[trigger_type]}`]: trigger_object,
    },
    condition: condition_type,
    action: action,
    allow_notification: 'True',
    notification_message: 'test_noti',
  };

  const requestOptions = {
    url: `${API_CONSTANTS.AUTOMATION}/`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: requestBody,
  };

  console.log(requestOptions)
  try {
    await axios.request(requestOptions).then((responseJson) => {
      return responseJson;
    });
  } catch (error) {
    console.error('[Services] post automation Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
};

const deleteAutomation = async (id) => {
  const token = localStorage.getItem('token');

  const requestBody = {
    automation_id: id,
  };

  const requestOptions = {
    url: `${API_CONSTANTS.AUTOMATION}?by=id&query=${id}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: requestBody,
  };

  try {
    let responseJson = await axios.request(requestOptions);
    return responseJson;
  } catch (error) {
    console.error('[Services] delete automation error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
};

export const automation = {
  getAutomation,
  postAutomation,
  deleteAutomation,
};
