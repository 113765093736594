import React from 'react';
import { Card, Row } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';

export const ShowStatusTab = (props) => {

  const handleChangeAirStatus = (event) => {
    props.handleChangeAirStatus(event.target.checked);
  };

  const handleChangeEnvStatus = (event) => {
    props.handleChangeEnvStatus(event.target.checked);
  };

  return (
    <div>
      <Card>
        <div style={{ margin: '5px', paddingLeft: '25px', paddingTop: '5px' }}>
          <Row>
            <strong>Show Status</strong>
          </Row>
          <Row style={{paddingLeft:'3px'}}>
            <Row style={{width:'100%'}}>

              <Checkbox
                checked={props.airStatusChecked}
                onChange={handleChangeAirStatus}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: '3px' }}
              />
              <p style={{ margin: '0', paddingTop: '5px' }}>Air conditioners</p>
            </Row>

            <Row style={{ width: '100%' }}>

              <Checkbox
                checked={props.envStatusChecked}
                onChange={handleChangeEnvStatus}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: '3px' }}
              />
              <p style={{ margin: '0', paddingTop: '5px' }}>Environment sensors</p>
            </Row>
          </Row>
        </div>
      </Card>
    </div>
  );
};
