import { GLOBAL_CONSTANTS } from '../../../constants';

import { HOTEL } from '../types';
import { hotelServices, roomServices, roomActivitiesHistories } from '../../../api/services';

function requestGetHotelMapData() {
  return {
    type: HOTEL.GET_MAP_DATA_REQUEST,
  };
}

function successGetHotelMapData(data) {
  return {
    type: HOTEL.GET_MAP_DATA_SUCCESS,
    data,
  };
}

function failureGetHotelMapData(error) {
  return {
    type: HOTEL.GET_MAP_DATA_FAILURE,
    error,
  };
}

function getHotelMapData(hotelCode) {
  return async (dispatch, getState) => {
    await dispatch(requestGetHotelMapData());

    try {
      let responseJson = await hotelServices.getHotelMapData(hotelCode);

      if (responseJson) {
        if (responseJson['errors'] !== undefined) {
          let errorMsg = responseJson['errors'];
          console.error(errorMsg);

          await dispatch(failureGetHotelMapData(errorMsg));

          return null;
        } else {
          if (responseJson.data) {
            let mapData = responseJson.data;
            await dispatch(successGetHotelMapData(mapData));
            return mapData;
          } else {
            await dispatch(failureGetHotelMapData(GLOBAL_CONSTANTS.ERROR_JSON_DATA_NOT_FOUND));
            return null;
          }
        }
      } else {
        await dispatch(failureGetHotelMapData(GLOBAL_CONSTANTS.ERROR_RESPONSE_NOT_FOUND));
        return null;
      }
    } catch (error) {
      await dispatch(failureGetHotelMapData(error.message));
      return null;
    }
  };
}

function requestGetAllDevices() {
  return {
    type: HOTEL.GET_ALL_DEVICES_REQUEST,
  };
}

function successGetAllDevices(data) {
  return {
    type: HOTEL.GET_ALL_DEVICES_SUCCESS,
    data,
  };
}

function failureGetAllDevices(error) {
  return {
    type: HOTEL.GET_ALL_DEVICES_FAILURE,
    error,
  };
}

function getAllDevices(currentRoomNo, building) {
  return async (dispatch) => {
    await dispatch(requestGetAllDevices());
    try {
      let reponseJsonDevices = await roomServices.getAllDevices(currentRoomNo, building);
      if (reponseJsonDevices) {
        if (reponseJsonDevices['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = reponseJsonDevices['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          await dispatch(failureGetAllDevices(errorMsgDevice));

          return null;
        } else {
          if (reponseJsonDevices.data) {
            let mapDataDevice = reponseJsonDevices.data;
            // console.log(mapDataDevice);
            await dispatch(successGetAllDevices(mapDataDevice));
            return mapDataDevice;
          } else {
            await dispatch(failureGetAllDevices(GLOBAL_CONSTANTS.ERROR_JSON_DATA_NOT_FOUND));
            return null;
          }
        }
      } else {
        await dispatch(failureGetAllDevices(GLOBAL_CONSTANTS.ERROR_RESPONSE_NOT_FOUND));
        return null;
      }
    } catch (error) {
      await dispatch(failureGetAllDevices(error.message));
      return null;
    }
  };
}

function requestGetAllActivities() {
  return {
    type: HOTEL.GET_ALL_ACTIVITIES_REQUEST,
  };
}

function successGetAllActivities(data) {
  return {
    type: HOTEL.GET_ALL_ACTIVITIES_SUCCESS,
    data,
  };
}

function failureGetAllActivities(error) {
  return {
    type: HOTEL.GET_ALL_ACTIVITIES_FAILURE,
    error,
  };
}

function getAllActivities(
  roomNumber,
  deviceId,
  sub_dev,
  name,
  device_activity,
  startDate,
  stopDate
) {
  return async (dispatch) => {
    await dispatch(requestGetAllActivities());
    try {
      let reponseJsonActivities = await roomActivitiesHistories.getActivitiesHistories(
        roomNumber,
        deviceId,
        sub_dev,
        name,
        true,
        startDate,
        stopDate
      );
      if (reponseJsonActivities) {
        if (reponseJsonActivities['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = reponseJsonActivities['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          await dispatch(failureGetAllActivities(errorMsgDevice));

          return null;
        } else {
          if (reponseJsonActivities.data) {
            let mapDataActivity = reponseJsonActivities.data.results;
            await dispatch(successGetAllActivities(mapDataActivity));
            return mapDataActivity;
          } else {
            await dispatch(failureGetAllActivities(GLOBAL_CONSTANTS.ERROR_JSON_DATA_NOT_FOUND));
            return null;
          }
        }
      } else {
        await dispatch(failureGetAllActivities(GLOBAL_CONSTANTS.ERROR_RESPONSE_NOT_FOUND));
        return null;
      }
    } catch (error) {
      await dispatch(failureGetAllActivities(error.message));
      return null;
    }
  };
}

function plotFirebase(data) {
  return {
    type: HOTEL.PLOT_FIREBASE,
    payload: data,
  };
}

function clearPlot() {
  return {
    type: HOTEL.CLEAR_PLOT,
  };
}

function dropDownActivity(data) {
  return {
    type: HOTEL.DROP_DOWN_ACCTIVITY,
    payload: data,
  };
}

function acPlotActivity(data) {
  return {
    type: HOTEL.AC_ACTIVITY_PLOT,
    payload: data,
  };
}

export const hotelActions = {
  requestGetHotelMapData,
  successGetHotelMapData,
  failureGetHotelMapData,
  getHotelMapData,
  requestGetAllDevices,
  successGetAllDevices,
  failureGetAllDevices,
  getAllDevices,
  requestGetAllActivities,
  successGetAllActivities,
  failureGetAllActivities,
  getAllActivities,
  plotFirebase,
  clearPlot,
  dropDownActivity,
  acPlotActivity,
};
