import React from 'react';
import { NavComponent } from '../../components/Nav/NavNew.component';

const AutomationComponent = () => {
  return (
    <div className="content">
      <NavComponent />
    </div>
  );
};

export { AutomationComponent };
