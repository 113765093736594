import React, { useState } from 'react';
import { ShowContentControl, Modal } from '../TDPKComponents';
import { MapAir, MapCam, MapEnv } from '../MapData';
import { tdpkActions } from '../actions';
import { useDispatch, useSelector } from 'react-redux';

const floorPlan = '/assets/images/tdpk/tdpk_floor_plan.png';

export const TDPKContents = () => {
  const dispatch = useDispatch();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [activeTab, setActiveTab] = useState('1');

  const [isCursor, setIsCursor] = useState(false);

  const showModal = useSelector((state) => state.tdpk.modal);
  const [airNum, setAirNum] = useState('');
  const [device, setDevice] = useState('');

  const onChangeMouseCursor = async (event, imageRef) => {
    event.stopPropagation();
    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;
        let rect = event.target.getBoundingClientRect();
        let xr = (event.clientX - rect.left) / w;
        let yr = (event.clientY - rect.top) / h;

        let isHoverOnOneIcon = false;

        let elems = MapAir.concat(MapEnv, MapCam);

        elems.forEach((el) => {
          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            isHoverOnOneIcon = true;
          }
        });

        setIsCursor(isHoverOnOneIcon);
      }
    }
  };

  const onClick = async (event, imageRef) => {
    event.stopPropagation();

    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;

        let rect = event.target.getBoundingClientRect();
        let xr = (event.clientX - rect.left) / w;
        let yr = (event.clientY - rect.top) / h;

        let elems = MapAir.concat(MapEnv, MapCam);
        elems.forEach((el) => {
          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            if (el['name']) {
              dispatch(tdpkActions.showModal());
              dispatch(tdpkActions.tabName(el['triggers'][2]));

              // console.log(el['triggers'][2]);
              setAirNum(el['triggers'][0]);
              setDevice(el['name'].substring(0, 3));
            }
          }
        });
      }
    }
  };

  return (
    <>
      <ShowContentControl
        image={floorPlan}
        toggle={(data) => toggle(data)}
        activeTab={activeTab}
        onClick={onClick}
        onChangeMouseCursor={onChangeMouseCursor}
        isCursor={isCursor ? 'is-cursor' : ''}
      />
      {showModal && (
        <Modal
          onCloseModal={() => dispatch(tdpkActions.hideModal())}
          airNum={airNum}
          device={device}
        />
      )}
    </>
  );
};
