import React from 'react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import Chart from 'react-apexcharts';

const TdpkCostBreakDownComponents = (props) => {
  return (
    <div>
      <Card>
        <CardBody>
          <Row style={{ padding: '5px 10px 10px 10px' }}>
            <Col lg="7" md="12">
              <Row>
                <Col md="12">
                  <h4 style={{ margin: '10px' }}>HOTEL</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Chart series={props.plot_1.series} options={props.plot_1.options} type="donut" />
                </Col>
              </Row>
            </Col>
            <Col lg="4" md="12">
              <Row>
                <Col md="12">
                  <h4 style={{ margin: '10px' }}>COMMON SPACE</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Chart series={props.plot_2.series} options={props.plot_2.options} type="donut" />
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md="12">
                  <h4 style={{ margin: '10px' }}>GUEST ROOM</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Chart series={props.plot_3.series} options={props.plot_3.options} type="donut" />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export { TdpkCostBreakDownComponents };
