// import { GLOBAL_CONSTANTS } from '../../../constants';

import { DASHBOARD } from '../types';

function changeFilterRoomStatus(filterRoomStatusList) {
  return {
    type: DASHBOARD.CHANGE_FILTER_ROOM_STATUS_LIST,
    filterRoomStatusList,
  };
}

function changeFilterCountry(filterCountryList) {
  return {
    type: DASHBOARD.CHANGE_FILTER_COUNTRY_LIST,
    filterCountryList,
  };
}

export const dashboardFilterActions = {
  changeFilterRoomStatus,
  changeFilterCountry,
};
