import React from 'react';
import { Col, Card, CardBody, Label, FormGroup, Input } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';

export const FilterComponents = (props) => {
  const checked = props.checked;
  return (
    <Col md="2">
      <Card>
        <Scrollbars style={{ width: '100%', height: '510px' }} autoHide>
          <CardBody>
            <b>Room Status</b>
            <hr />
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="vc" checked={checked.vc} onChange={props.onChange} />
                <span className="form-check-sign" />
                VC
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="vd" checked={checked.vd} onChange={props.onChange} />
                <span className="form-check-sign" />
                VD
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="oc" checked={checked.oc} onChange={props.onChange} />
                <span className="form-check-sign" />
                OC
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="od" checked={checked.od} onChange={props.onChange} />
                <span className="form-check-sign" />
                OD
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="oo" checked={checked.oo} onChange={props.onChange} />
                <span className="form-check-sign" />
                OO
              </Label>
            </FormGroup>

            <hr />
            <b>Room Alert</b>
            <hr />

            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="non" checked={checked.non} onChange={props.onChange} />
                <span className="form-check-sign" />
                Non Permit room
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="leakage"
                  checked={checked.leakage}
                  onChange={props.onChange}
                />
                <span className="form-check-sign" />
                Electric leakage
              </Label>
            </FormGroup>

            <hr />

            <b>Device Alert</b>
            <hr />

            <FormGroup check>
              <Label check>
                <Input type="checkbox" id="iot" checked={checked.iot} onChange={props.onChange} />
                <span className="form-check-sign" />
                IoT Disconnected
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="predictive"
                  checked={checked.predictive}
                  onChange={props.onChange}
                />
                <span className="form-check-sign" />
                Predictive Maintenance
              </Label>
            </FormGroup>
          </CardBody>
        </Scrollbars>
      </Card>
    </Col>
  );
};
