import axios from 'axios';
import { API_CONSTANTS } from '../';

async function SYNAirAPI(device_name, temp, mode, fan, status) {
  //##-------------------------Payload-----------------------------------

  const token = localStorage.getItem('token');
  // const payload = {
  //   message: {
  //     settemp: temp,
  //     mode: mode,
  //     fan: fan,
  //     status: status,
  //   },
  //   device: device_name,
  //   topic: 'devicecontrol',
  //   room: 'syn_lobby',
  //   building: 'syn',
  //   RequestId: '12345',
  // };
  const payload = {
    topic: 'devicecontrol',
    action: {
      lobby: {
        [device_name]: {
          command: {
            mode: mode,
            fan: fan,
            temperature: temp,
            // state: mainSwitch ? 'off' : 'on',
          },
        },
      },
    },
    RequestId: '12345',
  };

console.log(payload);

//##------------------------request to API---------------------------
const requestOptions = {
    url: API_CONSTANTS.DEVICES_CONTROL_URL,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: JSON.stringify(payload),
  };

  // API
  try {
    let responseJson = await axios.request(requestOptions);
    console.log(responseJson.data);
    console.log('SYN AC control API ########');
  } catch {
    console.log('Request API error');
  }
}

export const SYN_API = {
  SYNAirAPI,
};
