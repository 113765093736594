import React from 'react';
import { DeviceOperationComponent } from '../../../components/Energy';

export const DeviceOperationContainer = () => {
  // let d = new Date();
  // function GetMonth() {
  //   // eslint-disable-next-line
  //   let month = new Array();
  //   month[0] = 'Jan';
  //   month[1] = 'Feb';
  //   month[2] = 'Mar';
  //   month[3] = 'Apr';
  //   month[4] = 'May';
  //   month[5] = 'Jun';
  //   month[6] = 'Jul';
  //   month[7] = 'Aug';
  //   month[8] = 'Sep';
  //   month[9] = 'Oct';
  //   month[10] = 'Nov';
  //   month[11] = 'Dec';

  //   return month[d.getMonth()];
  // }

  let colorPalette = ['#00D8B6', '#008FFB', '#FEB019', '#FF4560', '#775DD0'];
  let plot_bar = {
    series: [
      {
        name: 'HVAC',
        data: [
          42,
          52,
          16,
          55,
          59,
          51,
          45,
          32,
          26,
          33,
          44,
          51,
          42,
          56,
          60,
          60,
          54,
          44,
          45,
          46,
          40,
          42,
          40,
          40,
        ],
      },
      {
        name: 'Lighting',
        data: [6, 12, 4, 7, 5, 3, 6, 4, 3, 3, 5, 6, 7, 4, 12, 4, 7, 5, 5, 3, 6, 4, 3, 3],
      },
      {
        name: 'Pump',
        data: [6, 12, 4, 7, 5, 3, 6, 4, 3, 3, 5, 6, 7, 4, 12, 4, 7, 5, 5, 3, 6, 4, 3, 3],
      },
    ],
    options: {
      chart: {
        stacked: true,
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
        },
      },
      colors: colorPalette,

      labels: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
      xaxis: {
        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: 'kW',
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: '#78909c',
          },
        },
      },
      // xaxis: {
      //   type: 'datetime',
      //   min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
      //   max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
      //   labels: {
      //     datetimeUTC: false,
      //   },
      //   tooltip: {
      //     enabled: false,
      //   },
      // },
      title: {
        text: 'Energy Consumption by System',
        align: 'center',
        style: {
          fontSize: '18px',
        },
      },
    },
  };

  return (
    <div className="content">
      <DeviceOperationComponent plot_bar={plot_bar} />
    </div>
  );
};
