import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Tables } from 'components/ReactTable/ReactTable';
import Select from 'react-select';
import { ScheduleRooms } from 'components/List/List';
import { roomServices } from 'api/services';


export const DevicesTab = () => {
  const [tableData, setTableData] = useState(null);
  const [currentRoomNo, setCurrentRoomNo] = useState('201');

  const columns = [
    {
      Header: 'ID',
      accessor: 'device_id',
    },
    {
      Header: 'Name',
      accessor: 'device_name',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
    {
      Header: 'Gateway',
      accessor: 'gateway',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
    {
      Header: 'Room no.',
      accessor: 'room',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
    {
      Header: 'Location',
      accessor: 'ui_location',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
    {
      Header: 'Installed date',
      accessor: 'installed_at',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
    {
      Header: 'Updated date',
      accessor: 'updated_at',
      // Use our custom `fuzzyText` filter on this column
      filter: 'fuzzyText',
    },
  ];

  const fetchData = async (no) => {
    const data = await roomServices.getAllDevices(no);
    console.log(data);
    if (data) {
      setTableData(data.data.devices);
    } else {
      return 'Loading...';
    }
  };

  useEffect(() => {
    fetchData(currentRoomNo);
  }, [currentRoomNo]);

  return (
    <>
      <Row>
        <p
          style={{
            marginTop: '25px',
            marginLeft: '17px',
            fontSize: '17px',
            fontFamily: 'Montserrat',
          }}
        >
          Select Rooms
        </p>
        <div style={{ marginLeft: '25px', marginTop: '18px', width: '30%' }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            value={currentRoomNo}
            onChange={(value) => setCurrentRoomNo(value.value)}
            options={ScheduleRooms}
            placeholder={currentRoomNo}
          />
        </div>
      </Row>
      <Row>
        <Col lg="12">
          {tableData !== null ? <Tables columns={columns} data={tableData} /> : 'Loading...'}
        </Col>
      </Row>
    </>
  );
};
