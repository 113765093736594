import React, { useState } from 'react';
import { List } from './List';

export const Menu = (props) => {
  const [currentTab, setCurrentTab] = useState('control');

  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
    props.currentTab(tabName);
  };
  return (
    <div className="tabs is-toggle is-fullwidth">
      <ul style={{ margin: 0, padding: 10 }}>
        <List isSelected={currentTab === 'control'} onClick={(e) => onClickTabMenu(e, 'control')}>
          CONTROL
        </List>
        <List isSelected={currentTab === 'info'} onClick={(e) => onClickTabMenu(e, 'info')}>
          INFO
        </List>
        <List isSelected={currentTab === 'activity'} onClick={(e) => onClickTabMenu(e, 'activity')}>
          ACTIVITY
        </List>
        <List isSelected={currentTab === 'history'} onClick={(e) => onClickTabMenu(e, 'history')}>
          HISTORY
        </List>
      </ul>
    </div>
  );
};
