import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const SubdeviceContainer = styled.div`
  height: 100px;
  width: 270px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  overflow: scroll;
`;

const SubdeviceDetail = styled.div`
  height: 20px;
  width: 270px;
  padding: 2px;
  display: flex;
  align-items: center;
`;

const SubdeviceHeader = styled.div`
  width: 100px;
  font-weight: bold;
  font-size: 14px;
`;

const ConvertValue = (props) => {
  return (
    <>
      {props.item === 'installed_at' || props.item === 'updated_at'
        ? props.dt.slice(1, 11) + ' ' + props.dt.slice(12, 17)
        : props.item === 'subdevices'
        ? Object.keys(JSON.parse(props.dt)).map((data, index) => {
            return (
              <SubdeviceContainer key={index}>
                <SubdeviceDetail>
                  <SubdeviceHeader>NUMBER :</SubdeviceHeader> {data}
                </SubdeviceDetail>
                <SubdeviceDetail>
                  <SubdeviceHeader>NAME :</SubdeviceHeader> {JSON.parse(props.dt)[data].nickname}
                </SubdeviceDetail>
                <SubdeviceDetail>
                  <SubdeviceHeader>SCHEMA :</SubdeviceHeader> {JSON.parse(props.dt)[data].schema}
                </SubdeviceDetail>
                <SubdeviceDetail>
                  <SubdeviceHeader>ID :</SubdeviceHeader> {JSON.parse(props.dt)[data].device_id}
                </SubdeviceDetail>
              </SubdeviceContainer>
            );
          })
        : props.dt}
    </>
  );
};

const Info = (props) => {
  //##-----------------header, detial, image--------------------
  const h_d = props.header ? props.header : [];
  const d_t = props.detail ? props.detail : [];

  const hd = h_d.map((item, index) => {
    return (
      <>
        {d_t[index] !== 'null' ? (
          <tr key={item}>
            <th>{item}</th>
            <td>
              {/* {item === 'installed_at'
                ? console.log(d_t[index].slice(0, 11) + ' ' + d_t[index].slice(12, 17))
                : null}
              {item !== 'subdevices' ? (
                d_t[index]
              ) : (
                <>
                  {Object.keys(JSON.parse(d_t[index])).map((data) => {
                    return (
                      <SubdeviceContainer>
                        <SubdeviceDetail>
                          <SubdeviceHeader>NUMBER :</SubdeviceHeader> {data}
                        </SubdeviceDetail>
                        <SubdeviceDetail>
                          <SubdeviceHeader>NAME :</SubdeviceHeader>{' '}
                          {JSON.parse(d_t[index])[data].nickname}
                        </SubdeviceDetail>
                        <SubdeviceDetail>
                          <SubdeviceHeader>SCHEMA :</SubdeviceHeader>{' '}
                          {JSON.parse(d_t[index])[data].schema}
                        </SubdeviceDetail>
                        <SubdeviceDetail>
                          <SubdeviceHeader>ID :</SubdeviceHeader>{' '}
                          {JSON.parse(d_t[index])[data].device_id}
                        </SubdeviceDetail>
                      </SubdeviceContainer>
                    );
                  })}
                </>
              )} */}
              <ConvertValue item={item} dt={d_t[index]} />
            </td>
            <td>
              <a href="#edit" onClick={(e) => onClickEdit(e, d_t[index])}>
                <FontAwesomeIcon title="name" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>
        ) : null}
      </>
    );
  });

  const onClickEdit = (event, field) => {
    event.preventDefault();
    var newMessage = window.prompt('เปลี่ยนเป็น ?', field);
    if (newMessage) {
      newMessage = newMessage.trim();
      if (newMessage === field) {
        /* Do Nothing */
      } else {
        alert(`ข้อความได้เปลี่ยนเป็น ${newMessage}`);

        //##-------------------Waiting for Rest API-----------------------------------
        // let payload = {
        //   line_id: lineId,
        //   source: location,
        //   destination: newRoom,
        // };
        // let onSuccess = await dispatch(userActions.changeGuestRoom(payload));
        // if (onSuccess) {
        //   alert('[API] Change Guest Room success');
        // } else {
        //   alert('[API] Change Guest Room failed');
        // }
      }
    }
  };

  return (
    <div className="tab-content column cgroup-3" id="info">
      <div className="iot-element" id="iot-info-meter" style={{ width: '100%' }}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <img alt="infoImg" src={props.image} style={{ height: '100px', width: 'auto' }} />
        </div>
        <center>
          <div className="table-container is-striped" style={{ width: '80%' }}>
            <table className="table is-fullwidth">
              <tbody>{hd}</tbody>
            </table>
          </div>
        </center>
      </div>
    </div>
  );
};

export { Info };
