import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { hotelActions } from '../../../../hotel/actions';
import { FirebaseContext2 } from '../../../../../firebase';
import { RoomContainers } from '../components';
import { Scrollbars } from 'react-custom-scrollbars';
import { Card, Row, Col } from 'react-bootstrap';
import { FiltterContainers } from './Filtter.containers';
import { MainModalContainer } from '../../../../../containers';
import { EnergyDashboardContainer } from '../../../../../containers';

export const DashboardContainer = () => {
  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext2);
  const filterRoomStatusList = useSelector((state) => state.dashboard.filterCountryList);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const isShowOneRoomDetailsModal = useSelector(
    (state) => state.dashboard.isShowOneRoomDetailsModal
  );

  const canvasRef = useRef(null);

  const hotelCode = localStorage.getItem('hotel_hotel_code');
  const [mapData, setMapData] = useState({
    hotel: null, // name of hotel
    name: 'main', // name of showing map
    xgrid: 1, // number of grids in x axis
    ygrid: 1, // number of grids in y axis
    map: {}, // data of hotel rooms, call from backend
    user_is_update: false, // communicate with p5js to draw bell when new user are registered
    showing_room_modal: null, // room number of showing modal
    show_filter_tab: true, // state of show or hide filter tab
    filter_case: filterRoomStatusList, // filter case that show rooms that satisfy this condition
  });

  const [isChangingMapData, setIsChangingMapData] = useState(true);

  //##--------------------- Prefetch Data ----------------------------

  const onPrefetchMapData = async (firebase, didMounted) => {
    let tempMapData = await dispatch(hotelActions.getHotelMapData(hotelCode));
    if (didMounted) {
      if (tempMapData) {
        console.log('Viewer Canvas Unsubscribe firebase');
        let drawSize = parseInt(tempMapData['drawSize']);
        let roomList = tempMapData['rooms'];

        let _map = {};
        for (let i in roomList) {
          let d = roomList[i];
          let roomNo = d.text;
          _map[roomNo] = {
            posx: parseInt(d.x) / drawSize,
            posy: parseInt(d.y) / drawSize,
            room_number: roomNo,
          };
          //## Unsubscribe firebase
          firebase.db.ref('hotel/mintel/user_info/room_status/' + roomNo + '/').off('value');
        }
      }
    } else {
      if (tempMapData) {
        let xGrid = parseInt(tempMapData['xGrid'], 10);
        let yGrid = parseInt(tempMapData['yGrid'], 10);
        let drawSize = parseInt(tempMapData['drawSize']);
        let roomList = tempMapData['rooms'];
        let _map = {};
        let newMapData = { ...tempMapData };
        for (let i in roomList) {
          let d = roomList[i];
          let roomNo = d.text;
          _map[roomNo] = {
            posx: parseInt(d.x) / drawSize,
            posy: parseInt(d.y) / drawSize,
            room_number: roomNo,
          };
          //## Subscribe firebase pending user has update
          firebase.db
            .ref('hotel/mintel/user_info/room_status/' + roomNo + '/')
            .on('value', function (capture) {
              let state = capture.val();

              for (let k in state) {
                _map[roomNo][k] = state[k];
              }

              newMapData.map = { ..._map };
              newMapData['xgrid'] = xGrid;
              newMapData['ygrid'] = yGrid;
              newMapData['filter_case'] = ['vc', 'od', 'vd', 'oc', 'oo', 'ac'];
              setMapData(newMapData);
            });
        }
      }
    }
  };

  //##--------------------- Mounted ----------------------------

  useEffect(() => {
    onPrefetchMapData(firebase, false);
    return () => onPrefetchMapData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##--------------------- Child Event Handler ----------------------------
  const onEventUpdateMapData = (newMapData) => {
    //##[DEBUG]
    console.log('!! [EVENT][onEventUpdateMapData] Fired !!');
  };

  useEffect(() => {
    if (currentRoomNo) {
      dispatch(dashboardControlActions.showOneRoomDetailsModal());
      dispatch(hotelActions.getAllDevices(currentRoomNo));
    } else {
      dispatch(dashboardControlActions.resetSelectedRoom());
      dispatch(dashboardControlActions.hideOneRoomDetailsModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomNo]);

  //##--------------------- UnMount Canvas until mapData Finished sync -----
  useEffect(() => {
    setIsChangingMapData(true);
    setTimeout(() => {
      setIsChangingMapData(false);
    }, 100);
  }, [mapData]);

  return (
    <div ref={canvasRef} className="content">
      <Row>
        <Col md="10">
          <Card>
            <Scrollbars style={{ width: '100%', height: '510px' }} autoHide>
              <Card.Body>
                <Row>
                  <Col md="12">
                    {!isChangingMapData && (
                      <RoomContainers
                        mapData={mapData}
                        canvasRef={canvasRef}
                        onEventUpdateMapData={onEventUpdateMapData}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Scrollbars>
          </Card>
        </Col>
        <FiltterContainers />
      </Row>
      <EnergyDashboardContainer />

      {isShowOneRoomDetailsModal && <MainModalContainer />}
    </div>
  );
};
