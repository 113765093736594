import React from 'react';
// import { useSelector } from 'react-redux';

import '../styles/root.styles.css';
// import logo from '../../../logo.svg';

import { MainNavigationPage } from '../../nav/pages';

function RootPage(props) {
  return (
    <div className="root">
      <MainNavigationPage />
    </div>
  );
}

export { RootPage };
