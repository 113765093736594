export const Devices = [
  { value: 'ac', label: 'Airconditioning' },
  { value: 'fan', label: 'Exhaust fan' },
  { value: 'main_switch', label: 'Main light' },
  { value: 'bed_switch', label: 'Bed light' },
  { value: 'corridor_switch', label: 'Corridor light' },
  { value: 'window_switch', label: 'Window light' },
  { value: 'toilet_switch', label: 'Toilet light' },
  { value: 'wardrob_switch', label: 'Wardrob light' },
  { value: 'tv', label: 'Television' },
  { value: 'fridge', label: 'Refridgerator' },
];

export const Rooms = [
  { value: '', label: 'All room' },
  { value: '201', label: '201' },
  { value: '202', label: '202' },
  { value: '203', label: '203' },
  { value: '204', label: '204' },
  { value: '205', label: '205' },
  { value: '206', label: '206' },
  { value: '207', label: '207' },
  { value: '208', label: '208' },
  { value: '209', label: '209' },
  { value: '210', label: '210' },
  { value: '211', label: '211' },
  { value: '212', label: '212' },
  { value: '214', label: '214' },
  { value: '215', label: '215' },
  { value: '216', label: '216' },
  { value: '301', label: '301' },
  { value: '302', label: '302' },
  { value: '303', label: '303' },
  { value: '304', label: '304' },
  { value: '305', label: '305' },
  { value: '306', label: '306' },
  { value: '307', label: '307' },
  { value: '308', label: '308' },
  { value: '309', label: '309' },
  { value: '310', label: '310' },
  { value: '311', label: '311' },
  { value: '312', label: '312' },
  { value: '314', label: '314' },
  { value: '315', label: '315' },
  { value: '316', label: '316' },
];

export const AutomationRooms = [
  { value: '201', label: '201' },
  { value: '202', label: '202' },
  { value: '203', label: '203' },
  { value: '204', label: '204' },
  { value: '205', label: '205' },
  { value: '206', label: '206' },
  { value: '207', label: '207' },
  { value: '208', label: '208' },
  { value: '209', label: '209' },
  { value: '210', label: '210' },
  { value: '211', label: '211' },
  { value: '212', label: '212' },
  { value: '214', label: '214' },
  { value: '215', label: '215' },
  { value: '216', label: '216' },
  { value: '301', label: '301' },
  { value: '302', label: '302' },
  { value: '303', label: '303' },
  { value: '304', label: '304' },
  { value: '305', label: '305' },
  { value: '306', label: '306' },
  { value: '307', label: '307' },
  { value: '308', label: '308' },
  { value: '309', label: '309' },
  { value: '310', label: '310' },
  { value: '311', label: '311' },
  { value: '312', label: '312' },
  { value: '314', label: '314' },
  { value: '315', label: '315' },
  { value: '316', label: '316' },
];

export const ScheduleRooms = [
  { value: '201', label: '201' },
  { value: '202', label: '202' },
  { value: '203', label: '203' },
  { value: '204', label: '204' },
  { value: '205', label: '205' },
  { value: '206', label: '206' },
  { value: '207', label: '207' },
  { value: '208', label: '208' },
  { value: '209', label: '209' },
  { value: '210', label: '210' },
  { value: '211', label: '211' },
  { value: '212', label: '212' },
  { value: '214', label: '214' },
  { value: '215', label: '215' },
  { value: '216', label: '216' },
  { value: '301', label: '301' },
  { value: '302', label: '302' },
  { value: '303', label: '303' },
  { value: '304', label: '304' },
  { value: '305', label: '305' },
  { value: '306', label: '306' },
  { value: '307', label: '307' },
  { value: '308', label: '308' },
  { value: '309', label: '309' },
  { value: '310', label: '310' },
  { value: '311', label: '311' },
  { value: '312', label: '312' },
  { value: '314', label: '314' },
  { value: '315', label: '315' },
  { value: '316', label: '316' },
  { value: '401', label: '401' },
  { value: '402', label: '402' },
  { value: '403', label: '403' },
  { value: '404', label: '404' },
  { value: '405', label: '405' },
  { value: '406', label: '406' },
  { value: '407', label: '407' },
  { value: '408', label: '408' },
  { value: '409', label: '409' },
  { value: '410', label: '410' },
  { value: '411', label: '411' },
  { value: '412', label: '412' },
  { value: '414', label: '414' },
  { value: '415', label: '415' },
  { value: '416', label: '416' },
  { value: '501', label: '501' },
  { value: '502', label: '502' },
  { value: '503', label: '503' },
  { value: '504', label: '504' },
  { value: '505', label: '505' },
  { value: '506', label: '506' },
  { value: '507', label: '507' },
  { value: '508', label: '508' },
  { value: '509', label: '509' },
  { value: '510', label: '510' },
  { value: '511', label: '511' },
  { value: '512', label: '512' },
  { value: '514', label: '514' },
  { value: '515', label: '515' },
  { value: '516', label: '516' },
  { value: '601', label: '601' },
  { value: '602', label: '602' },
  { value: '603', label: '603' },
  { value: '604', label: '604' },
  { value: '605', label: '605' },
  { value: '606', label: '606' },
  { value: '607', label: '607' },
  { value: '608', label: '608' },
  { value: '609', label: '609' },
  { value: '610', label: '610' },
  { value: '611', label: '611' },
  { value: '612', label: '612' },
  { value: '614', label: '614' },
  { value: '615', label: '615' },
  { value: '616', label: '616' },
];

export const ListOfAllRooms = [
  '201',
  '202',
  '203',
  '204',
  '205',
  '206',
  '207',
  '208',
  '209',
  '210',
  '211',
  '212',
  '214',
  '215',
  '301',
  '302',
  '303',
  '304',
  '305',
  '306',
  '307',
  '308',
  '309',
  '310',
  '311',
  '312',
  '314',
  '315',
  '401',
  '402',
  '403',
  '404',
  '405',
  '406',
  '407',
  '408',
  '409',
  '410',
  '411',
  '412',
  '414',
  '415',
  '501',
  '502',
  '503',
  '504',
  '505',
  '506',
  '507',
  '508',
  '509',
  '510',
  '511',
  '512',
  '514',
  '515',
  '601',
  '602',
  '603',
  '604',
  '605',
  '606',
  '607',
  '608',
  '609',
  '610',
  '611',
  '612',
  '614',
  '615',
];

export const AutomationDevices = [
  { value: 'ac', label: 'AC', borderBottom: false },
  { value: 'tv', label: 'Television', borderBottom: false },
  { value: 'fridge', label: 'Refridgerator', borderBottom: false },
  { value: 'fan', label: 'Exhaust fan', borderBottom: true },
  { value: 'toilet_switch', label: 'Toilet light', borderBottom: false },
  { value: 'corridor_switch', label: 'Corridor light', borderBottom: false },
  { value: 'main_switch', label: 'Main light', borderBottom: true },
  { value: 'window_switch', label: 'Window light', borderBottom: false },
  { value: 'wardrobe_switch', label: 'Wardrobe light', borderBottom: false },
  { value: 'bed_switch', label: 'Bed light', borderBottom: true },
];
