import { NAV } from '../types';
import { history } from '../../../history';

function startNavigatePage(currentPage, targetPage) {
  return {
    type: NAV.NAV_PAGE_START,
    currentPage,
    targetPage,
  };
}

function focusPage(focusPage) {
  return {
    type: NAV.NAV_PAGE_FOCUS,
    focusPage,
  };
}

function navigatePage(pathName) {
  return (dispatch, getState) => {
    const { focusPage } = getState().nav;
    dispatch(startNavigatePage(focusPage, pathName));

    if (focusPage === pathName) {
      //## Reload
      window.location.reload(false);
    } else {
      //## Redirect
      history.push(pathName);
    }
  };
}

export const navActions = {
  focusPage,
  navigatePage,
};
