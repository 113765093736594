import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { FirebaseContext2 } from '../../../../../firebase';
import { List } from '../../../../../components/List/TabList';
import { ActivityData, HistoryData } from '../../../components/Tabs';
import { energyActions } from '../../../../energy/actions';
import moment from 'moment';
import { roomActivitiesHistories } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';
import { iotControlServices } from '../../../../../api/services';

const RefrigeratorControl = (props) => {
  const dispatch = useDispatch();
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const InitPlotData = useSelector((state) => state.historyEnergy.hist);
  const dropdown = useSelector((state) => state.hotel.dropdown);

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;
  const [plotHistData, setPlotHistData] = useState([]);
  const [tmpHist, settmpHist] = useState(null);

  const [currentTab, setCurrentTab] = useState('control');
  const timeoutid = useRef();

  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(energyActions.clearHistoryData());
    dispatch(dashboardControlActions.showIotModal(false));
  };

  const firebase = useContext(FirebaseContext2);

  //##-------------------Switch--------------------------------
  const [openRefrigerator, setOpenRefrigerator] = useState(null);

  const [status, setStatus] = useState({
    refrigerator: {
      status: null,
      time: null,
    },
  });

  const [elec, setElec] = useState({
    elec: {
      current: null,
      device_name: null,
      power: null,
      state: null,
      timestamp: null,
      voltage: null,
    },
  });

  const dateTime = new Date();
  const [startDate, setStartDate] = useState(dateTime);
  const [stopDate, setStopDate] = useState(dateTime);

  const handleStartDate = (event) => {
    setStartDate(event);
  };

  const handleStopDate = (event) => {
    setStopDate(event);
  };

  const d = new Date();

  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/refrigerator/event/subdev_0';
    let fbpathElec =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/refrigerator/electric/subdev_0';

    if (didMounted) {
      console.log('Refrigerator did mounted');
      await firebase.db.ref(fbpath).off('value');
      await firebase.db.ref(fbpathElec).off('value');
    } else {
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        if (capt) {
          setStatus({
            refrigerator: {
              status: capt.state,
              time: capt.timestamp,
            },
          });
        }
      });

      await firebase.db.ref(fbpathElec).on('value', function (snap) {
        let capt = snap.val();
        console.log(capt);
        if (capt !== null || undefined) {
          setElec({
            elec: {
              current: capt.current,
              device_name: capt.device_name,
              power: capt.power,
              state: capt.state,
              timestamp: capt.timestamp,
              voltage: capt.voltage,
            },
          });
        }
      });
    }
  }

  async function triggerAPI(currentStatus, swLocation, currentRoomNo) {
    //##-------------------------Payload-----------------------------------
    const payload = {
      topic: 'devicecontrol',
      action: {
        [currentRoomNo]: {
          [swLocation]: {
            command: {
              state: currentStatus,
            },
          },
        },
      },
      RequestId: '12345',
      // const payload = {
      //   message: {
      //     turn: currentStatus, /////  on/off
      //   },
      //   device: swLocation,
      //   topic: 'devicecontrol',
      //   room: 'room_' + currentRoomNo,
      //   building: 'mintel',
      //   RequestId: '12345',
      //   schema: 'location',
      //   header: {
      //     message_type: 'command',
      //   },
    };

    console.log(payload.message, payload.device);

    iotControlServices.postIotApi(payload);
  }
  async function histData(dev_id, nick_name) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        dropdown,
        nick_name,
        false
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##-------------------sync data from firebase only-----------------
  useEffect(() => {
    fetchData(firebase, currentRoomNo, false);
    return () => {
      fetchData(firebase, currentRoomNo, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##-------------------update current status only-----------------
  useEffect(() => {
    if (tmpHist !== null) {
      let tmp1 = [];
      tmpHist.forEach((item) => {
        if (item.state !== null && item.timestamp !== null) {
          tmp1.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        return setPlotHistData(tmp1);
      });
    }
  }, [tmpHist]);

  useEffect(() => {
    initSwitch(status.refrigerator.status);
  }, [status]);

  useEffect(() => {
    clearTimeout(timeoutid.current);

    devices.forEach((element) => {
      if (element['device_name'] === 'fan') {
        try {
          histData(element.device_id, 'fan').then((results) => {
            return settmpHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });

    let tmp1 = [];
    let tmp2 = [];
    timeoutid.current = setTimeout(() => {
      // tmp.push(Number(moment(corridor.time).format('x')), corridor.state === 'on' ? 1 : 0);
      if (
        status.refrigerator.time !== null &&
        status.refrigerator.status !== null &&
        elec.elec.timestamp !== null &&
        elec.elec.power !== null &&
        status.refrigerator.time !== undefined &&
        status.refrigerator.status !== undefined &&
        elec.elec.timestamp !== undefined &&
        elec.elec.power !== undefined
      ) {
        switch (Number(dropdown)) {
          case 0:
            tmp1.push(
              Number(moment(elec.elec.timestamp).format('x')),
              status.refrigerator.status === 'on' ? 1 : 0
            );
            tmp2.push(Number(moment(elec.elec.timestamp).format('x')), elec.elec.power);
            console.log('fridge');
            break;
          default:
            console.log('default');
            break;
        }

        dispatch(
          energyActions.mintelHistoryData({
            hist: {
              front_switch: [],
              back_switch: [],
              ac: [],
              fan: [],
              fridge: { state: tmp1, power: tmp2 },
              smart_meter: { energy: [], power: [] },
              smart_tv: [],
            },
          })
        );
      }
    }, 2000);

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  //##-----------------Sync status from firebase------------------
  const initSwitch = (status1) => {
    if (status1 === 'on') {
      setOpenRefrigerator(true);
    } else {
      setOpenRefrigerator(false);
    }
  };

  const onClick1 = (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenRefrigerator((prev) => !prev);

    const location = 'Refrigerator';
    if (openRefrigerator !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openRefrigerator ? 'on' : 'off', location, currentRoomNo),
        700
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##-------------------Tab menu------------------------------
  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };

  const imagesPath = {
    open: '/assets/images/devices/icon-refrigerator-open.png',
    close: '/assets/images/devices/icon-refrigerator-close.png',
  };

  //const image_link = 'https://www.toshiba.co.th/upload/1524207254.jpg';

  const hist_plot = {
    series: [
      {
        name: 'Switch',
        // data: plotHistData.concat(Main_mdb.plot),
        // data: InitPlotData.front_switch.concat(TmpData),
        // name: 'Switch',
        data:
          plotHistData !== undefined &&
          plotHistData !== null &&
          InitPlotData.fridge.state !== undefined
            ? plotHistData.concat(InitPlotData.fridge.state)
            : [],
        // data: dataPlotRealTime[0] !== NaN ? dataPlotRealTime : [],
      },
    ],
    options: {
      annotations: {
        yaxis: [
          {
            // x: 0,
            y: 1,
            // borderColor: '#00E396',
            label: {
              // borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396',
              },
              text: 'Light ON',
            },
          },
          {
            // x: 0,
            y: 0,
            // borderColor: '#e30062',
            label: {
              // borderColor: '#e30062',
              style: {
                color: '#fff',
                background: '#e30062',
              },
              text: 'Light OFF',
            },
          },
        ],
      },
      // chart: {
      //   id: 'area-datetime',
      //   type: 'area',
      //   height: 350,
      //   zoom: {
      //     autoScaleYaxis: true,
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        // style: 'hollow',
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },

      yaxis: {
        max: 1.5,
        show: false,
      },
      title: {
        text: 'Activity in 24 hrs',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };
  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Refrigerator
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="ucount-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'control'}
                        onClick={(e) => onClickTabMenu(e, 'control')}
                      >
                        CONTROL
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        // onClick={(e) => onClickTabMenu(e, 'info')}
                        disabled={true}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'activity'}
                        onClick={(e) => onClickTabMenu(e, 'activity')}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'history'}
                        onClick={(e) => onClickTabMenu(e, 'history')}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>

                  {/* Control tab */}
                  {currentTab === 'control' && (
                    <div className="iot-element" id="iot-control-refig">
                      <div className="columns">
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div className="column">
                            <img
                              alt="refrigerator-icon"
                              id="icon-refrigerator-close"
                              style={{
                                width: '150px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '7%',
                                cursor: 'pointer',
                              }}
                              src={openRefrigerator ? imagesPath.open : imagesPath.close}
                              onClick={(e) => onClick1(e)}
                            />
                          </div>
                          <div className="column">
                            <button
                              className={
                                // status.refrigerator.status === 'on'
                                openRefrigerator
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/50c7bf98ed3a/data/gang 0", "type": "on-off", "deviceid": "50c7bf98ed3a", "gang": 0, "icons":["icon-refrigerator-close","icon-refrigerator-open"]}'
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            >
                              {/* {status.refrigerator.status === 'on' ? 'ON' : 'OFF'} */}
                              {openRefrigerator ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {currentTab === 'info' && (
                    <Info image={image_link} header={props.header} detail={props.detail} />
                  )} */}
                  {currentTab === 'activity' && <ActivityData subDevices={props.subDevices} />}
                  {currentTab === 'history' && (
                    <HistoryData
                      data={hist_plot}
                      startDate={startDate}
                      stopDate={stopDate}
                      handleStartDate={handleStartDate}
                      handleStopDate={handleStopDate}
                    />
                  )}
                </nav>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export { RefrigeratorControl };
