import { ENERGY } from '../types';

function mintelEnergy(data) {
  return {
    type: ENERGY.MINTEL_ENERGY,
    payload: data,
  };
}
function synEnergy(data) {
  return {
    type: ENERGY.SYN_ENERGY,
    payload: data,
  };
}

function klubEnergy(data) {
  return {
    type: ENERGY.KLUB_ENERGY,
    payload: data,
  };
}

function mintelHistoryData(data) {
  return {
    type: ENERGY.MINTE_HISTORY_DATA,
    payload: data,
  };
}

function clearHistoryData() {
  return { type: ENERGY.CLEAR_HISTORY_DATA };
}

function tmpData(data) {
  return {
    type: ENERGY.TMP_DATA,
    payload: data,
  };
}

function clearEnergyData() {
  return { type: ENERGY.CLEAR_ENERGY_DATA };
}

function energyTabSelect(data) {
  return {
    type: ENERGY.ENERGY_TAB_SELECT,
    payload: data,
  };
}

function testEnergy(payload) {
  return {
    type: ENERGY.TEST_ENERGY,
    payload: payload,
  };
}

function clearTestEnergy() {
  return {
    type: ENERGY.CLEAR_TEST_ENERGY,
  };
}

export const energyActions = {
  mintelEnergy,
  synEnergy,
  mintelHistoryData,
  tmpData,
  clearHistoryData,
  clearEnergyData,
  energyTabSelect,
  testEnergy,
  clearTestEnergy,
  klubEnergy,
};
