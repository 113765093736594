import React from 'react';
import { Row, Col } from 'reactstrap';
import { ScheduleRooms } from '../../List/fileLists.component';
import Select from 'react-select';

export const RoomSetting = (props) => {
  return (
    <div>
      <Col lg="12" md="12" style={{ padding: '0px' }}>
        <Row style={{ margin: '0px' }}>
          <Col lg="12" md="12" sm="12" style={{ marginLeft: '15px', paddingLeft: '0' }}>
            <h4 style={{ marginTop: '20px', borderLeft: '0' }}>1. Select a room</h4>
            <Row style={{ marginLeft: '20px' }}>
              <Col lg="5">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="roomSelect"
                  closeMenuOnSelect={true}
                  value={props.settingRoom}
                  onChange={(value) => props.setSettingRoom(value)}
                  options={ScheduleRooms}
                  placeholder="Select Rooms"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
