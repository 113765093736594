import React from 'react';
import { Row, Col } from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const TdpkReportComponents = (props) => {
  const classes = useStyles();
  return (
    <div style={{ padding: '80px 30px 50px 30px' }}>
      <Row>
        <Col lg="12" md="12" sm="12">
          <h1 style={{ margin: '15px 0px 20px 0px', fontSize: '2em', fontWeight: 700 }}>Reports</h1>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Col lg="12" md="12" sm="12">
          <Row></Row>
          <Row>
            <Col lg="4">
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Col lg="4">
                  <p style={{ margin: '0px', width: '100px' }}>Report types</p>
                </Col>
                <Col lg="8">
                  <FormControl variant="outlined" className={classes.formControl} size={'small'}>
                    <InputLabel id="demo-simple-select-outlined-label">Types</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={props.type}
                      onChange={props.handleType}
                      label="Type"
                      size={'small'}
                    >
                      <MenuItem value={0}>Energy saving</MenuItem>
                      <MenuItem value={1}>House keeper</MenuItem>
                      <MenuItem value={2}>Technician</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Col lg="4">
                  <p style={{ margin: '0px', width: '100px' }}>Start date</p>
                </Col>
                <Col lg="8">
                  <FormControl variant="outlined" className={classes.formControl} size={'small'}>
                    <InputLabel id="demo-simple-select-outlined-label">Start Date</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={props.startDate}
                      onChange={props.handleStart}
                      label="Start Date"
                      size={'small'}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>
            <Col lg="3">
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '56px 0px 0px 0px',
                }}
              >
                <p style={{ margin: '0px', width: '100px' }}>End date</p>
                <FormControl variant="outlined" className={classes.formControl} size={'small'}>
                  <InputLabel id="demo-simple-select-outlined-label">End Date</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={props.endDate}
                    onChange={props.handleEnd}
                    label="End Date"
                    size={'small'}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Row>
            </Col>
            <Col lg="3" style={{ padding: '58px 0px 0px 10px' }}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export { TdpkReportComponents };
