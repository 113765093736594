import React, { useState, useEffect, useContext } from 'react';
import { Row } from 'react-bootstrap';
import { RoomStatus } from 'components/DashboardTab/RoomStatus';
import { MaidStatus } from 'components/DashboardTab/MaidStatus';
import { IoTDevices } from 'components/DashboardTab/IoTDevices';
import { EnergyConsumption } from 'components/DashboardTab/EnergyConsumption';
import { FirebaseContext2 } from '../../../firebase';
import moment from 'moment';

export const SummaryBoxes = (props) => {
  const firebase = useContext(FirebaseContext2);
  const [energyConsumption, setEnergyConsumption] = useState(null);
  const [energySum, setEnergySum] = useState(null);
  const [bahtSum, setBahtSum] = useState(null);
  const [otherBahtSum, setOtherBahtSum] = useState(null);
  const [energyUpdated_at, setEnergyUpdated_at] = useState('...');

  async function fetchData(firebase, didMounted) {
    let energy_consumption_url = '/hotel/mintel/dashboard/energy_summary';
    let total_consumption_url = '/hotel/mintel/energy/electricity_bill/this_month_baht';

    if (didMounted) {
      await firebase.db.ref(energy_consumption_url).off('value');
    } else {
      await firebase.db.ref(energy_consumption_url).on('value', function (snap) {
        let enerygyByRoom = snap.val().items;
        setEnergyUpdated_at(moment(snap.val().energyUpdated_at).format('llll'));
        let sumOfEnergy = 0;
        let sumOfBaht = 0;
        let value;
        for (value in enerygyByRoom) {
          if (enerygyByRoom[value]['kwh']) {
            sumOfEnergy = sumOfEnergy + enerygyByRoom[value]['kwh'];
          }
        }
        setEnergySum(sumOfEnergy.toFixed(1));
        for (value in enerygyByRoom) {
          if (enerygyByRoom[value]['baht']) {
            sumOfBaht = sumOfBaht + enerygyByRoom[value]['baht'];
          }
        }
        setOtherBahtSum(sumOfBaht);
        setEnergyConsumption(enerygyByRoom);
      });
      await firebase.db.ref(total_consumption_url).on('value', function (snap) {
        let totalConsumption = snap.val();
        setBahtSum(totalConsumption.toFixed(1));
      });
    }
  }

  useEffect(() => {
    fetchData(firebase, false);
    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <RoomStatus name={'Room Status'} roomStatus={props.roomStatus} />
        <MaidStatus
          name={'Maid Status'}
          maidUrl={props.maidUrl !== null ? props.maidUrl : []}
          maidStatus={props.maidStatus}
        />
        <IoTDevices
          name={'IoT Devices'}
          devices={props.deviceStatus}
          sumDeviceStatus={props.sumDeviceStatus}
          updated_at={props.devicesUpdated_at}
        />
        <EnergyConsumption
          name={'Energy Consumption'}
          energy={energyConsumption}
          energySum={energySum}
          otherBaht={bahtSum - otherBahtSum}
          bahtSum={bahtSum}
          updated_at={energyUpdated_at}
        />
      </Row>
    </>
  );
};
