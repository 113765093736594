import React from 'react';
import { Row, Col } from 'reactstrap';
import { ToggleButton, ACControl } from './ToggleButton';
import styled from 'styled-components';

const BorderBottom = styled.div`
  border-bottom: ${(props) => (props.borderBottom ? '1px solid #ececec' : 'none')};
  position: relative;
  width: 90%;
  left: 5%;
  bottom: 5px;
`;

const SubmitButton = styled.div`
  height: 35px;
  width: 85px;
  margin: 15px;
  margin-left: 0px;
  border-radius: 3px;
  border: 0;
  background-color: #1e90ef;
  color: white;
  float: right;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
`;

export const DeviceSetting = (props) => {
  const setNewDeviceState = (dev, value) => {
    const newState = { ...props.deviceStates };
    newState[dev].command['state'] = value[dev].command['state'] === 'off' ? 'on' : 'off';
    props.setDeviceStates(newState);
  };

  const setNewACState = (type, value) => {
    const newState = { ...props.deviceStates };
    newState['ac'].command[type] = value;
    props.setDeviceStates(newState);
  };

  return (
    <div>
      <Col lg="12" md="12" style={{ padding: '0px' }}>
        <Row style={{ margin: '0px' }}>
          <Col lg="12" md="12" sm="12" style={{ marginLeft: '20px', paddingLeft: '0' }}>
            <p style={{ marginTop: '20px', borderLeft: '0' }}>
              <strong> </strong>
            </p>
            <Row style={{ marginLeft: '20px' }}>
              <Col lg="12" md="12" style={{ padding: '5px 0px 20px 5px' }}>
                {props.selectedDeviceList ? (
                  <div>
                    {props.selectedDeviceList.map((item, index) => (
                      <div key={index}>
                        <ToggleButton
                          defaultState={props.deviceStates[item].command['state'] !== 'off'}
                          name={item}
                          check={'state'}
                          setSelected={() => setNewDeviceState(item, props.deviceStates)}
                          device_name={item}
                          removeDevice={(data)=>props.removeDevice(data)}
                        />
                        {item === 'Air Conditioner' && props.deviceStates[item].state !== 'off' ? (
                          <ACControl
                            modeSelect={props.deviceStates['Air Conditioner'].command.mode}
                            tempSelect={props.deviceStates['Air Conditioner'].command.temp}
                            fanSelect={props.deviceStates['Air Conditioner'].command.fan}
                            setModeSelect={(mode) => setNewACState('mode', mode)}
                            setTempSelect={(temp) => setNewACState('temp', temp)}
                            setFanSelect={(fan) => setNewACState('fan', fan)}
                          />
                        ) : null}
                        <BorderBottom borderBottom={true} />
                      </div>
                    ))}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <SubmitButton onClick={props.sendRequest}>Submit</SubmitButton>
    </div>
  );
};
