import React from 'react';
import { Row, Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const TimeSetting = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Col lg="12" md="12" style={{ padding: '0px' }}>
        <Row style={{ margin: '0px' }}>
          <Col lg="12" md="12" sm="12" style={{ marginLeft: '15px', paddingLeft: '0' }}>
            <h5 style={{ marginTop: '20px', borderLeft: '0' }}>2. Time setting</h5>
            <Row style={{ marginLeft: '20px' }}>
              <Col lg="12">
                <form className={classes.container} noValidate>
                  <TextField
                    id="datetime-local"
                    label="Next appointment"
                    type="datetime-local"
                    defaultValue={props.datetime}
                    className={classes.textField}
                    onChange={(e) => props.changeDatetime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <div style={{ margin: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.repeatValue}
                          onChange={props.setRepeatWeek}
                          name="Repeat every week?"
                        />
                      }
                      label="Repeat every week?"
                    />
                  </div>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
