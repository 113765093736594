export const elementPositions = [
  // [name, display, control, imageurl, x1, y1, x2, y2, triggers, location]
  {
    name: 'fan',
    display: 'Exhaust Fan',
    control: true,
    imageurl: '/assets/images/devices/exhaust-fan.png',
    x1: 0.16666666666666666,
    y1: 0.06,
    x2: 0.2534722222222222,
    y2: 0.16025,
    triggers: ['fan'],
    location: 3,
    // location: 1,
  },
  {
    name: 'env',
    display: 'Environment Sensor',
    control: false,
    imageurl: '/assets/images/devices/env-sensor.png',
    x1: 0.5260416666666666,
    y1: 0.04791666666666667,
    x2: 0.6128472222222222,
    y2: 0.15416666666666667,
    triggers: ['broadlinkenv'],
    location: 4,
    // location: 1,
  },
  {
    name: 'front_switch',
    display: 'Front Switch',
    control: true,
    imageurl: '/assets/images/devices/light-switch.png',
    x1: 0.015625,
    y1: 0.3425,
    x2: 0.10243055555555555,
    y2: 0.4666666666666667,
    triggers: ['front_switch'],
    location: 2,
    // location: 1,
  },
  {
    name: 'fridge',
    display: 'Refrigerator',
    control: true,
    imageurl: '/assets/images/devices/refigerator.png',
    x1: 0.8923611111111112,
    y1: 0.39208333333333336,
    x2: 0.9775862068965517,
    y2: 0.49416666666666666,
    triggers: ['fridge'],
    location: 5,
    // location: 1,
  },
  {
    name: 'air',
    display: 'Air Conditioner',
    control: true,
    imageurl: '/assets/images/devices/air-conditioner.png',
    x1: 0.013888888888888888,
    y1: 0.5933333333333333,
    x2: 0.10243055555555555,
    y2: 0.7395833333333333,
    triggers: ['ac'],
    location: 6,
    // location: 1,
  },
  {
    name: 'back_switch',
    display: 'Back Switch',
    control: true,
    imageurl: '/assets/images/devices/light-switch.png',
    x1: 0.890625,
    y1: 0.5541666666666667,
    x2: 0.9791666666666666,
    y2: 0.6983333333333333,
    triggers: ['back_switch'],
    location: 1,
  },
  {
    name: 'smart_meter',
    display: 'Smart Meter',
    control: false,
    imageurl: '/assets/images/devices/smart-meter.png',
    x1: 0.16666666666666666,
    y1: 0.8091666666666666,
    x2: 0.2517361111111111,
    y2: 0.9354166666666667,
    triggers: ['smart_meter'],
    location: 8,
    // location: 1,
  },
  {
    name: 'smart_tv',
    display: 'Smart Television',
    control: true,
    imageurl: '/assets/images/devices/ir-sensor.png',
    x1: 0.4027777777777778,
    y1: 0.8008333333333333,
    x2: 0.4913194444444444,
    y2: 0.93125,
    triggers: ['smart_tv'],
    location: 7,
    // location: 1,
  },
];
