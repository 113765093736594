import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { MyResponsiveBar } from '../../../../components/NivoGraph/barChart';

const data = [
  {
    month: 'JAN',
    Prediction: 156,
    Actual: 77,
  },
  {
    month: 'FEB',
    Prediction: 11,
    Actual: 36,
  },
  {
    month: 'MAR',
    Prediction: 108,
    Actual: 126,
  },
  {
    month: 'APR',
    Prediction: 59,
    Actual: 198,
  },
  {
    month: 'MAY',
    Prediction: 15,
    Actual: 185,
  },
  {
    month: 'JUN',
    Prediction: 78,
    Actual: 172,
  },
  {
    month: 'JULY',
    Prediction: 56,
    Actual: 27,
  },
  {
    month: 'AUG',
    Prediction: 56,
    Actual: 27,
  },
  {
    month: 'SEP',
    Prediction: 56,
    Actual: 27,
  },
  {
    month: 'OCT',
    Prediction: 56,
    Actual: 27,
  },
  {
    month: 'NOV',
    Prediction: 56,
    Actual: 27,
  },
  {
    month: 'DEC',
    Prediction: 56,
    Actual: 27,
  },
];

const graphCompere = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <div style={{ height: '400px', width: '100%' }}>
              {/* <MyResponsiveLine data={data} xLabel="Month" yLabel="Baht" /> */}
              <MyResponsiveBar data={data} xLabel={`Months of ${year}`} yLabel="Baht" />
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div style={{ width: '100%' }}>
                <Form>
                  <FormGroup row>
                    <Label for="unit" sm={2}>
                      Type
                    </Label>
                    <Col sm={4}>
                      <Input type="text" name="unit" id="unit" placeholder="" />
                    </Col>

                    <Label for="month" sm={2}>
                      Baht
                    </Label>
                    <Col sm={4}>
                      <Input type="text" name="month" id="month" placeholder="" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="unit" sm={2}>
                      Consumption
                    </Label>
                    <Col sm={4}>
                      <Input type="text" name="unit" id="unit" placeholder="" />
                    </Col>

                    <Label for="month" sm={2}>
                      Bill Period
                    </Label>
                    <Col sm={4}>
                      <Input type="text" name="month" id="month" placeholder="" />
                    </Col>
                  </FormGroup>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <div style={{ height: '400px', width: '100%' }}>
              {/* <MyResponsiveLine data={data} xLabel="Month" yLabel="Baht" /> */}
              <MyResponsiveBar data={data} xLabel={`Months of ${year}`} yLabel="Baht" />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export { graphCompere };
