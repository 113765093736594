import axios from 'axios';
import { API_CONSTANTS } from '..';

async function postIotApi(payload) {
  const token = localStorage.getItem('token');
  const requestOptions = {
    url: API_CONSTANTS.DEVICES_CONTROL_URL,
    method: 'POST',

    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: JSON.stringify(payload),
  };

  try {
    let responseJson = await axios.request(requestOptions);
    return responseJson.data;
  } catch (error) {
    //## [DEBUG]
    console.error('[Services] messageByLineId Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

export const iotControlServices = {
  postIotApi,
};
