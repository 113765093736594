export const env_position_map = [
  {
    name: 'env1',
    x1: 0.5867368356805098,
    y1: 0.37293233082706767,
    x2: 0.6119999935752467,
    y2: 0.4030075187969925,
    triggers: ['env1'],
  },
  {
    name: 'env2',
    x1: 0.5435789409436678,
    y1: 0.6165413533834586,
    x2: 0.5688420988384046,
    y2: 0.6466165413533834,
    triggers: ['env2'],
  },
  {
    name: 'env3',
    x1: 0.921473677785773,
    y1: 0.6502255633361358,
    x2: 0.9446315725226151,
    y2: 0.6803007513060606,
    triggers: ['env3'],
  },
];
