import axios from 'axios';
import { API_CONSTANTS } from '../../api';

async function getHotelMapData(hotelCode) {
  const token = localStorage.getItem('token');

  const requestOptions = {
    url: `${API_CONSTANTS.GET_HOTEL_MAP_DATA}&hotel=${hotelCode}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
  };

  try {
    let responseJson = await axios.request(requestOptions);

    return responseJson.data;
  } catch (error) {
    //## [DEBUG]
    console.error('[Services] getHotelMapData Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

export const hotelServices = {
  getHotelMapData,
};
