import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ElectricityByHour } from '../../../components/Energy';
import { chartExample8 } from '../../../features/energy/variables/chart';
import { Row, Col, CardTitle, CardHeader, Card } from 'reactstrap';

export const ElectricityByHourContainer = () => {
  const [daySelectStart, setDaySelectStart] = useState(null);
  const [daySelectStop, setDaySelectStop] = useState(null);

  return (
    <div>
      <Col lg="12" sm="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Select Time</CardTitle>
          </CardHeader>
          <Row style={{ padding: '20px', paddingTop: '30px' }}>
            <Col lg="4" sm="6">
              From{' '}
              <DatePicker selected={daySelectStart} onChange={(date) => setDaySelectStart(date)} />
            </Col>
            <Col lg="4" sm="6">
              To <DatePicker selected={daySelectStop} onChange={(date) => setDaySelectStop(date)} />
            </Col>
            <Col lg="4" sm="6"></Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <ElectricityByHour
                title={'Electricity Allocation'}
                options={chartExample8.options}
                series={chartExample8.series}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
};
