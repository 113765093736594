import React, { useState } from 'react';
//import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export const ActivityData = (props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [dropdownText, setDropdownText] = useState(props.subDevices[0]);
  const data = useSelector((state) => state.hotel.allActivities);
  const showIoTModal = useSelector((state) => state.dashboard.showIotModal);
  const modalName = useSelector((state) => state.dashboard.modalName);
  let _data = null;

  if (data !== null && data !== undefined) {
    _data = data.map((item, index) => {
      return (
        <tr key={index}>
          <th>{item.device_id}</th>
          <td>{item.timestamp}</td>
          <td>{item.state}</td>
        </tr>
      );
    });
  }

  const toggle = () => setOpen(!dropdownOpen);

  const onClick = (data) => {
    setDropdownText(data.slice(2));
    props.onClick(data.charAt(0));
  };
  const clickOnDevice = useSelector((state) => state.dashboard);

  return (
    <div style={{ padding: 20, margin: 0, paddingTop: 0 }}>
      {/** Environment Sensor */}
      {modalName === 'broadlinkenv' && showIoTModal && (
        <div>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div className="table-container is-striped" style={{ width: '80%' }}>
                    <table className="table is-fullwidth">
                      <tbody>
                        {data !== null && data !== undefined
                          ? data.map((item, index) => (
                              <tr key={index}>
                                <th>{item.timestamp}</th>
                                <td>{item.humidity}</td>
                                <td>{item.luminosity_sub}</td>
                                <td>{item.noise_sub}</td>
                                <td>{item.temperature}</td>
                              </tr>
                            ))
                          : 'Loading...'}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** AC  */}
      {modalName === 'ac' && showIoTModal && (
        <div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{dropdownText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onClick('0_AC')}>Air Conditioner</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div
                    className="table-container is-striped"
                    style={{ width: '100%', margin: '0px' }}
                  >
                    <table className="table is-fullwidth">
                      <tbody>
                        <tr>
                          <th>Time</th>
                          <th>Mode</th>
                          <th>Fan level</th>
                          <th>Set temp</th>
                          <th>Room temp</th>
                          <th>Controlled by</th>
                        </tr>

                        {data
                          ? data.length !== 0
                            ? data.map((item, index) => (
                                <tr key={index}>
                                  <th>{item.timestamp}</th>
                                  <td>{item.mode}</td>
                                  <td>{item.fan}</td>
                                  <td>{item.temperature}</td>
                                  <td>{item.room_temperature}</td>
                                  <td>no data</td>
                                </tr>
                              ))
                            : 'No activity'
                          : 'Loading...'}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** Front Light  */}
      {modalName === 'front_switch' && showIoTModal && (
        <div>
          {/* <div>Select sub-devices</div> */}
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{dropdownText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onClick('0_Toilet Switch')}>Toilet Switch</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onClick('1_Corridor Switch')}>
                Corridor Switch
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onClick('2_Main Switch')}>Main Switch</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div className="table-container is-striped" style={{ width: '80%' }}>
                    <table className="table is-fullwidth">
                      <tbody>
                        <tr>
                          <th>Time</th>
                          <th>State</th>
                        </tr>
                        {data
                          ? data.length !== 0
                            ? data.map((item, index) => (
                                <tr key={index}>
                                  <th>{item.timestamp}</th>
                                  <td>{item.state}</td>
                                </tr>
                              ))
                            : 'No activity'
                          : 'Loading...'}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** Back Light  */}
      {modalName === 'back_switch' && showIoTModal && (
        <div>
          <div>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>{dropdownText}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onClick('0_Window Switch')}>
                  Window Switch
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onClick('1_Wardrobe Switch')}>
                  Wardrobe Switch
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onClick('2_Bed Switch')}>Bed Switch</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <div className="tab-content column cgroup-3" id="activity">
              <div className="iot-element" id="iot-activity-env">
                <div>
                  <center>
                    <div className="table-container is-striped" style={{ width: '80%' }}>
                      <table className="table is-fullwidth">
                        <tbody>
                          <tr>
                            <th>Time</th>
                            <th>State</th>
                          </tr>

                          {data
                            ? data.length !== 0
                              ? data.map((item, index) => (
                                  <tr key={index}>
                                    <th>{item.timestamp}</th>
                                    <td>{item.state}</td>
                                  </tr>
                                ))
                              : 'No activity'
                            : 'Loading...'}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** Refrigerator Control  */}
      {modalName === 'fridge' && showIoTModal && (
        // <div>
        //   <Chart options={data.options} series={data.series} type="line" />
        // </div>
        <div className="tab-content column cgroup-3" id="activity">
          <div className="iot-element" id="iot-activity-env">
            <div>
              <center>
                <div className="table-container is-striped" style={{ width: '80%' }}>
                  <table className="table is-fullwidth">
                    <tbody>
                      <tr>
                        <th>Time</th>
                        <th>State</th>
                      </tr>
                      {data
                        ? data.length !== 0
                          ? data.map((item, index) => (
                              <tr key={index}>
                                <th>{item.timestamp}</th>
                                <td>{item.state}</td>
                              </tr>
                            ))
                          : 'No activity'
                        : 'Loading...'}
                    </tbody>
                  </table>
                </div>
              </center>
            </div>
          </div>
        </div>
      )}

      {/** isShowExhaust Fan Modal  */}
      {modalName === 'fan' && showIoTModal && (
        <div>
          <div>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>{dropdownText}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onClick('0_Fan Switch')}>Exhaust Fan</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <div className="tab-content column cgroup-3" id="activity">
              <div className="iot-element" id="iot-activity-env">
                <div>
                  <center>
                    <div className="table-container is-striped" style={{ width: '80%' }}>
                      <table className="table is-fullwidth">
                        <tbody>
                          <tr>
                            <th>Time</th>
                            <th>State</th>
                          </tr>
                          {data
                            ? data.length !== 0
                              ? data.map((item, index) => (
                                  <tr key={index}>
                                    <th>{item.timestamp}</th>
                                    <td>{item.state}</td>
                                  </tr>
                                ))
                              : 'No activity'
                            : 'Loading...'}
                        </tbody>
                      </table>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** isShow Smart Meter Modal */}
      {modalName === 'smart_meter' && showIoTModal && (
        <div>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div className="table-container is-striped" style={{ width: '80%' }}>
                    <table className="table is-fullwidth">
                      <tbody>{_data}</tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** is Show SmartTv Control Modal */}
      {modalName === 'smart_tv' && showIoTModal && (
        <div>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div className="table-container is-striped" style={{ width: '80%' }}>
                    <table className="table is-fullwidth">
                      <tbody>
                        <tr>
                          <th>Time</th>
                          <th>CID</th>
                        </tr>

                        {data
                          ? data.length !== 0
                            ? data.map((item, index) => (
                                <tr key={index}>
                                  <th>{item.timestamp}</th>
                                  <td>{item.cid}</td>
                                </tr>
                              ))
                            : 'No activity'
                          : 'Loading...'}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.alternative && (
        <div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{dropdownText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onClick('0_AC')}>Air Conditioner</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div
                    className="table-container is-striped"
                    style={{ width: '100%', margin: '0px' }}
                  >
                    <table className="table is-fullwidth">
                      <tbody>
                        <tr>
                          <th>Time</th>
                          <th>Mode</th>
                          <th>Fan level</th>
                          <th>Set temp</th>
                          <th>Room temp</th>
                          <th>Controlled by</th>
                        </tr>

                        {data
                          ? data.length !== 0
                            ? data.map((item, index) => (
                                <tr key={index}>
                                  <th>{item.timestamp}</th>
                                  <td>{item.mode}</td>
                                  <td>{item.fan}</td>
                                  <td>{item.temperature}</td>
                                  <td>{item.room_temperature}</td>
                                  <td>no data</td>
                                </tr>
                              ))
                            : 'No activity'
                          : 'Loading...'}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}

      {/** is Syn AC Control Modal */}
      {clickOnDevice.isSynACControlModal && (
        <div>
          <div className="tab-content column cgroup-3" id="activity">
            <div className="iot-element" id="iot-activity-env">
              <div>
                <center>
                  <div className="table-container is-striped" style={{ width: '80%' }}>
                    <table className="table is-fullwidth">
                      <tbody>{_data}</tbody>
                    </table>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
