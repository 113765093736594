import React, { useContext, useRef, useMemo, useState, useEffect } from 'react';
import { TdpkEnergyComponents } from '../../components/TDPK';
import { FirebaseContext2 } from '../../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { energyActions } from '../../actions';
// import moment from 'moment';

export const NAPEnergyContainer = () => {
  const firebase = useContext(FirebaseContext2);
  const dispatch = useDispatch();
  const timeoutid = useRef();

  const Main_mdb = useSelector((state) => state.energy.tdpk_energy);
  // const Init = useSelector((state) => state.energy);

  // Main MDB
  const [mainMdb, setMainMdb] = useState({
    main: {
      plot: null,
      p_1: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
        power_factor: null,
      },
      p_2: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
      },
      p_3: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
      },
    },
  });

  //##--------------------------Fetch Data----------------------------------
  async function fetchData(firebase, didMounted) {
    // Constant URL
    let main_hotel =
      'hotel/mintel/building main/main_energy/iot_devices/9c:a5:25:ab:3a:b4:9/electric';

    if (didMounted) {
      await firebase.db.ref(main_hotel).off('value');
    } else {
      // Fetch Main MDB
      await firebase.db.ref(main_hotel).on('value', function (snap) {
        let capt = snap.val();
        setMainMdb({
          main: {
            p_1: {
              current: parseFloat(capt.subdev_0.current),
              power: parseFloat(capt.subdev_0.power),
              power_reactive: parseFloat(capt.subdev_0.power_reactive),
              timestamp: capt.subdev_0.timestamp,
              voltage: parseFloat(capt.subdev_0.voltage),
              energy: parseFloat(capt.subdev_0.energy),
              energy_to_grid: parseFloat(capt.subdev_0.energy_to_grid),
              power_factor: parseFloat(capt.subdev_0.power_factor),
            },
            p_2: {
              current: parseFloat(capt.subdev_1.current),
              power: parseFloat(capt.subdev_1.power),
              power_reactive: parseFloat(capt.subdev_1.power_reactive),
              timestamp: capt.subdev_1.timestamp,
              voltage: parseFloat(capt.subdev_1.voltage),
            },
            p_3: {
              current: parseFloat(capt.subdev_2.current),
              power: parseFloat(capt.subdev_2.power),
              power_reactive: parseFloat(capt.subdev_2.power_reactive),
              timestamp: capt.subdev_2.timestamp,
              voltage: parseFloat(capt.subdev_2.voltage),
            },
          },
        });
      });
    }
  }

  //##-------------------------- Fetching ----------------------------------
  useEffect(() => {
    fetchData(firebase, false);

    return () => {
      fetchData(firebase, true);
      dispatch(energyActions.clearHistoryData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    clearTimeout(timeoutid.current);
    if (mainMdb.main.p_1.current !== null) {
      timeoutid.current = setTimeout(
        () =>
          // dispatch(
          //   energyActions.tdpkEnergy({
          //     // ...Init,
          //     tdpk_energy: {
          //       plot: [
          //         Number(moment(mainMdb.main.p_1.timestamp).format('x')),
          //         Number(
          //           (
          //             mainMdb.main.p_1.power +
          //             mainMdb.main.p_2.power +
          //             mainMdb.main.p_3.power
          //           ).toFixed(2)
          //         ),
          //       ],
          //     },
          //   })
          // ),
          1000
      );
    }

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMdb]);

  const main_graph = {
    series: [
      {
        name: 'Main Power (kW)',
        data: Main_mdb !== undefined && Main_mdb !== null ? Main_mdb.plot : [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        width: 2,
      },
      xaxis: {
        type: 'datetime',

        labels: {
          datetimeUTC: false,
        },
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      title: {
        text: 'Main Power (kW)',
        align: 'left',
      },
    },
  };

  return (
    <div className="content">
      <TdpkEnergyComponents main_graph={main_graph} />
    </div>
  );
};
