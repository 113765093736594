import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FirebaseContext2 } from '../../../firebase';
import { GuestInfoComponent } from '../components';

function GuestInfoTab() {
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const firebase = useContext(FirebaseContext2);
  const [currentUserData, setCurrentUserData] = useState({
    userdata: [],
  });

  const [guestName, setGuestName] = useState(null);

  //##---------------------- Event -----------------------
  const onClickEdit = async (event, field, type) => {
    event.preventDefault();
    var newMessage = window.prompt('เปลี่ยนเป็น ?', field);
    if (newMessage) {
      newMessage = newMessage.trim();
      if (type == 'room_status_change') {
        if (['oc', 'od', 'vc', 'vd', 'oo', 'ac'].includes(newMessage)) {
          await axios.post(`https://mintelfunctions.azurewebsites.net/api/update_room_status`, {
            "room_no": currentRoomNo,
            "last_status": currentUserData?.userdata?.clean_status,
            "room_status": newMessage
          });
        } else{
          alert("กรุณากรอก status ของห้องใหม่ เช่น oc, od, vc, vd, oo, ac");
        }
      } else {
        if (newMessage === field) {
          /* Do Nothing */
        } else {
          alert(`ข้อความได้เปลี่ยนเป็น ${newMessage}`);
        }
      }
    }
  };

  useMemo(() => {
    //##-----------------------Temporary Guest Name Display-------------------------

    if (
      currentUserData.userdata['guest_list'] !== undefined &&
      currentUserData.userdata['guest_list'] !== ''
    ) {
      currentUserData.userdata['guest_list'].forEach((element) => {
        setGuestName({
          guest_id: element['GuestId'],
          guest_name: element['GuestName'],
          country: element['Country'],
          nationality: element['Nationality'],
          seq: element['Seq'],
        });
      });
    } else {
      setGuestName({
        guest_id: '',
        guest_name: '',
        country: '',
        nationality: '',
        seq: '',
      });
    }
  }, [currentUserData.userdata]);

  useEffect(() => {
    let fb_path = '/hotel/mintel/user_info/room_status/' + currentRoomNo;
    firebase.db.ref(fb_path).on('value', function (snap) {
      let capt = snap.val();
      setCurrentUserData({
        userdata: capt,
      });
    });

    return () => firebase.db.ref(fb_path).off('value');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomNo]);

  //##---------------------- Render-----------------------
  return (
    <>
      <GuestInfoComponent
        currentUserData={currentUserData}
        guestName={guestName}
        onClickEdit={onClickEdit}
      />
    </>
  );
}

export { GuestInfoTab };
