export const MapEnv = [
  // [type, mac, x1, y1, x2, y2]
  {
    name: 'env1',
    x1: 0.083810888252149,
    y1: 0.08030592734225621,
    x2: 0.0995702005730659,
    y2: 0.10516252390057361,
    triggers: ['C0601', 'env_1', 'env'],
  },
  {
    name: 'env2',
    x1: 0.20988538681948424,
    y1: 0.08986615678776291,
    x2: 0.22564469914040114,
    y2: 0.1147227533460803,
    triggers: ['C0612', 'env_2', 'env'],
  },
  {
    name: 'env3',
    x1: 0.3044412607449857,
    y1: 0.08986615678776291,
    x2: 0.3216332378223496,
    y2: 0.11663479923518165,
    triggers: ['C0611', 'env_3', 'env'],
  },
  {
    name: 'env4',
    x1: 0.44054441260744986,
    y1: 0.124282982791587,
    x2: 0.4577363896848137,
    y2: 0.14722753346080306,
    triggers: ['C0603', 'env_4', 'env'],
  },
  {
    name: 'env5',
    x1: 0.5279369627507163,
    y1: 0.21414913957934992,
    x2: 0.5436962750716332,
    y2: 0.24091778202676864,
    triggers: ['C0604', 'env_5', 'env'],
  },
  {
    name: 'env6',
    x1: 0.3359598853868195,
    y1: 0.23709369024856597,
    x2: 0.3531518624641834,
    y2: 0.25812619502868067,
    triggers: ['C0602', 'env_6', 'env'],
  },
  {
    name: 'env7',
    x1: 0.32593123209169056,
    y1: 0.35564053537284895,
    x2: 0.34169054441260743,
    y2: 0.37858508604206503,
    triggers: ['C0601', 'env_7', 'env'],
  },
  {
    name: 'env8',
    x1: 0.2700573065902579,
    y1: 0.4340344168260038,
    x2: 0.28724928366762176,
    y2: 0.4569789674952199,
    triggers: ['C0612', 'env_8', 'env'],
  },
  {
    name: 'env9',
    x1: 0.1812320916905444,
    y1: 0.4340344168260038,
    x2: 0.1984240687679083,
    y2: 0.4588910133843212,
    triggers: ['C0611', 'env_9', 'env'],
  },
  {
    name: 'env10',
    x1: 0.17979942693409742,
    y1: 0.5219885277246654,
    x2: 0.1969914040114613,
    y2: 0.5468451242829828,
    triggers: ['C0603', 'env_10', 'env'],
  },
  {
    name: 'env11',
    x1: 0.3015759312320917,
    y1: 0.5487571701720841,
    x2: 0.31876790830945556,
    y2: 0.5736137667304015,
    triggers: ['C0601', 'env_11', 'env'],
  },
  {
    name: 'env12',
    x1: 0.45343839541547276,
    y1: 0.5372848948374761,
    x2: 0.47206303724928367,
    y2: 0.5602294455066922,
    triggers: ['C0612', 'env_12', 'env'],
  },
  {
    name: 'env13',
    x1: 0.4362464183381089,
    y1: 0.6003824091778203,
    x2: 0.45343839541547276,
    y2: 0.627151051625239,
    triggers: ['C0611', 'env_13', 'env'],
  },
  {
    name: 'env14',
    x1: 0.417621776504298,
    y1: 0.6864244741873805,
    x2: 0.4391117478510029,
    y2: 0.7112810707456979,
    triggers: ['C0603', 'env_14', 'env'],
  },
  {
    name: 'env15',
    x1: 0.5938395415472779,
    y1: 0.4933078393881453,
    x2: 0.6124641833810889,
    y2: 0.5239005736137667,
    triggers: ['C0604', 'env_15', 'env'],
  },
  {
    name: 'env16',
    x1: 0.582378223495702,
    y1: 0.6730401529636711,
    x2: 0.5981375358166189,
    y2: 0.6959847036328872,
    triggers: ['C0602', 'env_16', 'env'],
  },
  {
    name: 'env17',
    x1: 0.5436962750716332,
    y1: 0.7323135755258127,
    x2: 0.5594555873925502,
    y2: 0.7552581261950286,
    triggers: ['C0601', 'env_17', 'env'],
  },
];
