import React from 'react';
//import { ModeTab } from '../TDPKComponents/ModeTab.components';
import { ShowStatusTab } from '../NAPComponents';
import { ShowIconTab } from '../NAPComponents';
import { Row } from 'reactstrap';

export const OptionContents = (props) => {
  return (
    <div>
      <Row>
        <ShowStatusTab
          airStatusChecked={props.airStatusChecked}
          envStatusChecked={props.envStatusChecked}
          handleChangeAirStatus={props.handleChangeAirStatus}
          handleChangeEnvStatus={props.handleChangeEnvStatus}
        />
        <ShowIconTab
          airIconChecked={props.airIconChecked}
          envIconChecked={props.envIconChecked}
          secIconChecked={props.secIconChecked}
          handleChangeAirIcon={props.handleChangeAirIcon}
          handleChangeEnvIcon={props.handleChangeEnvIcon}
          handleChangeSecIcon={props.handleChangeSecIcon}
        />
      </Row>
    </div>
  );
};
