import React, { useState } from 'react';
import { Menu } from './Tabs/Menu';
import { Row, Col, Card } from 'react-bootstrap';

export const Cam = () => {
  const [currentTab, setCurrentTab] = useState('control');
  // console.log({ currentTab });
  return (
    <div>
      <Menu currentTab={(e) => setCurrentTab(e)} />
      {currentTab === 'control' && (
        <div
          style={{ height: 450, alignItems: 'center', display: 'flex', justifyContent: 'center' }}
        >
          <img
            src="https://img.advice.co.th/images_nas/pic_product4/A0128582/A0128582OK_BIG_1.jpg"
            alt="cctv"
            width="400px"
            height="400px"
          />
        </div>
      )}

      {currentTab === 'info' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {currentTab === 'activity' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {currentTab === 'history' && (
        <>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body
                  style={{
                    height: 450,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ color: '#d3d3d3' }}>Coming soon ...</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
