import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ScheduleSetting } from '../Menu/ScheduleSetting/ScheduleSetting';
import { PMS } from '../Menu/PMS/PMS.component';
import { Device } from '../Menu/Device/Device';
import { Timeline, Power } from '@material-ui/icons';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(0.5),
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container style={{ padding: '0px' }}>
          <Box p={3}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const NavComponent = withStyles(styles)(() => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content" style={{ minHeight: '100%', marginLeft: '0px', marginRight: '0px' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
      >
        <Tab
          label="PMS"
          icon={<Timeline />}
          {...a11yProps(0)}
          style={{ fontSize: 12, outline: 'none' }}
        />
        <Tab
          label="SCHEDULE"
          icon={<AccessAlarmIcon />}
          {...a11yProps(1)}
          style={{ fontSize: 12, outline: 'none' }}
        />
        <Tab
          label="DEVICE"
          icon={<Power />}
          {...a11yProps(2)}
          style={{ fontSize: 12, outline: 'none' }}
        />
      </Tabs>
      {value === 0 && <PMS />}
      {value === 1 && <ScheduleSetting />}
      {value === 2 && <Device />}
    </div>
  );
});

export { NavComponent };
