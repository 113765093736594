import React from 'react';
import { Card, CardHeader, CardBody, Col } from 'reactstrap';
import Chart from 'react-apexcharts';
import { UpdatedAt } from 'components/UpdatedAt';

const EnergyPerRoomnight = (props) => {
  return (
    <Col md="4">
      <Card>
        <div style={{ height: '350px' }}>
          <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
            <h5 className="card-title">{props.name}</h5>
          </CardHeader>
          <CardBody>
            {/* <Plot
            data={props.data}
            layout={{ width: 300, height: 235, margin: { l: 50, t: 20, b: 20, r: 50 } }}
            // layout={{ width: '100%', height: '100%', margin: { l: 50, t: 20, b: 20, r: 50 } }}
          /> */}
            <Chart
              options={props.data.options}
              series={props.data.series}
              type="bar"
              height="225"
            />
          </CardBody>
          {/* <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-info" />
            Open
          </div>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar" />
            Number of emails sent
          </div>
        </CardFooter> */}
        </div>
        <UpdatedAt>
          {props.room_night_UpdatedAt ? 'Updated at:' + props.room_night_UpdatedAt : '...'}
        </UpdatedAt>
      </Card>
    </Col>
  );
};

export { EnergyPerRoomnight };
