import React, { useState } from 'react';
import { OptionContents } from './OptionContents.containers';
import { NAPContents } from './NAPContents.containers';
import { Col, Row } from 'react-bootstrap';

export const NAPFloorPlan = () => {
  const [airStatusChecked, setAirStatusChecked] = useState(true);
  const [envStatusChecked, setEnvStatusChecked] = useState(true);
  const [airIconChecked, setAirIconChecked] = useState(true);
  const [envIconChecked, setEnvIconChecked] = useState(true);
  const [secIconChecked, setSecIconChecked] = useState(true);

  const handleChangeAirStatus = () => {
    setAirStatusChecked(!airStatusChecked);
  };

  const handleChangeEnvStatus = () => {
    setEnvStatusChecked(!envStatusChecked);
  };

  const handleChangeAirIcon = () => {
    setAirIconChecked(!airIconChecked);
  };

  const handleChangeEnvIcon = () => {
    setEnvIconChecked(!envIconChecked);
  };

  const handleChangeSecIcon = () => {
    setSecIconChecked(!secIconChecked);
  };

  return (
    <div className="content">
      <Row>
        <Col lg="10" md="12" sm="12">
          <NAPContents />
        </Col>
        <Col lg="2" md="12" sm="12" style={{ marginTop: '3%' }}>
          <OptionContents
            airStatusChecked={airStatusChecked}
            envStatusChecked={envStatusChecked}
            handleChangeAirStatus={handleChangeAirStatus}
            handleChangeEnvStatus={handleChangeEnvStatus}
            airIconChecked={airIconChecked}
            envIconChecked={envIconChecked}
            secIconChecked={secIconChecked}
            handleChangeAirIcon={handleChangeAirIcon}
            handleChangeEnvIcon={handleChangeEnvIcon}
            handleChangeSecIcon={handleChangeSecIcon}
          />
        </Col>
      </Row>
    </div>
  );
};
