import React, { useState, useContext, useEffect } from 'react';
import { DatePickers } from '../../../../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { ElectricityForcastedGraph } from '../../../components/EnergyCostBreakdown/ElectricityForcastedGraph/ElectricityForcastedGraph';
import { FirebaseContext2 } from '../../../../../firebase';

import { Row, Col, CardTitle, CardHeader, Card } from 'reactstrap';

const ElectricityByHour = () => {
  const firebase = useContext(FirebaseContext2);

  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

  const [electricity, setElectricity] = useState(null);

  async function fetchData(didMount) {
    const main_path = 'hotel/mintel/energy/electricity_by_hour';
    if (didMount) {
      await firebase.db.ref(main_path).off('value');
    } else {
      await firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        setElectricity(capt);
      });
    }
  }

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  useEffect(() => {
    fetchData(false);

    return () => fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plotData = {
    series: [
      {
        name: 'kWh',
        data: electricity !== null ? Object.values(electricity) : [],
      },
    ],

    options: {
      colors: ['#A9ECA9'],
      dataLabels: {
        enabled: true,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '12px',
          colors: ['#808080'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: electricity !== null ? Object.keys(electricity) : [],
      },
      yaxis: {
        // tickAmount: 9,
        title: {
          text: '(kWh)',
        },
      },
    },
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Col lg="12" sm="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Select Time</CardTitle>
          </CardHeader>
          <Row style={{ padding: '20px', paddingTop: '30px' }}>
            <Col lg="4" sm="6">
              <DatePickers
                daySelectStart={startDate}
                daySelectStop={stopDate}
                handleStartDate={handleStartDate}
                handleStopDate={handleStopDate}
              />
              {/* From{' '}
              <DatePicker selected={daySelectStart} onChange={(date) => setDaySelectStart(date)} />
            </Col>
            <Col lg="4" sm="6">
              To <DatePicker selected={daySelectStop} onChange={(date) => setDaySelectStop(date)} /> */}
            </Col>
            <Col lg="4" sm="6"></Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <ElectricityForcastedGraph
                title={'Electricity Allocation'}
                options={plotData.options}
                series={plotData.series}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
};

export { ElectricityByHour };
