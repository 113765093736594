import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCheck, faLock } from '@fortawesome/free-solid-svg-icons';

import { useDispatch } from 'react-redux';

import './Login.css';

import { loginActions } from '../../auth/actions';
import { navActions } from '../../nav/actions';

const logo = '/assets/images/dashbard/LogoAltoTech-03.png';

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

function LoginForm() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log({ data });

    if (data.username && data.password) {
      let payload = {
        username: data.username,
        password: data.password,
      };
      let token = await dispatch(loginActions.login(payload));
      if (token) {
        await dispatch(navActions.navigatePage('/admin/dashboard'));
      } else {
        alert('username หรือ password อาจไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง');
      }
    } else {
      alert('Invalid Username or Password');
    }
  };

  return (
    <div className="Login">
      <h1>
        <img src={logo} alt="react-logo" height="50" width="50" /> Login
      </h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group size="lg">
          <Form.Label>Username</Form.Label>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                ref={register}
                className="input"
                name="username"
                type="text"
                placeholder="Username"
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            </p>
          </div>
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <div className="field">
            <p className="control has-icons-left">
              <input
                ref={register}
                className="input"
                name="password"
                type="password"
                placeholder="Password"
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
            </p>
          </div>
        </Form.Group>
        <Button block size="md" type="submit">
          Login
        </Button>
      </Form>
    </div>
    // <div
    //   className="Login"
    //   style={{ display: 'inline-block', textAlign: 'center', alignItems: 'center' }}
    // >
    //   <form
    //     onSubmit={handleSubmit(onSubmit)}
    //     className="column is-6"
    //     style={{ display: 'inline-block', textAlign: 'center', alignItems: 'center' }}
    //   >
    //     <h1 className="title is-2">Login</h1>
    //     <div className="field">
    //       <p className="control has-icons-left has-icons-right">
    //         <input
    //           ref={register}
    //           className="input"
    //           name="username"
    //           type="text"
    //           placeholder="Username"
    //         />
    //         <span className="icon is-small is-left">
    //           <FontAwesomeIcon icon={faUser} />
    //         </span>
    //         <span className="icon is-small is-right">
    //           <FontAwesomeIcon icon={faCheck} />
    //         </span>
    //       </p>
    //     </div>
    //     <div className="field">
    //       <p className="control has-icons-left">
    //         <input
    //           ref={register}
    //           className="input"
    //           name="password"
    //           type="password"
    //           placeholder="Password"
    //         />
    //         <span className="icon is-small is-left">
    //           <FontAwesomeIcon icon={faLock} />
    //         </span>
    //       </p>
    //     </div>
    //     <input className="btn btn-round" type="submit" value="Login" />
    //   </form>
    //   <div
    //     className="full-page-background"
    //     style={{
    //       backgroundImage: `url(${require('assets/img/bg/fabio-mangione.jpg')})`,
    //     }}
    //   />
    // </div>
  );
}

export { LoginForm };
