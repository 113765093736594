import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';

const Topic = styled.h5`
  margin: 0px;
  float: right;
`;

const Header = styled.h4`
  margin: 0px;
`;

const TdpkEnergySavingComponents = (props) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <Col lg="12" sm="12">
        <Card>
          <Row>
            <Col lg="12" md="12" sm="12">
              <div>
                <CardHeader style={{ boxShadow: 'none' }}>
                  <CardTitle tag="h4">{props.title}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={props.plotData.options}
                    series={props.plotData.series}
                    type="bar"
                    height="300"
                  />
                </CardBody>
              </div>
            </Col>
          </Row>
          <div style={{ margin: '20px', marginLeft: '40px' }}>
            <Row>
              <Header>Alto Tech Solution</Header>
            </Row>

            <Row>
              <Col lg="3">
                <Topic>Investment :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {props.energySaving !== null && props.energySaving.finance.investment.value}{' '}
                  {props.energySaving !== null && props.energySaving.finance.investment.unit}
                </p>
              </Col>
              <Col lg="3">
                <Topic>Investment Score :</Topic>
              </Col>
              <Col lg="3">
                <p>{props.energySaving !== null && props.energySaving.finance.investment_score}</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Save Monthly :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {props.energySaving !== null && props.energySaving.finance.save_monthly.value}{' '}
                  {props.energySaving !== null && props.energySaving.finance.save_monthly.unit}
                </p>
              </Col>
              <Col lg="3">
                <Topic>Save Yearly :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {props.energySaving !== null && props.energySaving.finance.save_yearly.value}{' '}
                  {props.energySaving !== null && props.energySaving.finance.save_yearly.unit}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Payback :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {props.energySaving !== null && props.energySaving.finance.payback.value}{' '}
                  {props.energySaving !== null && props.energySaving.finance.payback.unit}
                </p>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export { TdpkEnergySavingComponents };
