import React from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
// import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { hotelActions } from '../../../hotel/actions';
// import { energyActions } from '../../../energy/actions';
import { DatePickers } from '../../../../components/DatePickers/DatePickers.container';

export const HistoryData = (props) => {
  const data = props.data;
  const windowPlot = props.windowPlot;
  const wardrobePlot = props.wardrobePlot;
  const bedPlot = props.bedPlot;
  const toiletPlot = props.toiletPlot;
  const corridorPlot = props.corridorPlot;
  const mainPlot = props.mainPlot;

  // const dispatch = useDispatch();
  const showIoTModal = useSelector((state) => state.dashboard.showIotModal);
  const modalName = useSelector((state) => state.dashboard.modalName);
  // const [dropdownOpen, setOpen] = useState(false);
  // const [dropdownText, setDropdownText] = useState('Select Device');

  // const toggle = () => setOpen(!dropdownOpen);

  // const onClick = (data) => {
  //   setDropdownText(data.slice(2));
  //   dispatch(energyActions.clearHistoryData());
  //   dispatch(hotelActions.dropDownActivity({ dropdown: data.charAt(0) }));
  // };

  // Clink device on the web
  const clickOnDevice = useSelector((state) => state.dashboard);

  return (
    <div style={{ padding: 20, margin: 0, paddingTop: 0 }}>
      {/** Environment Sensor */}
      {modalName === 'broadlinkenv' && showIoTModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.temp.options} series={data.temp.series} type="line" />
          <Chart options={data.humid.options} series={data.humid.series} type="line" />
        </div>
      )}

      {/** AC  */}
      {modalName === 'ac' && showIoTModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.options} series={data.series} type="line" />
          <Chart
            options={props.mode_plot.options}
            series={props.mode_plot.series}
            type="line"
            height="200px"
          />
        </div>
      )}

      {/** Front Light  */}
      {modalName === 'front_switch' && showIoTModal && (
        <div>
          {/* <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{dropdownText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onClick('0_Toilet Switch')}>Toilet Switch</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onClick('1_Corridor Switch')}>
                Corridor Switch
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onClick('2_Main Switch')}>Main Switch</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown> */}
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          {/* <Chart options={data.options} series={data.series} type="line" height="200px" /> */}
          <Chart
            options={toiletPlot.options}
            series={toiletPlot.series}
            type="line"
            height="150px"
          />
          <Chart
            options={corridorPlot.options}
            series={corridorPlot.series}
            type="line"
            height="150px"
          />
          <Chart options={mainPlot.options} series={mainPlot.series} type="line" height="150px" />
        </div>
      )}

      {/** Back Light  */}
      {modalName === 'back_switch' && showIoTModal && (
        <div>
          {/* <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{dropdownText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onClick('0_Window Switch')}>Window Switch</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onClick('1_Wardrope Switch')}>
                Wardrope Switch
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => onClick('2_Bed Switch')}>Bed Switch</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown> */}
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          {/* <Chart options={data.options} series={data.series} type="line" height="200px" /> */}
          <Chart
            options={windowPlot.options}
            series={windowPlot.series}
            type="line"
            height="150px"
          />
          <Chart
            options={wardrobePlot.options}
            series={wardrobePlot.series}
            type="line"
            height="150px"
          />
          <Chart options={bedPlot.options} series={bedPlot.series} type="line" height="150px" />
        </div>
      )}

      {/** Refrigerator Control  */}
      {modalName === 'fridge' && showIoTModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.options} series={data.series} type="line" />
        </div>
      )}

      {/** isShowExhaust Fan Modal  */}
      {modalName === 'fan' && showIoTModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.options} series={data.series} type="line" height="200px" />
        </div>
      )}

      {/** isShow Smart Meter Modal */}
      {modalName === 'smart_meter' && showIoTModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.options} series={data.series} type="line" />
        </div>
      )}

      {/** is Show SmartTv Control Modal */}
      {modalName === 'smart_tv' && showIoTModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.options} series={data.series} type="line" />
        </div>
      )}

      {/** is Syn AC Control Modal */}
      {clickOnDevice.isSynACControlModal && (
        <div>
          <DatePickers
            daySelectStart={props.startDate}
            daySelectStop={props.stopDate}
            handleStartDate={props.handleStartDate}
            handleStopDate={props.handleStopDate}
          />
          <Chart options={data.options} series={data.series} type="line" />
        </div>
      )}
    </div>
  );
};
