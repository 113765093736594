import React, { useMemo, useContext, useState, useRef, useEffect } from 'react';
import { EnergyComponent } from '../../../components/Energy';
import { FirebaseContext2 } from '../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { energyActions } from '../../../features/energy/actions';
import { modBus } from '../../../api/services';
import { API_CONSTANTS } from '../../../api';
import moment from 'moment';

export const EnergyContainer = (props) => {
  const timeoutid = useRef();
  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext2);

  // const E_mdb = useSelector((state) => state.energy.syn_emdb);
  // const M_mdb = useSelector((state) => state.energy.syn_mdb);
  const T_mdb = useSelector((state) => state.energy.syn_total);
  const energyTabSelect = useSelector((state) => state.energy.energyTabSelect);

  // const [histEMDB, setHistEMDB] = useState(null);
  // const [histMDB, setHistMDB] = useState(null);
  const [histTotal, setHistTotal] = useState(null);

  // console.log({ histTotal });

  // const [plothistEMDB, setPlotHistEMDB] = useState([]);
  // const [plothistMDB, setPlotHistMDB] = useState([]);
  const [plothistTotal, setPlothistTotal] = useState([]);

  //##-----------------DatePicker---------------------------
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

  // console.log(plothistEMDB);

  const [emdb, setEmdb] = useState({
    emdb: {
      total_power: null,
      p_1: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
        energy: null,
        energy_reactive: null,
        energy_reactive_to_grid: null,
        energy_to_grid: null,
        power_factor: null,
      },
      p_2: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
      },
      p_3: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
      },
    },
  });

  const [mdb, setMdb] = useState({
    total_power: null,
    mdb: {
      p_1: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
        energy: null,
        energy_reactive: null,
        energy_reactive_to_grid: null,
        energy_to_grid: null,
        power_factor: null,
      },
      p_2: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
      },
      p_3: {
        current: null,
        power: null,
        power_reactive: null,
        timestamp: null,
        voltage: null,
      },
    },
  });

  const [totalElec, setTotalElec] = useState({
    power: null,
    current: null,
    power_factor: null,
    power_reactive: null,
    voltage: null,
    timestamp: null,
  });

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  //##----------------Get month number to string---------------------------
  function GetMonth(start) {
    // eslint-disable-next-line
    let month = new Array();

    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    if (start) {
      return month[startDate.getMonth()];
    } else {
      return month[stopDate.getMonth()];
    }
  }

  //##--------------------------Fetch Data----------------------------------
  async function fetchData(firebase, didMounted) {
    let fbpath1 =
      API_CONSTANTS.FIREBASE_SYN_REAL_TIME_PATH + 'main_energy/iot_devices/00:21:79:b2:38:0e:1';
    let fbpath2 =
      API_CONSTANTS.FIREBASE_SYN_REAL_TIME_PATH + 'main_energy/iot_devices/00:21:79:b2:38:0e:2';
    let fbpath3 =
      API_CONSTANTS.FIREBASE_SYN_REAL_TIME_PATH + 'main_energy/iot_devices/00:21:79:b2:38:0e:total';

    if (didMounted) {
      console.log('Firebase did mounted');
      await firebase.db.ref(fbpath1).off('value');
      await firebase.db.ref(fbpath2).off('value');
      await firebase.db.ref(fbpath3).off('value');
    } else {
      await firebase.db.ref(fbpath1).on('value', function (snap) {
        let capt = snap.val();
        setEmdb({
          emdb: {
            pf_total: parseFloat(capt.data.electric.total.power_factor),
            p_1: {
              current: parseFloat(capt.data.electric.phase_1.current),
              power: parseFloat(capt.data.electric.phase_1.power),
              power_reactive: parseFloat(capt.data.electric.phase_1.power_reactive),
              timestamp: capt.data.electric.phase_1.timestamp,
              voltage: parseFloat(capt.data.electric.phase_1.voltage),
              energy: parseFloat(capt.data.electric.phase_1.energy),
              energy_reactive: parseFloat(capt.data.electric.phase_1.energy_reactive),
              energy_reactive_to_grid: parseFloat(
                capt.data.electric.phase_1.energy_reactive_to_grid
              ),
              energy_to_grid: parseFloat(capt.data.electric.phase_1.energy_to_grid),
              power_factor: parseFloat(capt.data.electric.phase_1.power_factor),
            },
            p_2: {
              current: parseFloat(capt.data.electric.phase_2.current),
              power: parseFloat(capt.data.electric.phase_2.power),
              power_reactive: parseFloat(capt.data.electric.phase_2.power_reactive),
              timestamp: capt.data.electric.phase_2.timestamp,
              voltage: parseFloat(capt.data.electric.phase_2.voltage),
            },
            p_3: {
              current: parseFloat(capt.data.electric.phase_3.current),
              power: parseFloat(capt.data.electric.phase_3.power),
              power_reactive: parseFloat(capt.data.electric.phase_3.power_reactive),
              timestamp: capt.data.electric.phase_3.timestamp,
              voltage: parseFloat(capt.data.electric.phase_3.voltage),
            },
          },
        });
      });
      await firebase.db.ref(fbpath2).on('value', function (snap) {
        let capt = snap.val();
        setMdb({
          mdb: {
            pf_total: parseFloat(capt.data.electric.total.power_factor),
            p_1: {
              current: parseFloat(capt.data.electric.phase_1.current),
              power: parseFloat(capt.data.electric.phase_1.power),
              power_reactive: parseFloat(capt.data.electric.phase_1.power_reactive),
              timestamp: capt.data.electric.phase_1.timestamp,
              voltage: parseFloat(capt.data.electric.phase_1.voltage),
              energy: parseFloat(capt.data.electric.phase_1.energy),
              energy_reactive: parseFloat(capt.data.electric.phase_1.energy_reactive),
              energy_reactive_to_grid: parseFloat(
                capt.data.electric.phase_1.energy_reactive_to_grid
              ),
              energy_to_grid: parseFloat(capt.data.electric.phase_1.energy_to_grid),
              power_factor: parseFloat(capt.data.electric.phase_1.power_factor),
            },
            p_2: {
              current: parseFloat(capt.data.electric.phase_2.current),
              power: parseFloat(capt.data.electric.phase_2.power),
              power_reactive: parseFloat(capt.data.electric.phase_2.power_reactive),
              timestamp: capt.data.electric.phase_2.timestamp,
              voltage: parseFloat(capt.data.electric.phase_2.voltage),
            },
            p_3: {
              current: parseFloat(capt.data.electric.phase_3.current),
              power: parseFloat(capt.data.electric.phase_3.power),
              power_reactive: parseFloat(capt.data.electric.phase_3.power_reactive),
              timestamp: capt.data.electric.phase_3.timestamp,
              voltage: parseFloat(capt.data.electric.phase_3.voltage),
            },
          },
        });
      });
      await firebase.db.ref(fbpath3).on('value', function (snap) {
        let capt = snap.val();
        setTotalElec({
          power: Number(capt.data.electric.total.power),
          current: Number(capt.data.electric.total.current),
          power_factor: Number(capt.data.electric.total.power_factor),
          power_reactive: Number(capt.data.electric.total.power_reactive),
          voltage: Number(capt.data.electric.total.voltage),
          timestamp: capt.data.electric.total.timestamp,
        });
      });
    }
  }

  //##------------------------History Data----------------------------------
  useEffect(() => {
    console.log('try to fetch');
    histData('00:21:79:b2:38:0e:1', 3, startDate, stopDate).then((results) => {
      /// EMDB
      // return setHistEMDB(results);
    });
    histData('00:21:79:b2:38:0e:2', 3, startDate, stopDate).then((results) => {
      /// MDB
      // return setHistMDB(results);
    });
    histData('00:21:79:b2:38:0e:total', 3, startDate, stopDate).then((results) => {
      /// EMDB + MDB
      return setHistTotal(results);
    });

    fetchData(firebase, false);
    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, stopDate, energyTabSelect]);

  //##-------------------------For loop get hist data----------------------------
  useMemo(() => {
    if (histTotal !== null) {
      // let mdb = [];
      // let emdb = [];
      let total = [];
      // histEMDB.forEach((item) => {
      //   if (item.power !== null && item.timestamp !== null) {
      //     emdb.push([
      //       Number(moment(item.timestamp).format('x')),
      //       Number(item.power.toPrecision(3)),
      //     ]);
      //   }
      //   setPlotHistEMDB(emdb);
      // });
      // histMDB.forEach((item) => {
      //   if (item.power !== null && item.timestamp !== null) {
      //     mdb.push([Number(moment(item.timestamp).format('x')), Number(item.power.toPrecision(3))]);
      //   }
      //   setPlotHistMDB(mdb);
      // });
      histTotal.forEach((item) => {
        if (item.power !== null && item.timestamp !== null) {
          total.push([
            Number(moment(item.timestamp).format('x')),
            Number(item.power.toPrecision(3)),
          ]);
        }
        setPlothistTotal(total);
      });
    }
  }, [histTotal]);

  useMemo(() => {
    clearTimeout(timeoutid.current);
    if (totalElec.current !== null) {
      timeoutid.current = setTimeout(
        () =>
          dispatch(
            energyActions.synEnergy({
              // ...all_state,
              syn_emdb: {
                total_power: (
                  emdb.emdb.p_1.power +
                  emdb.emdb.p_2.power +
                  emdb.emdb.p_3.power
                ).toFixed(2),
                plot: [
                  Number(moment(emdb.emdb.p_1.timestamp).format('x')),
                  Number(
                    (emdb.emdb.p_1.power + emdb.emdb.p_2.power + emdb.emdb.p_3.power).toFixed(2)
                  ),
                ],
                p_1: {
                  power: emdb.emdb.p_1.power.toFixed(2),
                  current: emdb.emdb.p_1.current.toFixed(2),
                  voltage: emdb.emdb.p_1.voltage.toFixed(2),
                  power_reactive: emdb.emdb.p_1.power_reactive.toFixed(2),
                  time: emdb.emdb.p_1.timestamp,
                  energy: emdb.emdb.p_1.energy,
                  energy_reactive: emdb.emdb.p_1.energy_reactive,
                  energy_reactive_to_grid: emdb.emdb.p_1.energy_reactive_to_grid,
                  energy_to_grid: emdb.emdb.p_1.energy_to_grid,
                  power_factor: emdb.emdb.p_1.power_factor,
                },
                p_2: {
                  power: emdb.emdb.p_2.power.toFixed(2),
                  current: emdb.emdb.p_2.current.toFixed(2),
                  voltage: emdb.emdb.p_2.voltage.toFixed(2),
                  power_reactive: emdb.emdb.p_2.power_reactive.toFixed(2),
                  time: emdb.emdb.p_2.timestamp,
                },
                p_3: {
                  power: emdb.emdb.p_3.power.toFixed(2),
                  current: emdb.emdb.p_3.current.toFixed(2),
                  voltage: emdb.emdb.p_3.voltage.toFixed(2),
                  power_reactive: emdb.emdb.p_3.power_reactive.toFixed(2),
                  time: emdb.emdb.p_3.timestamp,
                },
              },
              syn_mdb: {
                total_power: (mdb.mdb.p_1.power + mdb.mdb.p_2.power + mdb.mdb.p_3.power).toFixed(2),
                plot: [
                  Number(moment(mdb.mdb.p_1.timestamp).format('x')),
                  Number((mdb.mdb.p_1.power + mdb.mdb.p_2.power + mdb.mdb.p_3.power).toFixed(2)),
                ],
                p_1: {
                  power: mdb.mdb.p_1.power.toFixed(2),
                  current: mdb.mdb.p_1.current.toFixed(2),
                  voltage: mdb.mdb.p_1.voltage.toFixed(2),
                  power_reactive: mdb.mdb.p_1.power_reactive.toFixed(2),
                  time: mdb.mdb.p_1.timestamp,
                  energy: mdb.mdb.p_1.energy.toFixed(2),
                  energy_reactive: mdb.mdb.p_1.energy_reactive.toFixed(2),
                  energy_reactive_to_grid: mdb.mdb.p_1.energy_reactive_to_grid.toFixed(2),
                  energy_to_grid: mdb.mdb.p_1.energy_to_grid.toFixed(2),
                  power_factor: mdb.mdb.p_1.power_factor,
                },
                p_2: {
                  power: mdb.mdb.p_2.power.toFixed(2),
                  current: mdb.mdb.p_2.current.toFixed(2),
                  voltage: mdb.mdb.p_2.voltage.toFixed(2),
                  power_reactive: mdb.mdb.p_2.power_reactive.toFixed(2),
                  time: mdb.mdb.p_2.timestamp,
                },
                p_3: {
                  power: mdb.mdb.p_3.power.toFixed(2),
                  current: mdb.mdb.p_3.current.toFixed(2),
                  voltage: mdb.mdb.p_3.voltage.toFixed(2),
                  power_reactive: mdb.mdb.p_3.power_reactive.toFixed(2),
                  time: mdb.mdb.p_3.timestamp,
                },
              },
              syn_total: {
                plot: [
                  Number(moment(totalElec.timestamp).format('x')),
                  Number(totalElec.power.toFixed(2)),
                ],
              },
            })
          ),
        2000
      );
    }

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalElec]);

  const main_graph = {
    series: [
      {
        name: `กราฟแสดงปริมาณการใช้ไฟฟ้า ${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()} - ${stopDate.getDate()}/${
          stopDate.getMonth() + 1
        }/${stopDate.getFullYear()}`,
        data:
          plothistTotal !== [] || plothistTotal !== null ? plothistTotal.concat(T_mdb.plot) : [],
      },
    ],
    options: {
      // chart: {
      //   id: 'chart2',
      //   toolbar: {
      //     autoSelected: 'pan',
      //     show: false,
      //   },
      // },
      // fill: {
      //   gradient: {
      //     enabled: true,
      //     opacityFrom: 0.55,
      //     opacityTo: 0,
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        // style: 'hollow',
      },
      stroke: {
        width: 2,
        cruve: 'smooth',
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (val) {
            return val.toFixed(2);
          },
        },
        // tickAmount: 9,
        title: {
          text: '(kW)',
        },
      },
      xaxis: {
        type: 'datetime',
        min: new Date(
          `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 00:00:00`
        ).getTime(),
        max: new Date(
          `${stopDate.getDate() - 1} ${GetMonth(false)} ${stopDate.getFullYear()} 23:59:59`
        ).getTime(),
        labels: {
          datetimeUTC: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      title: {
        text: `กราฟแสดงปริมาณการใช้ไฟฟ้า  ${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()} - ${stopDate.getDate()}/${
          stopDate.getMonth() + 1
        }/${stopDate.getFullYear()}`,
        align: 'center',
      },
      annotations: {
        xaxis: [
          {
            x: new Date(
              `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 09:00:00`
            ).getTime(),
            x2: new Date(
              `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 22:00:00`
            ).getTime(),
            fillColor: '#F7819F',
          },
        ],
      },
    },
  };
  return (
    <div>
      <EnergyComponent
        main_graph={main_graph}
        startDate={startDate}
        stopDate={stopDate}
        handleStartDate={handleStartDate}
        handleStopDate={handleStopDate}
        // test_plot={test_plot}
      />
    </div>
  );
};

async function histData(dev_id, sub_dev, startDate, stopDate) {
  try {
    let results = await modBus.getModBusHistory(dev_id, sub_dev, false, startDate, stopDate);
    if (results) {
      if (results['errors'] !== undefined) {
        // [DEBUG]
        let errorMsgDevice = results['errors'];
        // alert(errorMsg);
        console.error(errorMsgDevice);

        return null;
      } else {
        if (results.data) {
          let mapDataActivity = results.data.results;

          return mapDataActivity;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
