var endpoint = 'http://localhost:8080';

if (process.env.REACT_APP_API_URL) {
  endpoint = process.env.REACT_APP_API_URL;
}

const API_CONSTANTS = {
  //## [Login]
  LOGIN_URL: endpoint + '/api/v2.0/login/',

  //## [User]
  GET_USER_LIST_URL: endpoint + '/api/v0.3/get_user_list/',
  RESET_USER_STATE_URL: endpoint + '/api/v0.3/reset_user_state/',
  DELETE_USER_URL: endpoint + '/api/v0.3/remove_user/',
  CHANGE_GUEST_ROOM_URL: endpoint + '/api/v0.3/change_guest_room/',

  GET_PENDING_USER_LIST_URL: endpoint + '/api/v0.3/get_pending_user_list/',
  ACCEPT_PENDING_USER_URL: endpoint + '/api/v0.3/accept_pending_user/',
  DISCARD_PENDING_USER_URL: endpoint + '/api/v0.3/discard_pending_user/',

  //## [Line]
  MSG_BY_LINE_ID_URL: endpoint + '/api/v0.3/post_to_lineid/',

  //## [Hotel]
  GET_HOTEL_MAP_DATA: endpoint + '/api/v0.3/loadmapdata?map_name=main',

  GET_ALL_DEVICES_URL: endpoint + '/api/v2.0/devices/',

  GET_ALL_ACTIVITIES_URL: endpoint + '/api/v2.0/deviceactivity/',

  GET_ALL_ACTIVITIES_HISTORIES_URL: endpoint + '/api/v2.0/historydevice',

  POST_SCHEDULE_TDPK: endpoint + '/api/v2.0/automations/',

  GET_SCHEDULE: endpoint + '/api/v2.0/schedule',

  POST_SCHEDULE: endpoint + '/api/v2.0/schedule',

  AUTOMATION: endpoint + '/api/v2.0/automations',

  DEVICES_CONTROL_URL: endpoint + '/api/v2.0/devicecontrol',

  FIREBASE_MINTEL_REAL_TIME_PATH: 'hotel/mintel/building main/',
  FIREBASE_SYN_REAL_TIME_PATH: '/hotel/syn/building main/',
  FIREBASE_TDPK_REAL_TIME_PATH: '/publicspace/tdpk/',

  C2D: endpoint+'/api/v2.0/c2d'
};

export { API_CONSTANTS };
