import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardControlActions } from '../../../../features/dashboard/actions';
import { hotelActions } from '../../../../features/hotel/actions';
import { elementPositions } from './DevicesPosition.variables';
import { ModalIoTLayout } from '../../../../components';

export const IotDevicesContainer = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const dispatch = useDispatch();
  const [header, setHeader] = useState([]);
  const [detail, setDetail] = useState([]);
  const imageRef = useRef(null);
  const [isCursor, setIsCursor] = useState(false);
  const [dropDownActivity, setDropDownActivity] = useState(0);
  const [currentName, setCurrentName] = useState('');

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);

  const showIoTModal = useSelector((state) => state.dashboard.showIotModal);

  const modalName = useSelector((state) => state.dashboard.modalName);

  function onCloseModal() {
    dispatch(dashboardControlActions.showIotModal(false));
  }

  const handleStartDate = (event) => {
    setStartDate(event);
  };

  const handleStopDate = (event) => {
    setStopDate(event);
  };

  const handleClickDropdown = (data) => {
    setDropDownActivity(Number(data));
    fetchModalActivityData(currentName);
  };

  const reverseText = (text) => {
    return text.split(':').reverse().join(':');
  };

  //##--------------------------- Fetch Activity Data ---------------------------
  const fetchModalActivityData = async (name) => {
    setCurrentName(name);
    if (name === 'smart_meter' || name === 'fridge') {
      devices.forEach((element) => {
        if (element['device_name'] === 'front_switch') {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);
          try {
            dispatch(
              hotelActions.getAllActivities(
                currentRoomNo,
                element.device_id,
                dropDownActivity,
                'front_switch',
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    } else if (name === 'smart_tv' || name === 'broadlinkenv') {
      devices.forEach((element) => {
        if (element['device_name'] === name) {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);
          try {
            dispatch(
              hotelActions.getAllActivities(
                currentRoomNo,
                reverseText(element.device_id),
                dropDownActivity,
                name,
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    } else {
      devices.forEach((element) => {
        if (element['device_name'] === name) {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);
          try {
            dispatch(
              hotelActions.getAllActivities(
                currentRoomNo,
                element.device_id,
                dropDownActivity,
                name,
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    }
  };

  //##------------------------ Event handler ------------------------------------
  const onClickPropOnImage = (event) => {
    event.stopPropagation();
    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;

        var rect = event.target.getBoundingClientRect();
        var xr = (event.clientX - rect.left) / w;
        var yr = (event.clientY - rect.top) / h;

        var elems = elementPositions;
        for (var ei in elems) {
          var el = elems[ei];
          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            // devices.forEach((element) => {
            // if (el['display'] === 'Exhaust Fan' && element['ui_location'] === el['location']) {}})

            if (el['display']) {
              console.log(el['display']);
              dispatch(dashboardControlActions.showIotModal(true));
              dispatch(dashboardControlActions.isControlIot());
              // fetchModalActivityData(el['triggers'][0]);
              dispatch(dashboardControlActions.modalName(el['triggers'][0]));
              // setModalName(el['triggers'][0]);
            }
          }
        }
      }
    }
  };

  const onChangeMouseCursor = async (event) => {
    event.stopPropagation();
    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;
        var rect = event.target.getBoundingClientRect();
        var xr = (event.clientX - rect.left) / w;
        var yr = (event.clientY - rect.top) / h;
        var elems = elementPositions;

        let isHoverOnOneIcon = false;
        for (var ei in elems) {
          var el = elems[ei];

          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            // devices.forEach((element) => {
            // console.log(element['ui_location']);
            // location_device = element['ui_location'];
            // if (element['ui_location'] === el['location']) {
            isHoverOnOneIcon = true;
            // }
            // });
          }
        }

        setIsCursor(isHoverOnOneIcon);
      }
    }
  };
  if (devices === undefined) {
    return 'Loading...';
  }

  //##------------------------ Render -----------------------------------
  return (
    <div className="tab-content column cgroup-1" id="IOT">
      <img
        ref={imageRef}
        alt="room-floor-plan"
        id="iot-room-floorplan-image"
        onClick={onClickPropOnImage}
        onMouseMove={(e) => onChangeMouseCursor(e)}
        src="/assets/images/mintel-room-floorplan.png"
        className={isCursor ? 'is-cursor' : ''}
      />
      {showIoTModal && (
        <ModalIoTLayout
          roomNo={currentRoomNo}
          onCloseModal={onCloseModal}
          header={header}
          detail={detail}
          handleClickDropdown={handleClickDropdown}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          showIoTModal={showIoTModal}
          modalName={modalName}
        />
      )}
    </div>
  );
};
