import React, { useState, useContext, useEffect, useMemo } from 'react';

import { Row, Col } from 'reactstrap';
import { FirebaseContext2 } from '../../../../../firebase';
import { Roomcomponents } from '../components';

function MinttowerDashboard() {
  const firebase = useContext(FirebaseContext2);

  const [firebaseData, setFirebaseData] = useState(null);
  const [keys, setKeys] = useState(null);
  const [values, setValues] = useState(null);
  const [data, setData] = useState(null);

  async function fetchData(firebase, didMount) {
    // Constant URL
    // const minttower_node = 'hotel/minttower/energy';
    const minttower_node = 'mintower/';
    if (didMount) {
      console.log('Firebase Did Mounted');
      await firebase.db.ref(minttower_node).off('value');
    } else {
      await firebase.db.ref(minttower_node).on('value', function (snap) {
        let capt = snap.val();
        setFirebaseData(capt);
      });
    }
  }

  useEffect(() => {
    fetchData(firebase, false);

    return () => {
      return fetchData(firebase, true);
    };
  }, [firebase]);

  useEffect(() => {
    const Data = () => {
      if (firebaseData !== null) {
        setKeys(Object.keys(firebaseData));
        setValues(Object.values(firebaseData));
      }
    };
    Data();
  }, [firebaseData]);

  useMemo(() => {
    let tmp = {};
    if (keys && values !== null) {
      keys.forEach((element) => {
        tmp = {
          ...tmp,
          [String(element).slice(5)]: Object.values(firebaseData[element])[0],
        };
      });
      return setData(tmp);
    }
  }, [keys, values, firebaseData]);
  console.log(data);
  return (
    <div className="content">
      <Row>
        <Col md="12">
          {/* <Scrollbars style={{ width: '100%', height: '480px' }} autoHide> */}
          <Row>
            <Col md="12">
              <Roomcomponents data={data} />
            </Col>
          </Row>
          {/* </Scrollbars> */}
        </Col>
      </Row>
    </div>
  );
}

export { MinttowerDashboard };
