import { ROOT } from '../types';

const INIT_STATE = {
  error: [],
  prefetchingLoading: true,
  prefetchState: false,
};

const root = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROOT.PREFETCH_START:
      return {
        ...state,
        prefetchingLoading: true,
        error: [],
      };
    case ROOT.PREFETCH_FINISHED:
      return {
        ...state,
        prefetchingLoading: false,
        error: [],
      };

    default:
      return state;
  }
};

export { root };
