import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import { HistoryData, ActivityData, Info } from '../';

export const ACComponents = (props) => {
  const [currentMode, setCurrentMode] = useState('cool');
  const [fanMode, setFanMode] = useState('auto');
  const [temp, setTemp] = useState(25.0);
  const envTemp = 26;
  const currentTab = props.currentTab;

  // const [currentTab, setCurrentTab] = useState('control');

  // const currentTab = 'control';
  // const [envTemp, setEnvTemp] = useState(26);

  // const timeoutid = useRef();

  // useMemo(() => {
  //   clearTimeout(timeoutid.current);

  //   if (currentMode !== null && fanMode !== null && temp !== null) {
  //     timeoutid.current = setTimeout(
  //       () => TDPK_API.tdpkAirAPI(props.airNum, temp, currentMode, fanMode),
  //       1000
  //     );
  //   }

  //   return () => clearTimeout(timeoutid.current);
  // }, [temp, currentMode, fanMode, props.airNum]);

  const onClickControlMode = (e, type) => {
    e.preventDefault();
    setCurrentMode(type);
  };

  const onClickFanMode = (e, type) => {
    e.preventDefault();
    setFanMode(type);
  };

  const onSettemp = (e, type) => {
    e.preventDefault();
    switch (type) {
      case 'increment':
        setTemp(temp + 0.5);
        break;
      case 'decrement':
        setTemp(temp - 0.5);
        break;
      default:
        return temp;
    }
  };

  return (
    <>
      {props.menu}
      {currentTab === 'control' && (
        <>
          <div className="columns">
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Status
              </div>
              <div className="column">
                <img
                  alt="remote"
                  id="iot-device-icon"
                  src="https://www.carrier.co.th/products/carrier/images/40vct.jpg"
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              </div>
              <div className="column" style={{ textAlign: 'center' }}>
                {currentMode === 'off' ? (
                  <h1 style={{ color: 'gray' }}>OFF</h1>
                ) : (
                  <h1 style={{ color: 'green' }}>ON</h1>
                )}
              </div>
            </div>
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              {currentMode === 'fan' ? (
                <>
                  <div
                    className="column"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Fan Mode
                  </div>
                  <br />
                  <br /> <p style={{ textAlign: 'center' }}>Can not control temperature</p>{' '}
                </>
              ) : (
                <>
                  {' '}
                  <div
                    className="column"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Set Temp
                  </div>
                  {/* increte button */}
                  {currentMode === 'off' ? (
                    ''
                  ) : (
                    <button
                      className="button is-info"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0px',
                      }}
                      // onClick={() => setTemp(temp + 1)}
                      onClick={(e) => onSettemp(e, 'increment')}
                    >
                      <i
                        className="fa fa-caret-up"
                        style={{
                          color: 'white',
                          fontSize: '44px',
                          lineHeight: '40px',
                        }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  <div className="column">
                    <span
                      style={{
                        fontSize: '44px',
                        color: '#0af',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* {status.air_con.set_temp } */}
                      {currentMode === 'off' ? '' : parseFloat(temp).toFixed(1)}
                    </span>{' '}
                    <span
                      style={{
                        fontSize: '26px',
                        color: '#aaa',
                      }}
                    >
                      {currentMode === 'off' ? '' : 'ºC'}
                    </span>
                  </div>
                  {/* decrete button */}
                  {currentMode === 'off' ? (
                    ''
                  ) : (
                    <button
                      className="button is-info"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0px',
                      }}
                      // onClick={() => setTemp(temp - 1)}
                      onClick={(e) => onSettemp(e, 'decrement')}
                    >
                      <i
                        className="fa fa-caret-down"
                        style={{
                          color: 'white',
                          fontSize: '44px',
                          lineHeight: '40px',
                        }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </>
              )}
            </div>

            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Room Temp
              </div>
              <div
                className="column"
                style={{
                  marginTop: '40px',
                }}
              >
                <span
                  style={{
                    fontSize: '46px',
                    color: '#0af',
                    fontWeight: 'bold',
                  }}
                >
                  {envTemp}
                </span>{' '}
                <span
                  style={{
                    fontSize: '26px',
                    color: '#aaa',
                  }}
                >
                  ºC
                </span>
              </div>
            </div>
          </div>

          <div className="columns">
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Mode Control
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    <button
                      value="off"
                      // className={status.air_con.mode === 'off' ? 'button is-info' : 'button'}
                      className={currentMode === 'off' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'off')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Off
                    </button>
                    <button
                      value="cool"
                      className={currentMode === 'cool' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'cool')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Cool
                    </button>

                    <button
                      value="dry"
                      className={currentMode === 'dry' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'dry')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Dry
                    </button>
                    <button
                      value="fan"
                      className={currentMode === 'fan' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'fan')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Fan
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Fan Speed
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    {currentMode === 'fan' ? (
                      ''
                    ) : (
                      <button
                        value="auto"
                        className={
                          currentMode === 'off'
                            ? 'button'
                            : fanMode === 'auto'
                            ? 'button is-info'
                            : 'button'
                        }
                        onClick={(e) => onClickFanMode(e, 'auto')}
                        style={{
                          margin: '10px 1px 20px 1px',

                          boxSizing: 'border-box',
                          width: '65px',
                          font: '15px',
                        }}
                      >
                        Auto
                      </button>
                    )}
                    <button
                      value="low"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'low'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'low')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '65px',
                        font: '15px',
                      }}
                    >
                      Low
                    </button>

                    <button
                      value="medium"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'medium'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'medium')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '85px',
                        font: '15px',
                      }}
                    >
                      Medium
                    </button>
                    <button
                      value="high"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'high'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'high')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '65px',
                        font: '15px',
                      }}
                    >
                      High
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
          </div>
        </>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <div>
          <Info image={image_link} detail={props.detail} header={props.header} />
        </div>
      )}

      {currentTab === 'activity' && <ActivityData onClick={onchildClickActivity} />}
      {currentTab === 'history' && (
        <HistoryData
          data={Temp_plot}
          startDate={props.startDate}
          stopDate={props.stopDate}
          handleStartDate={props.handleStartDate}
          handleStopDate={props.handleStopDate}
        />
      )} */}
    </>
  );
};
