import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const RankingComponent = (props) => {
  const activeStep = props.rank;
  const steps = getSteps();
  function getSteps() {
    return ['Needs Improvment', 'Fair', 'Good', 'Excellent'];
  }
  return (
    <div>
      <h6 style={{ textAlign: 'center' }}>Your hotel energy performance</h6>
      <Stepper
        activeStep={activeStep}
        nonLinear
        style={{ padding: 0, margin: 0, backgroundColor: 'transparent' }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export { RankingComponent };
