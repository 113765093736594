import styled from 'styled-components';

export const List = styled.div`
  height: 100%;
  background: rgb(250, 251, 252);
  flex-grow: 1;
  flex-shrink: 0;
  display: block;
  margin: 0;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 1rem;
  background-color: rgb(250, 251, 252);
  text-decoration: none;
  text-align: center;
  border: ${(props) => (props.isSelected ? `0px` : `1px solid #dbdbdb`)};
  border-left: 0px;
  cursor: ${(props) => (props.disabled ? `default` : `pointer`)};
  background-color: ${(props) => (props.isSelected ? `#3273dc` : `rgb(250, 251, 252)`)};
  color: ${(props) => (props.isSelected ? `white` : props.disabled ? `gray` : `#4a4a4a`)};
  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :first-child {
    border-left: ${(props) => (props.isSelected ? `0px` : `1px solid #dbdbdb`)};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;
