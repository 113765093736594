import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { MockUpRoomServiceTabComponent } from '../components/MockUpRoomServiceTab.components';
// import { MockUpRoomRepairServiceTabComponent } from '../components/MockUpRoomRepairServiceTab.components';
import { List } from '../../components/List/TabList';
import { GuestInfoContainer } from './GuestInfo/GuestInfo.container';
import { IotDevicesContainer } from './RoomMapIOT';

import { dashboardControlActions } from '../../features/dashboard/actions';

function MainModalContainer(props) {
  const dispatch = useDispatch();

  const currentRoomData = useSelector((state) => state.dashboard.currentRoomData);
  const [currentTab, setCurrentTab] = useState('info');

  const onCloseModal = (event) => {
    event.preventDefault();
    // Close Hotel Room Details Modal
    dispatch(dashboardControlActions.resetSelectedRoom());
    dispatch(dashboardControlActions.showIotModal(false));
    dispatch(dashboardControlActions.hideOneRoomDetailsModal());
  };

  const onClickMenu = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);
  };

  //##------------------------- Render -------------------------
  return (
    <div className="modal is-active" id="room-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-general"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <div className="room-name" style={{ width: '110px' }}>
            <p className="modal-card-title" id="room-modal-title" style={{ marginTop: '10%' }}>
              {`ห้อง ${currentRoomData['room_number']}`}
              {/* {`ห้อง ${currentRoomData}`} */}
            </p>
          </div>
          <div
            className={
              currentRoomData['clean_status'] !== 'vc' ? 'tag is-large is-success' : 'tag is-large'
            }
            id="room-modal-title-error"
            style={{ marginLeft: '420px' }}
          >
            {currentRoomData['clean_status'] === 'vc' && 'ว่าง'}
            {currentRoomData['clean_status'] !== 'vc' && 'อยู่'}
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          <div className="columns">
            <div className="column is-12">
              {currentRoomData['clean_status'] && (
                <div id="modal-menu">
                  <nav className="panel" style={{ boxShadow: 'none' }}>
                    <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '0' }}>
                      <ul style={{ margin: '20px' }}>
                        <List
                          isSelected={currentTab === 'info'}
                          onClick={(e) => onClickMenu(e, 'info')}
                        >
                          INFO
                        </List>
                        <List isSelected={currentTab === 'room-service'} disabled={true}>
                          ROOM SERVICE
                        </List>
                        <List isSelected={currentTab === 'repair'} disabled={true}>
                          REPAIR
                        </List>
                        <List
                          isSelected={currentTab === 'IOT'}
                          onClick={(e) => onClickMenu(e, 'IOT')}
                        >
                          IOT
                        </List>
                      </ul>
                    </div>

                    {/* Guest Info Tab */}
                    {currentTab === 'info' && <GuestInfoContainer />}

                    {/* Room Services Tab */}
                    {/* {currentTab === 'room-service' && <MockUpRoomServiceTabComponent />} */}

                    {/* Repair Tab*/}
                    {/* {currentTab === 'repair' && <MockUpRoomRepairServiceTabComponent />} */}

                    {/* Iot Tab*/}
                    {currentTab === 'IOT' && <IotDevicesContainer />}
                  </nav>
                </div>
              )}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-fullwidth">
          <div className="column is-fullwidth">
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              CLOSE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export { MainModalContainer };
