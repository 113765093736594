export const MapCam = [
  // [type, mac, x1, y1, x2, y2]
  {
    name: 'cam1',
    x1: 0.22421203438395415,
    y1: 0.28680688336520077,
    x2: 0.251432664756447,
    y2: 0.32122370936902483,
    triggers: ['C0601', 'cam_1', 'cam'],
  },
  {
    name: 'cam2',
    x1: 0.5136103151862464,
    y1: 0.40344168260038243,
    x2: 0.5393982808022922,
    y2: 0.4397705544933078,
    triggers: ['C0612', 'cam_2', 'cam'],
  },
  {
    name: 'cam3',
    x1: 0.7098853868194842,
    y1: 0.367112810707457,
    x2: 0.7342406876790831,
    y2: 0.40152963671128106,
    triggers: ['C0611', 'cam_3', 'cam'],
  },
  {
    name: 'cam4',
    x1: 0.7958452722063037,
    y1: 0.5086042065009561,
    x2: 0.8202005730659025,
    y2: 0.5449330783938815,
    triggers: ['C0603', 'cam_4', 'cam'],
  },
  {
    name: 'cam5',
    x1: 0.7958452722063037,
    y1: 0.5086042065009561,
    x2: 0.8904011461318052,
    y2: 0.6959847036328872,
    triggers: ['C0604', 'cam_5', 'cam'],
  },
  {
    name: 'cam6',
    x1: 0.6497134670487106,
    y1: 0.8336520076481836,
    x2: 0.6755014326647565,
    y2: 0.8680688336520076,
    triggers: ['C0602', 'cam_6', 'cam'],
  },
];
