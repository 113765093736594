import React, { useContext, useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { EnergyByDevice, EnergyPerRoomnight, EnergySaving, EnergyUseRatio } from '../../components';
import { FirebaseContext2 } from '../../firebase';

export const EnergyDashboardContainer = () => {
  const firebase = useContext(FirebaseContext2);
  const [fdata, setFdata] = useState({
    room_night: {
      val: [],
      timestamp: [],
    },
    saving: {
      percent: null,
      val: null,
    },
    allocation: {
      common_room: null,
      room: null,
    },
    appliances: {
      ac: null,
      light: null,
      plug_load: null,
    },
  });

  async function fetchData(firebase, didMounted) {
    let fbpath = '/hotel/energy/dashboar';

    if (didMounted) {
      await firebase.db.ref(fbpath).off('value');
    } else {
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        setFdata({
          room_night: {
            val: [capt.room_night.val[0], capt.room_night.val[1]],
            timestamp: [capt.room_night.timestamp[0], capt.room_night.timestamp[1]],
          },
          saving: {
            percent: capt.saving.percent,
            val: capt.saving.val,
            hotel: capt.saving.hotel,
            esco: capt.saving.esco,
            alto: capt.saving.alto,
          },
          allocation: {
            common_room: capt.allocation.common_room,
            room: capt.allocation.room,
          },
          appliances: {
            ac: capt.appliances.ac,
            light: capt.appliances.light,
            plug_load: capt.appliances.plug_load,
          },
        });
      });
    }
  }

  useEffect(() => {
    fetchData(firebase, false);
    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var y1Value = [
    `${fdata.appliances.ac} kWh`,
    `${fdata.appliances.light} kWh`,
    `${fdata.appliances.plug_load} kWh`,
  ];

  let data1 = [
    {
      type: 'bar',
      text: y1Value.map(String),
      textposition: 'inside',
      x: [fdata.appliances.ac, fdata.appliances.light, fdata.appliances.plug_load],
      y: ['AC', 'Light', 'Plug Load'],
      orientation: 'h',
    },
  ];

  let Energy_per_room = {
    options: {
      xaxis: {
        categories: fdata.room_night.timestamp,
      },
    },
    series: [
      {
        name: 'Energy/Room Night',
        data: fdata.room_night.val,
      },
    ],
  };

  let Energy_use_ratio = {
    options: {
      labels: ['Room', 'Common room'],

      donut: {
        size: '65%',
        background: 'transparent',
        labels: {
          show: false,
          name: {
            show: true,
            fontSize: '22px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
          },
        },
      },
    },
    series: [Number(fdata.allocation.room), Number(fdata.allocation.common_room)],
  };

  return (
    <Row>
      <EnergyPerRoomnight name={'Electricity Bills / Room night'} data={Energy_per_room} />
      <EnergySaving name={'Energy Saving'} data={fdata.saving} />
      <EnergyUseRatio name={'Electricity Allocation'} data={Energy_use_ratio} />
      <EnergyByDevice name={'Active Appliances'} data={data1} />
    </Row>
  );
};
