export const ENERGY = {
  MINTEL_ENERGY: 'MINTEL_ENERGY',
  SYN_ENERGY: 'SYN_ENERGY',
  MINTE_HISTORY_DATA: 'MINTE_HISTORY_DATA',
  CLEAR_HISTORY_DATA: 'CLEAR_HISTORY_DATA',
  CLEAR_ENERGY_DATA: 'CLEAR_ENERGY_DATA',
  ENERGY_TAB_SELECT: 'ENERGY_TAB_SELECT',
  TEST_ENERGY: 'TEST_ENERGY',
  CLEAR_TEST_ENERGY: 'CLEAR_TEST_ENERGY',
  KLUB_ENERGY: 'KLUB_ENERGY',
};
