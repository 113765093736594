import React, { useState, useContext, useEffect } from 'react';
import { MintelCostBreakDownComponent } from '../../../components';
import { FirebaseContext2 } from '../../../../../firebase';

// const plot_1 = {
//   series: [62, 38],
//   options: {
//     labels: ['Common Space', 'Guest Room'],
//   },
// };

// const plot_2 = {
//   series: [38, 25, 14, 15, 8],
//   options: {
//     labels: ['Floor1', 'Floor2', 'Floor3', 'Floor4', 'Floor5'],
//   },
// };

// const plot_3 = {
//   series: [33, 15, 47, 5],
//   options: {
//     labels: ['Lobby', 'Restaurant', 'Conference Room', 'Gallery'],
//   },
// };

const MintelEnergyCostBreakdownContainer = () => {
  const firebase = useContext(FirebaseContext2);
  const [costbreakdown, setCostbreakdown] = useState(null);

  function fetchData(didMount) {
    const main_path = 'hotel/mintel/energy/cost_breakdown';
    if (didMount) {
      firebase.db.ref(main_path).off('value');
    } else {
      firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        // console.log(capt);
        setCostbreakdown(capt);
      });
    }
  }
  useEffect(() => {
    fetchData(false);
    return () => {
      fetchData(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plot_building = {
    series: costbreakdown !== null ? Object.values(costbreakdown.building) : [],
    options: {
      labels: costbreakdown !== null ? Object.keys(costbreakdown.building) : [],
    },
  };
  const plot_by_floor = {
    series: costbreakdown !== null ? Object.values(costbreakdown.by_floor) : [],
    options: {
      labels: costbreakdown !== null ? Object.keys(costbreakdown.by_floor) : [],
    },
  };
  const plot_by_type = {
    series: costbreakdown !== null ? Object.values(costbreakdown.by_type) : [],
    options: {
      labels: costbreakdown !== null ? Object.keys(costbreakdown.by_type) : [],
    },
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <MintelCostBreakDownComponent
        plot_1={plot_building}
        plot_2={plot_by_floor}
        plot_3={plot_by_type}
      />
    </div>
  );
};

export { MintelEnergyCostBreakdownContainer };
