import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { DatePickers } from 'components';
import './styles.css';
import moment from 'moment';
import { RankingComponent } from 'components';
import styled from 'styled-components';
import { UpdatedAt } from 'components/UpdatedAt'

const MintelRealTimeComponent = (props) => {
  const main_data = props.main_mdb;
  const restaurant_data = props.restaurant_mdb;
  const laundry_data = props.laundry_mdb;

  const only_energy = props.onlyEnergy;
  const TextOnImg = styled.div`
    position: absolute;
    font-size: 18px;
  `;

  const BorderText = styled.div`
    position: absolute;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-size: 14px;
    color: #77dd77;
    font-weight: bold;
  `;

  return (
    <div className="content">
      <Card>
        <Row>
          <Col style={{ textAlign: '-webkit-center' }}>
            <div
              style={{
                position: 'relative',

                padding: '30px',
                width: '800px',
              }}
            >
              <img src="/assets/images/realtime/realtime_dashboard.gif" alt="dashboard" />
              <TextOnImg style={{ left: '1.5rem', bottom: '9rem' }}>Transformer</TextOnImg>
              <BorderText style={{ left: '2.6rem', bottom: '6rem' }}>100 kW</BorderText>

              {/* <TextOnImg style={{ left: '27.3rem', bottom: '9rem' }}>Hotel</TextOnImg> */}
              <BorderText style={{ left: '19.5rem', bottom: '6rem' }}>
                {' '}
                {main_data !== null ? Number(main_data.total.power).toFixed(2) : 0} kW
              </BorderText>

              <TextOnImg style={{ left: '43rem', bottom: '25rem' }}>Room</TextOnImg>
              <BorderText style={{ left: '43rem', bottom: '22rem' }}>
                {' '}
                {main_data && restaurant_data && laundry_data !== null
                  ? Number(
                      main_data.total.power - restaurant_data.total.power - laundry_data.total.power
                    ).toFixed(2)
                  : 0}{' '}
                kW
              </BorderText>

              <TextOnImg style={{ left: '43rem', bottom: '18rem' }}>Restaurant</TextOnImg>
              <BorderText style={{ left: '43rem', bottom: '15rem' }}>
                {' '}
                {restaurant_data !== null ? Number(restaurant_data.total.power).toFixed(2) : 0} kW
              </BorderText>

              <TextOnImg style={{ left: '43rem', bottom: '12rem' }}>Laundry</TextOnImg>
              <BorderText style={{ left: '43rem', bottom: '9rem' }}>
                {' '}
                {laundry_data !== null ? Number(laundry_data.total.power).toFixed(2) : 0} kW
              </BorderText>

              <TextOnImg style={{ left: '43rem', bottom: '5.5rem' }}>7-Eleven</TextOnImg>
              <BorderText style={{ left: '43rem', bottom: '2.5rem' }}> 0 kW</BorderText>
            </div>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row style={{ justifyContent: 'center' }}>
          <div style={{ width: 700, padding: 30 }}>
            <RankingComponent rank={props.ranking !== null ? props.ranking - 1 : 0} />
          </div>
        </Row>
      </Card>

      <Row>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center">
                    <i
                      className="material-icons text-secondary"
                      style={{ fontSize: 50, textAlign: 'center' }}
                    >
                      domain
                    </i>
                  </div>
                </Col>
                <Col md="8" xs="7" style={{ height: '120px' }}>
                  <div style={{ textAlign: 'right' }}>
                    <p className="card-category">
                      <i className="material-icons text-info">leaderboard</i> Main Energy
                    </p>
                    <Card.Title>
                      {only_energy !== null
                        ? Number(
                            only_energy.items.laundry.kwh +
                              only_energy.items.restaurant.kwh +
                              only_energy.items.room.kwh
                          ).toFixed(2)
                        : 0}{' '}
                      kWh
                    </Card.Title>
                  </div>
                </Col>
                <UpdatedAt>
                  {'Updated at: '} {only_energy !== null ? only_energy.updated_at : ''}
                </UpdatedAt>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center">
                    {/* <i className="fas fa-tachometer text-success" /> */}
                    <i
                      className="material-icons text-secondary"
                      style={{ fontSize: 50, textAlign: 'center' }}
                    >
                      restaurant
                    </i>
                    {/* <p style={{ fontSize: 10, textAlign: 'center' }}>
                      Updated at {only_energy !== null ? only_energy.updated_at : ''}
                    </p> */}
                  </div>
                </Col>
                <Col md="8" xs="7" style={{ height: '120px' }}>
                  <div style={{ textAlign: 'right' }}>
                    <p className="card-category">
                      <i className="material-icons text-info">leaderboard</i> Restaurant Energy
                    </p>
                    <Card.Title>
                      {/* {restaurant_data !== null
                        ? Number(restaurant_data.total.energy).toFixed(2)
                        : 0}{' '}
                      kWh */}
                      {only_energy !== null
                        ? Number(only_energy.items.restaurant.kwh).toFixed(2)
                        : 0}{' '}
                      kWh
                    </Card.Title>
                  </div>
                </Col>
                <UpdatedAt>
                  {'Updated at: '} {only_energy !== null ? only_energy.updated_at : ''}
                </UpdatedAt>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center">
                    <i
                      className="material-icons text-secondary"
                      style={{ fontSize: 50, textAlign: 'center' }}
                    >
                      local_laundry_service
                    </i>
                    {/* <p style={{ fontSize: 10, textAlign: 'center' }}>
                      Updated at {only_energy !== null ? only_energy.updated_at : ''}
                    </p> */}
                    {/* <i className="fas fa-tachometer text-success" /> */}
                  </div>
                </Col>
                <Col md="8" xs="7" style={{ height: '120px' }}>
                  <div style={{ textAlign: 'right' }}>
                    <p className="card-category">
                      <i className="material-icons text-info">leaderboard</i> Laundry Shop Energy
                    </p>
                    <Card.Title>
                      {/* {laundry_data !== null ? Number(laundry_data.total.energy).toFixed(2) : 0} kWh */}
                      {only_energy !== null ? Number(only_energy.items.laundry.kwh).toFixed(2) : 0}{' '}
                      kWh
                    </Card.Title>
                  </div>
                  <UpdatedAt>
                    {'Updated at: '} {only_energy !== null ? only_energy.updated_at : ''}
                  </UpdatedAt>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col md="7">
                    <p style={{ fontSize: 20 }}>Main</p>
                  </Col>
                  {/* <Col md="7" style={{ height: '120px' }}>
                    <p style={{ fontSize: 12, textAlign: 'center' }}>
                      Updated at{' '}
                      {main_data !== null
                        ? moment(main_data.phase_1.timestamp).format('DD/MM/YYYY HH:mm')
                        : 0}
                    </p>
                  </Col> */}
                </Row>
              </Card.Title>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>
                      Power:{' '}
                      <b>{main_data !== null ? Number(main_data.total.power).toFixed(2) : 0}</b> kW
                    </td>

                    <td>
                      Voltage:{' '}
                      <b>{main_data !== null ? Number(main_data.total.voltage).toFixed(2) : 0}</b> V
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current:{' '}
                      <b>{main_data !== null ? Number(main_data.total.current).toFixed(2) : 0}</b> A
                    </td>
                    <td>
                      PF:{' '}
                      <b>
                        {main_data !== null ? Number(main_data.total.power_factor).toFixed(2) : 0}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {main_data !== null
                ? moment(main_data.phase_1.timestamp).format('DD/MM/YYYY HH:mm')
                : 0}
            </UpdatedAt>
          </Card>
        </Col>
        <Col sm="4">
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col md="7">
                    <p style={{ fontSize: 20 }}>Restaurant</p>
                  </Col>
                  {/* <Col md="7">
                    <p style={{ fontSize: 12, textAlign: 'center' }}>
                      Updated at{' '}
                      {restaurant_data !== null
                        ? moment(restaurant_data.total.timestamp).format('DD/MM/YYYY HH:mm')
                        : ''}
                    </p>
                  </Col> */}
                </Row>
              </Card.Title>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>
                      Power:{' '}
                      <b>
                        {restaurant_data !== null
                          ? Number(restaurant_data.total.power).toFixed(2)
                          : 0}
                      </b>{' '}
                      kW
                    </td>

                    <td>
                      Voltage:{' '}
                      <b>
                        {restaurant_data !== null
                          ? Number(restaurant_data.total.voltage).toFixed(2)
                          : 0}
                      </b>{' '}
                      V
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current:{' '}
                      <b>
                        {restaurant_data !== null
                          ? Number(restaurant_data.total.current).toFixed(2)
                          : 0}
                      </b>{' '}
                      A
                    </td>

                    <td>
                      PF:{' '}
                      <b>
                        {restaurant_data !== null
                          ? Number(restaurant_data.total.power_factor).toFixed(2)
                          : 0}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {restaurant_data !== null
                ? moment(restaurant_data.total.timestamp).format('DD/MM/YYYY HH:mm')
                : ''}
            </UpdatedAt>
          </Card>
        </Col>
        <Col sm="4">
          <Card className="card-stats">
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col md="7">
                    <p style={{ fontSize: 20 }}>Laundry</p>
                  </Col>
                  {/* <Col md="7" style={{ height: '120px' }}>
                    <p style={{ fontSize: 12, textAlign: 'center' }}>
                      Updated at{' '}
                      {laundry_data !== null
                        ? moment(laundry_data.total.timestamp).format('DD/MM/YYYY HH:mm')
                        : ''}
                    </p>
                  </Col> */}
                </Row>
              </Card.Title>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>
                      Power:{' '}
                      <b>
                        {laundry_data !== null ? Number(laundry_data.total.power).toFixed(2) : 0}
                      </b>{' '}
                      kW
                    </td>

                    <td>
                      Voltage:{' '}
                      <b>
                        {laundry_data !== null ? Number(laundry_data.total.voltage).toFixed(2) : 0}
                      </b>{' '}
                      V
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current:{' '}
                      <b>
                        {laundry_data !== null ? Number(laundry_data.total.current).toFixed(2) : 0}
                      </b>{' '}
                      A
                    </td>

                    <td>
                      PF:{' '}
                      <b>
                        {laundry_data !== null
                          ? Number(laundry_data.total.power_factor).toFixed(2)
                          : 0}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <UpdatedAt>
              {'Updated at: '}{' '}
              {laundry_data !== null
                ? moment(laundry_data.total.timestamp).format('DD/MM/YYYY HH:mm')
                : ''}
            </UpdatedAt>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="card-stats">
            <Card.Body>
              <div className="in-filter">
                <div style={{ display: 'inline-flex', marginLeft: '0' }}>
                  <button
                    className={props.fetchSampling === 1 ? 'my-btn-clicked ' : 'my-btn'}
                    onClick={() => props.sampling(1)}
                  >
                    1M
                  </button>
                  <button
                    className={props.fetchSampling === 5 ? 'my-btn-clicked ' : 'my-btn'}
                    onClick={() => props.sampling(5)}
                  >
                    5M
                  </button>
                  <button
                    className={props.fetchSampling === 15 ? 'my-btn-clicked ' : 'my-btn'}
                    onClick={() => props.sampling(15)}
                  >
                    15M
                  </button>
                </div>

                <DatePickers
                  daySelectStart={props.startDate}
                  daySelectStop={props.stopDate}
                  handleStartDate={props.handleStartDate}
                  handleStopDate={props.handleStopDate}
                />
              </div>

              <Chart
                options={props.main_graph.options}
                series={props.main_graph.series}
                type="line"
                width="100%"
                height="300px"
              />
              {/* <Chart
                options={props.only_plot.options}
                series={props.only_plot.series}
                type="bar"
                width="100%"
                height="130px"
              /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export { MintelRealTimeComponent };
