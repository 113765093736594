import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import styled from 'styled-components';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

import logo from 'assets/img/altologo2.svg';

let ps;

const Logo = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;

const Li = styled.li`
  pointer-events: ${(prop) => (prop.disabled ? 'none' : 'auto')};
`;

const P = styled.p`
  /* color: ${(prop) => (prop.disabled ? '#66615b' : '#dadada')}; */
  color: ${(prop) => (prop.disabled ? '#66615b' : 'white')};
`;

const Sidebar = (props) => {
  const sidebar = useRef(null);
  const [state, setState] = useState(getCollapseStates(props.routes));

  const refreshCacheAndReload = () => {
    localStorage.clear();
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !state[prop.state];
        console.log(st[prop['state']]);
        return (
          <li className={getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
            <a
              href="#paho"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <Li className={activeRoute(prop.layout + prop.path)} key={key} disabled={prop.disabled}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i
                  className={prop.icon}
                  onClick={prop.name === 'Logout' ? () => refreshCacheAndReload() : undefined}
                  style={{ opacity: prop.disabled ? '0.1' : '0.7', color: '#5bc9bd' }}
                />
                <P
                  onClick={prop.name === 'Logout' ? () => refreshCacheAndReload() : undefined}
                  disabled={prop.disabled}
                >
                  {prop.name}
                </P>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </Li>
      );
    });
  };

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  }, []);

  return (
    <div className="sidebar" data-color="black" data-active-color="primary">
      <div className="logo" style={{ height: '72px', justifyContent: 'center' }}>
        <NavLink className="simple-text logo-mini" to="/admin/dashboard" style={{ opacity: '1' }}>
          <div className="logo-img" style={{ backgroundColor: 'transparent', marginTop: '5px' }}>
            <Logo />
          </div>
        </NavLink>
        <NavLink
          className="simple-text logo-normal"
          to="/admin/dashboard"
          style={{
            textAlign: 'left',
            fontSize: '25px',
            paddingTop: '18px',
            fontFamily: 'Ubuntu',
            color: 'white',
            textDecoration: 'none',
            textTransform: 'none',
          }}
        >
          AltoTech
        </NavLink>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
      {/* <div><MessengerCustomerChat pageId="685983694926359" appId="" /></div> */}
    </div>
  );
};

function getCollapseStates(routes) {
  let initialState = {};
  routes.map((prop, key) => {
    if (prop.collapse) {
      initialState = {
        [prop.state]: getCollapseInitialState(prop.views),
        ...getCollapseStates(prop.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
}

function getCollapseInitialState(routes) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
      return true;
    } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
      return true;
    }
  }
  return false;
}

export { Sidebar };
