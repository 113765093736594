import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
// import { HistoryData, Info, ActivityData } from '../';

export const EnvironmentSensorComponents = (props) => {
  const currentTab = props.currentTab;
  const environment = null;
  return (
    <>
      {props.menu}
      {/* real time graph */}
      {currentTab === 'control' && (
        <div className="tab-content column cgroup-3 cfirst-elem">
          <div id="gl-cards">
            <CardDeck>
              <Card bg="secondary" text="light" style={{ width: '18rem' }} className="mb-2">
                <Card.Header>Temperature</Card.Header>
                <Card.Body>
                  <Card.Title>{environment.env.temperature} &deg;C </Card.Title>
                </Card.Body>
              </Card>
              <Card bg="secondary" text="light" style={{ width: '18rem' }} className="mb-2">
                <Card.Header>Air Quality</Card.Header>
                <Card.Body>
                  <Card.Title>{environment.env.air_quality_sub}</Card.Title>
                </Card.Body>
              </Card>

              <Card bg="success" text="light" style={{ width: '18rem' }} className="mb-2">
                <Card.Header>Humidity</Card.Header>
                <Card.Body>
                  <Card.Title>{environment.env.humidity} %</Card.Title>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
          <div id="gl-cards">
            <CardDeck>
              <Card bg="primary" text="light" style={{ width: '18rem' }} className="mb-2">
                <Card.Header>Luminosity</Card.Header>
                <Card.Body>
                  <Card.Title>{environment.env.luminosity}</Card.Title>
                </Card.Body>
              </Card>
              <Card bg="success" text="light" style={{ width: '18rem' }} className="mb-2">
                <Card.Header>Noise</Card.Header>
                <Card.Body>
                  <Card.Title>{environment.env.noise}</Card.Title>
                </Card.Body>
              </Card>
              <Card bg="success" text="light" style={{ width: '18rem' }} className="mb-2">
                <Card.Header>Last Update</Card.Header>
                <Card.Body>
                  <Card.Title>{String(environment.env.timestamp).split('.')[0]}</Card.Title>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <Info image={image_link} detail={props.detail} header={props.header} />
      )}
      {currentTab === 'activity' && <ActivityData onClick={onchildClickActivity} />}
      {currentTab === 'history' && (
        <HistoryData
          data={{ temp: Temp_plot, humid: Humid_plot }}
          startDate={props.startDate}
          stopDate={props.stopDate}
          handleStartDate={props.handleStartDate}
          handleStopDate={props.handleStopDate}
        />
      )} */}
    </>
  );
};
