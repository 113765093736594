export const MapEnv = [
  {
    name: 'env1',
    x1: 0.05786163522012579,
    y1: 0.30033557046979864,
    x2: 0.09559748427672957,
    y2: 0.35570469798657717,
    triggers: ['C0601', 'env_1', 'env'],
  },
  {
    name: 'env2',
    x1: 0.389937106918239,
    y1: 0.4429530201342282,
    x2: 0.4276729559748428,
    y2: 0.49328859060402686,
    triggers: ['C0612', 'env_2', 'env'],
  },
  {
    name: 'env3',
    x1: 0.6566037735849056,
    y1: 0.4412751677852349,
    x2: 0.6955974842767295,
    y2: 0.49328859060402686,
    triggers: ['C0611', 'env_3', 'env'],
  },
];
