import React, { useRef } from 'react';
import { Nav, NavItem, NavLink } from './StyledComponents.components';
import { TabContent, TabPane, Card, Row, Col, CardBody } from 'reactstrap';

export const ShowContentControl = (props) => {
  const imageRef = useRef();
  return (
    <>
      <Nav tabs className="ml-0">
        <NavItem className="list-unstyled m-0">
          <NavLink
            onClick={() => {
              props.toggle('1');
            }}
          >
            <b>FLOOR6 C1</b>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={props.activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col md="12">
              <Card className="text-center" style={{ borderTopLeftRadius: '0' }}>
                <CardBody>
                  <img
                    src={props.image}
                    alt="tdpk-floor-plan"
                    width="900px"
                    ref={imageRef}
                    onClick={(e) => props.onClick(e, imageRef)}
                    onMouseMove={(e) => props.onChangeMouseCursor(e, imageRef)}
                    className={props.isCursor}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
};
