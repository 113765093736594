import React from 'react';
import { MaidNav } from '../NavTabs/Nav';
// import styled from 'styled-components';

// export const ComingSoon = styled.div`
//   padding-top: 10px;
//   position: fixed;
//   bottom: 30px;
//   right: 30px;
//   vertical-align: center;
//   font-size: 30px;
//   color: black;
//   font-weight: bold;
//   z-index: 999;
// `;

const MaidLayout = (props) => {
  return (
    <div>
      <h1>Housekeeper Members</h1>
      <MaidNav />
      {/* <ComingSoon>Coming soon...</ComingSoon>
      <div
        style={{
          position: 'absolute',
          top: '73px',
          left: '0px',
          width: '100%',
          height: 'calc( 100vh - 73px )',
          backgroundColor: '#00000040',
        }}
      /> */}
    </div>
  );
};

export { MaidLayout };
