import React from 'react';
import styled from 'styled-components';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';

const Box = styled.div`
  width: 90%;
  height: 100px;
  border-radius: 10px;
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.19);
`;

const BoxNumber = styled.div`
  margin: -10px 3px 3px 3px;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 50px;
  height: 60px;
`;

const BoxHeader = styled.div`
  margin: 3px;
  font-size: 13px;
  text-align: center;
  text-decoration: bold;
`;

const Column = styled(Col)`
  && {
    margin: 0px;
    padding: 5px;
    display: flex;
    justify-content: center;
  }
`;

export const RoomStatus = (props) => {
  return (
    <>
      <Col lg="3" md="6">
        <Card>
          <div style={{ height: '300px' }}>
            <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
              <h5 className="card-title">{props.name}</h5>
            </CardHeader>
            <CardBody>
              <Row style={{ margin: '0px' }}>
                <Column lg="6" md="6" sm="6">
                  <Box>
                    <BoxHeader>
                      <strong>V</strong>acant
                      <br /> <strong>C</strong>lean
                    </BoxHeader>
                    <BoxNumber style={{ color: '#168576' }}>{props.roomStatus.vc}</BoxNumber>
                  </Box>
                </Column>
                <Column lg="6" md="6" sm="6">
                  <Box>
                    <BoxHeader>
                      <strong>O</strong>ccupied
                      <br /> <strong>C</strong>lean
                    </BoxHeader>
                    <BoxNumber style={{ color: '#1858a8' }}>{props.roomStatus.oc}</BoxNumber>
                  </Box>
                </Column>
              </Row>
              <Row style={{ margin: '0px' }}>
                <Column lg="6" md="6" sm="6">
                  <Box>
                    <BoxHeader>
                      <strong>V</strong>acant
                      <br /> <strong>D</strong>irty
                    </BoxHeader>
                    <BoxNumber style={{ color: '#666666' }}>{props.roomStatus.vd}</BoxNumber>
                  </Box>
                </Column>
                <Column lg="6" md="6" sm="6">
                  <Box>
                    <BoxHeader>
                      <strong>O</strong>ccupied
                      <br /> <strong>D</strong>irty
                    </BoxHeader>
                    <BoxNumber style={{ color: '#faa21b' }}>{props.roomStatus.od}</BoxNumber>
                  </Box>
                </Column>
              </Row>
            </CardBody>
          </div>
        </Card>
      </Col>
    </>
  );
};
