import React from 'react';
import { Ac, Cam, Env } from './Control';
import { Col } from 'reactstrap';

export const Modal = (props) => {
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={props.onCloseModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <Col md="6">
            <b className="modal-card-title" style={{ padding: 0, margin: 0 }}>
              {props.device === 'air' && <>AC Control</>}
              {props.device === 'env' && <>Environment Sensor</>}
              {props.device === 'cam' && <>CCTV</>}
            </b>
          </Col>
          <Col md="6" style={{ textAlign: 'right' }}>
            <p className="modal-card-title" style={{ padding: 0, margin: 0, marginLeft: '50%' }}>
              {props.airNum}
            </p>
          </Col>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {props.device === 'air' && <Ac airNum={props.airNum} />}
          {props.device === 'cam' && <Cam />}
          {props.device === 'env' && <Env />}
        </section>
        <footer className="modal-card-foot">
          <div
            className="column is-fullwidth"
            style={{ padding: 0, textAlign: 'right', margin: 0 }}
          >
            <button onClick={props.onCloseModal} className="btn btn-danger">
              Close
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};
