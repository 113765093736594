import React, { useContext, useMemo, useRef, useState } from 'react';
import { MintelEnergyComponent } from '../../../components';
import { FirebaseContext2 } from '../../../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { energyActions } from '../../../actions';
import { modBus } from '../../../../../api/services';
import moment from 'moment';

export const MintelEnergyContainer = (props) => {
  const timeoutid = useRef();
  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext2);
  const Main_mdb = useSelector((state) => state.energy.main_mdb);
  const [histMain, setHistMain] = useState(null);
  const [histRest, setHistRest] = useState(null);
  const [histLaun, setHistLaun] = useState(null);
  const [plothistMain, setPlotHistMain] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [ebill, setEbill] = useState(null);
  const [energyForecast, setEnergyForecast] = useState(null);
  // const [plotforeCast, setPlotforeCast] = useState([]);

  const [mainMdb, setMainMdb] = useState(null);
  const [restaurantMdb, setrestaurantMdb] = useState(null);
  const [laundryMdb, setLaundryMdb] = useState(null);

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  //##--------------------Fetch Data From Firebase-------------------------
  async function fetchData(firebase, didMount) {
    const main_path = 'hotel/mintel/';
    // Constant URL
    let laundry_shop = main_path + 'building main/laundry/iot_devices/9c:a5:25:ab:3a:b4:7/electric';
    let restaurant =
      main_path + 'building main/restaurant/iot_devices/9c:a5:25:ab:3a:b4:8/electric';
    let main_hotel =
      main_path + 'building main/main_energy/iot_devices/9c:a5:25:ab:3a:b4:9/electric';

    // E-bill Path
    let ebill_path = main_path + 'energy/electricity_bill';

    // Energy forecast
    let energy_forecast = main_path + 'energy/energy_forecast';

    if (didMount) {
      console.log('Firebase Did Mounted');
      await firebase.db.ref(main_hotel).off('value');

      await firebase.db.ref(restaurant).off('value');

      await firebase.db.ref(laundry_shop).off('value');

      await firebase.db.ref(ebill_path).off('value');

      await firebase.db.ref(energy_forecast).off('value');
    } else {
      // Fetch Main MDB
      await firebase.db.ref(main_hotel).on('value', function (snap) {
        let capt = snap.val();
        setMainMdb(capt);
      });

      // Fetch restaurant MDB
      await firebase.db.ref(restaurant).on('value', function (snap) {
        let capt = snap.val();
        setrestaurantMdb(capt);
      });

      // Fetch Laundry MDB
      await firebase.db.ref(laundry_shop).on('value', function (snap) {
        let capt = snap.val();
        setLaundryMdb(capt);
      });

      await firebase.db.ref(ebill_path).on('value', function (snap) {
        let capt = snap.val();
        setEbill({
          offpeak_baht: capt.offpeak_baht,
          offpeak_kwh: capt.offpeak_kwh,
          onpeak_baht: capt.onpeak_baht,
          onpeak_kwh: capt.onpeak_kwh,
          this_month_baht: capt.this_month_baht,
          this_month_kwh: capt.this_month_kwh,
          this_month_forecast_baht: capt.this_month_forecast_baht,
          peak_demand_kw: capt.onpeak_demand_kw,
          co2: capt.co2,
          kwh_per_room_night: capt.kwh_per_room_night,
          kwh_per_m2: capt.kwh_per_m2,
          offpeak_baht_updated_at: capt.offpeak_baht_updated_at,
          offpeak_demand_kw_updated_at: capt.offpeak_demand_kw_updated_at,
          offpeak_kwh_updated_at: capt.offpeak_kwh_updated_at,
          onpeak_baht_updated_at: capt.onpeak_baht_updated_at,
          onpeak_demand_kw_updated_at: capt.onpeak_demand_kw_updated_at,
          onpeak_kwh_updated_at: capt.onpeak_kwh_updated_at,
          this_month_baht_updated_at: capt.this_month_baht_updated_at,
          this_month_kwh_updated_at: capt.this_month_kwh_updated_at,
          avg_kwh_per_room_night: capt.avg_kwh_per_room_night,
          avg_kwh_per_m2: capt.avg_kwh_per_m2,
          avg_co2_emission: capt.avg_co2_emission,
          avg_peak_power: capt.avg_peak_power,
        });
      });

      await firebase.db.ref(energy_forecast).on('value', function (snap) {
        let capt = snap.val();
        if ((capt !== undefined) !== null) {
          setEnergyForecast(capt);
        }
      });
    }
  }

  //##------------------Fetch Historical Data From REST API----------------
  async function histData(dev_id, sub_dev) {
    try {
      let results = await modBus.getModBusHistory(dev_id, sub_dev, false, startDate, stopDate);
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);
          return null;
        } else {
          if (results.data) {
            return results.data.results;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##----------------Get month number to string---------------------------
  function GetMonth(start) {
    // eslint-disable-next-line
    let month = [];

    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    if (start) {
      return month[startDate.getMonth()];
    } else {
      return month[stopDate.getMonth()];
    }
  }

  //##-------------------------- Fetching realtime data ----------------------------------
  useMemo(() => {
    if ((histLaun && histMain && histRest) !== null) {
      fetchData(firebase, false);
    }

    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histLaun, histMain, histRest]);

  //##----------------Fetching History Data-----------------------
  useMemo(() => {
    console.log('try to fetch');
    histData('9c:a5:25:ab:3a:b4:7', 0).then((results) => {
      /// Laundry
      return setHistLaun(results);
    });
    histData('9c:a5:25:ab:3a:b4:8', 0).then((results) => {
      /// Restuarant
      return setHistRest(results);
    });
    histData('9c:a5:25:ab:3a:b4:9', 3).then((results) => {
      /// Main
      return setHistMain(results);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, stopDate]);

  //##---------------------Preparing historical data from REST API to plot----------------------
  useMemo(() => {
    if (histMain !== null && histMain !== undefined) {
      let main = [];
      histMain.forEach((item) => {
        if (item.power !== null && item.timestamp !== null) {
          main.push([Number(moment(item.timestamp).format('x')), Number(item.power)]);
        }
        setPlotHistMain(main);
      });
    }
  }, [histMain]);

  //##------------------------- Preparing realtimg data from Firebase to plot -------------------------
  useMemo(() => {
    clearTimeout(timeoutid.current);
    if (histMain !== null) {
      if (restaurantMdb !== null && mainMdb !== null) {
        timeoutid.current = setTimeout(
          async () =>
            dispatch(
              energyActions.mintelEnergy({
                main_mdb: {
                  plot: [
                    Number(moment(mainMdb.total.timestamp).format('x')),
                    Number(mainMdb.total.power.toFixed(2)),
                  ],
                  p_1: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                  p_2: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                  p_3: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                },
                restaurant_mdb: {
                  plot: [],
                  p_1: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                  p_2: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                  p_3: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                },
                laundry_mdb: {
                  plot: [],
                  p_1: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                  p_2: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                  p_3: {
                    power: [],
                    current: [],
                    voltage: [],
                    power_reactive: [],
                    time: [],
                  },
                },
              })
            ),
          2000
        );
      }
    }
    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMdb, restaurantMdb, laundryMdb, histMain, histLaun, histRest]);

  // useMemo(() => {
  //   if (energyForecast !== null) {
  //     let tmp = [];
  //     Object.keys(energyForecast).forEach((element) => {
  //       return tmp.push([element, energyForecast[element]]);
  //     });
  //     // console.log(tmp);
  //     setPlotforeCast(tmp);
  //   }
  // }, [energyForecast]);

  //##-------------------------- Main Graph plot -------------------------
  const main_graph = {
    series: [
      {
        name: `Electricity consumption ${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()} - ${stopDate.getDate()}/${
          stopDate.getMonth() + 1
        }/${stopDate.getFullYear()}`,
        data:
          plothistMain !== [] && plothistMain !== null ? plothistMain.concat(Main_mdb.plot) : [],
      },
      // {
      //   name: `Energy Forecast Today`,
      //   data: plotforeCast !== [] ? plotforeCast : [],
      // },
    ],
    options: {
      noData: {
        text: 'Loading...',
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        // style: 'hollow',
      },
      stroke: {
        width: 2,
        cruve: 'smooth',
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (val) {
            return val.toFixed(2);
          },
        },
        // tickAmount: 9,
        title: {
          text: '(kW)',
        },
      },
      xaxis: {
        type: 'datetime',
        min: new Date(
          `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 00:00:00`
        ).getTime(),
        max: new Date(
          `${stopDate.getDate() - 1} ${GetMonth(false)} ${stopDate.getFullYear()} 23:59:59`
        ).getTime(),
        labels: {
          datetimeUTC: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      title: {
        text: `Electricity consumption ${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()} - ${stopDate.getDate()}/${
          stopDate.getMonth() + 1
        }/${stopDate.getFullYear()}`,
        align: 'center',
      },
      annotations: {
        xaxis: [
          {
            x: new Date(
              `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 09:00:00`
            ).getTime(),
            x2: new Date(
              `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 22:00:00`
            ).getTime(),
            fillColor: '#F7819F',
          },
        ],
      },
    },
  };

  const plotEnergyForecast = {
    series: [
      {
        name: 'Energy Forecast',
        data: energyForecast !== null ? Object.values(energyForecast) : [],
        type: 'area',
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      yaxis: {
        title: {
          text: '(kW)',
        },
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      xaxis: {
        type: 'category',
        categories: energyForecast !== null ? Object.keys(energyForecast) : [],
      },
      title: {
        text: 'Energy Forecast Today',
        align: 'center',
        offsetX: 14,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: -10,
      },
    },
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <MintelEnergyComponent
        main_graph={main_graph}
        startDate={startDate}
        stopDate={stopDate}
        handleStartDate={handleStartDate}
        handleStopDate={handleStopDate}
        ebill={ebill}
        plotEnergyForecast={plotEnergyForecast}
      />
    </div>
  );
};
