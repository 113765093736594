import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { Card, CardTextHeader } from '../components/StyledComponents/StyledComponents.components';
import { StoreList } from '../components/storeList';
import styled from 'styled-components';

const LogoContainer = styled.div`
  width: 360px;
  height: 120px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LogoImage = styled.div`
  height: 60px;
  width: 60px;
  margin: 0px;
  border-radius: 10px;
  padding: 0px;
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

const LogoDetail = styled.div`
  position: relative;
  height: 75px;
  width: 200px;
  margin: 0px 0px 0px 35px;
  padding: 0px;
`;

const LogoStatus = styled.div`
  position: absolute;
  width: 80px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  border-radius: 10px;
  background-color: #edeef1;
  text-align: center;
  font-size: 11px;
  bottom: 0px;
  left: 0px;
  padding-top: 1.5px;
  font-weight: 500;
`;

const LogoName = styled.h3`
  float: left;
  margin: 0px;
  padding: 0px;
  font-size: 15px;
`;

const Store = () => {
  return (
    <div style={{ marginTop: '96px', height: '120vh' }}>
      <Row
        style={{ marginLeft: '30px', marginRight: '30px', marginBottom: '20px', marginTop: '10px' }}
      >
        <Col lg="12" md="12" sm="12" style={{ padding: '0px' }}>
          <Row style={{ height: '150px', marginBottom: '30px' }}>
            <Col lg="12" md="12" sm="12" style={{ margin: '0px' }}>
              <Card
                disabled={false}
                url={
                  'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2Fbackground%2Fstore_background.jpg?alt=media&token=056703e0-a8df-46b1-8435-22a0140e1f74'
                }
              >
                <CardBody>
                  <CardTextHeader>Alto Applications</CardTextHeader>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ height: '90vh' }}>
            <Col lg="12" md="12" sm="12">
              <Card style={{ height: '96%' }}>
                <CardBody style={{ padding: '20px 20px 20px 40px' }}>
                  <Row>
                    {StoreList.map((data) => (
                      <Col lg="4" md="6" sm="12">
                        <LogoContainer>
                          <LogoImage url={data.url} />
                          <LogoDetail>
                            <LogoName>{data.name}</LogoName>
                            <LogoStatus>{data.status}</LogoStatus>
                          </LogoDetail>
                        </LogoContainer>
                      </Col>
                    ))}
                    {/* {StoreList.map((data) => (
                      <Col lg="6" md="6" sm="12" key={data.name}>
                        <AppBox disabled={false}>
                          <Row>
                            <Col lg="2" md="4" sm="4">
                              <Logo url={data.url}></Logo>
                            </Col>
                            <Col lg="10" md="4" sm="4">
                              <CardText>{data.name}</CardText>
                            </Col>
                          </Row>
                        </AppBox>
                      </Col>
                    ))} */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <ComingSoon>Coming soon...</ComingSoon>
      <div
        style={{
          position: 'absolute',
          top: '73px',
          left: '0px',
          width: '100%',
          height: 'calc( 100vh - 73px )',
          backgroundColor: '#00000040',
        }}
      /> */}
    </div>
  );
};

export { Store };
