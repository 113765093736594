import React from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, CardDeck } from 'reactstrap';

const EnergySaving = (props) => {
  return (
    <Col md="4">
      <Card>
        <div style={{ height: '350px' }}>
          <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
            <h5 className="card-title">{props.name}</h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <b>
                        Savings This Month: <b style={{ color: '#23a597' }}>{props.data.percent} %</b>
                      </b>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p style={{ color: '#23a597' }}>{props.data.val} Baht</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <CardDeck>
              <Col md="4" style={{ paddingLeft: '3px', paddingRight: '3px', textAlign: 'center' }}>
                <p>
                  {/* <i className="nc-icon nc-bank" /> */}
                  <img
                    src="/assets/images/dashbard/hotel.png"
                    alt="hotel-logo"
                    width="25"
                    height="25"
                  />
                </p>

                <b>Hotel saving</b>
                <p>{props.data.hotel} Baht</p>
              </Col>
              <Col md="4" style={{ paddingLeft: '3px', paddingRight: '3px', textAlign: 'center' }}>
                <p>
                  {/* <i className="nc-icon nc-app" /> */}
                  <img
                    src="/assets/images/dashbard/cash.png"
                    alt="hotel-logo"
                    width="27"
                    height="27"
                  />
                </p>

                <b>ESCO</b>
                <p>{props.data.esco} Baht</p>
              </Col>
              <Col md="4" style={{ paddingLeft: '3px', paddingRight: '3px', textAlign: 'center' }}>
                <p>
                  {/* <i className="nc-icon nc-bag-16" /> */}
                  <img
                    src="/assets/images/dashbard/LogoAltoTech-03.png"
                    alt="logo"
                    width="25"
                    height="25"
                  />
                </p>

                <b>AltoTech</b>
                <p>{props.data.alto} Baht</p>
              </Col>
            </CardDeck>
            {/* <Plot data={props.data} layout={props.layout} config={{ displayModeBar: false }} /> */}
          </CardBody>
          {/* <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-info" />
            Open
          </div>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar" />
            Number of emails sent
          </div>
        </CardFooter> */}
        </div>
      </Card>
    </Col>
  );
};

export { EnergySaving };
