import React from 'react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import Chart from 'react-apexcharts';

export const CostBreakDownComponent = (props) => {
  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col md="8">
              <h5>Over All</h5>
              <Chart series={props.plot_1.series} options={props.plot_1.options} type="donut" />
            </Col>
            <Col md="4">
              <Row>
                <h5>Guest Room</h5>
                <Col md="12">
                  <Chart series={props.plot_2.series} options={props.plot_2.options} type="donut" />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <h5>Common Space</h5>
                  <Chart series={props.plot_3.series} options={props.plot_3.options} type="donut" />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
