export const Devices = [
  { value: 'ac', label: 'Airconditioning' },
  { value: 'fan', label: 'Exhaust fan' },
  { value: 'main_switch', label: 'Main light' },
  { value: 'bed_switch', label: 'Bed light' },
  { value: 'corridor_switch', label: 'Corridor light' },
  { value: 'window_switch', label: 'Window light' },
  { value: 'toilet_switch', label: 'Toilet light' },
  { value: 'wardrob_switch', label: 'Wardrob light' },
  { value: 'tv', label: 'Television' },
  { value: 'fridge', label: 'Refridgerator' },
];

export const MintelRooms = [
  // {
  //   value: [
  //     'room_201',
  //     'room_202',
  //     'room_203',
  //     'room_204',
  //     'room_205',
  //     'room_206',
  //     'room_207',
  //     'room_208',
  //     'room_209',
  //     'room_210',
  //     'room_211',
  //     'room_212',
  //     'room_214',
  //     'room_215',
  //     'room_216',
  //     'room_301',
  //     'room_302',
  //     'room_303',
  //     'room_304',
  //     'room_305',
  //     'room_306',
  //     'room_307',
  //     'room_308',
  //     'room_309',
  //     'room_310',
  //     'room_311',
  //     'room_312',
  //     'room_314',
  //     'room_315',
  //     'room_316',
  //     'room_401',
  //     'room_402',
  //     'room_403',
  //     'room_404',
  //     'room_405',
  //     'room_406',
  //     'room_407',
  //     'room_408',
  //     'room_409',
  //     'room_410',
  //     'room_411',
  //     'room_412',
  //     'room_414',
  //     'room_415',
  //     'room_416',
  //     'room_501',
  //     'room_502',
  //     'room_503',
  //     'room_504',
  //     'room_505',
  //     'room_506',
  //     'room_507',
  //     'room_508',
  //     'room_509',
  //     'room_510',
  //     'room_511',
  //     'room_512',
  //     'room_514',
  //     'room_515',
  //     'room_516',
  //     'room_601',
  //     'room_602',
  //     'room_603',
  //     'room_604',
  //     'room_605',
  //     'room_606',
  //     'room_607',
  //     'room_608',
  //     'room_609',
  //     'room_610',
  //     'room_611',
  //     'room_612',
  //     'room_614',
  //     'room_615',
  //     'room_616',
  //   ],
  //   label: 'All room',
  // },
  { value: ['room_201'], label: '201' },
  { value: ['room_202'], label: '202' },
  { value: ['room_203'], label: '203' },
  { value: ['room_204'], label: '204' },
  { value: ['room_205'], label: '205' },
  { value: ['room_206'], label: '206' },
  { value: ['room_207'], label: '207' },
  { value: ['room_208'], label: '208' },
  { value: ['room_209'], label: '209' },
  { value: ['room_210'], label: '210' },
  { value: ['room_211'], label: '211' },
  { value: ['room_212'], label: '212' },
  { value: ['room_214'], label: '214' },
  { value: ['room_215'], label: '215' },
  { value: ['room_216'], label: '216' },
  { value: ['room_301'], label: '301' },
  { value: ['room_302'], label: '302' },
  { value: ['room_303'], label: '303' },
  { value: ['room_304'], label: '304' },
  { value: ['room_305'], label: '305' },
  { value: ['room_306'], label: '306' },
  { value: ['room_307'], label: '307' },
  { value: ['room_308'], label: '308' },
  { value: ['room_309'], label: '309' },
  { value: ['room_310'], label: '310' },
  { value: ['room_311'], label: '311' },
  { value: ['room_312'], label: '312' },
  { value: ['room_314'], label: '314' },
  { value: ['room_315'], label: '315' },
  { value: ['room_316'], label: '316' },
  { value: ['room_401'], label: '401' },
  { value: ['room_402'], label: '402' },
  { value: ['room_403'], label: '403' },
  { value: ['room_404'], label: '404' },
  { value: ['room_405'], label: '405' },
  { value: ['room_406'], label: '406' },
  { value: ['room_407'], label: '407' },
  { value: ['room_408'], label: '408' },
  { value: ['room_409'], label: '409' },
  { value: ['room_410'], label: '410' },
  { value: ['room_411'], label: '411' },
  { value: ['room_412'], label: '412' },
  { value: ['room_414'], label: '414' },
  { value: ['room_415'], label: '415' },
  { value: ['room_416'], label: '416' },
  { value: ['room_501'], label: '501' },
  { value: ['room_502'], label: '502' },
  { value: ['room_503'], label: '503' },
  { value: ['room_504'], label: '504' },
  { value: ['room_505'], label: '505' },
  { value: ['room_506'], label: '506' },
  { value: ['room_507'], label: '507' },
  { value: ['room_508'], label: '508' },
  { value: ['room_509'], label: '509' },
  { value: ['room_510'], label: '510' },
  { value: ['room_511'], label: '511' },
  { value: ['room_512'], label: '512' },
  { value: ['room_514'], label: '514' },
  { value: ['room_515'], label: '515' },
  { value: ['room_516'], label: '516' },
  { value: ['room_601'], label: '601' },
  { value: ['room_602'], label: '602' },
  { value: ['room_603'], label: '603' },
  { value: ['room_604'], label: '604' },
  { value: ['room_605'], label: '605' },
  { value: ['room_606'], label: '606' },
  { value: ['room_607'], label: '607' },
  { value: ['room_608'], label: '608' },
  { value: ['room_609'], label: '609' },
  { value: ['room_610'], label: '610' },
  { value: ['room_611'], label: '611' },
  { value: ['room_612'], label: '612' },
  { value: ['room_614'], label: '614' },
  { value: ['room_615'], label: '615' },
  { value: ['room_616'], label: '616' },
];

export const AutomationMintelRooms = [
  { value: ['201'], label: '201' },
  { value: ['202'], label: '202' },
  { value: ['203'], label: '203' },
  { value: ['204'], label: '204' },
  { value: ['205'], label: '205' },
  { value: ['206'], label: '206' },
  { value: ['207'], label: '207' },
  { value: ['208'], label: '208' },
  { value: ['209'], label: '209' },
  { value: ['210'], label: '210' },
  { value: ['211'], label: '211' },
  { value: ['212'], label: '212' },
  { value: ['214'], label: '214' },
  { value: ['215'], label: '215' },
  { value: ['216'], label: '216' },
  { value: ['301'], label: '301' },
  { value: ['302'], label: '302' },
  { value: ['303'], label: '303' },
  { value: ['304'], label: '304' },
  { value: ['305'], label: '305' },
  { value: ['306'], label: '306' },
  { value: ['307'], label: '307' },
  { value: ['308'], label: '308' },
  { value: ['309'], label: '309' },
  { value: ['310'], label: '310' },
  { value: ['311'], label: '311' },
  { value: ['312'], label: '312' },
  { value: ['314'], label: '314' },
  { value: ['315'], label: '315' },
  { value: ['316'], label: '316' },
  { value: ['401'], label: '401' },
  { value: ['402'], label: '402' },
  { value: ['403'], label: '403' },
  { value: ['404'], label: '404' },
  { value: ['405'], label: '405' },
  { value: ['406'], label: '406' },
  { value: ['407'], label: '407' },
  { value: ['408'], label: '408' },
  { value: ['409'], label: '409' },
  { value: ['410'], label: '410' },
  { value: ['411'], label: '411' },
  { value: ['412'], label: '412' },
  { value: ['414'], label: '414' },
  { value: ['415'], label: '415' },
  { value: ['416'], label: '416' },
  { value: ['501'], label: '501' },
  { value: ['502'], label: '502' },
  { value: ['503'], label: '503' },
  { value: ['504'], label: '504' },
  { value: ['505'], label: '505' },
  { value: ['506'], label: '506' },
  { value: ['507'], label: '507' },
  { value: ['508'], label: '508' },
  { value: ['509'], label: '509' },
  { value: ['510'], label: '510' },
  { value: ['511'], label: '511' },
  { value: ['512'], label: '512' },
  { value: ['514'], label: '514' },
  { value: ['515'], label: '515' },
  { value: ['516'], label: '516' },
  { value: ['601'], label: '601' },
  { value: ['602'], label: '602' },
  { value: ['603'], label: '603' },
  { value: ['604'], label: '604' },
  { value: ['605'], label: '605' },
  { value: ['606'], label: '606' },
  { value: ['607'], label: '607' },
  { value: ['608'], label: '608' },
  { value: ['609'], label: '609' },
  { value: ['610'], label: '610' },
  { value: ['611'], label: '611' },
  { value: ['612'], label: '612' },
  { value: ['614'], label: '614' },
  { value: ['615'], label: '615' },
  { value: ['616'], label: '616' },
];

export const ScheduleRooms = [
  { value: '201', label: '201' },
  { value: '202', label: '202' },
  { value: '203', label: '203' },
  { value: '204', label: '204' },
  { value: '205', label: '205' },
  { value: '206', label: '206' },
  { value: '207', label: '207' },
  { value: '208', label: '208' },
  { value: '209', label: '209' },
  { value: '210', label: '210' },
  { value: '211', label: '211' },
  { value: '212', label: '212' },
  { value: '214', label: '214' },
  { value: '215', label: '215' },
  { value: '216', label: '216' },
  { value: '301', label: '301' },
  { value: '302', label: '302' },
  { value: '303', label: '303' },
  { value: '304', label: '304' },
  { value: '305', label: '305' },
  { value: '306', label: '306' },
  { value: '307', label: '307' },
  { value: '308', label: '308' },
  { value: '309', label: '309' },
  { value: '310', label: '310' },
  { value: '311', label: '311' },
  { value: '312', label: '312' },
  { value: '314', label: '314' },
  { value: '315', label: '315' },
  { value: '316', label: '316' },
];

// export const AutomationDevices = [
//   { value: 'ac', label: 'AC', borderBottom: false },
//   { value: 'tv', label: 'Television', borderBottom: false },
//   { value: 'fridge', label: 'Refridgerator', borderBottom: false },
//   { value: 'fan', label: 'Exhaust fan', borderBottom: true },
//   { value: 'toilet_switch', label: 'Toilet light', borderBottom: false },
//   { value: 'corridor_switch', label: 'Corridor light', borderBottom: false },
//   { value: 'main_switch', label: 'Main light', borderBottom: true },
//   { value: 'window_switch', label: 'Window light', borderBottom: false },
//   { value: 'wardrobe_switch', label: 'Wardrobe light', borderBottom: false },
//   { value: 'bed_switch', label: 'Bed light', borderBottom: false },
// ];

export const AutomationDevices = [
  { value: 'Air Conditioner', label: 'Air Conditioner', borderBottom: false },
  { value: 'Exhaust Fan', label: 'Exhaust Fan', borderBottom: false },
  { value: 'Toilet Switch', label: 'Toilet Switch', borderBottom: false },
  { value: 'Corridor Switch', label: 'Corridor Switch', borderBottom: false },
  { value: 'Main Switch', label: 'Main Switch', borderBottom: false },
  { value: 'Window Switch', label: 'Window Switch', borderBottom: false },
  { value: 'Wardrobe Switch', label: 'Wardrobe Switch', borderBottom: false },
  { value: 'Bed Switch', label: 'Bed Switch', borderBottom: false },
  { value: 'Smart TV', label: 'Smart TV', borderBottom: false },
  { value: 'Refrigerator', label: 'Refrigerator', borderBottom: false },
];

export const ListOfAllRoomsMintel = [
  '201',
  '202',
  '203',
  '204',
  '205',
  '206',
  '207',
  '208',
  '209',
  '210',
  '211',
  '212',
  '214',
  '215',
  '301',
  '302',
  '303',
  '304',
  '305',
  '306',
  '307',
  '308',
  '309',
  '310',
  '311',
  '312',
  '314',
  '315',
];
