import React from 'react';
import 'firebase/database';
import './App.scss';
import { RootPage } from './features/root/pages';

function App(props) {
  return (
    <div>
      <RootPage />
    </div>
  );
}

export default App;
