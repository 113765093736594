import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';
import { NavBar, Sidebar } from '../components';

import { navActions } from '../features/nav/actions';
import { logoutActions } from '../features/auth/actions';

// import { FirebaseContext } from '../firebase';

import routes from '../routes';

let ps;

const Admin = (props) => {
  const dispatch = useDispatch();
  // const firebase = useContext(FirebaseContext);
  // const { api } = useContext(FirebaseContext);

  let mainPanel = useRef(null);

  const [state, setState] = useState({
    backgroundColor: 'white',
    activeColor: 'primary',
    sidebarMini: false,
  });

  const hotelCode = localStorage.getItem('hotel_hotel_code');
  const token = localStorage.getItem('token');

  // function syncFirebaseHasNewPendingUserState(data) {
  //   let hasNewPendingUserFirebaseState = data.val();
  //   //## Reset Firebase state
  //   if (hasNewPendingUserFirebaseState) {
  //     api.setUserIsUpdatedStateFirebaseServices(firebase, hotelCode, false);
  //   }
  // }

  const mounted = () => {
    if (!hotelCode || !token) {
      alert('เกิดข้อผิดพลาดในการเข้าสู่ระบบ กรุณาเข้าสู้ระบบใหม่อีกครั้ง');

      dispatch(logoutActions.logout());
    } else {
      dispatch(navActions.focusPage('DashboardPage'));
      //## Subscribe firebase pending user has update
      // firebase.db
      //   .ref('dashboard/' + hotelCode + '/user_is_update')
      //   .on('value', syncFirebaseHasNewPendingUserState);
    }
  };
  useEffect(mounted, []);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(mainPanel.current);
    }

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    };
  }, []);

  const handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      setState({ sidebarMini: false });
    } else {
      setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collaps) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <div className="wrapper" style={{backgroundColor:'#f4f3ee'}}>
      <Sidebar
        {...props}
        routes={routes}
        bgColor={state.backgroundColor}
        activeColor={state.activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <NavBar {...state} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routes)}</Switch>
      </div>
    </div>
  );
};
export default Admin;
