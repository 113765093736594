import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ElectricityByHour } from '../../../components/Energy';
import moment from 'moment';
import styled from 'styled-components';
import { Row, Col, Card } from 'reactstrap';

const Topic = styled.h5`
  margin: 0px;
  float: right;
`;

const Header = styled.h4`
  margin: 0px;
`;

const SolarPVRecommendation = () => {
  const chartExample9 = {
    series: [
      {
        name: 'Your consumption',
        data: [
          8.539976,
          8.614388,
          8.582749,
          7.517993,
          7.262279,
          7.016542,
          6.857621,
          6.921049,
          8.776966,
          8.873454,
          8.918077,
          8.947409,
          8.846572,
          8.827238,
          8.966243,
          8.125336,
          6.53771,
          6.591735,
          6.550695,
          6.201282,
          11.248147,
          14.068158,
          14.279064,
          12.579728,
          12.515461,
          12.877053,
          12.874335,
          13.896292,
          14.686622,
          15.871143,
          15.949583,
          15.549898,
          14.933038,
          14.69559,
          15.974334,
          17.749465,
          20.710307,
          21.131439,
          21.241066,
          22.635336,
          24.781952,
          25.071209,
          25.314,
          29.905502,
          30.247279,
          29.651698,
          28.830945,
          30.725671,
          31.713039,
          30.558139,
          30.422712,
          31.230506,
          31.713323,
          30.945764,
          31.572265,
          31.591887,
          31.884949,
          29.05647,
          29.552308,
          28.751637,
          28.476081,
          27.873094,
          29.948341,
          30.898871,
          34.172921,
          32.893443,
          32.350418,
          33.456459,
          31.793569,
          31.580316,
          32.08147,
          32.629795,
          30.082356,
          28.105295,
          28.934504,
          28.411999,
          27.461706,
          27.694045,
          28.962978,
          27.30228,
          26.364915,
          27.719325,
          27.243787,
          26.32057,
          27.320513,
          27.79945,
          27.367332,
          26.340017,
          26.375968,
          28.580943,
          25.307116,
          23.009169,
          23.917285,
          21.492493,
          18.574805,
          17.398835,
        ],

        type: 'area',
      },
      {
        name: 'Alto Tech Solution',
        data: [
          8.344304,
          7.965161,
          7.913883,
          7.749085,
          7.952757,
          8.202067,
          8.329601,
          7.955501,
          8.051633,
          8.152024,
          8.041711,
          8.016443,
          8.092522,
          7.827919,
          7.576355,
          7.775033,
          7.771879,
          6.36233,
          7.625227,
          9.61482,
          12.460859,
          12.42094,
          11.596309,
          9.956095,
          10.344553,
          12.699206,
          11.891768,
          12.625774,
          13.222398,
          15.883633,
          15.733353,
          15.967054,
          17.469314,
          17.226706,
          16.96274,
          17.456361,
          17.482323,
          18.193503,
          18.502044,
          18.491155,
          19.028928,
          19.402545,
          19.170295,
          19.651998,
          18.853061,
          18.171565,
          18.601543,
          20.617247,
          22.777631,
          21.951359,
          19.950795,
          19.289268,
          17.204465,
          17.540207,
          17.978221,
          18.583486,
          18.54552,
          18.513302,
          20.380591,
          21.513718,
          20.633335,
          21.54004,
          21.43317,
          21.582437,
          20.972504,
          21.24608,
          22.832547,
          24.60101,
          24.680474,
          23.868081,
          23.639692,
          24.679577,
          22.248184,
          22.558561,
          23.439716,
          21.554307,
          20.504746,
          20.235768,
          19.376239,
          19.879343,
          20.770852,
          20.653005,
          19.726962,
          20.185894,
          20.130721,
          19.988883,
          18.703356,
          19.300431,
          19.733759,
          19.425676,
          19.233118,
          16.901921,
          12.981403,
          11.34113,
          11.343296,
          11.318912,
        ],
        type: 'area',
      },
    ],

    options: {
      chart: {
        height: 350,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T00:15:00.000Z',
          '2018-09-19T00:30:00.000Z',
          '2018-09-19T00:45:00.000Z',
          '2018-09-19T01:00:00.000Z',
          '2018-09-19T01:15:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T01:45:00.000Z',
          '2018-09-19T02:00:00.000Z',
          '2018-09-19T02:15:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T02:45:00.000Z',
          '2018-09-19T03:00:00.000Z',
          '2018-09-19T03:15:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T03:45:00.000Z',
          '2018-09-19T04:00:00.000Z',
          '2018-09-19T04:15:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T04:45:00.000Z',
          '2018-09-19T05:00:00.000Z',
          '2018-09-19T05:15:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T05:45:00.000Z',
          '2018-09-19T06:00:00.000Z',
          '2018-09-19T06:15:00.000Z',
          '2018-09-19T06:30:00.000Z',
          '2018-09-19T06:45:00.000Z',
          '2018-09-19T07:00:00.000Z',
          '2018-09-19T07:15:00.000Z',
          '2018-09-19T07:30:00.000Z',
          '2018-09-19T07:45:00.000Z',
          '2018-09-19T08:00:00.000Z',
          '2018-09-19T08:15:00.000Z',
          '2018-09-19T08:30:00.000Z',
          '2018-09-19T08:45:00.000Z',
          '2018-09-19T09:00:00.000Z',
          '2018-09-19T09:15:00.000Z',
          '2018-09-19T09:30:00.000Z',
          '2018-09-19T09:45:00.000Z',
          '2018-09-19T10:00:00.000Z',
          '2018-09-19T10:15:00.000Z',
          '2018-09-19T10:30:00.000Z',
          '2018-09-19T10:45:00.000Z',
          '2018-09-19T11:00:00.000Z',
          '2018-09-19T11:15:00.000Z',
          '2018-09-19T11:30:00.000Z',
          '2018-09-19T11:45:00.000Z',
          '2018-09-19T12:00:00.000Z',
          '2018-09-19T12:15:00.000Z',
          '2018-09-19T12:30:00.000Z',
          '2018-09-19T12:45:00.000Z',
          '2018-09-19T13:00:00.000Z',
          '2018-09-19T13:15:00.000Z',
          '2018-09-19T13:30:00.000Z',
          '2018-09-19T13:45:00.000Z',
          '2018-09-19T14:00:00.000Z',
          '2018-09-19T14:15:00.000Z',
          '2018-09-19T14:30:00.000Z',
          '2018-09-19T14:45:00.000Z',
          '2018-09-19T15:00:00.000Z',
          '2018-09-19T15:15:00.000Z',
          '2018-09-19T15:30:00.000Z',
          '2018-09-19T15:45:00.000Z',
          '2018-09-19T16:00:00.000Z',
          '2018-09-19T16:15:00.000Z',
          '2018-09-19T16:30:00.000Z',
          '2018-09-19T16:45:00.000Z',
          '2018-09-19T17:00:00.000Z',
          '2018-09-19T17:15:00.000Z',
          '2018-09-19T17:30:00.000Z',
          '2018-09-19T17:45:00.000Z',
          '2018-09-19T18:00:00.000Z',
          '2018-09-19T18:15:00.000Z',
          '2018-09-19T18:30:00.000Z',
          '2018-09-19T18:45:00.000Z',
          '2018-09-19T19:00:00.000Z',
          '2018-09-19T19:15:00.000Z',
          '2018-09-19T19:30:00.000Z',
          '2018-09-19T19:45:00.000Z',
          '2018-09-19T20:00:00.000Z',
          '2018-09-19T20:15:00.000Z',
          '2018-09-19T20:30:00.000Z',
          '2018-09-19T20:45:00.000Z',
          '2018-09-19T21:00:00.000Z',
          '2018-09-19T21:15:00.000Z',
          '2018-09-19T21:30:00.000Z',
          '2018-09-19T21:45:00.000Z',
          '2018-09-19T22:00:00.000Z',
          '2018-09-19T22:15:00.000Z',
          '2018-09-19T22:30:00.000Z',
          '2018-09-19T22:45:00.000Z',
          '2018-09-19T23:00:00.000Z',
          '2018-09-19T23:15:00.000Z',
          '2018-09-19T23:30:00.000Z',
          '2018-09-19T23:45:00.000Z',
        ],
      },
      yaxis: {
        tickAmount: 9,
        title: {
          text: '(kWh)',
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  };
  const chartExample10 = {
    series: [
      {
        name: 'PRODUCT A',
        data: chartExample9.options.xaxis.categories.map(function (value, index) {
          return [value, chartExample9.series[0].data[index]];
        }),
        type: 'area',
      },
      {
        name: 'PRODUCT B',
        data: [
          ['2018-09-19T11:00:00.000Z', 0],
          ['2018-09-19T11:15:00.000Z', 3],
          ['2018-09-19T11:30:00.000Z', 8],
          ['2018-09-19T11:45:00.000Z', 11],
          ['2018-09-19T12:00:00.000Z', 13],
          ['2018-09-19T12:15:00.000Z', 14],
          ['2018-09-19T12:30:00.000Z', 13],
          ['2018-09-19T12:45:00.000Z', 17],
          ['2018-09-19T13:00:00.000Z', 18],
          ['2018-09-19T13:15:00.000Z', 21],
          ['2018-09-19T13:30:00.000Z', 23],
          ['2018-09-19T13:45:00.000Z', 25],
          ['2018-09-19T14:00:00.000Z', 28],
          ['2018-09-19T14:15:00.000Z', 31],
          ['2018-09-19T14:30:00.000Z', 34],
          ['2018-09-19T14:45:00.000Z', 33],
          ['2018-09-19T15:00:00.000Z', 36],
          ['2018-09-19T15:15:00.000Z', 35],
          ['2018-09-19T15:30:00.000Z', 31],
          ['2018-09-19T15:45:00.000Z', 27],
          ['2018-09-19T16:00:00.000Z', 25],
          ['2018-09-19T16:15:00.000Z', 26],
          ['2018-09-19T16:30:00.000Z', 28],
          ['2018-09-19T16:45:00.000Z', 25],
          ['2018-09-19T17:00:00.000Z', 23],
          ['2018-09-19T17:15:00.000Z', 21],
          ['2018-09-19T17:30:00.000Z', 18],
          ['2018-09-19T17:45:00.000Z', 15],
          ['2018-09-19T18:00:00.000Z', 14],
          ['2018-09-19T18:15:00.000Z', 12],
          ['2018-09-19T18:30:00.000Z', 11],
          ['2018-09-19T18:45:00.000Z', 9],
          ['2018-09-19T19:00:00.000Z', 6],
          ['2018-09-19T19:15:00.000Z', 3],
          ['2018-09-19T19:30:00.000Z', 1],
        ],
        type: 'area',
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'area',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100],
        },
      },
      yaxis: {
        tickAmount: 9,
        title: {
          text: '(kWh)',
        },
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 8,
        min: '2018-09-19T00:00:00.000Z',
        max: '2018-09-19T23:45:00.000Z',
        labels: {
          rotate: -15,
          rotateAlways: true,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format('DD MMM YYYY');
          },
        },
      },
      title: {
        text: 'Irregular Data in Time Series',
        align: 'left',
        offsetX: 14,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: -10,
      },
    },
  };
  return (
    <div>
      <Col lg="12" sm="12">
        <Card>
          <Row>
            <Col lg="12" md="12" sm="12">
              <ElectricityByHour
                title={'Daily solar PV energy production (kWh)'}
                options={chartExample10.options}
                series={chartExample10.series}
              />
            </Col>
          </Row>
          <div style={{ margin: '20px', marginLeft: '40px' }}>
            <Row>
              <Header>Alto Tech Solution</Header>
            </Row>

            <Row>
              <Col lg="3">
                <Topic>Save :</Topic>
              </Col>
              <Col lg="3">
                <p>5,010 kWh or 20,041 Baht</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Budget :</Topic>
              </Col>
              <Col lg="3">
                <p>1,515,114 Baht</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Breake Event Point :</Topic>
              </Col>
              <Col lg="3">
                <p>6 year 3 month</p>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export { SolarPVRecommendation };
