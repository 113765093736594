import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { DatePickers } from '../../../components';

export const RealTimeComponent = (props) => {
  const emdb = props.emdb.emdb;
  const mdb = props.mdb.mdb;

  // Energy Total
  const energy_emdb = Number(emdb?.total?.energy).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const energy_mdb = Number(mdb?.total?.energy).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const total_energy_mdb = Number(Number(emdb?.total?.energy) + mdb?.total?.energy).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  // Total
  const t_current = {
    c_1: Number(emdb.p_1.current + mdb.p_1.current).toFixed(2),
    c_2: Number(emdb.p_2.current + mdb.p_2.current).toFixed(2),
    c_3: Number(emdb.p_3.current + mdb.p_3.current).toFixed(2),
  };

  const all_t_current = Number(
    Number(t_current.c_1) + Number(t_current.c_2) + Number(t_current.c_3)
  ).toFixed(2);

  const t_power = {
    p_1: Number(emdb.p_1.power + mdb.p_1.power).toFixed(2),
    p_2: Number(emdb.p_2.power + mdb.p_2.power).toFixed(2),
    p_3: Number(emdb.p_3.power + mdb.p_3.power).toFixed(2),
  };
  const all_t_power = Number(
    Number(t_power.p_1) + Number(t_power.p_2) + Number(t_power.p_3)
  ).toFixed(2);

  const t_voltage = {
    v_1: Number(
      Math.sqrt((Math.pow(emdb.p_1.voltage, 2) + Math.pow(mdb.p_1.voltage, 2)) / 2)
    ).toFixed(2),
    v_2: Number(
      Math.sqrt((Math.pow(emdb.p_2.voltage, 2) + Math.pow(mdb.p_2.voltage, 2)) / 2)
    ).toFixed(2),
    v_3: Number(
      Math.sqrt((Math.pow(emdb.p_3.voltage, 2) + Math.pow(mdb.p_3.voltage, 2)) / 2)
    ).toFixed(2),
  };

  const all_t_voltage = Number(
    (Number(t_voltage.v_1) + Number(t_voltage.v_2) + Number(t_voltage.v_3)) / 3
  ).toFixed(2);

  //EMB

  const emdb_current = {
    c_1: Number(emdb.p_1.current).toFixed(2),
    c_2: Number(emdb.p_2.current).toFixed(2),
    c_3: Number(emdb.p_3.current).toFixed(2),
  };

  const e_t_current = Number(
    Number(emdb_current.c_1) + Number(emdb_current.c_2) + Number(emdb_current.c_3)
  ).toFixed(2);

  const e_power = {
    p_1: Number(emdb.p_1.power).toFixed(2),
    p_2: Number(emdb.p_2.power).toFixed(2),
    p_3: Number(emdb.p_3.power).toFixed(2),
  };

  const e_t_power = Number(Number(e_power.p_1) + Number(e_power.p_2) + Number(e_power.p_3)).toFixed(
    2
  );

  const emdb_voltage = {
    v_1: Number(emdb.p_1.voltage).toFixed(2),
    v_2: Number(emdb.p_2.voltage).toFixed(2),
    v_3: Number(emdb.p_3.voltage).toFixed(2),
  };

  const e_t_voltage = Number(
    Math.sqrt(
      (Math.pow(Number(emdb_voltage.v_1), 2) +
        Math.pow(Number(emdb_voltage.v_2), 2) +
        Math.pow(Number(emdb_voltage.v_3), 2)) /
      3
    )
  ).toFixed(2);

  // MBD
  const m_power = {
    p_1: Number(mdb.p_1.power).toFixed(2),
    p_2: Number(mdb.p_2.power).toFixed(2),
    p_3: Number(mdb.p_3.power).toFixed(2),
  };

  const m_t_power = Number(Number(m_power.p_1) + Number(m_power.p_2) + Number(m_power.p_3)).toFixed(
    2
  );

  const mdb_current = {
    c_1: Number(mdb.p_1.current).toFixed(2),
    c_2: Number(mdb.p_2.current).toFixed(2),
    c_3: Number(mdb.p_3.current).toFixed(2),
  };
  const m_t_current = Number(
    Number(mdb_current.c_1) + Number(mdb_current.c_2) + Number(mdb_current.c_3)
  ).toFixed(2);

  const mdb_voltage = {
    v_1: Number(mdb.p_1.voltage).toFixed(2),
    v_2: Number(mdb.p_2.voltage).toFixed(2),
    v_3: Number(mdb.p_3.voltage).toFixed(2),
  };

  const m_t_voltage = Number(
    Math.sqrt(
      (Math.pow(Number(mdb_voltage.v_1), 2) +
        Math.pow(Number(mdb_voltage.v_2), 2) +
        Math.pow(Number(mdb_voltage.v_3), 2)) /
      3
    )
  ).toFixed(2);

  return (
    <div className="content">
      <Row>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="2" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="fas fa-tachometer text-success" />
                  </div>
                </Col>
                <Col md="10" xs="7">
                  <div style={{ textAlign: 'right' }}>
                    <p className="card-category">Total Energy</p>
                    <Card.Title>{total_energy_mdb} kWh</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="2" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="fas fa-tachometer text-success" />
                  </div>
                </Col>
                <Col md="10" xs="7">
                  <div style={{ textAlign: 'right' }}>
                    <p className="card-category">EMDB Energy</p>
                    <Card.Title>{energy_emdb} kWh</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" md="6" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="2" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="fas fa-tachometer text-success" />
                  </div>
                </Col>
                <Col md="10" xs="7">
                  <div style={{ textAlign: 'right' }}>
                    <p className="card-category">MDB Energy</p>
                    <Card.Title>{energy_mdb} kWh</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Card body>
            <Card.Title tag="h5">Total</Card.Title>
            <Card.Body>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>
                      Power: <b>{all_t_power}</b> kW
                    </td>

                    <td>
                      Voltage: <b>{all_t_voltage}</b> V
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current: <b>{all_t_current}</b> A
                    </td>
                    <td>
                      PF :{' '}
                      <b>{Number((Number(mdb.pf_total) + Number(emdb.pf_total)) / 2).toFixed(2)}</b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col sm="4">
          <Card body>
            <Card.Title tag="h5">EMDB</Card.Title>
            <Card.Body>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>
                      Power: <b>{e_t_power}</b> kW
                    </td>

                    <td>
                      Voltage: <b>{e_t_voltage}</b> V
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current: <b>{e_t_current}</b> A
                    </td>
                    <td>
                      PF : <b>{Number(emdb.pf_total).toFixed(2)}</b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col sm="4">
          <Card body>
            <Card.Title tag="h5">MDB</Card.Title>
            <Card.Body>
              <Table borderless size="sm">
                <tbody>
                  <tr>
                    <td>
                      Power: <b>{m_t_power}</b> kW
                    </td>

                    <td>
                      Voltage: <b>{m_t_voltage}</b> V
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current: <b>{m_t_current}</b> A
                    </td>
                    <td>
                      PF : <b>{Number(mdb.pf_total).toFixed(2)}</b>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card className="card-stats">
            <Card.Body>
              {props.e_data.series[0].data && props.e_data.series[1].data !== undefined ? (
                <>
                  <DatePickers
                    daySelectStart={props.startDate}
                    daySelectStop={props.stopDate}
                    handleStartDate={props.handleStartDate}
                    handleStopDate={props.handleStopDate}
                  />
                  <Chart
                    options={props.e_data.options}
                    series={props.e_data.series}
                    type="line"
                    width="100%"
                    height="350px"
                  />
                </>
              ) : (
                <h1>Loading ...</h1>
              )}

              {/* <Chart
                options={props.m_data.options}
                series={props.m_data.series}
                type="bar"
                width="100%"
                height="100px"
              /> */}
            </Card.Body>
          </Card>

          {/* <Card className="card-stats">
            <Card.Body>
              <Chart
                options={props.m_data.options}
                series={props.m_data.series}
                type="bar"
                width="100%"
              />
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
    </div>
  );
};
