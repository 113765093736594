import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px;
`;

export const ScheduleApp = styled.div`
  width: 80px;
  height: 80px;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FAlto_Apps%2Fchronometer.svg?alt=media&token=97657df7-257f-473b-992d-f6fc696563a8');
  background-size: cover;
  margin: auto;
  border-radius: 5px;
  cursor: pointer;
`;

export const AutomationApp = styled.div`
  width: 80px;
  height: 80px;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoStore%2FAlto_Apps%2Fautomation.svg?alt=media&token=54950646-ec2d-4f88-99f8-6d79e5d8c4d0');
  background-size: cover;
  margin: auto;
  border-radius: 5px;
  cursor: pointer;
`;

export const Header = styled.h1`
  font-size: 15px;
`;

export const ModalHeader = styled.div`
  height: 80px;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  font-size: 20px;
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  float: right;
  margin: 0;
  margin-right: 15px;
  cursor: pointer;
`;

export const DropdownSpace = styled.div`
  border: 2px;
  padding-top: 5px;
  width: 150px;
  height: 30px;
  font-size: 15px;
  margin-left: 20px;
`;

export const ScheduleOptionArea = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: flex-start;
`;

export const GridSpace = styled.div`
  width: 70vw;
  height: 600px;
  --cell-height: 20px;
  --cell-width: 20px;
`;

export const SubmitButton = styled.div`
  border: 2px;
  border-color: grey;
  padding-top: 10px;
  width: 70px;
  height: 40px;
  font-size: 15px;
  margin-top: 3px;
  margin-left: 20px;
  cursor: pointer;
  background-color: #6495ed;
  text-align: center;
  border-radius: 3px;
  color: white;
`;

export const AppBox = styled.div`
  width: 100px;
  height: 130px;
  margin: 3px;
  padding: 0px;
`;

export const AppName = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;
