import { DASHBOARD } from '../types';

const INIT_STATE = {
  error: [],

  filterCountryList: [],
  filterRoomStatusList: ['vc', 'od', 'vd', 'oc', 'oo', 'ac'],

  previousRoomNo: null,
  currentRoomNo: null,
  currentRoomData: null,
  isShowOneRoomDetailsModal: false,
  isSynACControlModal: false,
  showIotModal: false,
  modalName: '',
};

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    //##---------------------- [FILTER] ------------------------------
    case DASHBOARD.CHANGE_FILTER_ROOM_STATUS_LIST:
      return {
        ...state,
        filterRoomStatusList: action.filterRoomStatusList,
      };

    case DASHBOARD.CHANGE_FILTER_COUNTRY_LIST:
      return {
        ...state,
        filterCountryList: action.filterCountryList,
      };

    //##----------------------- Selected Room No ---------------------------------
    case DASHBOARD.SELECTED_ROOM:
      return {
        ...state,
        currentRoomNo: action.roomNo,
        currentRoomData: action.roomData,
      };

    case DASHBOARD.RESET_SELECTED_ROOM:
      return {
        ...state,
        previousRoomNo: state.currentRoomNo,
        currentRoomNo: null,
        currentRoomData: null,
      };
    //##----------------------- Room details modal ---------------------------------
    case DASHBOARD.SHOW_ONE_ROOM_DETAILS_MODAL:
      return {
        ...state,
        isShowOneRoomDetailsModal: true,
      };

    case DASHBOARD.HIDE_ONE_ROOM_DETAILS_MODAL:
      return {
        ...state,
        isShowOneRoomDetailsModal: false,
      };

    //##----------------------- Room Iot modal ---------------------------------
    case DASHBOARD.SHOW_ONE_ROOM_IOT_MODAL:
      return {
        ...state,
        isShowOneRoomIotModal: true,
      };

    case DASHBOARD.HIDE_ONE_ROOM_IOT_MODAL:
      return {
        ...state,
        isShowOneRoomIotModal: false,
      };

    //##-----------------------Is conrtol IoT---------------------------------
    case DASHBOARD.IS_CONTROL_IOT:
      return {
        ...state,
        isControlIot: true,
      };

    case DASHBOARD.MOUSE_HOVER:
      return {
        ...state,
        mouse_hover: action.payload,
      };

    case DASHBOARD.SYN_DEIVIES_CONTROL:
      return {
        ...state,
        syn_devices_control: action.payload,
      };

    case DASHBOARD.SHOW_SYN_AC_CONTROL:
      return {
        ...state,
        isSynACControlModal: true,
      };

    case DASHBOARD.HIDE_SYN_AC_CONTROL:
      return {
        ...state,
        isSynACControlModal: false,
      };

    case DASHBOARD.SHOW_IOT_MODAL:
      return {
        ...state,
        showIotModal: action.payload,
      };

    case DASHBOARD.MODAL_NAME:
      return {
        ...state,
        modalName: action.payload,
      };
    //##------------------------------------------------------------------
    default:
      return state;
  }
};

export { dashboard };
