import React from 'react';
import styled from 'styled-components';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

const StyledRow = styled(Row)`
  && {
    margin: 0px;
    height: 27px;
  }
`;

const StyledCol = styled(Col)`
  && {
    margin: 0px;
    padding: 2px;
  }
`;

const Topic = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NewCardBody = styled(CardBody)`
  && {
    max-height: 230px;
    overflow: scroll;
    margin-top: -15px;
  }
`;

const StyledRowFooter = styled(Row)`
  && {
    margin: 0px;
    height: 27px;
    padding: 0px;
    border-top: 1px solid gray;
    position: relative;
    bottom: 0px;
    width: 100%;
  }
`;

const ColumnBody = styled(Col)`
  && {
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ColumnFooter = styled(Col)`
  && {
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const FooterWrapper = styled.div`
  margin: 0px;
  padding: 0px 15px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  box-shadow: 0px -10px 10px 0px rgb(255 255 255 / 80%);
`;

const UpdatedAt = styled.div`
  font-size: 9px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 4px 0px 0px 0px;
  background-color: #dddddd;
  padding-left: 3px;
`;

export const IoTDevices = (props) => {
  return (
    <>
      <Col lg="3" md="6">
        <Card>
          <div style={{ height: '300px' }}>
            <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
              <h5 className="card-title">{props.name}</h5>
            </CardHeader>
            <NewCardBody>
              <StyledRow>
                <StyledCol lg="6">
                  <Topic>
                    <strong>DEVICES</strong>
                  </Topic>
                </StyledCol>
                <StyledCol
                  lg="3"
                  style={{
                    textAlign: 'center',
                    padding: '0px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <strong>ON</strong>
                </StyledCol>
                <StyledCol
                  lg="3"
                  style={{
                    textAlign: 'center',
                    padding: '0px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <strong>OFF</strong>
                </StyledCol>
              </StyledRow>
              {Object.keys(props.devices)
                ? Object.keys(props.devices).map((device, index) => (
                    <StyledRow key={index}>
                      <StyledCol lg="6">
                        <Topic>{device}</Topic>
                      </StyledCol>
                      <ColumnBody lg="3">{props.devices[device]['on']}</ColumnBody>
                      <ColumnBody lg="3">{props.devices[device]['off']}</ColumnBody>
                    </StyledRow>
                  ))
                : null}
            </NewCardBody>
            <FooterWrapper>
              <StyledRowFooter>
                <StyledCol lg="6">
                  <Topic>
                    <strong>Total</strong>
                  </Topic>
                </StyledCol>
                <ColumnFooter lg="3">
                  <strong>{props.sumDeviceStatus.on}</strong>
                </ColumnFooter>
                <ColumnFooter lg="3">
                  <strong>{props.sumDeviceStatus.off}</strong>
                </ColumnFooter>
              </StyledRowFooter>
            </FooterWrapper>
          </div>
          <UpdatedAt>
            {'Updated at: '} {props.updated_at}
          </UpdatedAt>
        </Card>
      </Col>
    </>
  );
};
