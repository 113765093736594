import React from 'react';
import styled from 'styled-components';

const Scale = '/assets/images/powerscale/Frame.svg';
const Arrow = '/assets/images/powerscale/Indicator.svg';
// const UnionSvg = '/assets/images/powerscale/Union.svg';

const ArrowComponent = styled.img`
  position: absolute;
  margin-top: -1.3em;
  margin-left: ${(prop) => prop.positionArrow}em;
`;

const TextScale = styled.div`
  position: absolute;
  margin-top: 1.5em;
  font-size: 12px;
  font-weight: bold;
`;

const Union = styled.img`
  position: absolute;
  margin-top: -5em;
  margin-left: ${(prop) => prop.unionPosition - 0.2}em;
`;

const UnionLabel = styled.div`
  position: absolute;
  margin-top: -6.4em;
  font-size: 10px;
  margin-left: ${(prop) => prop.labelPosition}em;
`;

const UnionPercent = styled.div`
  position: absolute;
  margin-top: -3.2em;
  font-size: 16px;
  color: #089953;
  font-weight: bold;
  margin-left: ${(prop) => prop.labelPercent}em;
`;

const BuildingPerformance = ({ data }) => {
  /* console.log(data) */
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1em',
      }}
    >
      <ArrowComponent src={Arrow} positionArrow={-5.7} />
      {/* <Union src={UnionSvg} alt="union" unionPosition={21.5} /> */}
      {/* <UnionLabel labelPosition={34}>Excellent</UnionLabel>
      <UnionPercent labelPercent={21.5}>90%</UnionPercent> */}

      <img src={Scale} alt="scale" width="80%" />

      <TextScale style={{ marginLeft: '-22.5em' }}>Poor</TextScale>
      <TextScale style={{ marginLeft: '-7em' }}>Fair</TextScale>
      <TextScale style={{ marginLeft: '8em' }}>Good</TextScale>
      <TextScale style={{ marginLeft: '23em' }}>Excellent</TextScale>
    </div>
  );
};

export { BuildingPerformance };
