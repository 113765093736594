import { HOTEL } from '../types';

const INIT_STATE = {
  error: [],
  hotelFloorPlanMapData: null,
  devicesError: [],
  allDevices: null,
  activitiesError: [],
  allActivities: null,
  temp: [],
  humid: [],
  // time: [],
  dropdown: 0,
  ac: {
    room_temp: [],
    temp: [],
    mode: [],
  },
  // ac: {},
};

const hotel = (state = INIT_STATE, action) => {
  switch (action.type) {
    case HOTEL.GET_MAP_DATA_REQUEST:
      return {
        ...state,
        error: [],
      };
    case HOTEL.GET_MAP_DATA_SUCCESS:
      return {
        ...state,
        error: [],
        hotelFloorPlanMapData: action.data,
      };
    case HOTEL.GET_MAP_DATA_FAILURE:
      return {
        ...state,
        error: [...state.error, action.error],
      };

    case HOTEL.GET_ALL_DEVICES_REQUEST:
      return {
        ...state,
        devicesError: [],
      };
    case HOTEL.GET_ALL_DEVICES_SUCCESS:
      return {
        ...state,
        devicesError: [],
        allDevices: action.data,
      };
    case HOTEL.GET_ALL_DEVICES_FAILURE:
      return {
        ...state,
        devicesError: [...state.devicesError, action.error],
      };
    case HOTEL.GET_ALL_ACTIVITIES_REQUEST:
      return {
        ...state,
        activitiesError: [],
      };
    case HOTEL.GET_ALL_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activitiesError: [],
        allActivities: action.data,
      };
    case HOTEL.GET_ALL_ACTIVITIES_FAILURE:
      return {
        ...state,
        activitiesError: [...state.activitiesError, action.error],
      };

    case HOTEL.PLOT_FIREBASE:
      return {
        ...state,
        temp: [...state.temp, action.payload.temp],
        humid: [...state.humid, action.payload.humid],
        // time: [...state.time, action.payload.time],
      };

    case HOTEL.CLEAR_PLOT:
      return {
        ...state,
        temp: [],
        humid: [],
        ac: {
          room_temp: [],
          temp: [],
          mode: [],
        },
      };

    case HOTEL.DROP_DOWN_ACCTIVITY:
      return {
        ...state,
        dropdown: action.payload.dropdown,
      };

    case HOTEL.AC_ACTIVITY_PLOT:
      return {
        ...state,
        ac: {
          room_temp: [...state.ac.room_temp, action.payload.ac.room_temp],
          temp: [...state.ac.temp, action.payload.ac.temp],
          mode: [...state.ac.mode, action.payload.ac.mode],
        },
      };
    //##------------------------------------------------------------------
    default:
      return state;
  }
};

export { hotel };
