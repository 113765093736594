import React, { useState, useMemo, useRef, useContext, useEffect } from 'react';
import { Menu } from './Tabs/Menu';
import { Row, Col } from 'react-bootstrap';
import { TDPK_API } from 'api/services';
import { FirebaseContext2 } from '../../../../../../firebase';

import { Historicalcomponents } from 'components/Historical/History.components';
import { roomActivitiesHistories } from 'api/services/getActivitiesHistories.services';
import { tdpkActions } from '../../actions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Info, ActivityData } from 'features/hotel/components/Tabs';
import { hotelActions } from 'features/hotel/actions';

export const Ac = (props) => {
  const dispatch = useDispatch();
  const acPlotRealTime = useSelector((state) => state.tdpk.ac);

  //##---------------------Constant-------------------------------
  const timeoutid = useRef();
  const firebase = useContext(FirebaseContext2);

  const [mode, setMode] = useState(null);
  const [fan, setFan] = useState(null);
  const [tempValue, setTempValue] = useState(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [fanMode, setFanMode] = useState(null);
  const [temp, setTemp] = useState(null);
  const [envTemp, setEnvTemp] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);

  const [currentTab, setCurrentTab] = useState('control');
  const [stats, setStats] = useState();
  const [header, setHeader] = useState([]);
  const [detail, setDetail] = useState([]);
  const [subDevices, setSubDevices] = useState([]);

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;

  //##-----------------DatePicker---------------------------
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

  const [tmpHist, settmpHist] = useState(null);
  // const [plotMode, setPlotMode] = useState([]);
  const [plotRoomTemp, setPlotRoomTemp] = useState([]);
  const [plotTemp, setPlotTemp] = useState([]);

  const d = new Date();

  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  async function fetchData(firebase, device_id, didMounted) {
    let main_path =
      'publicspace/tdpk/pegasus/lobby_61/iot_devices/ac_TDPK_' + device_id + '/ac/subdev_0/';
    let stats_path =
      'publicspace/tdpk/pegasus/lobby_61/iot_devices/ac_TDPK_' + device_id + '/ac/stats/set_temp';
    const room_temp = main_path + 'room_temperature';
    const set_temp = main_path + 'temperature';
    const mode = main_path + 'mode';
    const fan = main_path + 'fan';
    const timestamp = main_path + 'timestamp';

    if (didMounted) {
      console.log('TDPK AC did mounted');
      await firebase.db.ref(room_temp).off('value');
      await firebase.db.ref(set_temp).off('value');
      await firebase.db.ref(mode).off('value');
      await firebase.db.ref(fan).off('value');
      await firebase.db.ref(timestamp).off('value');
    } else {
      console.log('Begin to initial data');
      await firebase.db.ref(mode).on('value', function (snap) {
        let capt = snap.val();
        setMode(capt);
      });
      await firebase.db.ref(fan).on('value', function (snap) {
        let capt = snap.val();
        setFan(capt);
      });
      await firebase.db.ref(set_temp).on('value', function (snap) {
        let capt = snap.val();
        setTempValue(capt);
      });
      await firebase.db.ref(room_temp).on('value', function (snap) {
        let capt = snap.val();
        setEnvTemp(capt);
      });
      await firebase.db.ref(timestamp).on('value', function (snap) {
        let capt = snap.val();
        setTimeStamp(capt);
      });
      await firebase.db.ref(stats_path).on('value', function (snap) {
        if (snap) {
          let capt = snap.val();
          setStats(capt);
        }
      });
    }
  }

  //##------------------------Fetch History data--------------------------
  async function histData(dev_id, nick_name) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        '6C1',
        dev_id,
        0,
        nick_name,
        false,
        startDate,
        stopDate
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  function initState(mode, fan, temp) {
    setCurrentMode(mode);
    setFanMode(fan);
    setTemp(temp);
  }

  //##----------------------------Fetch Firebase--------------------------
  useMemo(() => {
    fetchData(firebase, props.airNum, false);
    return () => fetchData(firebase, props.airNum, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(props.airNum);

  //##---------------------Fetch History Data-------------------
  useMemo(() => {
    // devices.forEach((element) => {

    try {
      histData(`ac_TDPK_${props.airNum}`, 'ac').then((results) => {
        return settmpHist(results);
      });
    } catch {
      console.log('Fetch error');
    }

    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, stopDate, props.airNum]);

  // console.log(tmpHist);

  //##------------------------Initial Button state------------------------
  useMemo(() => {
    initState(mode, fan, tempValue);
  }, [mode, fan, tempValue]);

  useEffect(() => {
    if (devices) {
      devices.forEach((element) => {
        if (element['device_id'] === 'ac_TDPK_' + props.airNum) {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);
          if (element.subdevices) {
            setSubDevices(Object.values(element.subdevices).map((object) => object.nickname));
          }
          try {
            dispatch(
              hotelActions.getAllActivities(
                '6C1',
                element.device_id,
                0,
                element.device_name,
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [devices]);

  //##------------------------Mode---------------------------------------
  const onClickControlMode = (e, type) => {
    e.preventDefault();
    setCurrentMode(type);
    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(
        () => TDPK_API.tdpkAirAPI(props.airNum, temp, type, fanMode),
        1000
      );
    }

    return () => clearTimeout(timeoutid.current);
  };

  //##------------------------Fan----------------------------------------
  const onClickFanMode = (e, type) => {
    e.preventDefault();
    setFanMode(type);
    clearTimeout(timeoutid.current);
    if (currentMode !== null && fanMode !== null && temp !== null) {
      timeoutid.current = setTimeout(
        () => TDPK_API.tdpkAirAPI(props.airNum, temp, currentMode, type),
        1000
      );
    }

    return () => clearTimeout(timeoutid.current);
  };

  //##-----------------------Set temp-----------------------------------
  const onSettemp = (e, type) => {
    e.preventDefault();
    switch (type) {
      case 'increment':
        setTemp(temp + 0.5);
        clearTimeout(timeoutid.current);
        if (currentMode !== null && fanMode !== null && temp !== null) {
          timeoutid.current = setTimeout(
            () => TDPK_API.tdpkAirAPI(props.airNum, temp + 0.5, currentMode, fanMode),
            1000
          );
        }

        return () => clearTimeout(timeoutid.current);

      case 'decrement':
        setTemp(temp - 0.5);
        clearTimeout(timeoutid.current);
        if (currentMode !== null && fanMode !== null && temp !== null) {
          timeoutid.current = setTimeout(
            () => TDPK_API.tdpkAirAPI(props.airNum, temp - 0.5, currentMode, fanMode),
            1000
          );
        }
        return () => clearTimeout(timeoutid.current);
      default:
        return temp;
    }
  };

  //##-------------------History Data for plotting-------------
  useMemo(() => {
    if (tmpHist !== null) {
      let temp = [];
      let room_temp = [];
      // let mode = [];
      tmpHist.forEach((item) => {
        if (
          item.temperature !== undefined &&
          item.timestamp !== undefined &&
          item.room_temperature !== undefined
          // item.mode !== undefined &&
          // item.fan !== undefined
        ) {
          temp.push([Number(moment(item.timestamp).format('x')), item.temperature]);
          room_temp.push([Number(moment(item.timestamp).format('x')), item.room_temperature]);
          // mode.push([Number(moment(item.timestamp).format('x')), item.mode === 'off' ? 0 : 1]);
          // fan.push([Number(moment(item.timestamp).format('x')), item.temperature]);
        }
        setPlotTemp(temp);
        // setPlotMode(mode);
        setPlotRoomTemp(room_temp);
        // setPlotHumi(humi);
      });
    }
  }, [tmpHist]);

  //##-------------------Dispath plot Data-----------------------
  useMemo(() => {
    if (currentMode !== null && envTemp !== null && temp !== null && timeStamp !== null) {
      dispatch(
        tdpkActions.acPlotHistory({
          ac: {
            room_temp: [Number(moment(timeStamp).format('x')), envTemp],
            temp: [Number(moment(timeStamp).format('x')), temp],
            mode: [Number(moment(timeStamp).format('x')), currentMode === 'off' ? 0 : 1],
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMode, envTemp, temp, timeStamp]);

  //##----------------------Plot Data---------------------------
  const Temp_plot = {
    series: [
      {
        name: 'Room Temperature',
        // data: Temp !== undefined ? plotTemp.concat(Temp) : [],

        data: plotRoomTemp.concat(acPlotRealTime.room_temp),
        // data: [],
        // data: acPlotRealTime.room_temp,
      },
      {
        name: 'Set Temperature',

        data: plotTemp.concat(acPlotRealTime.temp),
        // data: [],
        // data: acPlotRealTime.temp,
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      title: {
        text: 'Temperature',
        align: 'left',
      },
      yaxis: [
        {
          seriesName: 'Room Temperature',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              color: '#008FFB',
            },
          },
          title: {
            text: '°C',
            style: {
              color: '#008FFB',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      stroke: {
        // OR provide an array
        width: 2,
        curve: ['smooth', 'smooth'],
      },
    },
  };
  const mode_plot = {
    series: [
      {
        name: 'Mode',
        // data: plotMode.concat(acPlotRealTime.mode),
        data: acPlotRealTime.mode,
        // data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      title: {
        text: 'Mode (on/off)',
        align: 'left',
      },
      yaxis: [
        {
          tickAmount: 1,
          labels: {
            formatter: function (value) {
              return value === 1 ? 'ON' : 'OFF';
            },
          },
          seriesName: 'Mode',
          title: {
            text: 'Mode (on/off)',
            style: {
              color: '#FEB019',
            },
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      stroke: {
        // OR provide an array
        width: 2,
        curve: ['stepline'],
      },
    },
  };

  return (
    <div>
      <Menu currentTab={(e) => setCurrentTab(e)} />

      {currentTab === 'control' && (
        <>
          <div className="columns">
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Status
              </div>
              <div className="column">
                <img
                  alt="remote"
                  id="iot-device-icon"
                  src="https://www.carrier.co.th/products/carrier/images/40vct.jpg"
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              </div>
              <div className="column" style={{ textAlign: 'center' }}>
                {currentMode === 'off' ? (
                  <h1 style={{ color: 'gray' }}>OFF</h1>
                ) : (
                  <h1 style={{ color: 'green' }}>ON</h1>
                )}
              </div>
            </div>
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              {currentMode === 'fan' ? (
                <>
                  <div
                    className="column"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Fan Mode
                  </div>
                  <br />
                  <br /> <p style={{ textAlign: 'center' }}>Can not control temperature</p>{' '}
                </>
              ) : (
                <>
                  {' '}
                  <div
                    className="column"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Set Temp
                  </div>
                  {/* increte button */}
                  {currentMode === 'off' ? (
                    ''
                  ) : (
                    <button
                      className="button is-info"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0px',
                      }}
                      // onClick={() => setTemp(temp + 1)}
                      onClick={(e) => onSettemp(e, 'increment')}
                    >
                      <i
                        className="fa fa-caret-up"
                        style={{
                          color: 'white',
                          fontSize: '44px',
                          lineHeight: '40px',
                        }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  <div className="column">
                    <span
                      style={{
                        fontSize: '44px',
                        color: '#0af',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* {status.air_con.set_temp } */}
                      {currentMode === 'off' ? '' : parseFloat(temp).toFixed(1)}
                    </span>{' '}
                    <span
                      style={{
                        fontSize: '26px',
                        color: '#aaa',
                      }}
                    >
                      {currentMode === 'off' ? '' : 'ºC'}
                    </span>
                  </div>
                  {/* decrete button */}
                  {currentMode === 'off' ? (
                    ''
                  ) : (
                    <button
                      className="button is-info"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0px',
                      }}
                      // onClick={() => setTemp(temp - 1)}
                      onClick={(e) => onSettemp(e, 'decrement')}
                    >
                      <i
                        className="fa fa-caret-down"
                        style={{
                          color: 'white',
                          fontSize: '44px',
                          lineHeight: '40px',
                        }}
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </>
              )}
            </div>

            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Room Temp
              </div>
              <div
                className="column"
                style={{
                  marginTop: '40px',
                }}
              >
                <span
                  style={{
                    fontSize: '46px',
                    color: '#0af',
                    fontWeight: 'bold',
                  }}
                >
                  {envTemp}
                </span>{' '}
                <span
                  style={{
                    fontSize: '26px',
                    color: '#aaa',
                  }}
                >
                  ºC
                </span>
              </div>
            </div>
          </div>
          <div className="columns">
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Mode Control
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    <button
                      value="off"
                      // className={status.air_con.mode === 'off' ? 'button is-info' : 'button'}
                      className={currentMode === 'off' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'off')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Off
                    </button>
                    <button
                      value="cool"
                      className={currentMode === 'cool' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'cool')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Cool
                    </button>

                    <button
                      value="dry"
                      className={currentMode === 'dry' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'dry')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Dry
                    </button>
                    <button
                      value="fan"
                      className={currentMode === 'fan' ? 'button is-info' : 'button'}
                      onClick={(e) => onClickControlMode(e, 'fan')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '70px',
                        font: '15px',
                      }}
                    >
                      Fan
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Fan Speed
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    {currentMode === 'fan' ? (
                      ''
                    ) : (
                      <button
                        value="auto"
                        className={
                          currentMode === 'off'
                            ? 'button'
                            : fanMode === 'auto'
                            ? 'button is-info'
                            : 'button'
                        }
                        onClick={(e) => onClickFanMode(e, 'auto')}
                        style={{
                          margin: '10px 1px 20px 1px',

                          boxSizing: 'border-box',
                          width: '65px',
                          font: '15px',
                        }}
                      >
                        Auto
                      </button>
                    )}
                    <button
                      value="low"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'low'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'low')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '65px',
                        font: '15px',
                      }}
                    >
                      Low
                    </button>

                    <button
                      value="medium"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'medium'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'medium')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '85px',
                        font: '15px',
                      }}
                    >
                      Medium
                    </button>
                    <button
                      value="high"
                      className={
                        currentMode === 'off'
                          ? 'button'
                          : fanMode === 'high'
                          ? 'button is-info'
                          : 'button'
                      }
                      onClick={(e) => onClickFanMode(e, 'high')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '65px',
                        font: '15px',
                      }}
                    >
                      High
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
          </div>
        </>
      )}
      {currentTab === 'info' && (
        <>
          <div>
            <Info
              image={
                'https://firebasestorage.googleapis.com/v0/b/altohotel-b6ae5.appspot.com/o/AltoLogo%2Fdaikin-logo.jpeg?alt=media&token=a8ab35d9-3db5-403a-b303-597e28c8d92e'
              }
              detail={detail}
              header={header}
            />
          </div>
        </>
      )}
      {currentTab === 'activity' && (
        <>
          <ActivityData subDevices={subDevices} alternative={subDevices} />
        </>
      )}
      {currentTab === 'history' && (
        <>
          <Historicalcomponents
            data={Temp_plot}
            mode_plot={mode_plot}
            startDate={startDate}
            stopDate={stopDate}
            handleStartDate={handleStartDate}
            handleStopDate={handleStopDate}
          />
        </>
      )}
      {currentTab === 'self' && (
        <>
          <div className="columns">
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
                position: 'relative',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                MIN
              </div>
              <div
                className="column"
                style={{
                  marginTop: '10px',
                }}
              >
                <span
                  style={{
                    fontSize: '46px',
                    color: '#0af',
                    fontWeight: 'bold',
                  }}
                >
                  {stats ? stats.min : 'Loading...'}
                </span>{' '}
                <span
                  style={{
                    fontSize: '26px',
                    color: '#aaa',
                  }}
                >
                  ºC
                </span>
              </div>
              <div
                style={{
                  position: 'absolute',
                  height: '14px',

                  bottom: '2px',
                  right: '2px',
                  borderRadius: '5px',
                  fontSize: '10px',
                  backgroundColor: '#e6e6e6',
                  padding: '0px 3px',
                }}
              >
                UPDATED AT {stats.updated_at}
              </div>
            </div>
            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
                position: 'relative',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                MAX
              </div>
              <div
                className="column"
                style={{
                  marginTop: '10px',
                }}
              >
                <span
                  style={{
                    fontSize: '46px',
                    color: '#0af',
                    fontWeight: 'bold',
                  }}
                >
                  {stats ? stats.max : 'Loading...'}
                </span>{' '}
                <span
                  style={{
                    fontSize: '26px',
                    color: '#aaa',
                  }}
                >
                  ºC
                </span>
                <div
                  style={{
                    position: 'absolute',
                    height: '14px',

                    bottom: '2px',
                    right: '2px',
                    borderRadius: '5px',
                    fontSize: '10px',
                    backgroundColor: '#e6e6e6',
                    padding: '0px 3px',
                  }}
                >
                  UPDATED AT {stats.updated_at}
                </div>
              </div>
            </div>

            <div
              className="column"
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
                position: 'relative',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                MEAN
              </div>
              <div
                className="column"
                style={{
                  marginTop: '10px',
                }}
              >
                <span
                  style={{
                    fontSize: '46px',
                    color: '#0af',
                    fontWeight: 'bold',
                  }}
                >
                  {stats ? stats.mean.toFixed(1) : 'Loading...'}
                </span>{' '}
                <span
                  style={{
                    fontSize: '26px',
                    color: '#aaa',
                  }}
                >
                  ºC
                </span>
                <div
                  style={{
                    position: 'absolute',
                    height: '14px',

                    bottom: '2px',
                    right: '2px',
                    borderRadius: '5px',
                    fontSize: '10px',
                    backgroundColor: '#e6e6e6',
                    padding: '0px 3px',
                  }}
                >
                  UPDATED AT {stats.updated_at}
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <Col
              style={{
                border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Self-driving AC
              </div>
              <div className="columns">
                <div className="column">
                  <Row style={{ justifyContent: 'center' }}>
                    <button
                      value="off"
                      // className={status.air_con.mode === 'off' ? 'button is-info' : 'button'}
                      className={currentMode === 'off' ? 'button is-info' : 'button'}
                      onClick={(e) => alert('Stop')}
                      style={{
                        margin: '10px 1px 20px 1px',

                        boxSizing: 'border-box',
                        width: '200px',
                        font: '15px',
                        backgroundColor: 'tomato',
                        color: 'white',
                        fontWeight: '600',
                      }}
                    >
                      STOP
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
          </div>
        </>
      )}
    </div>
  );
};
