import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MockUpRoomServiceTabComponent } from '../components/MockUpRoomServiceTab.components';
import { MockUpRoomRepairServiceTabComponent } from '../components/MockUpRoomRepairServiceTab.components';
import { List } from '../../../components/List/TabList';
import { GuestInfoTab } from './GuestInfoTab.containers';
import { IotTab } from './IotTab.containers';
import { dashboardControlActions } from '../../dashboard/actions';
import { FirebaseContext2 } from '../../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { iotControlServices } from '../../../api/services';

function HotelRoomDetailsModal() {
  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext2);
  const currentRoomData = useSelector((state) => state.dashboard.currentRoomData);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const [currentTab, setCurrentTab] = useState('info');
  const [cleanstatus, setCleanstatus] = useState(null);
  const [occupiedStatus, setOccupiedStatus] = useState(null);
  const [guestType, setGuestType] = useState('daily');
  const [deviceStatus, setDeviceStatus] = useState({
    6: [false],
    1: [false, false, false],
    2: [false, false, false],
    3: [false],
  });

  const firebase_url = '/hotel/mintel/building main/room_' + currentRoomNo + '/iot_devices';

  let DevicesUrl = {
    6: [firebase_url + '/ac_' + currentRoomNo + '/ac/ac/mode'],
    1: [
      firebase_url + '/back_switch/relay/bed_switch/state',
      firebase_url + '/back_switch/relay/wardrobe_switch/state',
      firebase_url + '/back_switch/relay/window_switch/state',
    ],
    2: [
      firebase_url + '/front_switch/relay/corridor_switch/state',
      firebase_url + '/front_switch/relay/main_switch/state',
      firebase_url + '/front_switch/relay/toilet_switch/state',
    ],
    3: [firebase_url + '/exfan/relay/fan/state'],
  };

  const fetchDevicesState = () => {
    Object.keys(DevicesUrl).forEach((location) => {
      let state = deviceStatus;
      DevicesUrl[location].forEach((url, index) => {
        firebase.db.ref(url).on('value', function (snap) {
          let capt = snap.val();
          if (capt !== 'off') {
            state[location][index] = true;
            setDeviceStatus((oldState) => {
              if (oldState !== state) {
                return state;
              }
              return oldState;
            });
          } else {
            state[location][index] = false;
            setDeviceStatus((oldState) => {
              if (oldState !== state) {
                return state;
              }
              return oldState;
            });
          }
        });
      });
    });
  };

  useMemo(() => {
    const main_path = `hotel/mintel/user_info/room_status/${currentRoomData['room_number']}/clean_status`;
    firebase.db.ref(main_path).on('value', function (snap) {
      const capt = snap.val();
      setCleanstatus(capt);
    });
    firebase.db.ref(`hotel/mintel/user_info/room_status/${currentRoomData['room_number']}/occupancy_status`).on("value", function (snap) {
      const capt = snap.val();
      setOccupiedStatus(capt);
    })
    // eslint-disable-next-line
  }, [cleanstatus, occupiedStatus]);

  useEffect(() => {
    let fb_path = '/hotel/mintel/user_info/room_status/' + currentRoomNo + '/guest_type';
    firebase.db.ref(fb_path).on('value', function (snap) {
      let capt = snap.val();
      if (capt) {
        setGuestType(capt);
      }
    });

    fetchDevicesState();

    return () => {
      firebase.db.ref(fb_path).off('value');
      Object.keys(DevicesUrl).forEach((urls) => {
        DevicesUrl[urls].forEach((url) => {
          firebase.db.ref(url).off('value');
        });
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = (event) => {
    event.preventDefault();
    // Close Hotel Room Details Modal
    dispatch(dashboardControlActions.resetSelectedRoom());
    dispatch(dashboardControlActions.hideOneRoomDetailsModal());
  };

  const onClickMenu = (event, tab) => {
    event.preventDefault();
    setCurrentTab(tab);
  };

  const changeRoomStatus = () => {
    const payload = {
      topic: 'devicecontrol',
      action: {
        [currentRoomNo]: {
          'Air Conditioner': {
            command: {
              mode: 'off',
              fan: 'medium',
              temperature: 25,
            },
          },
          'Window Switch': {
            command: {
              state: 'off',
            },
          },
        },
      },
      RequestId: '12345',
    };
    let fb_path = '/hotel/mintel/user_info/room_status/' + currentRoomNo;
    if (guestType === 'daily') {
      if (window.confirm('Are you sure to change room status to "MONTHLY"')) {
        if (guestType === 'daily') {
          firebase.db.ref(fb_path).update({ guest_type: 'monthly' });
          iotControlServices.postIotApi(payload);
          setGuestType('monthly');
        } else {
          firebase.db.ref(fb_path).update({ guest_type: 'daily' });
          setGuestType('daily');
        }
      }
    } else if (guestType === 'monthly') {
      if (window.confirm('Are you sure to change room status to "DAILY"')) {
        if (guestType === 'daily') {
          firebase.db.ref(fb_path).update({ guest_type: 'monthly' });
          iotControlServices.postIotApi(payload);
          setGuestType('monthly');
        } else {
          firebase.db.ref(fb_path).update({ guest_type: 'daily' });
          setGuestType('daily');
        }
      }
    }
  };

  //##------------------------- Render -------------------------
  return (
    <div className="modal is-active" id="room-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-general"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              {`Room ${currentRoomData['room_number']}`}
            </p>
          </div>
          <button
            id="change-room-button"
            className="button is-pulled-left is-info"
            style={{
              marginLeft: 'auto',
              marginTop: '1rem',
              marginBottom: '1rem',
              fontSize: '1.2rem',
              padding: '0px 5px 0px 5px',
              marginRight: '20px',
              height: '36px',
              width: '150px',
              paddingLeft: '15px',
              paddingRight: '15px',
              textDecoration: 'bold',
              color: 'white',
              backgroundColor: '#6391db',
              textTransform: 'uppercase',
            }}
            onClick={() => changeRoomStatus()}
          >
            {guestType}
            <a
              href="#edit-guest-id"
              onClick={() => changeRoomStatus()}
              style={{ marginLeft: '10px', color: 'white' }}
            >
              <FontAwesomeIcon title="guest-id" icon={faEdit} className="edit-icon" />
            </a>
          </button>
          <div
            className={
              cleanstatus !== null &&
              (cleanstatus === 'vc' || cleanstatus === 'vd' || cleanstatus === 'oo'
                ? 'alert alert-success'
                : (occupiedStatus == "True") ? 'alert alert-success' : 'alert alert-dark')
            }
            style={{
              marginLeft: 'auto',
              marginTop: '1rem',
              fontSize: '1.2rem',
              height: '36px',
              padding: '0px 15px 0px 15px',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              textDecoration: 'bold',
            }}
          >
            {cleanstatus !== null &&
              (cleanstatus === 'vc' || cleanstatus === 'vd' || cleanstatus === 'oo' ? (
                'AVAILABLE'
              ) :
                (occupiedStatus == "True") ? <strong style={{ color: 'white' }}>OCCUPIED</strong> : (
                  <strong style={{ color: 'white' }}>UNOCCUPIED</strong>
                ))}
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          <div className="columns">
            <div className="column is-12">
              {currentRoomData['clean_status'] && (
                <div id="modal-menu">
                  <nav className="panel" style={{ boxShadow: 'none' }}>
                    <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '0' }}>
                      <ul style={{ margin: '20px' }}>
                        <List
                          isSelected={currentTab === 'info'}
                          onClick={(e) => onClickMenu(e, 'info')}
                        >
                          INFO
                        </List>
                        <List isSelected={currentTab === 'room-service'} disabled={true}>
                          ROOM SERVICE
                        </List>
                        <List isSelected={currentTab === 'repair'} disabled={true}>
                          MAINTENANCE
                        </List>
                        <List
                          isSelected={currentTab === 'IOT'}
                          onClick={(e) => onClickMenu(e, 'IOT')}
                        >
                          IOT
                        </List>
                      </ul>
                    </div>
                    {currentTab === 'info' && <GuestInfoTab />}
                    {currentTab === 'room-service' && <MockUpRoomServiceTabComponent />}
                    {currentTab === 'repair' && <MockUpRoomRepairServiceTabComponent />}
                    {currentTab === 'IOT' && (
                      <IotTab deviceStatus={deviceStatus} fetchDevicesState={fetchDevicesState} />
                    )}
                  </nav>
                </div>
              )}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              CLOSE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export { HotelRoomDetailsModal };
