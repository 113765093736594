import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Bar, Line } from 'react-chartjs-2';
// import { ComingSoon } from '../components/StyledComponents/StyledComponents.components';
import Chart from 'react-apexcharts';

const HeatmapChart = {
  options: {
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: -30,
              to: 5,
              color: '#AEF3E7',
              name: 'low',
            },
            {
              from: 6,
              to: 20,
              color: '#37718E',
              name: 'medium',
            },
            {
              from: 21,
              to: 45,
              color: '#C33C54',
              name: 'high',
            },
          ],
        },
      },
    },
  },
};

const Analytics = () => {
  const RandomNumber = () => {
    var number = Math.floor(Math.random() * 100) + 1;
    return number;
  };

  const RandomHeatmapNumber = () => {
    var number = Math.floor(Math.random() * 40) + 1;
    return number;
  };

  let randomScalingFactor = function () {
    return Math.ceil(Math.random() * 10.0) * Math.pow(10, Math.ceil(Math.random() * 5));
  };

  let deviceTypes = [
    'Device1',
    'Device2',
    'Device3',
    'Device4',
    'Device5',
    'Device6',
    'Device7',
    'Device8',
    'Device9',
    'Device10',
    'Device11',
    'Device12',
  ];
  let weeks = [
    'W1',
    'W2',
    'W3',
    'W4',
    'W5',
    'W6',
    'W7',
    'W8',
    'W9',
    'W10',
    'W11',
    'W12',
    'W13',
    'W14',
    'W15',
    'W16',
    'W17',
    'W18',
    'W19',
    'W20',
    'W21',
    'W22',
    'W23',
    'W24',
    'W25',
    'W26',
    'W27',
    'W28',
    'W29',
    'W30',
    'W31',
  ];

  let heatmapData = deviceTypes.map((type) => {
    return {
      name: type,
      data: weeks.map((week) => {
        return { x: week, y: RandomHeatmapNumber() };
      }),
    };
  });

  let lineChartData1 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Consumption',
        backgroundColor: '#FFFFFF',
        borderColor: '#37718E',
        fill: false,
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
      },
      {
        label: 'Solar',
        backgroundColor: '#FFFFFF',
        borderColor: '#AEF3E7',
        fill: false,
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
      },
    ],
  };

  let lineChartData2 = {
    labels: ['July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Consumption',
        backgroundColor: '#FFFFFF',
        borderColor: '#37718E',
        fill: false,
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
      },
      {
        label: 'Solar',
        backgroundColor: '#FFFFFF',
        borderColor: '#AEF3E7',
        fill: false,
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
      },
    ],
  };

  let lineOptions = {
    responsive: true,
    title: {
      display: true,
      text: 'Electricity consumption vs Solor Power Generation',
    },
    scales: {
      xAxes: [
        {
          display: true,
        },
      ],
      yAxes: [
        {
          display: true,
          type: 'logarithmic',
        },
      ],
    },
  };

  let barChartData = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'December',
    ],
    datasets: [
      {
        label: 'Cost',
        backgroundColor: '#C33C54',
        borderColor: '#FFFFFF',
        borderWidth: 1,
        data: [
          [0, RandomNumber()],
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
        ],
      },
      {
        label: 'Saving',
        backgroundColor: '#254E70',
        borderColor: '#FFFFFF',
        borderWidth: 1,
        data: [
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
          [0, RandomNumber()],
          [0, -RandomNumber()],
        ],
      },
      {
        label: 'Solar',
        backgroundColor: '#AEF3E7',
        borderColor: '#FFFFFF',
        borderWidth: 1,
        data: [
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
          [0, RandomNumber()],
        ],
      },
    ],
  };
  return (
    <div style={{ padding: '80px 30px 50px 30px' }}>
      <Row>
        <Col lg="12" md="12" sm="12">
          <h1 style={{ margin: '15px 0px 20px 0px', fontSize: '2em', fontWeight: 700 }}>
            Analytics
          </h1>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" sm="12">
          <Row style={{ height: '600px' }}>
            <Col lg="8" md="12" sm="12">
              <Card disabled={true} style={{ height: '96%', padding: '10px' }}>
                <Bar
                  data={barChartData}
                  width={100}
                  height={20}
                  options={{ maintainAspectRatio: false }}
                />
              </Card>
            </Col>
            <Col lg="4" md="12" sm="12">
              <Card disabled={true} style={{ height: '96%', padding: '10px' }}>
                <Row>
                  <Col sm="12">
                    <Line data={lineChartData1} width={50} height={40} options={lineOptions} />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Line data={lineChartData2} width={50} height={40} options={lineOptions} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <Card disabled={true} style={{ height: '96%', padding: '10px' }}>
                <Chart
                  options={HeatmapChart.options}
                  series={heatmapData}
                  type="heatmap"
                  height={450}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row style={{ marginTop: '15px' }}>
        <Col lg="12" md="12" sm="12">
          <Row style={{ height: '250px' }}>
            <Col lg="4" md="6" sm="12">
              <Card disabled={true} style={{ height: '96%' }}>
                <Line data={lineChartData} width={50} height={40} options={lineOptions} />
              </Card>
            </Col>
            <Col lg="4" md="6" sm="12">
              <Card disabled={true} style={{ height: '96%' }}></Card>
            </Col>
            <Col lg="4" md="12" sm="12">
              <Card disabled={true} style={{ height: '96%' }}></Card>
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* <ComingSoon>Coming soon...</ComingSoon>
      <div
        style={{
          position: 'absolute',
          top: '73px',
          left: '0px',
          width: '100%',
          height: 'calc( 100vh - 73px )',
          backgroundColor: '#00000040',
        }}
      /> */}
    </div>
  );
};

export { Analytics };
