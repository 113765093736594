import React from 'react';
import styled from 'styled-components';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

const StyledRow = styled(Row)`
  && {
    margin: 0px;
    height: 27px;
    padding: 0px;
  }
`;

const StyledRowFooter = styled(Row)`
  && {
    margin: 0px;
    height: 27px;
    padding: 0px;
    border-top: 1px solid gray;
    position: relative;
    bottom: 0px;
    width: 100%;
  }
`;

const StyledCol = styled(Col)`
  && {
    margin: 0px;
    padding: 0px;
  }
`;

const Topic = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FooterWrapper = styled.div`
  margin: 0px;
  padding: 0px 15px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  box-shadow: 0px -10px 10px 0px rgb(255 255 255 / 80%);
`;

const ColumnBody = styled(Col)`
  && {
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ColumnFooter = styled(Col)`
  && {
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const UpdatedAt = styled.div`
  font-size: 9px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 4px 0px 0px 0px;
  background-color: #dddddd;
  padding-left: 3px;
`;

export const EnergyConsumption = (props) => {
  return (
    <>
      <Col lg="3" md="6">
        <Card style={{ position: 'relative' }}>
          <div style={{ height: '300px' }}>
            <CardHeader style={{ boxShadow: '0 0.125em 0.25em white' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h5 className="card-title">{props.name}</h5>
                <p style={{ fontSize: 13, marginBottom: '8px', marginTop: '-10px' }}>
                  Performance: &nbsp;{' '}
                  <strong style={{ color: '#32bcad', fontSize: '23px' }}>GOOD</strong>{' '}
                </p>
              </div>
            </CardHeader>
            <CardBody style={{ paddingTop: '0px' }}>
              <StyledRow>
                <StyledCol lg="4">
                  <Topic>
                    <strong>TYPE</strong>
                  </Topic>
                </StyledCol>

                <StyledCol lg="4" style={{ textAlign: 'center' }}>
                  <strong>KWH</strong>
                </StyledCol>
                <StyledCol lg="4" style={{ textAlign: 'center' }}>
                  <strong>BAHT</strong>
                </StyledCol>
              </StyledRow>
              {props.energy
                ? Object.keys(props.energy).map((roomType, index) => (
                    <StyledRow key={index}>
                      <StyledCol lg="4">
                        <Topic>{roomType === 'room' ? 'room+lobby' : roomType}</Topic>
                      </StyledCol>
                      <ColumnBody lg="4">{props.energy[roomType]['kwh']}</ColumnBody>
                      <ColumnBody lg="4">{props.energy[roomType]['baht']}</ColumnBody>
                    </StyledRow>
                  ))
                : null}
              <StyledRow>
                <StyledCol lg="4">
                  <Topic>other(VAT)</Topic>
                </StyledCol>
                <ColumnBody lg="4" />
                <ColumnBody lg="4">{props.otherBaht.toFixed(1)}</ColumnBody>
              </StyledRow>
              <br />
            </CardBody>
          </div>
          <FooterWrapper>
            <StyledRowFooter>
              <StyledCol lg="4">
                <Topic>
                  <strong>Total</strong>
                </Topic>
              </StyledCol>
              <ColumnFooter lg="4">
                <strong>{props.energySum}</strong>
              </ColumnFooter>
              <ColumnFooter lg="4">
                <strong>{props.bahtSum}</strong>
              </ColumnFooter>
            </StyledRowFooter>
          </FooterWrapper>
          <UpdatedAt>
            {'Updated at: '} {props.updated_at}
          </UpdatedAt>
        </Card>
      </Col>
    </>
  );
};
