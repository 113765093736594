import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { FirebaseContext2 } from '../../../../../firebase';
import { List } from '../../../../../components/List/TabList';
import { Info, ActivityData, HistoryData } from '../../../components/Tabs';
import moment from 'moment';
import { energyActions } from '../../../../energy/actions';
import { roomActivitiesHistories } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';
import { iotControlServices } from '../../../../../api/services';

const ExhaustFanControl = (props) => {
  const dispatch = useDispatch();
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);

  const InitPlotData = useSelector((state) => state.historyEnergy.hist);
  const dropdown = useSelector((state) => state.hotel.dropdown);

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;

  const [currentTab, setCurrentTab] = useState('control');
  const timeoutid = useRef();
  const [plotHistData, setPlotHistData] = useState([]);
  const [tmpHist, settmpHist] = useState(null);

  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(dashboardControlActions.showIotModal(false));
    dispatch(energyActions.clearHistoryData());
  };

  const firebase = useContext(FirebaseContext2);

  //##-------------------Switch--------------------------------
  const [openFan, setOpenFan] = useState(null);

  const [status, setStatus] = useState({
    exhaust_fan_switch: {
      status: null,
      time: null,
    },
  });

  const d = new Date();

  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  function onchildClickActivity(event) {
    props.onchildClickActivity(event);
  }

  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/exfan/relay/fan';

    if (didMounted) {
      console.log('Fan did mounted');
      await firebase.db.ref(fbpath).off('value');
    } else {
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setStatus({
            exhaust_fan_switch: {
              status: capt.state,
              time: capt.timestamp,
            },
          });
        }
      });
    }
  }

  async function triggerAPI(currentStatus, swLocation, currentRoomNo) {
    //##-------------------------Payload-----------------------------------
    const payload = {
      topic: 'devicecontrol',
      action: {
        [currentRoomNo]: {
          [swLocation]: {
            command: {
              state: currentStatus,
            },
          },
        },
      },
      RequestId: '12345',
    };

    console.log(payload.message, payload.device);
    iotControlServices.postIotApi(payload);
  }

  async function histData(dev_id, nick_name) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        dropdown,
        nick_name,
        false,
        props.startDate,
        props.stopDate
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##-------------------sync data from firebase only-----------------
  useEffect(() => {
    fetchData(firebase, currentRoomNo, false);

    return () => fetchData(firebase, currentRoomNo, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##-------------------update current status only-----------------
  useEffect(() => {
    if (tmpHist !== null) {
      let tmp1 = [];
      tmpHist.forEach((item) => {
        if (item.state !== null && item.timestamp !== null) {
          tmp1.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        return setPlotHistData(tmp1);
      });
    }
  }, [tmpHist]);

  useEffect(() => {
    initSwitch(status.exhaust_fan_switch.status);
  }, [status]);

  useEffect(() => {
    clearTimeout(timeoutid.current);
    devices.forEach((element) => {
      if (element['device_name'] === 'fan') {
        try {
          histData(element.device_id, 'fan').then((results) => {
            return settmpHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });
    let tmp = [];
    timeoutid.current = setTimeout(() => {
      if (status.exhaust_fan_switch.time !== null && status.exhaust_fan_switch.status !== null) {
        switch (Number(dropdown)) {
          case 0:
            tmp.push(
              Number(moment(status.exhaust_fan_switch.time).format('x')),
              status.exhaust_fan_switch.status === 'on' ? 1 : 0
            );
            console.log('fan');
            break;
          default:
            console.log('default');
            break;
        }
        dispatch(
          energyActions.mintelHistoryData({
            hist: {
              front_switch: [],
              back_switch: [],
              ac: [],
              fan: tmp,
              fridge: { state: [], power: [] },
              smart_meter: { energy: [], power: [] },
              smart_tv: [],
            },
          })
        );
      }
    }, 2000);

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown, currentRoomNo, props.startDate, props.stopDate, status]);

  //##-----------------Sync status from firebase------------------
  const initSwitch = (status1) => {
    if (status1 === 'on') {
      setOpenFan(true);
    } else {
      setOpenFan(false);
    }
  };

  //##-------------------Tab menu------------------------------
  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };
  const onClick1 = (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenFan((prev) => !prev);
    const location = 'Exhaust Fan';

    if (openFan !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openFan ? 'on' : 'off', location, currentRoomNo),
        700
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  const imagesPath = {
    open: '/assets/images/devices/open_fan.gif',
    close: '/assets/images/devices/close_fan.png',
  };

  const image_link =
    'https://images-na.ssl-images-amazon.com/images/I/51Y87YFu%2B%2BL._AC_SL1000_.jpg';

  const hist_plot = {
    series: [
      {
        name: 'Fan',
        // data: plotHistData.concat(Main_mdb.plot),
        // data: InitPlotData.front_switch.concat(TmpData),
        // name: 'Switch',
        data:
          plotHistData !== undefined && plotHistData !== null && InitPlotData.fan !== undefined
            ? plotHistData.concat(InitPlotData.fan)
            : [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        // style: 'hollow',
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },

      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },
      title: {
        text: 'Activity in 24 hrs',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Exhaust Fan
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="ucount-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'control'}
                        onClick={(e) => onClickTabMenu(e, 'control')}
                      >
                        CONTROL
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        onClick={(e) => onClickTabMenu(e, 'info')}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'activity'}
                        subDevices={props.subDevices}
                        onClick={(e) => onClickTabMenu(e, 'activity')}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'history'}
                        onClick={(e) => onClickTabMenu(e, 'history')}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>

                  {/* Control tab */}
                  {currentTab === 'control' && (
                    <div className="iot-element" id="iot-control-fan">
                      <div className="columns">
                        <div
                          className="column"
                          // style={{
                          //   border: '1px #aaa solid',
                          //   borderRadius: '5px',
                          //   margin: '5px',
                          // }}
                        >
                          <div className="column">
                            <img
                              alt="fan-icon"
                              id="icon-fan-close"
                              style={{
                                width: '150px',
                                height: '150px',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '5%',
                                cursor: 'pointer',
                              }}
                              // src="/assets/images/devices/icon-fan-close.png"
                              src={openFan ? imagesPath.open : imagesPath.close}
                              onClick={(e) => onClick1(e)}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            <button
                              className={
                                // status.exhaust_fan_switch.status === 'on'
                                openFan
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_711c11/data/gang 0", "type": "on-off",
                              //                                       "deviceid": "tasmota_711C11", "gang": 0, "icons":["icon-fan-close","icon-fan-open"]}'
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={(e) => onClick1(e)}
                            >
                              {/* {status.exhaust_fan_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openFan ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === 'info' && (
                    <Info image={image_link} detail={props.detail} header={props.header} />
                  )}
                  {currentTab === 'activity' && (
                    <ActivityData onClick={onchildClickActivity} subDevices={props.subDevices} />
                  )}
                  {currentTab === 'history' && (
                    <HistoryData
                      data={hist_plot}
                      startDate={props.startDate}
                      stopDate={props.stopDate}
                      handleStartDate={props.handleStartDate}
                      handleStopDate={props.handleStopDate}
                    />
                  )}
                </nav>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export { ExhaustFanControl };
