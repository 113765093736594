import React, { useContext, useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { EnergyPerRoomnight, EnergySaving, EnergyUseRatio } from '../../../components';
import { FirebaseContext2 } from '../../../firebase';

const EnergyReport = (props) => {
  const firebase = useContext(FirebaseContext2);
  const [room_night, setRoom_night] = useState({
    val: [],
  });
  const [room_night_UpdatedAt, setRoom_night_UpdatedAt] = useState('...');
  const [fdata, setFdata] = useState({
    saving: {
      percent: null,
      val: null,
    },
    allocation: {
      common_room: null,
      room: null,
    },
    appliances: {
      ac: null,
      light: null,
      plug_load: null,
    },
  });

  async function fetchData(firebase, didMounted) {
    let fbpath = '/hotel/mintel/energy/energy_dashboard';
    let room_night_url = '/hotel/mintel/dashboard/electricity_bills_per_room_night';
    let maid_url = 'hotel/mintel/user_info/room_status';

    if (didMounted) {
      await firebase.db.ref(fbpath).off('value');
      await firebase.db.ref(maid_url).off('value');
    } else {
      await firebase.db.ref(room_night_url).on('value', function (snap) {
        let capt = snap.val();
        let prevValue = room_night;
        prevValue['val'] = [capt.items.before, capt.items.after];
        setRoom_night(prevValue);
        setRoom_night_UpdatedAt(capt.updated_at);
      });
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        setFdata({
          saving: {
            percent: capt.saving.percent,
            val: capt.saving.val,
            hotel: capt.saving.hotel,
            esco: capt.saving.esco,
            alto: capt.saving.alto,
          },
          allocation: {
            common_room: capt.allocation.common_room,
            room: capt.allocation.room,
          },
          appliances: {
            ac: capt.appliances.ac,
            light: capt.appliances.light,
            plug_load: capt.appliances.plug_load,
          },
        });
      });
    }
  }

  useEffect(() => {
    fetchData(firebase, false);
    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let Energy_per_room = {
    options: { labels: ['Before', 'After'] },
    series: [
      {
        name: 'Energy/Room Night',
        data: room_night.val,
        color: '#1858a8',
      },
    ],
  };

  let Energy_use_ratio = {
    options: {
      labels: ['Room', 'Common room'],

      donut: {
        size: '65%',
        background: 'transparent',
        labels: {
          show: false,
          name: {
            show: true,
            fontSize: '22px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            offsetY: -10,
          },
        },
      },
      colors: ['#5bc9bd', '#3970b9'],
    },
    series: [Number(fdata.allocation.room), Number(fdata.allocation.common_room)],
  };

  return (
    <Row>
      <EnergyPerRoomnight
        name={'Electricity Bills / Room night'}
        data={Energy_per_room}
        room_night_UpdatedAt={room_night_UpdatedAt}
      />
      <EnergySaving name={'Energy Saving'} data={fdata.saving} />
      <EnergyUseRatio name={'Electricity Allocation'} data={Energy_use_ratio} />
    </Row>
  );
};

export { EnergyReport };
