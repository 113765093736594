import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCog } from '@fortawesome/free-solid-svg-icons';

function MockUpRoomRepairServiceTabComponent() {
  return (
    <div className="tab-content column cgroup-1" id="repair">
      <center>
        <table
          className="table"
          style={{
            borderCollapse: 'collapse',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th>อุปกรณ์</th>
              <th>ปัญหา/อาการ</th>
              <th>วันที่แจ้งซ่อม</th>
              <th>สถานะ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>โทรทัศน์</td>
              <td>เปิดไม่ติด</td>
              <td>20/06/2020</td>
              <td>
                <FontAwesomeIcon
                  title="ซ่อมแล้ว"
                  icon={faCheck}
                  style={{
                    color: '#62B76F',
                  }}
                />
                <span> ซ่อมแล้ว </span>
              </td>
            </tr>
            <tr>
              <td>ฝักบัว</td>
              <td>น้ำรั่ว</td>
              <td>24/06/2020</td>
              <td>
                <FontAwesomeIcon
                  title="ยังไม่รับการซ่อม"
                  icon={faTimes}
                  style={{
                    color: 'red',
                  }}
                />
                <span> ยังไม่รับการซ่อม </span>
              </td>
            </tr>

            <tr>
              <td>ผ้าม่าน</td>
              <td>ผ้าม่านรูดไม่ได้</td>
              <td>28/06/2020</td>
              <td>
                <FontAwesomeIcon
                  title="อยู่ระหว่างการซ่อม"
                  icon={faCog}
                  style={{
                    color: '#f1c232',
                  }}
                />
                <span> อยู่ระหว่างการซ่อม</span>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </div>
  );
}

export { MockUpRoomRepairServiceTabComponent };
