import React from 'react';
import { Card, Row, Col, Table } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { DatePickers } from '../../../../../components';
import './styles.css';
import moment from 'moment';

export const TdpkEnergyComponents = (props) => {
  const d = new Date();
  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }
  return (
    <>
      <Row>
        <Col lg="4" md="6" sm="6">
          <div>
            <h5 style={{ color: '#70706E' }}>This month E-bill</h5>{' '}
          </div>
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td colSpan="2">
                          <Row>
                            <Col>
                              <p style={{ fontSize: 12, margin: 0 }}>
                                Updated at{' '}
                                {props.ebill !== null
                                  ? moment(props.ebill.this_month_baht_updated_at * 1000).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  : ''}
                              </p>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center', fontSize: 20 }}>
                        <td colSpan="2">
                          Total:{' '}
                          <strong style={{ color: 'red' }}>
                            {props.ebill !== null ? props.ebill.this_month_baht : 0}
                          </strong>{' '}
                          Baht
                        </td>
                        {/* <td>Baht</td> */}
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <Col md="12">ON PEAK</Col>
                          <Col md="12">
                            <b style={{ color: 'red' }}>
                              {props.ebill !== null ? props.ebill.onpeak_baht : 0}
                            </b>{' '}
                            Baht
                          </Col>
                        </td>
                        <td>
                          <Col md="12">OFF PEAK</Col>
                          <Col md="12">
                            <b style={{ color: 'blue' }}>
                              {props.ebill !== null ? props.ebill.offpeak_baht : 0}
                            </b>{' '}
                            Baht
                          </Col>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="4" md="6" sm="6">
          <div>
            <h5 style={{ color: '#70706E' }}>This month E-consumption</h5>
          </div>
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <Row>
                            <Col>
                              <p style={{ fontSize: 12, margin: 0 }}>
                                Updated at{' '}
                                {props.ebill !== null
                                  ? moment(props.ebill.onpeak_kwh_updated_at * 1000).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  : ''}
                              </p>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <b style={{ color: 'red' }}>
                            On peak: {props.ebill !== null ? props.ebill.onpeak_kwh : 0} kWh
                          </b>
                          <br />
                          (M-F 9am - 10pm)
                          <br />
                          <br />
                          <b style={{ color: 'blue' }}>
                            Off peak: {props.ebill !== null ? props.ebill.offpeak_kwh : 0} kWh
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="4" md="6" sm="6">
          <div>
            <h5 style={{ color: '#70706E' }}>This month E-bill forecast</h5>
          </div>

          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col md="12">
                  <Table borderless size="sm">
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <Row>
                            <Col>
                              <p style={{ fontSize: 12, margin: 0 }}>
                                Updated at{' '}
                                {props.ebill !== null
                                  ? moment(props.ebill.this_month_baht_updated_at * 1000).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  : ''}
                              </p>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          <br />
                          <h4 style={{ color: 'orange' }}>
                            {props.ebill !== null ? props.ebill.this_month_forecast_baht : 0} Baht
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card className="card-stats">
            <Card.Body>
              <Chart
                options={props.main_graph.options}
                series={props.main_graph.series}
                type="line"
                width="100%"
                height="300px"
              />
              <div className="filter">
                <DatePickers
                  daySelectStart={props.startDate}
                  daySelectStop={props.stopDate}
                  handleStartDate={props.handleStartDate}
                  handleStopDate={props.handleStopDate}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card className="card-stats">
            <Card.Title>
              <div
                style={{
                  textAlign: 'center',
                  alignContent: 'center',
                  marginTop: '2%',
                  fontSize: 18,
                }}
              >
                Baseline (Monthly) : {GetMonth()} {d.getFullYear()}
              </div>
            </Card.Title>
            <Card.Body>
              <Row>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats" style={{ borderRadius: '0' }}>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <Table borderless size="sm">
                            <tbody>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b>kWh per room-night</b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b style={{ color: 'gray' }}>
                                    {props.ebill !== null ? props.ebill.kwh_per_room_night : 0}
                                  </b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  ({props.ebill !== null ? props.ebill.avg_kwh_per_room_night : 0}%
                                  above avg)
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <p style={{ fontSize: 12, textAlign: 'right' }}>
                            Updated at:{' '}
                            {props.ebill !== null
                              ? moment(props.ebill.onpeak_demand_kw_updated_at * 1000).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                              : ''}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats" style={{ borderRadius: '0' }}>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <Table borderless size="sm">
                            <tbody>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b>
                                    kWh per m<sup>2</sup>
                                  </b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b style={{ color: 'gray' }}>
                                    {props.ebill !== null ? props.ebill.kwh_per_m2 : 0}
                                  </b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  ({props.ebill !== null ? props.ebill.avg_kwh_per_m2 : 0}% above
                                  avg)
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <p style={{ fontSize: 12, textAlign: 'right' }}>
                            Updated at:{' '}
                            {props.ebill !== null
                              ? moment(props.ebill.onpeak_demand_kw_updated_at * 1000).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                              : ''}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats" style={{ borderRadius: '0' }}>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <Table borderless size="sm">
                            <tbody>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b>Peak Power (kW)</b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b style={{ color: 'gray' }}>
                                    {props.ebill !== null ? props.ebill.peak_demand_kw : 0}
                                  </b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  ({props.ebill !== null ? props.ebill.avg_peak_power : 0}% above
                                  avg)
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <p style={{ fontSize: 12, textAlign: 'right' }}>
                            Updated at:{' '}
                            {props.ebill !== null
                              ? moment(props.ebill.onpeak_demand_kw_updated_at * 1000).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                              : ''}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats" style={{ borderRadius: '0' }}>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <Table borderless size="sm">
                            <tbody>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b>
                                    CO<sub>2</sub> Emission (kgCO<sub>2</sub>e)
                                  </b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  <b style={{ color: 'gray' }}>
                                    {props.ebill !== null ? props.ebill.co2 : 0}
                                  </b>
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                <td>
                                  ({props.ebill !== null ? props.ebill.avg_co2_emission : 0}% above
                                  avg)
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <p style={{ fontSize: 12, textAlign: 'right' }}>
                            Updated at:{' '}
                            {props.ebill !== null
                              ? moment(props.ebill.onpeak_demand_kw_updated_at * 1000).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                              : ''}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
    // <Row>
    //   <Col md={12}>
    //     <Card>
    //       <Card.Body
    //         style={{
    //           height: '600px',
    //           alignItems: 'center',
    //           display: 'flex',
    //           justifyContent: 'center',
    //         }}
    //       >
    //         <h1 style={{ color: '#d3d3d3' }}>Comming Soon ...</h1>
    //       </Card.Body>
    //     </Card>
    //   </Col>
    // </Row>
  );
};
