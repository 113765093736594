import axios from 'axios';
import { API_CONSTANTS } from '../../api';

const TypeConversion = {
  ac: 'ac',
  fan: 'relay',
  front_switch: 'relay',
  back_switch: 'relay',
  fridge: 'electric',
  smart_tv: 'remotec',
  broadlinkenv: 'environment',
  smart_meter: 'electric',
};

const getActivitiesHistories = async (
  roomNumber,
  deviceId,
  sub_dev,
  name,
  device_activity,
  startDate,
  stopDate
) => {
  const token = localStorage.getItem('token');
  //const dateTime = new Date();
  // const day = Number(dateTime.getDate());
  // const month = Number(dateTime.getMonth()) + 1;
  // const year = dateTime.getFullYear();
  //const StartTime = `${year}-${month}-${day} 00:00`;
  const StartTime = `${startDate.getFullYear()}-${
    startDate.getMonth() + 1 < 10
      ? '0' + String(startDate.getMonth() + 1)
      : '0' + String(startDate.getMonth() + 1)
  }-${
    startDate.getDate() < 10 ? '0' + String(startDate.getDate()) : String(startDate.getDate())
  } 00:00`;
  // const EndTime = `${stopDate.getFullYear()}-${Number(stopDate.getMonth())+1}-${stopDate.getDate()} ${
  //   dateTime.getHours() < 10 ? '0' + dateTime.getHours() : dateTime.getHours()
  // }:${dateTime.getMinutes() < 10 ? '0' + dateTime.getMinutes() : dateTime.getMinutes()}`;
  const EndTime = `${stopDate.getFullYear()}-${
    stopDate.getMonth() + 1 < 10
      ? '0' + String(stopDate.getMonth() + 1)
      : '0' + String(stopDate.getMonth() + 1)
  }-${
    stopDate.getDate() < 10 ? '0' + String(stopDate.getDate()) : String(stopDate.getDate())
  } 00:00`;

  const requestOptions = {
    url: `${
      API_CONSTANTS.GET_ALL_ACTIVITIES_HISTORIES_URL
    }?RequestId=${roomNumber}&starttime=${StartTime}&endtime=${EndTime}&device_id=${deviceId}&type=${
      TypeConversion[name] ? TypeConversion[name] : 'ac'
    }&subdevice_idx=${sub_dev}&device_activity=${device_activity}&sample_min`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
  };

  try {
    let responseJson = await axios.request(requestOptions);
    return responseJson;
  } catch (error) {
    console.error('[Services] getAllActivities Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
};

export const roomActivitiesHistories = {
  getActivitiesHistories,
};
