import { TDPK } from '../types';

function showModal() {
  return {
    type: TDPK.SHOW_MODAL,
  };
}

function hideModal() {
  return {
    type: TDPK.HIDE_MODAL,
  };
}

function tabName(payload) {
  return {
    type: TDPK.TAB_NAME,
    payload: payload,
  };
}

function acPlotHistory(data) {
  return {
    type: TDPK.AC_HISTORY_PLOT,
    payload: data,
  };
}

export const tdpkActions = {
  showModal,
  hideModal,
  tabName,
  acPlotHistory,
};
