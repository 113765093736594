import { ENERGY } from '../types';

const INIT_STATE = {
  // MINTEL ENERGY
  main_mdb: {
    plot: [],
    p_1: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_2: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_3: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
  },
  restaurant_mdb: {
    plot: [],
    p_1: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_2: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_3: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
  },
  laundry_mdb: {
    plot: [],
    p_1: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_2: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_3: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
  },
  // SYN ENERGY
  syn_emdb: {
    total_power: [],
    plot: [],
    p_1: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
      energy: [],
      energy_reactive: [],
      energy_reactive_to_grid: [],
      energy_to_grid: [],
      power_factor: [],
    },
    p_2: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_3: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
  },
  syn_mdb: {
    total_power: [],
    plot: [],
    p_1: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
      energy: [],
      energy_reactive: [],
      energy_reactive_to_grid: [],
      energy_to_grid: [],
      power_factor: [],
    },
    p_2: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
    p_3: {
      power: [],
      current: [],
      voltage: [],
      power_reactive: [],
      time: [],
    },
  },
  syn_total: {
    plot: [],
  },
  energy_data: undefined,
};

const INIT_STATE_TEST = {
  test: [],
};

const INIT_STATE_HISTORY = {
  // History Data
  hist: {
    // tmp: [],
    front_switch: {
      toilet: [],
      corridor: [],
      main: [],
    },
    back_switch: {
      window: [],
      wardrobe: [],
      bed: [],
    },
    ac: [],
    fan: [],
    fridge: { state: [], power: [] },
    smart_meter: { energy: [], power: [] },
    smart_tv: [],
  },
};

const energy = (state = INIT_STATE, action) => {
  switch (action.type) {
    // MINTEL ENERGY
    case ENERGY.MINTEL_ENERGY:
      return {
        main_mdb: {
          plot: [...state.main_mdb.plot, action.payload.main_mdb.plot],
          p_1: {
            power: [...state.main_mdb.p_1.power, action.payload.main_mdb.p_1.power],
            current: [...state.main_mdb.p_1.current, action.payload.main_mdb.p_1.current],
            voltage: [...state.main_mdb.p_1.voltage, action.payload.main_mdb.p_1.voltage],
            power_reactive: [
              ...state.main_mdb.p_1.power_reactive,
              action.payload.main_mdb.p_1.power_reactive,
            ],
            time: [...state.main_mdb.p_1.time, action.payload.main_mdb.p_1.time],
          },
          p_2: {
            power: [...state.main_mdb.p_2.power, action.payload.main_mdb.p_2.power],
            current: [...state.main_mdb.p_2.current, action.payload.main_mdb.p_2.current],
            voltage: [...state.main_mdb.p_2.voltage, action.payload.main_mdb.p_2.voltage],
            power_reactive: [
              ...state.main_mdb.p_2.power_reactive,
              action.payload.main_mdb.p_2.power_reactive,
            ],
            time: [...state.main_mdb.p_2.time, action.payload.main_mdb.p_2.time],
          },
          p_3: {
            power: [...state.main_mdb.p_3.power, action.payload.main_mdb.p_3.power],
            current: [...state.main_mdb.p_3.current, action.payload.main_mdb.p_3.current],
            voltage: [...state.main_mdb.p_3.voltage, action.payload.main_mdb.p_3.voltage],
            power_reactive: [
              ...state.main_mdb.p_3.power_reactive,
              action.payload.main_mdb.p_1.power_reactive,
            ],
            time: [...state.main_mdb.p_3.time, action.payload.main_mdb.p_3.time],
          },
        },
        restaurant_mdb: {
          plot: [...state.restaurant_mdb.plot, action.payload.restaurant_mdb.plot],
          p_1: {
            power: [...state.restaurant_mdb.p_1.power, action.payload.restaurant_mdb.p_1.power],
            current: [
              ...state.restaurant_mdb.p_1.current,
              action.payload.restaurant_mdb.p_1.current,
            ],
            voltage: [
              ...state.restaurant_mdb.p_1.voltage,
              action.payload.restaurant_mdb.p_1.voltage,
            ],
            power_reactive: [
              ...state.restaurant_mdb.p_1.power_reactive,
              action.payload.restaurant_mdb.p_1.power_reactive,
            ],
            time: [...state.restaurant_mdb.p_1.time, action.payload.restaurant_mdb.p_1.time],
          },
          p_2: {
            power: [...state.restaurant_mdb.p_2.power, action.payload.restaurant_mdb.p_2.power],
            current: [
              ...state.restaurant_mdb.p_2.current,
              action.payload.restaurant_mdb.p_2.current,
            ],
            voltage: [
              ...state.restaurant_mdb.p_2.voltage,
              action.payload.restaurant_mdb.p_2.voltage,
            ],
            power_reactive: [
              ...state.restaurant_mdb.p_2.power_reactive,
              action.payload.restaurant_mdb.p_2.power_reactive,
            ],
            time: [...state.restaurant_mdb.p_2.time, action.payload.restaurant_mdb.p_2.time],
          },
          p_3: {
            power: [...state.restaurant_mdb.p_3.power, action.payload.restaurant_mdb.p_3.power],
            current: [
              ...state.restaurant_mdb.p_3.current,
              action.payload.restaurant_mdb.p_3.current,
            ],
            voltage: [
              ...state.restaurant_mdb.p_1.voltage,
              action.payload.restaurant_mdb.p_3.voltage,
            ],
            power_reactive: [
              ...state.restaurant_mdb.p_3.power_reactive,
              action.payload.restaurant_mdb.p_3.power_reactive,
            ],
            time: [...state.restaurant_mdb.p_3.time, action.payload.restaurant_mdb.p_3.time],
          },
        },
        laundry_mdb: {
          plot: [...state.laundry_mdb.plot, action.payload.laundry_mdb.plot],
          p_1: {
            power: [...state.laundry_mdb.p_1.power, action.payload.laundry_mdb.p_1.power],
            current: [...state.laundry_mdb.p_1.current, action.payload.laundry_mdb.p_1.current],
            voltage: [...state.laundry_mdb.p_1.voltage, action.payload.laundry_mdb.p_1.voltage],
            power_reactive: [
              ...state.laundry_mdb.p_1.power_reactive,
              action.payload.laundry_mdb.p_1.power_reactive,
            ],
            time: [...state.laundry_mdb.p_1.time, action.payload.laundry_mdb.p_1.time],
          },
          p_2: {
            power: [...state.laundry_mdb.p_2.power, action.payload.laundry_mdb.p_2.power],
            current: [...state.laundry_mdb.p_2.current, action.payload.laundry_mdb.p_2.current],
            voltage: [...state.laundry_mdb.p_2.voltage, action.payload.laundry_mdb.p_2.voltage],
            power_reactive: [
              ...state.laundry_mdb.p_2.power_reactive,
              action.payload.laundry_mdb.p_2.power_reactive,
            ],
            time: [...state.laundry_mdb.p_2.time, action.payload.laundry_mdb.p_2.time],
          },
          p_3: {
            power: [...state.laundry_mdb.p_3.power, action.payload.laundry_mdb.p_3.power],
            current: [...state.laundry_mdb.p_3.current, action.payload.laundry_mdb.p_3.current],
            voltage: [...state.laundry_mdb.p_1.voltage, action.payload.laundry_mdb.p_3.voltage],
            power_reactive: [
              ...state.laundry_mdb.p_3.power_reactive,
              action.payload.laundry_mdb.p_3.power_reactive,
            ],
            time: [...state.laundry_mdb.p_3.time, action.payload.laundry_mdb.p_3.time],
          },
        },
      };
    case ENERGY.SYN_ENERGY:
      return {
        syn_emdb: {
          total_power: [...state.syn_emdb.total_power, action.payload.syn_emdb.total_power],
          plot: [...state.syn_emdb.plot, action.payload.syn_emdb.plot],
          p_1: {
            power: [...state.syn_emdb.p_1.power, action.payload.syn_emdb.p_1.power],
            current: [...state.syn_emdb.p_1.current, action.payload.syn_emdb.p_1.current],
            voltage: [...state.syn_emdb.p_1.voltage, action.payload.syn_emdb.p_1.voltage],
            power_reactive: [
              ...state.syn_emdb.p_1.power_reactive,
              action.payload.syn_emdb.p_1.power_reactive,
            ],
            time: [...state.syn_emdb.p_1.time, action.payload.syn_emdb.p_1.time],
            energy: [...state.syn_emdb.p_1.energy, action.payload.syn_emdb.p_1.energy],
            energy_reactive: [
              ...state.syn_emdb.p_1.energy_reactive,
              action.payload.syn_emdb.p_1.energy_reactive,
            ],
            energy_reactive_to_grid: [
              ...state.syn_emdb.p_1.energy_reactive_to_grid,
              action.payload.syn_emdb.p_1.energy_reactive_to_grid,
            ],
            energy_to_grid: [
              ...state.syn_emdb.p_1.energy_to_grid,
              action.payload.syn_emdb.p_1.energy_to_grid,
            ],
            power_factor: [
              ...state.syn_emdb.p_1.power_factor,
              action.payload.syn_emdb.p_1.power_factor,
            ],
          },
          p_2: {
            power: [...state.syn_emdb.p_2.power, action.payload.syn_emdb.p_2.power],
            current: [...state.syn_emdb.p_2.current, action.payload.syn_emdb.p_2.current],
            voltage: [...state.syn_emdb.p_2.voltage, action.payload.syn_emdb.p_2.voltage],
            power_reactive: [
              ...state.syn_emdb.p_2.power_reactive,
              action.payload.syn_emdb.p_2.power_reactive,
            ],
            time: [...state.syn_emdb.p_2.time, action.payload.syn_emdb.p_2.time],
          },
          p_3: {
            power: [...state.syn_emdb.p_3.power, action.payload.syn_emdb.p_3.power],
            current: [...state.syn_emdb.p_3.current, action.payload.syn_emdb.p_3.current],
            voltage: [...state.syn_emdb.p_3.voltage, action.payload.syn_emdb.p_3.voltage],
            power_reactive: [
              ...state.syn_emdb.p_3.power_reactive,
              action.payload.syn_emdb.p_1.power_reactive,
            ],
            time: [...state.syn_emdb.p_3.time, action.payload.syn_emdb.p_3.time],
          },
        },
        syn_mdb: {
          total_power: [...state.syn_mdb.total_power, action.payload.syn_mdb.total_power],
          plot: [...state.syn_mdb.plot, action.payload.syn_mdb.plot],
          p_1: {
            power: [...state.syn_mdb.p_1.power, action.payload.syn_mdb.p_1.power],
            current: [...state.syn_mdb.p_1.current, action.payload.syn_mdb.p_1.current],
            voltage: [...state.syn_mdb.p_1.voltage, action.payload.syn_mdb.p_1.voltage],
            power_reactive: [
              ...state.syn_mdb.p_1.power_reactive,
              action.payload.syn_mdb.p_1.power_reactive,
            ],
            time: [...state.syn_mdb.p_1.time, action.payload.syn_mdb.p_1.time],
            energy: [...state.syn_mdb.p_1.energy, action.payload.syn_mdb.p_1.energy],
            energy_reactive: [
              ...state.syn_mdb.p_1.energy_reactive,
              action.payload.syn_mdb.p_1.energy_reactive,
            ],
            energy_reactive_to_grid: [
              ...state.syn_mdb.p_1.energy_reactive_to_grid,
              action.payload.syn_mdb.p_1.energy_reactive_to_grid,
            ],
            energy_to_grid: [
              ...state.syn_mdb.p_1.energy_to_grid,
              action.payload.syn_mdb.p_1.energy_to_grid,
            ],
            power_factor: [
              ...state.syn_mdb.p_1.power_factor,
              action.payload.syn_mdb.p_1.power_factor,
            ],
          },
          p_2: {
            power: [...state.syn_mdb.p_2.power, action.payload.syn_mdb.p_2.power],
            current: [...state.syn_mdb.p_2.current, action.payload.syn_mdb.p_2.current],
            voltage: [...state.syn_mdb.p_2.voltage, action.payload.syn_mdb.p_2.voltage],
            power_reactive: [
              ...state.syn_mdb.p_2.power_reactive,
              action.payload.syn_mdb.p_2.power_reactive,
            ],
            time: [...state.syn_mdb.p_2.time, action.payload.syn_mdb.p_2.time],
          },
          p_3: {
            power: [...state.syn_mdb.p_3.power, action.payload.syn_mdb.p_3.power],
            current: [...state.syn_mdb.p_3.current, action.payload.syn_mdb.p_3.current],
            voltage: [...state.syn_mdb.p_1.voltage, action.payload.syn_mdb.p_3.voltage],
            power_reactive: [
              ...state.syn_mdb.p_3.power_reactive,
              action.payload.syn_mdb.p_3.power_reactive,
            ],
            time: [...state.syn_mdb.p_3.time, action.payload.syn_mdb.p_3.time],
          },
        },
        syn_total: {
          plot: [...state.syn_total.plot, action.payload.syn_total.plot],
        },
      };
    case ENERGY.CLEAR_ENERGY_DATA:
      return {
        // MINTEL ENERGY
        main_mdb: {
          plot: [],
          p_1: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_2: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_3: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
        },
        restaurant_mdb: {
          plot: [],
          p_1: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_2: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_3: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
        },
        laundry_mdb: {
          plot: [],
          p_1: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_2: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_3: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
        },
        // SYN ENERGY
        syn_emdb: {
          total_power: [],
          plot: [],
          p_1: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
            energy: [],
            energy_reactive: [],
            energy_reactive_to_grid: [],
            energy_to_grid: [],
            power_factor: [],
          },
          p_2: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_3: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
        },
        syn_mdb: {
          total_power: [],
          plot: [],
          p_1: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
            energy: [],
            energy_reactive: [],
            energy_reactive_to_grid: [],
            energy_to_grid: [],
            power_factor: [],
          },
          p_2: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
          p_3: {
            power: [],
            current: [],
            voltage: [],
            power_reactive: [],
            time: [],
          },
        },
        syn_total: {
          plot: [],
        },
      };
    case ENERGY.KLUB_ENERGY:
      console.log('action : ', action);
      console.log('state : ', state);
      if (state.energy_data === undefined) {
        return {
          ...state,
          energy_data: {
            main_energy: {
              power: [action.payload.power],
              frequency: [action.payload.frequency],
              voltage: [action.payload.voltage],
              current: [action.payload.current],
            },
          },
        };
      } else {
        return {
          ...state,
          energy_data: {
            main_energy: {
              power: [...state.energy_data.main_energy.power, action.payload.power],
              frequency: [...state.energy_data.main_energy.frequency, action.payload.frequency],
              voltage: [...state.energy_data.main_energy.voltage, action.payload.voltage],
              current: [...state.energy_data.main_energy.current, action.payload.current],
            },
          },
        };
      }

    default:
      return state;
  }
};

const historyEnergy = (state = INIT_STATE_HISTORY, action) => {
  switch (action.type) {
    case ENERGY.MINTE_HISTORY_DATA:
      return {
        hist: {
          // tmp: [...state.hist.tmp, action.payload.hist.tmp],
          smart_meter: {
            energy: [...state.hist.smart_meter.energy, action.payload.hist.smart_meter.energy],
            power: [...state.hist.smart_meter.power, action.payload.hist.smart_meter.power],
          },
          smart_tv: [...state.hist.smart_tv, action.payload.hist.smart_tv],
          fridge: {
            state: [...state.hist.fridge.state, action.payload.hist.fridge.state],
            power: [...state.hist.fridge.power, action.payload.hist.fridge.power],
          },
          front_switch: {
            toilet: [...state.hist.front_switch.toilet, action.payload.hist.front_switch.toilet],
            corridor: [
              ...state.hist.front_switch.corridor,
              action.payload.hist.front_switch.corridor,
            ],
            main: [...state.hist.front_switch.main, action.payload.hist.front_switch.main],
          },
          ac: [...state.hist.ac, action.payload.hist.ac],
          fan: [...state.hist.fan, action.payload.hist.fan],
          back_switch: {
            window: [...state.hist.back_switch.window, action.payload.hist.back_switch.window],
            wardrobe: [
              ...state.hist.back_switch.wardrobe,
              action.payload.hist.back_switch.wardrobe,
            ],
            bed: [...state.hist.back_switch.bed, action.payload.hist.back_switch.bed],
          },
        },
      };
    case ENERGY.CLEAR_HISTORY_DATA:
      // return state;
      return {
        // ...state,
        hist: {
          front_switch: {
            toilet: [],
            corridor: [],
            main: [],
          },
          back_switch: {
            window: [],
            wardrobe: [],
            bed: [],
          },
          ac: [],
          fan: [],
          fridge: { state: [], power: [] },
          smart_meter: { energy: [], power: [] },
          smart_tv: [],
        },
      };
    default:
      return state;
  }
};

const testEnergy = (state = INIT_STATE_TEST, action) => {
  switch (action.type) {
    case ENERGY.TEST_ENERGY:
      return {
        test: [...state.test, action.payload],
      };
    case ENERGY.CLEAR_TEST_ENERGY:
      return { test: [] };
    default:
      return state;
  }
};

const energyTabSelect = (state = null, action) => {
  switch (action.type) {
    case ENERGY.ENERGY_TAB_SELECT:
      return action.payload;
    default:
      return state;
  }
};

export { energy, historyEnergy, energyTabSelect, testEnergy };
