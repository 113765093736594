import { TDPK } from '../types';

const INIT_STATE = {
  modal: false,
  name: '',
  ac: {
    room_temp: [],
    temp: [],
    mode: [],
  },
};

const tdpk = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TDPK.SHOW_MODAL:
      return {
        ...state,
        modal: true,
      };
    case TDPK.HIDE_MODAL:
      return INIT_STATE;

    case TDPK.TAB_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case TDPK.AC_HISTORY_PLOT:
      return {
        ...state,
        ac: {
          room_temp: [...state.ac.room_temp, action.payload.ac.room_temp],
          temp: [...state.ac.temp, action.payload.ac.temp],
          mode: [...state.ac.mode, action.payload.ac.mode],
        },
      };

    default:
      return state;
  }
};

export { tdpk };
