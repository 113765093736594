import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ElectricityByHour } from '../../../components/Energy';
import { chartExample9 } from '../../../features/energy/variables/chart';
import styled from 'styled-components';
import { Row, Col, Card } from 'reactstrap';

const Topic = styled.h5`
  margin: 0px;
  float: right;
`;

const Header = styled.h4`
  margin: 0px;
`;

const EnergySavingRecommendation = () => {
  return (
    <div>
      <Col lg="12" sm="12">
        <Card>
          <Row>
            <Col lg="12" md="12" sm="12">
              <ElectricityByHour
                title={'Energy saving recommendation'}
                options={chartExample9.options}
                series={chartExample9.series}
              />
            </Col>
          </Row>
          <div style={{ margin: '20px', marginLeft: '40px' }}>
            <Row>
              <Header>Alto Tech Solution</Header>
            </Row>

            <Row>
              <Col lg="3">
                <Topic>Save :</Topic>
              </Col>
              <Col lg="3">
                <p>16,165 kWh or 64,660 Baht</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Budget :</Topic>
              </Col>
              <Col lg="3">
                <p>850,000 Baht</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Breake Event Point :</Topic>
              </Col>
              <Col lg="3">
                <p>1 year 1 month</p>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export { EnergySavingRecommendation };
