import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const BorderBottom = styled.div`
  border-bottom: ${(props) => (props.borderBottom ? '1px solid #ececec' : 'none')};
  position: relative;
  width: 90%;
  left: 5%;
  bottom: 5px;
`;

export const DeviceList = (props) => {
  return (
    <div>
      <Col lg="12" md="12" style={{ padding: '0px' }}>
        <Row style={{ margin: '0px' }}>
          <Col lg="12" md="12" sm="12" style={{ marginLeft: '15px', paddingLeft: '0' }}>
            <h4 style={{ marginTop: '20px', borderLeft: '0' }}>3. Device settings</h4>
            <Row style={{ marginLeft: '20px' }}>
              <Col lg="12" md="12" style={{ padding: '10px 0px 10px 0px' }}>
                {props.scheduleDevices ? (
                  <div>
                    {props.scheduleDevices.map((item, index) => (
                      <div key={index} data-tip="Add new devices" data-type="info" place="top">
                     
                        <Row style={{ marginBottom: '5px' }}>
                          <Col lg="2" style={{ padding: '0' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                cursor: 'pointer',
                              }}
                              onClick={() => props.selectDevice(item)}
                            >
                              <i className="fas fa-plus"></i>
                            </div>
                          </Col>
                          <Col
                            lg="10"
                            style={{ margin: '0px', padding: '10px', cursor: 'pointer' }}
                            onClick={() => props.selectDevice(item)}
                          >
                            {item}
                          </Col>
                        </Row>

                        <BorderBottom borderBottom={true} />
                        <ReactTooltip effect="solid" />
                      </div>
                    ))}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
