import React, { createContext } from 'react';
import app from 'firebase/app';

// import {
//   deleteUserFirebaseServices,
//   getUserIsUpdatedStateFirebaseServices,
//   setUserIsUpdatedStateFirebaseServices,
// } from './services/user.firebase.services';

// import {
//   getLineIdInRoomFirebaseServices,
//   setLineIdInRoomFirebaseServices,
// } from './services/room.firebase.services';

const FirebaseContext2 = createContext(null);

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_2,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_2,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_2,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_2,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_2,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_2,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_2,
  measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID_2,
};

const FirebaseProvider2 = ({ children }) => {
  let firebase = {
    app: null,
    db: null,
  };

  let isInitializedSecondary = false;
  for (var i = 0; i < app.apps.length; i++) {
    let firebaseApp = app.apps[0];
    if (firebaseApp.name_ === 'secondary') {
      isInitializedSecondary = true;
    }
  }

  if (!isInitializedSecondary) {
    var secondaryApp = app.initializeApp(config, 'secondary');
    firebase = {
      app: secondaryApp,
      db: secondaryApp.database(),
      api: {
        // deleteUserFirebaseServices,
        // getUserIsUpdatedStateFirebaseServices,
        // setUserIsUpdatedStateFirebaseServices,
        // getLineIdInRoomFirebaseServices,
        // setLineIdInRoomFirebaseServices,
      },
    };
  }
  return <FirebaseContext2.Provider value={firebase}>{children}</FirebaseContext2.Provider>;
};

export { FirebaseContext2, FirebaseProvider2 };
