import React, { useContext, useRef, useMemo, useState } from 'react';
import { KlubRealtimeComponents } from '../../../components/KLUB/Energy';
import { FirebaseContext2 } from 'firebase/Firebase2.provider';
import { useDispatch, useSelector } from 'react-redux';
import { energyActions } from '../../../actions';
import moment from 'moment';
import { modBus } from 'api/services';

export const KlubEnergycontainer = (props) => {
  const firebase = useContext(FirebaseContext2);
  const timeoutid = useRef();

  //##-----------------Global Variables Redux-----------------------
  const dispatch = useDispatch();
  const Main_mdb = useSelector((state) => state.energy.main_mdb);
  //##----------------Plot From API-------------------------
  const [histMain, setHistMain] = useState(null);
  //##-----------------Plot From Firebase-------------------
  const [plothistMain, setPlotHistMain] = useState([]);

  //##-----------------DatePicker---------------------------
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [fetchSampling, setFetchSampling] = useState(15);
  const [mainMdb, setMainMdb] = useState(null);

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  //##-----------------set sampling-----------------------------------
  const sampling = (event) => {
    setHistMain(null);
    dispatch(energyActions.clearEnergyData());
    setFetchSampling(event);
  };

  //##--------------------------Fetch Data Function From Firebase----------------------------------
  async function fetchData(firebase, didMount) {
    // Constant URL
    let klub_path = 'hotel/klub/main/main_energy/9c:a5:25:a9:f5:a7:1/electric';
    if (didMount) {
      console.log('Firebase Did Mounted');
      await firebase.db.ref(klub_path).off('value');
    } else {
      // Fetch Main MDB
      await firebase.db.ref(klub_path).on('value', function (snap) {
        let capt = snap.val();
        setMainMdb(capt);
      });
    }
  }

  //##--------------------Fetch History Data From API---------------------
  async function histData(dev_id, sub_dev, sampling_time) {
    try {
      let results = await modBus.getModBusHistory(
        dev_id,
        sub_dev,
        false,
        startDate,
        stopDate,
        sampling_time
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            return results.data.results;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##----------------Get month number to string---------------------------
  function GetMonth(start) {
    // eslint-disable-next-line
    let month = [];
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';
    if (start) {
      return month[startDate.getMonth()];
    } else {
      return month[stopDate.getMonth()];
    }
  }

  //##-------------------------- Fetching ----------------------------------
  useMemo(() => {
    fetchData(firebase, false);
    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##---------------------------Historical Data----------------------------
  useMemo(() => {
    console.log('try to fetch');

    histData('9c:a5:25:a9:f5:a7:1', 3, fetchSampling).then((results) => {
      /// Main
      return setHistMain(results);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, stopDate, fetchSampling]);

  useMemo(() => {
    if (histMain !== null && histMain !== undefined) {
      let main = [];

      histMain.forEach((item) => {
        if (item.power !== null && item.timestamp !== null) {
          main.push([Number(moment(item.timestamp).format('x')), Number(item.power)]);
        }
        setPlotHistMain(main);
      });
    }
  }, [histMain]);

  //##------------------------- Dispatching --------------------------------
  useMemo(() => {
    clearTimeout(timeoutid.current);
    // seriesData(); /////////
    if (histMain !== null) {
      if (mainMdb !== null) {
        timeoutid.current = setTimeout(async () => {
          dispatch(
            energyActions.klubEnergy({
              power: [
                Number(moment(mainMdb.subdev_3.timestamp).format('x')),
                Number(mainMdb.subdev_3.power.toFixed(2)),
              ],
              frequency: [
                Number(moment(mainMdb.subdev_3.timestamp).format('x')),
                Number(mainMdb.subdev_3.frequency.toFixed(2)),
              ],
              voltage: [
                Number(moment(mainMdb.subdev_3.timestamp).format('x')),
                Number(mainMdb.subdev_3.voltage.toFixed(2)),
              ],
              current: [
                Number(moment(mainMdb.subdev_3.timestamp).format('x')),
                Number(mainMdb.subdev_3.current.toFixed(2)),
              ],
            })
          );
        }, fetchSampling * 60 * 1000);
      }
    }

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMdb, histMain]);

  const main_graph = {
    series: [
      {
        name: 'Main Power (kW)',
        data: histMain !== null && plothistMain !== [] ? plothistMain.concat(Main_mdb.plot) : [],
      },
    ],
    options: {
      noData: {
        text: 'Loading...',
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        width: 2,
        cruve: 'smooth',
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (val) {
            return Number(val).toFixed(2);
          },
        },
        title: {
          text: '(kW)',
        },
      },
      xaxis: {
        type: 'datetime',
        min: new Date(
          `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 00:00:00`
        ).getTime(),
        max: new Date(
          `${stopDate.getDate() - 1} ${GetMonth(false)} ${stopDate.getFullYear()} 23:59:59`
        ).getTime(),
        labels: {
          datetimeUTC: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      title: {
        text: `Real-Time Power (${startDate.getDate()} ${GetMonth(
          true
        )} ${startDate.getFullYear()})`,
        align: 'center',
      },
    },
  };

  const total_power_usage = {
    series: mainMdb !== null ? [(mainMdb.subdev_3.energy * 100) / 5000000000] : [0],

    options: {
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#333',
            strokeWidth: '98%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
              formatter: function (val) {
                return val.toFixed(2) + ' %';
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          // type: 'horizontal',
          gradientToColors: ['#87D4F9'],
          stops: [0, 100],
        },
      },

      labels: ['kWh'],
    },
  };
  const average_power_usage = {
    series: mainMdb !== null ? [(mainMdb.subdev_3.energy * 100) / 5000000000] : [0],

    options: {
      colors: ['#EE989A'],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#333',
            strokeWidth: '98%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
              formatter: function (val) {
                return val.toFixed(2) + ' %';
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          // type: 'horizontal',
          gradientToColors: ['#FCEAEB'],
          stops: [0, 100],
        },
      },

      labels: ['kW'],
    },
  };
  const daily_power_cost = {
    series: mainMdb !== null ? [(mainMdb.subdev_3.energy * 100) / 5000000000] : [0],

    options: {
      colors: ['#AFDAC1'],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#333',
            strokeWidth: '98%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
              formatter: function (val) {
                return val.toFixed(2) + ' %';
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          // type: 'horizontal',
          gradientToColors: ['#D2E9DA'],
          stops: [0, 100],
        },
      },

      labels: ['฿'],
    },
  };
  const estimated_mounthly_power_cost = {
    series: mainMdb !== null ? [(mainMdb.subdev_3.energy * 100) / 5000000000] : [0],

    options: {
      colors: ['#4F9EC4'],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#333',
            strokeWidth: '98%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '22px',
              formatter: function (val) {
                return val.toFixed(2) + ' %';
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          // type: 'horizontal',
          gradientToColors: ['#6CB2D1'],
          stops: [0, 100],
        },
      },

      labels: ['฿'],
    },
  };

  return (
    <div className="content">
      <KlubRealtimeComponents
        main_graph={main_graph}
        main_mdb={mainMdb}
        startDate={startDate}
        stopDate={stopDate}
        handleStartDate={handleStartDate}
        handleStopDate={handleStopDate}
        sampling={sampling}
        fetchSampling={fetchSampling}
        total_power_usage={total_power_usage}
        average_power_usage={average_power_usage}
        daily_power_cost={daily_power_cost}
        estimated_mounthly_power_cost={estimated_mounthly_power_cost}
      />
    </div>
  );
};
