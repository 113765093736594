import React from 'react';
import { Card, CardHeader, CardBody, CardTitle, Col } from 'reactstrap';
// import Plot from 'react-plotly.js';
import Avatar from '@material-ui/core/Avatar';
import GridList from '@material-ui/core/GridList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 50,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(1),
  },
  gridList: {
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: 0,
  },
}));

const EnergyByDevice = (props) => {
  const maidUrl = props.maidUrl;
  const classes = useStyles();

  return (
    <Col md="3">
      <Card>
        <div style={{ height: '350px' }}>
          <CardHeader>
            <CardTitle>{props.name}</CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Plot
              data={props.data}
              layout={{ width: 250, height: 230, margin: { l: 60, t: 20, b: 30, r: 30 } }}
            /> */}

            <GridList className={classes.gridList} cols={0}>
              {maidUrl.map(
                (data, index) => {
                  return (
                    <div
                      key={`${index}`}
                      style={{
                        textAlign: 'center',
                        display: 'inline',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <Avatar
                        alt={`${Object.values(data)}`}
                        src={`${Object.values(data)}`}
                        className={classes.large}
                        key={`${index}`}
                      />
                      <br />
                      {Object.keys(data)}
                    </div>
                  );
                }

                // <div
                //   key={index}
                //   style={{ textAlign: 'center', height: 130 }}
                //   onClick={() => {
                //     console.log(index);
                //   }}
                // >
                //   <Avatar alt="#" src={data['url']} className={classes.large} key={index} />
                //   <div style={{ textAlign: 'center', display: 'inline-flex' }}>
                //     <div>
                //       <p>{data['name']}</p>
                //     </div>
                //   </div>
                // </div>
              )}
            </GridList>
          </CardBody>
        </div>
      </Card>
    </Col>
  );
};

export { EnergyByDevice };
