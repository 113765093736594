import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const CustomTable = styled.table`
  &&& {
    table,
    th,
    td {
      border: 0px solid black;
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      padding-left: 15px;
    }
    th {
      text-align: left;
    }
    table {
      width: 100%;
    }
  }
`;

const GuestInfoComponent = (props) => {
  const onClickEdit = props.onClickEdit;
  const currentUserData = props.currentUserData;
  // console.log({ currentUserData });
  const guestName = props.guestName;
  return (
    <div className="column is-12" id="info">
      <CustomTable>
        <tbody>
          {/* Guest Name */}
          {/* <tr>
            <td>
              <b>Guest Name:&nbsp;&nbsp;</b>
            </td>
            <td>
              {currentUserData.userdata['guest_list'] !== ''
                ? guestName !== null
                  ? guestName['guest_name']
                  : ''
                : ''}{' '}
              <b>&nbsp;</b>
            </td>
            <td>
              <a
                href="#edit-guest-name"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['guest_list'] !== ''
                      ? guestName !== null
                        ? guestName['guest_name']
                        : ''
                      : ''
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="guest-name" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr> */}

          <tr>
            <td>
              <b>Clean Status:&nbsp;&nbsp;</b>
            </td>
            <td>
              {currentUserData?.userdata?.['clean_status']}
              <b>&nbsp;</b>
            </td>
            <td>
              <a
                href="#edit-guest-id"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['clean_status'] !== ''
                      ? guestName !== null
                        ? guestName['clean_status']
                        : ''
                      : '',
                    'room_status_change'
                  )
                }
              >
                <FontAwesomeIcon title="guest-id" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Guest Id */}
          <tr>
            <td>
              <b>Guest ID:&nbsp;&nbsp;</b>
            </td>
            <td>
              {currentUserData.userdata['guest_list'] !== ''
                ? guestName !== null
                  ? guestName['guest_id']
                  : ''
                : ''}{' '}
              <b>&nbsp;</b>
            </td>
            <td>
              <a
                href="#edit-guest-id"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['guest_list'] !== ''
                      ? guestName !== null
                        ? guestName['guest_id']
                        : ''
                      : ''
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="guest-id" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Guest Nationality */}
          <tr>
            <td>
              <b>Guest nationality:&nbsp;&nbsp;</b>
            </td>
            <td>
              {currentUserData.userdata['guest_list'] !== ''
                ? guestName !== null
                  ? guestName['nationality']
                  : ''
                : ''}{' '}
              <b>&nbsp;</b>
            </td>
            <td>
              <a
                href="#edit-guest-nationality"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['guest_list'] !== ''
                      ? guestName !== null
                        ? guestName['nationality']
                        : ''
                      : ''
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="guest-nationality" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Guest Country */}
          <tr>
            <td>
              <b>Guest Country:&nbsp;&nbsp;</b>
            </td>
            <td>
              {currentUserData.userdata['guest_list'] !== ''
                ? guestName !== null
                  ? guestName['country']
                  : ''
                : ''}{' '}
              <b>&nbsp;</b>
            </td>
            <td>
              <a
                href="#edit-guest-country"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['guest_list'] !== ''
                      ? guestName !== null
                        ? guestName['countru']
                        : ''
                      : ''
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="guest-country" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Room Type */}
          <tr>
            <td>
              <b>Room type:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['room_type']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-room-type"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['room_type']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="room-type" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Check in */}
          <tr>
            <td>
              <b>Check in:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['check_in'] !== ''
                ? moment(currentUserData.userdata['check_in']).format('DD/MM/YYYY HH:mm')
                : ''}{' '}
              <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-check-in"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['check_in']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="check-in" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Check out */}
          <tr>
            <td>
              <b>Check out:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['check_out'] !== ''
                ? moment(currentUserData.userdata['check_out']).format('DD/MM/YYYY HH:mm')
                : ''}{' '}
              <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-check-out"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['check_out']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="check-out" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* People */}
          <tr>
            <td>
              <b>People:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['people']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-people"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['people']
                    //  lineId
                  )
                }
              >
                <FontAwesomeIcon title="people" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Adult */}
          <tr>
            <td>
              <b>Adult:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['adult']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-adult"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['adult']
                    //  lineId
                  )
                }
              >
                <FontAwesomeIcon title="adult" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Child */}
          <tr>
            <td>
              <b>Child:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['child']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-child"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['child']
                    //  lineId
                  )
                }
              >
                <FontAwesomeIcon title="child" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Abf */}
          {/* <tr>
          <td>
            <b>Abf:&nbsp;&nbsp;</b>
          </td>

          <td>
            {currentUserData.userdata['abf']} <b>&nbsp;</b>
          </td>

          <td>
            <a
              href="#edit-abf"
              onClick={(e) => onClickEdit(e, currentUserData.userdata['abf'], lineId)}
            >
              <FontAwesomeIcon title="abf" icon={faEdit} className="edit-icon" />
            </a>
          </td>
        </tr> */}

          {/* Grop Code */}
          <tr>
            <td>
              <b>Group code:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['group_code']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-group-code"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['group_code']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="group-code" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Sob Code */}
          <tr>
            <td>
              <b>Sob Code:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['sob_code']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-sob-code"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['sob_code']
                    //  lineId
                  )
                }
              >
                <FontAwesomeIcon title="sob-code" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Guest type code */}
          <tr>
            <td>
              <b>Guest type code:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['guest_type_code']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-guest-type-code"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['guest_type_code']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="guest-type-code" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Channel code */}
          <tr>
            <td>
              <b>Channel code:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['channel_code']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-channel-code"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['channel_code']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="channel-code" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Rate code */}
          <tr>
            <td>
              <b>Rate code:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['rate_code']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-rate-code"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['rate_code']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="rate-code" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Bostringing Type */}
          <tr>
            <td>
              <b>Booking Type:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['booking_type']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-bostringing-type"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['booking_type']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="booking-type" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Contact Name */}
          <tr>
            <td>
              <b>Contact Name:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['contact_name']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-contact-name"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['contact_name']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="contact-name" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Night */}
          <tr>
            <td>
              <b>Night:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['night']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-night"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['night']
                    //  lineId
                  )
                }
              >
                <FontAwesomeIcon title="night" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Group Name */}
          <tr>
            <td>
              <b>Group name:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['group_name']} <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-group-name"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['group_name']
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="group-name" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>

          {/* Seq */}
          <tr>
            <td>
              <b>Seq:&nbsp;&nbsp;</b>
            </td>

            <td>
              {currentUserData.userdata['guest_list'] !== ''
                ? guestName !== null
                  ? guestName['seq']
                  : ''
                : ''}{' '}
              <b>&nbsp;</b>
            </td>

            <td>
              <a
                href="#edit-seq"
                onClick={(e) =>
                  onClickEdit(
                    e,
                    currentUserData.userdata['guest_list'] !== ''
                      ? guestName !== null
                        ? guestName['seq']
                        : ''
                      : ''
                    // lineId
                  )
                }
              >
                <FontAwesomeIcon title="seq" icon={faEdit} className="edit-icon" />
              </a>
            </td>
          </tr>
        </tbody>
      </CustomTable>
    </div>
  );
};

export { GuestInfoComponent };
