import React, { useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { TimeGridScheduler, classes } from '@remotelock/react-week-scheduler/index.js';
import '@remotelock/react-week-scheduler/index.css';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import {
  AppName,
  AppBox,
  Container,
  ScheduleApp,
  AutomationApp,
  Header,
  ModalHeader,
  CloseButton,
  ScheduleOptionArea,
  GridSpace,
  SubmitButton,
  DropdownSpace,
} from './SceduleStyledComponents.components';
import { DefaultEventRootComponent } from './ScheduleDefaultEventRootComponent.components';
import Tippy from '@tippy.js/react';
import demoClasses from './index.module.scss';
import Select from 'react-select';

const rangeStrings = [];

const defaultSchedule = rangeStrings.map((range) =>
  range.map((dateString) => new Date(dateString))
);

const options = [
  { value: 'airconditioning', label: 'Airconditioning' },
  { value: 'fan', label: 'Fan' },
  { value: 'main_switch', label: 'Main light' },
  { value: 'bed_switch', label: 'Bed light' },
  { value: 'corridor_switch', label: 'Corridor light' },
  { value: 'window_switch', label: 'Window light' },
  { value: 'toilet_switch', label: 'Toilet light' },
  { value: 'wardrob_switch', label: 'Wardrob light' },
];

const EventRoot = React.forwardRef(function EventRoot({ handleDelete, disabled, ...props }, ref) {
  return (
    <Tippy
      arrow
      interactive
      enabled={!disabled}
      hideOnClick={false}
      className={demoClasses.tooltip}
      content={
        <button disabled={disabled} onClick={handleDelete}>
          Delete
        </button>
      }
    >
      <DefaultEventRootComponent
        handleDelete={handleDelete}
        disabled={disabled}
        {...props}
        ref={ref}
      />
    </Tippy>
  );
});

const ModalContainer = () => {
  const [modal, setModal] = useState(false);
  const [schedule, setSchedule] = useState(defaultSchedule);
  const [disabled] = useState(false);
  const toggle = () => setModal(!modal);

  const isControlURL = useSelector((state) => state.dashboard.isControlURL);
  const token = localStorage.getItem('token');

  const handleSubmit = () => {
    console.log(schedule);
    const temporalyPayload = {};
    const periodOfRoom = {};
    const roomOfDay = {};
    const action = {};
    const arrayOfWeekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    schedule.map(async (data) => {
      const nameOfDay = await arrayOfWeekdays[data[0].getDay()];
      const hourOfAction = await data[0].getHours();
      const minuteOfAction = await data[0].getMinutes();
      const timeOfAction = (await hourOfAction) + ':' + minuteOfAction;
      action['mode'] = 'cool';
      periodOfRoom['main_switch'] = action;
      roomOfDay[timeOfAction] = periodOfRoom;
      temporalyPayload[nameOfDay] = roomOfDay;
    });
    console.log(temporalyPayload);
    triggerAPI(isControlURL, token, temporalyPayload);
  };

  async function triggerAPI(URL, token, temporalyPayload) {
    const requestBody = {
      device: 'airconditioning',
      topic: 'devicecontrol',
      scope: 'local',
      room: 'room_201',
      building: 'mintel',
      RequestId: '12345',
      payload: temporalyPayload,
    };

    const requestOptions = {
      url: URL,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `token ${token}`,
      },
      data: JSON.stringify(requestBody),
    };

    try {
      let responseJson = await axios.request(requestOptions);
      console.log(responseJson.data);
    } catch {
      console.log('Request API error');
    }
  }

  return (
    <Container>
      <Row>
        <Header>Alto Apps</Header>
      </Row>
      <Row>
        <Col lg="3" md="4" sm="6">
          <AppBox>
            <ScheduleApp onClick={() => toggle()}></ScheduleApp>
            <AppName>Schedule</AppName>
          </AppBox>
        </Col>
        <Col lg="3" md="4" sm="6">
          <AppBox>
            <AutomationApp></AutomationApp>
            <AppName>Automation</AppName>
          </AppBox>
        </Col>
        <Modal isOpen={modal} toggle={toggle} size="xl">
          <ModalHeader>
            IOT devices schedule
            <CloseButton>
              <CloseOutline onClick={toggle}></CloseOutline>
            </CloseButton>
          </ModalHeader>
          <ScheduleOptionArea>
            <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
            <DropdownSpace>
              <Select options={options} />
            </DropdownSpace>
          </ScheduleOptionArea>
          <GridSpace>
            <TimeGridScheduler
              classes={classes}
              style={{ width: '110%', height: '100%', margin: '10px' }}
              originDate={new Date('2019-03-04')}
              schedule={schedule}
              onChange={setSchedule}
              visualGridVerticalPrecision={30}
              verticalPrecision={15}
              cellClickPrecision={60}
              eventRootComponent={EventRoot}
              disabled={disabled}
            />
          </GridSpace>
        </Modal>
      </Row>
    </Container>
  );
};

export { ModalContainer };
