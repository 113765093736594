import React from 'react';
// import { HistoryData, Info, ActivityData } from '../';

export const FridgeComponents = (props) => {
  const currentTab = props.currentTab;
  const openRefrigerator = true;
  function onClick1() {}
  const imagesPath = {
    open: '/assets/images/devices/icon-refrigerator-open.png',
    close: '/assets/images/devices/icon-refrigerator-close.png',
  };
  return (
    <>
      {props.menu}
      {/* Control tab */}
      {currentTab === 'control' && (
        <div className="iot-element" id="iot-control-refig">
          <div className="columns">
            <div
              className="column"
              style={{
                // border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div className="column">
                <img
                  alt="refrigerator-icon"
                  id="icon-refrigerator-close"
                  style={{
                    width: '150px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '7%',
                    cursor: 'pointer',
                  }}
                  src={openRefrigerator ? imagesPath.open : imagesPath.close}
                  onClick={(e) => onClick1(e)}
                />
              </div>
              <div className="column">
                <button
                  className={
                    // status.refrigerator.status === 'on'
                    openRefrigerator
                      ? 'button is-success device-controller'
                      : 'button is-unsuccess device-controller'
                  }
                  // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/50c7bf98ed3a/data/gang 0", "type": "on-off", "deviceid": "50c7bf98ed3a", "gang": 0, "icons":["icon-refrigerator-close","icon-refrigerator-open"]}'
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {/* {status.refrigerator.status === 'on' ? 'ON' : 'OFF'} */}
                  {openRefrigerator ? 'ON' : 'OFF'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <Info image={image_link} header={props.header} detail={props.detail} />
      )}
      {currentTab === 'activity' && <ActivityData />}
      {currentTab === 'history' && (
        <HistoryData
          data={hist_plot}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
        />
      )} */}
    </>
  );
};
