import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // overflow: 'hidden',
    // backgroundColor: theme.palette.background.paper,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(2),
  },
  gridList: {
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  Button: {
    fontSize: 10,
    margin: 3,
  },
}));

const ExistingMembers = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom style={{ marginLeft: 0 }}>
        Members
      </Typography>
      <GridList className={classes.gridList} cols={4}>
        {props?.newMembers && props?.newMembers.map((data, index) => (
          <div key={index} style={{ textAlign: 'center', display: 'inline-flex' }}>
            <Avatar alt="#" src={data['pictureUrl']} className={classes.large} key={index} />
            <div style={{
              textAlign: 'center', display: 'inline-flex', whiteSpace: "nowrap",
              width: '150px',
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              <div >
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '150px',
                  }}>NAME:{data['displayName']}</p>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '150px',
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}>LINE ID: {data['lineId']}</p>
                <div style={{
                  display: 'flex', flexDirection: 'column', rowGap: '4px'
                }}>
                  {!props.disableAccept &&
                    <Button
                      className={classes.Button}
                      variant="contained"
                      style={{ backgroundColor: '#52BE80', color: '#ffff', margin: 0, padding: 0 }}
                      onClick={() => props.acceptNewMember(data['lineId'])}
                    >
                      Accept
                    </Button>
                  }
                  {/** Need API for Canceling while onClick */}
                  <Button
                    className={classes.Button}
                    variant="contained"
                    style={{ backgroundColor: '#E74C3C', color: '#ffff', margin: 0, padding: 0 }}
                    onClick={() => props.deleteNewMember(data['lineId'])}
                  >
                    {props.disableAccept ? "Delete" : "Reject"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))
        }
        {
          props?.newMembers && props?.newMembers.length == 0 && (
            <p style={{ marginTop: '16px', marginBottom: '16px' }}>
              0 New Member
            </p>
          )
        }
      </GridList >
    </div >
  );
};

export { ExistingMembers };
