import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { FirebaseContext2 } from '../../../../../firebase';
//import { Info } from '../../../components/Tabs';
import { List } from '../../../../../components/List/TabList';
import { energyActions } from '../../../../energy/actions';
import moment from 'moment';
import { roomActivitiesHistories } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';

const SmartMeter = (props) => {
  const dispatch = useDispatch();
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);

  //const InitPlotData = useSelector((state) => state.historyEnergy.hist);
  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;

  const dropdown = useSelector((state) => state.hotel.dropdown);

  const [currentTab, setCurrentTab] = useState('real-time');
  const [tmpHist, settmpHist] = useState(null);

  async function histData(dev_id, nick_name) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        dropdown,
        nick_name,
        false
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(energyActions.clearHistoryData());
    dispatch(dashboardControlActions.showIotModal(false));
  };

  const firebase = useContext(FirebaseContext2);

  const [status, setStatus] = useState({
    smart_meter: {
      status: {
        current: null,
        energy: null,
        energy_reactive: null,
        energy_reactive_to_grid: null,
        energy_to_grid: null,
        power: null,
        power_apparent: null,
        power_reactive: null,
        voltage: null,
        timestamp: null,
      },
    },
  });

  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/smartmeter/electric/subdev_0';

    if (didMounted) {
      console.log('Smart Meter did mounted');
      await firebase.db.ref(fbpath).off('value');
    } else {
      await firebase.db.ref(fbpath).on('value', function (snap) {
        console.log('start');
        let capt = snap.val();
        if (capt !== null || undefined) {
          setStatus({
            smart_meter: {
              status: {
                current: capt.current,
                energy: capt.energy,
                energy_reactive: capt.energy_reactive,
                energy_reactive_to_grid: capt.energy_reactive_to_grid,
                energy_to_grid: capt.energy_to_grid,
                power: capt.power,
                power_apparent: capt.power_apparent,
                power_reactive: capt.power_reactive,
                voltage: capt.voltage,
                timestamp: capt.timestamp,
              },
            },
          });
        }
      });
    }
  }

  useEffect(() => {
    fetchData(firebase, currentRoomNo, false);

    return () => {
      fetchData(firebase, currentRoomNo, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tmpHist !== null) {
      let tmp1 = [];
      tmpHist.forEach((item) => {
        if (item.state !== null && item.timestamp !== null) {
          tmp1.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        // return setPlotHistData(tmp1);
      });
    }
  }, [tmpHist]);

  useEffect(() => {
    devices.forEach((element) => {
      if (element['device_name'] === 'fan') {
        try {
          histData(element.device_id, 'fan').then((results) => {
            return settmpHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown, currentRoomNo]);

  // useEffect(() => {
  //   if (status.smart_meter.status.power !== null && status.smart_meter.status.energy !== null) {
  //     dispatch(
  //       energyActions.mintelHistoryData({
  //         hist: {
  //           fridge: {
  //             power: [],
  //             state: [],
  //           },
  //           smart_meter: {
  //             energy: [
  //               Number(moment(status.smart_meter.status.timestamp).format('x')),
  //               Number(status.smart_meter.status.energy).toFixed(2),
  //             ],
  //             power: [
  //               Number(moment(status.smart_meter.status.timestamp).format('x')),
  //               Number(status.smart_meter.status.power).toFixed(2),
  //             ],
  //           },
  //           ac: [],
  //           back_switch: [],
  //           fan: [],
  //           front_switch: [],
  //           smart_tv: [],
  //         },
  //       })
  //     );
  //   }
  // }, [status, dispatch]);

  //##-------------------Tab menu------------------------------

  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };

  // const image_link =
  //   'https://media.springernature.com/lw685/springer-static/image/art%3A10.1038%2Fs41597-019-0212-5/MediaObjects/41597_2019_212_Fig1_HTML.png';

  // const hist_plot = {
  //   series: [
  //     {
  //       name: 'Energy',
  //       // data: plotHistData.concat(Main_mdb.plot),
  //       // data: InitPlotData.front_switch.concat(TmpData),
  //       // name: 'Switch',
  //       // data:
  //       //   plotHistData !== undefined && plotHistData !== null && InitPlotData.tmp !== undefined
  //       //     ? plotHistData
  //       //     : [],
  //       data: InitPlotData.smart_meter.energy,
  //     },
  //     {
  //       name: 'Power',

  //       data: InitPlotData.smart_meter.power,
  //     },
  //   ],
  //   options: {
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     markers: {
  //       size: 0,
  //       // style: 'hollow',
  //     },
  //     stroke: {
  //       curve: 'smooth',
  //       width: 2,
  //     },
  //     xaxis: {
  //       type: 'datetime',

  //       // min: new Date('12/06/2020').getTime(),
  //       // max: new Date('12/07/2020').getTime(),
  //       labels: {
  //         datetimeUTC: false,
  //       },
  //     },

  //     yaxis: [
  //       {
  //         seriesName: 'Energy',
  //         axisTicks: {
  //           show: true,
  //         },
  //         axisBorder: {
  //           show: true,
  //           color: '#008FFB',
  //         },
  //         labels: {
  //           style: {
  //             color: '#008FFB',
  //           },
  //         },
  //         title: {
  //           text: 'Energy (Wh)',
  //           style: {
  //             color: '#008FFB',
  //           },
  //         },
  //         tooltip: {
  //           enabled: true,
  //         },
  //       },
  //       {
  //         seriesName: 'Power',
  //         opposite: true,
  //         axisTicks: {
  //           show: true,
  //         },
  //         axisBorder: {
  //           show: true,
  //           color: '#00E396',
  //         },
  //         labels: {
  //           style: {
  //             color: '#00E396',
  //           },
  //         },
  //         title: {
  //           text: 'Power (Watt)',
  //           style: {
  //             color: '#00E396',
  //           },
  //         },
  //       },
  //     ],

  //     title: {
  //       text: 'Activity in 24 hrs',
  //       align: 'left',
  //     },
  //     tooltip: {
  //       x: {
  //         format: 'dd.MM.yyyy HH:mm',
  //       },
  //     },
  //   },
  // };

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Smart Meter
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="ucount-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'real-time'}
                        onClick={(e) => onClickTabMenu(e, 'real-time')}
                      >
                        REAL TIME
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        // onClick={(e) => onClickTabMenu(e, 'info')}
                        disabled={true}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        // onClick={(e) => onClickTabMenu(e, 'info')}
                        disabled={true}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        // onClick={(e) => onClickTabMenu(e, 'info')}
                        disabled={true}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>

                  {/* Control tab */}
                  {currentTab === 'real-time' && (
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                      <table className="table table-borderless" style={{ width: '80%' }}>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Topic</th>
                            <th scope="col">Values</th>
                            <th scope="col">Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>Power</td>
                            <td>{status.smart_meter.status.power}</td>
                            <td>kW</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Energy</td>
                            <td>{status.smart_meter.status.energy}</td>
                            <td>kWh</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td>Energy Reactive</td>
                            <td>{status.smart_meter.status.energy_reactive}</td>
                            <td>kVARh</td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td>Energy Reactive to Grid</td>
                            <td>{status.smart_meter.status.energy_reactive_to_grid}</td>
                            <td>kVARh</td>
                          </tr>
                          <tr>
                            <th scope="row">5</th>
                            <td>Energy to Grid</td>
                            <td>{status.smart_meter.status.energy_to_grid}</td>
                            <td>kWh</td>
                          </tr>
                          <tr>
                            <th scope="row">6</th>
                            <td>Power Apparent</td>
                            <td>{status.smart_meter.status.power_apparent}</td>
                            <td>kVA</td>
                          </tr>
                          <tr>
                            <th scope="row">7</th>
                            <td>Power Reactive</td>
                            <td>{status.smart_meter.status.power_reactive}</td>
                            <td>kVAR</td>
                          </tr>
                          <tr>
                            <th scope="row">8</th>
                            <td>Voltage</td>
                            <td>{status.smart_meter.status.voltage}</td>
                            <td>V</td>
                          </tr>
                          <tr>
                            <th scope="row">9</th>
                            <td>Current</td>
                            <td>{status.smart_meter.status.current}</td>
                            <td>A</td>
                          </tr>
                          <tr>
                            <th scope="row">10</th>
                            <td>Updated at</td>
                            <td>{status.smart_meter.status.timestamp !==null && status.smart_meter.status.timestamp.substring(0,19)}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  {/* {currentTab === 'info' && (
                    <Info image={image_link} header={props.header} detail={props.detail} />
                  )} */}
                  {/* {currentTab === 'activity' && <ActivityData />}
                  {currentTab === 'history' && <HistoryData data={hist_plot} />} */}
                </nav>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export { SmartMeter };
