export const MapAir = [
  //Map Done
  {
    name: 'air1',
    x1: 0.10465277777777778,
    y1: 0.08444444444444445,
    x2: 0.11909722222222222,
    y2: 0.09925925925925926,
    triggers: ['T-6C1F-01', 'airconditioning_1', 'ac'],
  },
  //Map Done
  {
    name: 'air2',
    x1: 0.18243055555555557,
    y1: 0.09333333333333334,
    x2: 0.196875,
    y2: 0.10962962962962963,
    triggers: ['T-6C1F-02', 'airconditioning_2', 'ac'],
  },
  //Map Done
  {
    name: 'air3',
    x1: 0.23020833333333332,
    y1: 0.09185185185185185,
    x2: 0.24465277777777777,
    y2: 0.10962962962962963,
    triggers: ['T-6C1F-03', 'airconditioning_3', 'ac'],
  },
  //Map Done
  {
    name: 'air4',
    x1: 0.3257638888888889,
    y1: 0.09037037037037036,
    x2: 0.3435416666666667,
    y2: 0.1111111111111111,
    triggers: ['T-6C1F-04', 'airconditioning_4', 'ac'],
  },
  //Map Done
  {
    name: 'air5',
    x1: 0.3957638888888889,
    y1: 0.07851851851851852,
    x2: 0.41131944444444446,
    y2: 0.10074074074074074,
    triggers: ['T-6C1F-05', 'airconditioning_5', 'ac'],
  },
  //Map Done
  {
    name: 'air6',
    x1: 0.4690972222222222,
    y1: 0.1037037037037037,
    x2: 0.48354166666666665,
    y2: 0.11703703703703704,
    triggers: ['T-6C1F-06', 'airconditioning_6', 'ac'],
  },
  //Map Done
  {
    name: 'air7',
    x1: 0.5579861111111111,
    y1: 0.14814814814814814,
    x2: 0.5735416666666666,
    y2: 0.1688888888888889,
    triggers: ['T-6C1F-07', 'airconditioning_7', 'ac'],
  },
  //Map Done
  {
    name: 'air8',
    x1: 0.12243055555555556,
    y1: 0.19407407407407407,
    x2: 0.14131944444444444,
    y2: 0.21333333333333335,
    triggers: ['T-6C1F-08', 'airconditioning_8', 'ac'],
  },
  //Map Done
  {
    name: 'air9',
    x1: 0.07798611111111112,
    y1: 0.2325925925925926,
    x2: 0.09465277777777778,
    y2: 0.25333333333333335,
    triggers: ['T-6C1F-09', 'airconditioning_9', 'ac'],
  },
  //Map Done
  {
    name: 'air10',
    x1: 0.12243055555555556,
    y1: 0.30518518518518517,
    x2: 0.14020833333333332,
    y2: 0.3288888888888889,
    triggers: ['T-6C1F-10', 'airconditioning_10', 'ac'],
  },
  //Map Done
  {
    name: 'air11',
    x1: 0.07465277777777778,
    y1: 0.36,
    x2: 0.09465277777777778,
    y2: 0.38074074074074077,
    triggers: ['T-6C1F-11', 'airconditioning_11', 'ac'],
  },
  //Map Done
  {
    name: 'air12',
    x1: 0.12243055555555556,
    y1: 0.4103703703703704,
    x2: 0.13909722222222223,
    y2: 0.4311111111111111,
    triggers: ['T-6C1F-12', 'airconditioning_12', 'ac'],
  },
  //Map Done
  {
    name: 'air13',
    x1: 0.07465277777777778,
    y1: 0.45185185185185184,
    x2: 0.09243055555555556,
    y2: 0.47555555555555556,
    triggers: ['T-6C1F-13', 'airconditioning_13', 'ac'],
  },
  //Map Done
  {
    name: 'air14',
    x1: 0.2657638888888889,
    y1: 0.2311111111111111,
    x2: 0.2835416666666667,
    y2: 0.25333333333333335,
    triggers: ['T-6C1F-14', 'airconditioning_14', 'ac'],
  },
  //Map Done
  {
    name: 'air15',
    x1: 0.226875,
    y1: 0.34814814814814815,
    x2: 0.2457638888888889,
    y2: 0.3674074074074074,
    triggers: ['T-6C1F-15', 'airconditioning_15', 'ac'],
  },
  //Map Done
  {
    name: 'air16',
    x1: 0.20131944444444444,
    y1: 0.43703703703703706,
    x2: 0.216875,
    y2: 0.4533333333333333,
    triggers: ['T-6C1F-16', 'airconditioning_16', 'ac'],
  },
  //Map Done
  {
    name: 'air17',
    x1: 0.246875,
    y1: 0.4311111111111111,
    x2: 0.2657638888888889,
    y2: 0.45185185185185184,
    triggers: ['T-6C1F-17', 'airconditioning_17', 'ac'],
  },
  //Map Done
  {
    name: 'air18',
    x1: 0.1790972222222222,
    y1: 0.5511111111111111,
    x2: 0.19131944444444443,
    y2: 0.5688888888888889,
    triggers: ['T-6C1F-18', 'airconditioning_18', 'ac'],
  },
  //Map Done
  {
    name: 'air19',
    x1: 0.23798611111111112,
    y1: 0.5614814814814815,
    x2: 0.256875,
    y2: 0.5837037037037037,
    triggers: ['T-6C1F-19', 'airconditioning_19', 'ac'],
  },
  //Map Done
  {
    name: 'air20',
    x1: 0.34576388888888887,
    y1: 0.1925925925925926,
    x2: 0.36354166666666665,
    y2: 0.21777777777777776,
    triggers: ['T-6C1F-20', 'airconditioning_20', 'ac'],
  },
  //Map Done
  {
    name: 'air21',
    x1: 0.41354166666666664,
    y1: 0.19407407407407407,
    x2: 0.4313194444444444,
    y2: 0.2162962962962963,
    triggers: ['T-6C1F-21', 'airconditioning_21', 'ac'],
  },
  //Map Done
  {
    name: 'air22',
    x1: 0.5013194444444444,
    y1: 0.2162962962962963,
    x2: 0.5179861111111111,
    y2: 0.23407407407407407,
    triggers: ['T-6C1F-22', 'airconditioning_22', 'ac'],
  },
  //Map Done
  {
    name: 'air23',
    x1: 0.3479861111111111,
    y1: 0.2785185185185185,
    x2: 0.36354166666666665,
    y2: 0.2992592592592593,
    triggers: ['T-6C1F-23', 'airconditioning_23', 'ac'],
  },
  //Map Done
  {
    name: 'air24',
    x1: 0.34576388888888887,
    y1: 0.3585185185185185,
    x2: 0.36354166666666665,
    y2: 0.3762962962962963,
    triggers: ['T-6C1F-24', 'airconditioning_24', 'ac'],
  },
  //Map Done
  {
    name: 'air25',
    x1: 0.5013194444444444,
    y1: 0.27555555555555555,
    x2: 0.5179861111111111,
    y2: 0.2992592592592593,
    triggers: ['T-6C1F-25', 'airconditioning_25', 'ac'],
  },
  //Map Done
  {
    name: 'air26',
    x1: 0.5157638888888889,
    y1: 0.37333333333333335,
    x2: 0.5357638888888889,
    y2: 0.4,
    triggers: ['T-6C1F-26', 'airconditioning_26', 'ac'],
  },
  //Map Done
  {
    name: 'air27',
    x1: 0.3979861111111111,
    y1: 0.49037037037037035,
    x2: 0.41354166666666664,
    y2: 0.5140740740740741,
    triggers: ['T-6C1F-27', 'airconditioning_27', 'ac'],
  },
  //Map Done
  {
    name: 'air28',
    x1: 0.30020833333333335,
    y1: 0.5777777777777777,
    x2: 0.316875,
    y2: 0.5985185185185186,
    triggers: ['T-6C1F-28', 'airconditioning_28', 'ac'],
  },
  //Map Done
  {
    name: 'air29',
    x1: 0.3046527777777778,
    y1: 0.47555555555555556,
    x2: 0.3202083333333333,
    y2: 0.49925925925925924,
    triggers: ['T-6C1F-29', 'airconditioning_29', 'ac'],
  },
  //Map Done
  {
    name: 'air30',
    x1: 0.4031791852388768,
    y1: 0.6107899807321773,
    x2: 0.42196531240650687,
    y2: 0.6319845857418112,
    triggers: ['T-6C1F-30', 'airconditioning_30', 'ac'],
  },
  //Map Done
  {
    name: 'air31',
    x1: 0.42485548581691146,
    y1: 0.5414258188824663,
    x2: 0.4421965262793392,
    y2: 0.5606936416184971,
    triggers: ['T-6C1F-31', 'airconditioning_31', 'ac'],
  },
  //Map Done
  {
    name: 'air32',
    x1: 0.5534682025799172,
    y1: 0.5452793834296724,
    x2: 0.5693641563371427,
    y2: 0.5664739884393064,
    triggers: ['T-6C1F-32', 'airconditioning_32', 'ac'],
  },
  //Map Done
  {
    name: 'air33',
    x1: 0.5968208037359866,
    y1: 0.5838150289017341,
    x2: 0.6141618441984144,
    y2: 0.603082851637765,
    triggers: ['T-6C1F-33', 'airconditioning_33', 'ac'],
  },
  //Map Done
  {
    name: 'air34',
    x1: 0.46965317367818316,
    y1: 0.5761078998073218,
    x2: 0.4869942141406109,
    y2: 0.5953757225433526,
    triggers: ['T-6C1F-34', 'airconditioning_34', 'ac'],
  },
  //Map Done
  {
    name: 'air35',
    x1: 0.4436416129845415,
    y1: 0.6396917148362236,
    x2: 0.4638728268573739,
    y2: 0.6570327552986512,
    triggers: ['T-6C1F-35', 'airconditioning_35', 'ac'],
  },
  //Map Done
  {
    name: 'air36',
    x1: 0.367052017608819,
    y1: 0.6801541425818882,
    x2: 0.3829479713660444,
    y2: 0.7013487475915221,
    triggers: ['T-6C1F-36', 'airconditioning_36', 'ac'],
  },
  //Map Done
  {
    name: 'air37',
    x1: 0.4046242719440791,
    y1: 0.7109826589595376,
    x2: 0.42196531240650687,
    y2: 0.7341040462427746,
    triggers: ['T-6C1F-37', 'airconditioning_37', 'ac'],
  },
  //Map Done
  {
    name: 'air38',
    x1: 0.44942195980535077,
    y1: 0.7442884720818845,
    x2: 0.4667630002677785,
    y2: 0.7674098593651215,
    triggers: ['T-6C1F-38', 'airconditioning_38', 'ac'],
  },
  //Map Done
  {
    name: 'air39',
    x1: 0.6358381447764491,
    y1: 0.19515552410500586,
    x2: 0.6531791852388767,
    y2: 0.21827691138824287,
    triggers: ['T-6C1F-39', 'airconditioning_39', 'ac'],
  },
  //Map Done
  {
    name: 'air40',
    x1: 0.728323693909397,
    y1: 0.2722268150491292,
    x2: 0.7442196476666224,
    y2: 0.2953482023323662,
    triggers: ['T-6C1F-40', 'airconditioning_40', 'ac'],
  },
  //Map Done
  {
    name: 'air41',
    x1: 0.5722543297475473,
    y1: 0.33003028325722167,
    x2: 0.5881502835047727,
    y2: 0.35315167054045865,
    triggers: ['T-6C1F-41', 'airconditioning_41', 'ac'],
  },
  //Map Done
  {
    name: 'air42',
    x1: 0.5606936361059288,
    y1: 0.3897605337389172,
    x2: 0.5809248499787611,
    y2: 0.4148087032957573,
    triggers: ['T-6C1F-42', 'airconditioning_42', 'ac'],
  },
  //Map Done
  {
    name: 'air43',
    x1: 0.6473988384180676,
    y1: 0.3859069691917111,
    x2: 0.6661849655856976,
    y2: 0.41095513874855116,
    triggers: ['T-6C1F-43', 'airconditioning_43', 'ac'],
  },
  //Map Done
  {
    name: 'air44',
    x1: 0.7297687806145994,
    y1: 0.4148087032957573,
    x2: 0.7499999944874317,
    y2: 0.4379300905789943,
    triggers: ['T-6C1F-44', 'airconditioning_44', 'ac'],
  },
  //Map Done
  {
    name: 'air45',
    x1: 0.6011560638515936,
    y1: 0.4186622678429635,
    x2: 0.6184971043140213,
    y2: 0.44371043739980354,
    triggers: ['T-6C1F-45', 'airconditioning_45', 'ac'],
  },
  //Map Done
  {
    name: 'air46',
    x1: 0.6040462372619981,
    y1: 0.27415359732273226,
    x2: 0.6228323644296282,
    y2: 0.3030553314267785,
    triggers: ['T-6C1F-46', 'airconditioning_46', 'ac'],
  },
  //Map Done
  {
    name: 'air47',
    x1: 0.6777456592273161,
    y1: 0.33195706553082477,
    x2: 0.6921965262793393,
    y2: 0.35700523508766485,
    triggers: ['T-6C1F-47', 'airconditioning_47', 'ac'],
  },
  //Map Done
  {
    name: 'air48',
    x1: 0.6358381447764491,
    y1: 0.52270851061753,
    x2: 0.6531791852388767,
    y2: 0.545829897900767,
    triggers: ['T-6C1F-48', 'airconditioning_48', 'ac'],
  },
  //Map Done
  {
    name: 'air49',
    x1: 0.6358381447764491,
    y1: 0.4494907842206128,
    x2: 0.6575144453544837,
    y2: 0.47453895377745287,
    triggers: ['T-6C1F-49', 'airconditioning_49', 'ac'],
  },
  //Map Done
  {
    name: 'air50',
    x1: 0.5910404569151774,
    y1: 0.5246352928911331,
    x2: 0.6127167574932121,
    y2: 0.5496834624479732,
    triggers: ['T-6C1F-50', 'airconditioning_50', 'ac'],
  },
];
