import React from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';

const ScheduleRooms = [{ value: '6C1', label: '6C1' }];

export const RoomSetting = (props) => {
  return (
    <div>
      <Col lg="12" md="12">
        <Row>
          <Col lg="12" md="12" sm="12" style={{ marginLeft: '15px', paddingLeft: '0' }}>
            <h5 style={{ marginTop: '20px', borderLeft: '0' }}>1. Select a room</h5>
            <Row style={{ marginLeft: '20px' }}>
              <Col lg="5">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="roomSelect"
                  closeMenuOnSelect={true}
                  value={props.settingRoom}
                  onChange={(value) => props.setSettingRoom(value)}
                  options={ScheduleRooms}
                  placeholder="Select Rooms"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
