import React from 'react';
import { Row, Card, Table, Col } from 'react-bootstrap';
import Chart from 'react-apexcharts';

export const MintelDeviceOperationComponent = (props) => {
  const aa = props.system;
  const af = Object.keys(aa);

  function listData() {
    let res = [];
    for (let i = 0; i < af.length; i++) {
      res.push(
        <tr key={i}>
          <td>{af[i]}</td>
          <td>{aa[af[i]].day}</td>
          <td>{aa[af[i]].location}</td>
          <td>{aa[af[i]].on}</td>
          <td>{aa[af[i]].off}</td>
          <td>{aa[af[i]].note}</td>
        </tr>
      );
    }
    return res;
  }

  return (
    <div>
      <Card>
        <Card.Title>
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <h5 style={{ textAlign: 'center', marginBottom: '0' }}>Device Operation Schedule</h5>
          </div>
        </Card.Title>
        <Card.Body>
          <Row style={{ paddingLeft: '3%', paddingRight: '3%' }}>
            <Col md="12">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>System</th>
                    <th>Location</th>
                    <th>Day</th>
                    <th>On</th>
                    <th>Off</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {listData()}
                  {/* {props.system !== '' &&
                    props.system.map((item) => (
                      <tr key={item.length}>
                        <td>{item}</td>
                        <td>{item.location}</td>
                        <td>{item.day}</td>
                        <td>{item.on}</td>
                        <td>{item.off}</td>
                        <td>{item.on}</td>
                        <td>{item.note}</td>
                      </tr>
                    ))} */}
                  {/* <tr>
                    <td>Lighting System</td>
                    <td>Office</td>
                    <td>Mon-Fri</td>
                    <td>07:30</td>
                    <td>17:30</td>
                  </tr>
                  <tr>
                    <td>Lighting System</td>
                    <td>Outdoor</td>
                    <td>Mon-Sun</td>
                    <td>18:00</td>
                    <td>06:30</td>
                  </tr>
                  <tr>
                    <td>HVAC System</td>
                    <td>Office</td>
                    <td>Mon-Fri</td>
                    <td>07:30</td>
                    <td>17:30</td>
                  </tr>
                  <tr>
                    <td>Pump</td>
                    <td>Ground Floor</td>
                    <td>Mon-Fri</td>
                    <td>05:00</td>
                    <td>07:00</td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Chart
                series={props.plot_bar.series}
                options={props.plot_bar.options}
                type="bar"
                width="100%"
                height="300"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
