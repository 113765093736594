import React from 'react';
import { Col, Row, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { DatePickers } from 'components';
import Chart from 'react-apexcharts';

const TdpkElectricityByHoursComponents = (props) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <Col lg="12" sm="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Select Time</CardTitle>
          </CardHeader>
          <Row style={{ padding: '20px', paddingTop: '30px' }}>
            <Col lg="4" sm="6">
              <DatePickers
                daySelectStart={props.startDate}
                daySelectStop={props.stopDate}
                handleStartDate={props.handleStartDate}
                handleStopDate={props.handleStopDate}
              />
            </Col>
            <Col lg="4" sm="6"></Col>
          </Row>
          <CardBody>
            <Row>
              <Col lg="12" md="12" sm="12">
                <Chart
                  options={props.plotData.options}
                  series={props.plotData.series}
                  type="bar"
                  height="300"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export { TdpkElectricityByHoursComponents };
