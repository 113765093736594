import axios from 'axios';
import { API_CONSTANTS } from '../../api';

async function getAllActivities(roomNumber, deviceId, sub_dev) {
  const token = localStorage.getItem('token');
  console.log(roomNumber,deviceId,sub_dev)

  const payload = {
    RequestId: roomNumber,
    device_id: deviceId,
    subdevice_idx: sub_dev,
    type: 'switch',
  };

  const requestOptions = {
    url: `${API_CONSTANTS.GET_ALL_ACTIVITIES_URL}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `token ${token}`,
    },
    data: JSON.stringify(payload),
  };

  try {
    let responseJson = await axios.request(requestOptions);
    return responseJson;
  } catch (error) {
    console.error('[Services] getAllActivities Error:');
    console.error(error);

    var data = { requestError: error.message, response: error.response };
    return data;
  }
}

export const roomActivities = {
  getAllActivities,
};
