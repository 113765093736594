import React from 'react';
// import { ActivityData, HistoryData, Info } from '../';

export const SmartMeterComponents = (props) => {
  const currentTab = props.currentTab;
  const status = null;
  return (
    <>
      {props.menu}
      {/* Control tab */}
      {currentTab === 'control' && (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <table className="table table-borderless" style={{ width: '80%' }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Topic</th>
                <th scope="col">Values</th>
                <th scope="col">Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Power</td>
                <td>{status.smart_meter.status.power}</td>
                <td>kW</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Energy</td>
                <td>{status.smart_meter.status.energy}</td>
                <td>kWh</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Energy Reactive</td>
                <td>{status.smart_meter.status.energy_reactive}</td>
                <td>kVARh</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Energy Reactive to Grid</td>
                <td>{status.smart_meter.status.energy_reactive_to_grid}</td>
                <td>kVARh</td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>Energy to Grid</td>
                <td>{status.smart_meter.status.energy_to_grid}</td>
                <td>kWh</td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>Power Apparent</td>
                <td>{status.smart_meter.status.power_apparent}</td>
                <td>kVA</td>
              </tr>
              <tr>
                <th scope="row">7</th>
                <td>Power Reactive</td>
                <td>{status.smart_meter.status.power_reactive}</td>
                <td>kVAR</td>
              </tr>
              <tr>
                <th scope="row">8</th>
                <td>Voltage</td>
                <td>{status.smart_meter.status.voltage}</td>
                <td>V</td>
              </tr>
              <tr>
                <th scope="row">9</th>
                <td>Current</td>
                <td>{status.smart_meter.status.current}</td>
                <td>A</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <Info image={image_link} header={props.header} detail={props.detail} />
      )}
      {currentTab === 'activity' && <ActivityData />}
      {currentTab === 'history' && <HistoryData data={hist_plot} />} */}
    </>
  );
};
