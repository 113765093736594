import React, { useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardControlActions } from '../../dashboard/actions';
import { hotelActions } from '../../hotel/actions';
import { ModalContainer } from '../components/IotTab/ModalContainer.components';
import {
  AcControl,
  LightSwitchBackControl,
  RefrigeratorControl,
  SmartMeter,
  SmartTVControl,
  elementPositions,
  ExhaustFanControl,
  LightSwitchFrontControl,
  EnvSensor,
} from './IotDevices';
import styled from 'styled-components';

const Box = styled.div`
  position: absolute;
  padding: 10px;
  background-color: rgba(96, 96, 96, 0.7);
  border-radius: 4px;
  z-index: 200;
  width: 50px;
  height: 50px;
`;

const Status = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 5px;
  text-decoration: bold;
  text-align: center;
  font-size: 12px;
  background-color: ${(props) => (props.status ? '#5bce7b' : 'gray')};
  color: white;
  justify-content: center;
  font-weight: 600;
`;

function IotTab({ deviceStatus, fetchDevicesState }) {
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const dispatch = useDispatch();
  const [header, setHeader] = useState([]);
  const [detail, setDetail] = useState([]);
  const [subDevices, setSubDevices] = useState([]);
  const imageRef = useRef(null);
  const [isCursor, setIsCursor] = useState(false);
  const [dropDownActivity, setDropDownActivity] = useState(0);
  const [currentName, setCurrentName] = useState('');
  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);

  const showIoTModal = useSelector((state) => state.dashboard.showIotModal);

  const modalName = useSelector((state) => state.dashboard.modalName);

  const handleStartDate = (event) => {
    setStartDate(event);
  };

  const handleStopDate = (event) => {
    setStopDate(event);
  };

  const handleClickDropdown = (data) => {
    setDropDownActivity(Number(data));
  };

  const reverseText = (text) => {
    return text.split(':').reverse().join(':');
  };

  //##--------------------------- Fetch Activity Data ---------------------------
  const fetchModalActivityData = async (name) => {
    setCurrentName(name);
    if (devices && (name === 'smart_meter' || name === 'fridge')) {
      devices.forEach((element) => {
        if (element['device_name'] === 'front_switch') {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);
          if (element.subdevices) {
            setSubDevices(Object.values(element.subdevices).map((object) => object.nickname));
          }
          try {
            dispatch(
              hotelActions.getAllActivities(
                currentRoomNo,
                element.device_id,
                dropDownActivity,
                'front_switch',
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    } else if (devices && (name === 'smart_tv' || name === 'broadlinkenv')) {
      devices.forEach((element) => {
        if (element['device_name'] === name) {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);
          if (element.subdevices) {
            setSubDevices(Object.values(element.subdevices).map((object) => object.nickname));
          }
          try {
            dispatch(
              hotelActions.getAllActivities(
                currentRoomNo,
                reverseText(element.device_id),
                dropDownActivity,
                name,
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    } else if (devices) {
      devices.forEach((element) => {
        if (element['device_name'] === name) {
          let headerbefore = [];
          let detailbefore = [];
          Object.keys(element).forEach((key) => {
            headerbefore.push(key);
            detailbefore.push(JSON.stringify(element[key]));
          });
          setHeader(headerbefore);
          setDetail(detailbefore);

          if (element.subdevices) {
            setSubDevices(Object.values(element.subdevices).map((object) => object.nickname));
          }
          try {
            dispatch(
              hotelActions.getAllActivities(
                currentRoomNo,
                element.device_id,
                dropDownActivity,
                name,
                true,
                startDate,
                stopDate
              )
            );
          } catch {
            console.log('Fetch error');
          }
        }
      });
    }
  };

  //##------------------------ Event handler ------------------------------------
  const onClickPropOnImage = (event) => {
    event.stopPropagation();
    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;

        var rect = event.target.getBoundingClientRect();
        var xr = (event.clientX - rect.left) / w;
        var yr = (event.clientY - rect.top) / h;

        var elems = elementPositions;

        for (var ei in elems) {
          var el = elems[ei];
          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            // eslint-disable-next-line
            devices.forEach((element) => {
              if (element['ui_location'] === el['location']) {
                if (el['display']) {
                  dispatch(dashboardControlActions.showIotModal(true));
                  dispatch(dashboardControlActions.isControlIot());
                  fetchModalActivityData(el['triggers'][0]);
                  setDropDownActivity(0);
                  dispatch(dashboardControlActions.modalName(el['triggers'][0]));
                }
              }
            });
          }
        }
      }
    }
  };

  const hideDevices = () => {
    const devicesPos = [1, 2, 3, 4, 5, 6, 7, 8];
    let mapLocation = {
      1: { left: '32em', top: '14.7em' },
      2: { left: '0.5em', top: '9.4em' },
      3: { left: '6em', top: '1.5em' },
      4: { left: '19em', top: '1.5em' },
      5: { left: '6em', top: '21.9em' },
      6: { left: '0.5em', top: '16em' },
      7: { left: '14.5em', top: '21.7em' },
      8: { left: '32em', top: '10.4em' },
    };

    let statusMapLocation = {
      1: { left: '42.6em', top: '17.7em', zIndex: 999 },
      2: { left: '0.5em', top: '10.5em', zIndex: 999 },
      3: { left: '7.8em', top: '0em', zIndex: 999 },
      4: { display: 'none', zIndex: 999 },
      5: { left: '6em', top: '21.9em', zIndex: 999 },
      6: { left: '0.6em', top: '19.4em', zIndex: 999 },
      7: { display: 'none', zIndex: 999 },
      8: { display: 'none', zIndex: 999 },
    };
    let tmpArray = [];
    if (devices !== null) {
      // eslint-disable-next-line
      devices.map(function (element) {
        let dd = devicesPos.includes(element['ui_location']);
        if (dd) {
          return tmpArray.push(element['ui_location']);
        }
      });
    }

    if (tmpArray !== []) {
      let difference = devicesPos.filter((x) => !tmpArray.includes(x));

      // eslint-disable-next-line
      return devicesPos.map(function (element, index) {
        if (difference.includes(element)) {
          return <Box style={mapLocation[element]} key={index}></Box>;
        } else {
          return (
            <>
              {deviceStatus[element] ? (
                deviceStatus[element].reduce((sum, next) => sum || next, false) ? (
                  <Status style={statusMapLocation[element]} status={true}>
                    ON
                  </Status>
                ) : (
                  <Status style={statusMapLocation[element]} status={false}>
                    OFF
                  </Status>
                )
              ) : null}
            </>
          );
        }
      });
    }
  };

  const onChangeMouseCursor = async (event) => {
    event.stopPropagation();
    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;
        var rect = event.target.getBoundingClientRect();
        var xr = (event.clientX - rect.left) / w;
        var yr = (event.clientY - rect.top) / h;
        var elems = elementPositions;

        let isHoverOnOneIcon = false;

        for (var ei in elems) {
          var el = elems[ei];
          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            // eslint-disable-next-line
            devices.forEach((element) => {
              if (element['ui_location'] === el['location']) {
                isHoverOnOneIcon = true;
              }
            });
          }
        }

        setIsCursor(isHoverOnOneIcon);
      }
    }
  };

  useMemo(() => {
    // console.log(deviceStatus);
    fetchModalActivityData(currentName);
    // eslint-disable-next-line
  }, [dropDownActivity, deviceStatus]);

  if (devices === undefined) {
    return 'Loading...';
  }

  //##------------------------ Render -----------------------------------
  return (
    <div className="tab-content column cgroup-1" id="IOT">
      <div style={{ position: 'relative' }}>
        <img
          ref={imageRef}
          alt="room-floor-plan"
          id="iot-room-floorplan-image"
          onClick={onClickPropOnImage}
          onMouseMove={(e) => onChangeMouseCursor(e)}
          src="/assets/images/mintel-room-floorplan.png"
          className={isCursor ? 'is-cursor' : ''}
        />
        {hideDevices()}
      </div>

      <ModalContainer />

      {modalName === 'fan' && showIoTModal && (
        <ExhaustFanControl
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          subDevices={subDevices}
          fetchDevicesState={fetchDevicesState}
        />
      )}
      {modalName === 'front_switch' && showIoTModal && (
        <LightSwitchFrontControl
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          subDevices={subDevices}
          fetchDevicesState={fetchDevicesState}
        />
      )}
      {modalName === 'ac' && showIoTModal && (
        <AcControl
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          subDevices={subDevices}
          fetchDevicesState={fetchDevicesState}
        />
      )}

      {modalName === 'back_switch' && showIoTModal && (
        <LightSwitchBackControl
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          subDevices={subDevices}
          fetchDevicesState={fetchDevicesState}
        />
      )}
      {modalName === 'broadlinkenv' && showIoTModal && (
        <EnvSensor
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
        />
      )}
      {modalName === 'smart_meter' && showIoTModal && (
        <SmartMeter
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
        />
      )}
      {modalName === 'smart_tv' && showIoTModal && (
        <SmartTVControl
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          subDevices={subDevices}
        />
      )}
      {modalName === 'fridge' && showIoTModal && (
        <RefrigeratorControl
          header={header}
          detail={detail}
          onchildClickActivity={handleClickDropdown}
          startDate={startDate}
          stopDate={stopDate}
          handleStartDate={handleStartDate}
          handleStopDate={handleStopDate}
          subDevices={subDevices}
        />
      )}
    </div>
  );
}

export { IotTab };
