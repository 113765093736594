import React, { useRef, useState, useContext, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardControlActions } from '../../../actions';
import { Popup } from './Popup.containers';
import { AcControl } from '../synAc/synAc.containers';
import './synstyles.css';
import { FirebaseContext2 } from '../../../../../firebase';
import { ac_position_map, cam_position_map, env_position_map } from '../MapPositon';

const floorPlan = '/assets/images/synfloorplan/plan1.jpg';

const elementPositions = ac_position_map.concat(cam_position_map, env_position_map);

const SynFloorPlan = () => {
  const dispatch = useDispatch();

  const firebase = useContext(FirebaseContext2);
  const main_path = '/hotel/syn/building main/syn_lobby/iot_devices/';

  const imageRef = useRef();
  const [isCursor, setIsCursor] = useState(false);

  const isSynACControlModal = useSelector((state) => state.dashboard.isSynACControlModal);
  const [urlImages, setUrlImages] = useState('');

  const [ismouse, setIsmouse] = useState(null);

  const [data, setData] = useState({
    all_data: {
      air_1: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_2: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_3: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_4: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_5: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_6: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_7: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_8: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_9: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_10: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_11: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_12: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_13: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
      air_14: {
        mode: null,
        set_temp: null,
        now_temp: null,
      },
    },
  });

  const [envSen1, setEnvSen1] = useState({
    humid: null,
    noise: null,
    pressure: null,
    temp: null,
  });

  // const [envSen2, setEnvSen2] = useState({
  //   humid: null,
  //   noise: null,
  //   pressure: null,
  //   temp: null,
  // });

  // const AcMacList = [
  //   'airconet_c82b962c7e5e',
  //   'airconet_c82b962c7f00',
  //   'airconet_c82b962c7e47',
  //   'airconet_c82b962c7d6c',
  //   'airconet_c82b962c7e5c',
  //   'airconet_c82b962c7eb9',
  //   'airconet_c82b962c7cf5',
  //   'airconet_c82b962c7fc3',
  //   'airconet_c82b962c7f16',
  //   'airconet_c82b962c7e1e',
  //   'airconet_c82b962c8013',
  //   'airconet_c82b962c7d77',
  //   'airconet_c82b962c800f',
  //   'airconet_c82b962c7e56',
  // ];
  //##-----------------------Fetch data from firebase-------------------
  async function fetchData(firebase, didMounted) {
    let fbpath = main_path;
    const envPath1 = fbpath + '70:ee:50:13:48:d2/data/environment/subdev_0';

    if (didMounted) {
      console.log('SYN Floor Plane did mounted');
      await firebase.db.ref(fbpath).off('value');
      await firebase.db.ref(envPath1).off('value');
    } else {
      await firebase.db.ref(fbpath).on('value', function (snap) {
        let capt = snap.val();
        setData({
          all_data: {
            air_1: {
              mode: capt.airconet_c82b962c7e5e.data.status.data.status,
              set_temp: capt.airconet_c82b962c7e5e.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7e5e.data.status.data.temperature,
            },
            air_2: {
              mode: capt.airconet_c82b962c7f00.data.status.data.status,
              set_temp: capt.airconet_c82b962c7f00.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7f00.data.status.data.temperature,
            },
            air_3: {
              mode: capt.airconet_c82b962c7e47.data.status.data.status,
              set_temp: capt.airconet_c82b962c7e47.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7e47.data.status.data.temperature,
            },
            air_4: {
              mode: capt.airconet_c82b962c7d6c.data.status.data.status,
              set_temp: capt.airconet_c82b962c7d6c.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7d6c.data.status.data.temperature,
            },
            air_5: {
              mode: capt.airconet_c82b962c7e5c.data.status.data.status,
              set_temp: capt.airconet_c82b962c7e5c.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7e5c.data.status.data.temperature,
            },
            air_6: {
              mode: capt.airconet_c82b962c7eb9.data.status.data.status,
              set_temp: capt.airconet_c82b962c7eb9.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7eb9.data.status.data.temperature,
            },
            air_7: {
              mode: capt.airconet_c82b962c7cf5.data.status.data.status,
              set_temp: capt.airconet_c82b962c7cf5.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7cf5.data.status.data.temperature,
            },
            air_8: {
              mode: capt.airconet_c82b962c7fc3.data.status.data.status,
              set_temp: capt.airconet_c82b962c7fc3.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7fc3.data.status.data.temperature,
            },
            air_9: {
              mode: capt.airconet_c82b962c7f16.data.status.data.status,
              set_temp: capt.airconet_c82b962c7f16.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7f16.data.status.data.temperature,
            },
            air_10: {
              mode: capt.airconet_c82b962c7e1e.data.status.data.status,
              set_temp: capt.airconet_c82b962c7e1e.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7e1e.data.status.data.temperature,
            },
            air_11: {
              mode: capt.airconet_c82b962c8013.data.status.data.status,
              set_temp: capt.airconet_c82b962c8013.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c8013.data.status.data.temperature,
            },
            air_12: {
              mode: capt.airconet_c82b962c7d77.data.status.data.status,
              set_temp: capt.airconet_c82b962c7d77.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7d77.data.status.data.temperature,
            },
            air_13: {
              mode: capt.airconet_c82b962c800f.data.status.data.status,
              set_temp: capt.airconet_c82b962c800f.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c800f.data.status.data.temperature,
            },
            air_14: {
              mode: capt.airconet_c82b962c7e56.data.status.data.status,
              set_temp: capt.airconet_c82b962c7e56.data.status.data.settemp,
              now_temp: capt.airconet_c82b962c7e56.data.status.data.temperature,
            },
          },
        });
      });
      await firebase.db.ref(envPath1).on('value', function (snap) {
        let capt = snap.val();
        setEnvSen1({
          humid: capt.humidity,
          noise: capt.noise,
          pressure: capt.pressure,
          temp: capt.temperature,
        });
      });
    }
    // await firebase.db.ref(envPath2).on('value', function (snap) {
    //   let capt = snap.val();

    //   setEnvSen2({
    //     humid: capt.humidity,
    //     noise: capt.noise,
    //     pressure: capt.pressure,
    //     temp: capt.temperature,
    //   });
    // });
  }

  useEffect(() => {
    fetchData(firebase, false);

    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (event) => {
    event.stopPropagation();

    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;

        let rect = event.target.getBoundingClientRect();
        let xr = (event.clientX - rect.left) / w;
        let yr = (event.clientY - rect.top) / h;

        var elems = elementPositions;
        for (var ei in elems) {
          var el = elems[ei];
          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            console.log(el['name'].slice(0, 3));
            dispatch(dashboardControlActions.synDeviesControl(el['triggers']));
            if (el['name'].slice(0, 3) === 'air') {
              dispatch(dashboardControlActions.showSynACControlModal());
            }
          }
        }
      }
    }
  };

  const onChangeMouseCursor = async (event) => {
    event.stopPropagation();
    if (imageRef) {
      if (imageRef.current) {
        let w = imageRef.current.clientWidth,
          h = imageRef.current.clientHeight;
        var rect = event.target.getBoundingClientRect();
        var xr = (event.clientX - rect.left) / w;
        var yr = (event.clientY - rect.top) / h;
        var elems = elementPositions;
        let isHoverOnOneIcon = false;
        setIsmouse(false);
        // dispatch(dashboardControlActions.mouseHover(false));

        for (var ei in elems) {
          var el = elems[ei];

          if (xr > el['x1'] && xr < el['x2'] && yr > el['y1'] && yr < el['y2']) {
            isHoverOnOneIcon = true;
            if (el['name']) {
              setIsmouse(true);
              setUrlImages(el['img_url']);
            }
          }
        }

        setIsCursor(isHoverOnOneIcon);
      }
    }
  };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            {/* <Scrollbars style={{ width: '100%', height: '510px' }} autoHide> */}
            <CardBody>
              <Row>
                <Col md="12" style={{ textAlign: '-webkit-center' }}>
                  {/**  Syn Floor Plan */}
                  {/* <center> */}
                  <div
                    className="container"
                    style={{
                      position: 'relative',
                      padding: '30px',
                      width: '950px',
                    }}
                  >
                    <img
                      id="floorPlan"
                      src={process.env.PUBLIC_URL + floorPlan}
                      alt="floor-plane"
                      onClick={onClick}
                      ref={imageRef}
                      width="950px"
                      // height="300px"
                      onMouseMove={(e) => onChangeMouseCursor(e)}
                      className={isCursor ? 'is-cursor' : ''}
                      // style={{ marginLeft: '10%' }}
                    />
                    {/* <BorderText style={{ left: 460, top: 52 }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_1.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_1.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_1.mode}</b>
                        <br />
                        <b>Air: 10</b>
                      </p>
                    </BorderText> */}
                    <div className="text-block" style={{ left: '24em', top: '5.5em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_1.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_1.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_1.mode}</b>
                        <br />
                        <b>Air: 10</b>
                      </p>
                    </div>

                    <div className="text-block" style={{ left: '30em', bottom: '35.2em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_2.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_2.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_2.mode}</b>
                        <br />
                        <b>Air: 11</b>
                      </p>
                    </div>

                    <div className="text-block" style={{ left: '19.3em', bottom: '29em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_3.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_3.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_3.mode}</b>
                        <br />
                        <b>Air: 12</b>
                      </p>
                    </div>

                    <div className="text-block" style={{ left: '31em', bottom: '24.7em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_4.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_4.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_4.mode}</b>
                        <br />
                        <b>Air: 1</b>
                      </p>
                    </div>

                    <div className="text-block" style={{ left: '19em', bottom: '16.7em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_5.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_5.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_5.mode}</b>
                        <br />
                        <b>Air: 2</b>
                      </p>
                    </div>

                    {/* Begin Wall type air */}
                    <div className="text-block" style={{ left: '26em', bottom: '7.6em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_12.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_12.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_12.mode}</b>
                        <br />
                        <b>Air: 3</b>
                      </p>
                    </div>
                    <div className="text-block" style={{ left: '34em', bottom: '7.6em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_13.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_13.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_13.mode}</b>
                        <br />
                        <b>Air: 4</b>
                      </p>
                    </div>
                    <div className="text-block" style={{ left: '42em', bottom: '7.6em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_14.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_14.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_14.mode}</b>
                        <br />
                        <b>Air: 5</b>
                      </p>
                    </div>

                    {/* End Wall type air */}

                    <div className="text-block" style={{ left: '42em', bottom: '24.7em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_6.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_6.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_6.mode}</b>
                        <br />
                        <b>Air: 8</b>
                      </p>
                    </div>
                    <div className="text-block" style={{ left: '43em', bottom: '35.2em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_7.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_7.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_7.mode}</b>
                        <br />
                        <b>Air: 14</b>
                      </p>
                    </div>
                    <div className="text-block" style={{ left: '52em', top: '9.3em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_8.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_8.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_8.mode}</b>
                        <br />
                        <b>Air: 15</b>
                      </p>
                    </div>

                    <div className="text-block" style={{ left: '58.5em', bottom: '28em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_9.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_9.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_9.mode}</b>
                        <br />
                        <b>Air: 13</b>
                      </p>
                    </div>
                    <div className="text-block" style={{ left: '58.5em', bottom: '22em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_10.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_10.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_10.mode}</b>
                        <br />
                        <b>Air: 6</b>
                      </p>
                    </div>
                    <div className="text-block" style={{ left: '49.1em', bottom: '16em' }}>
                      <p className="condensedlines">
                        <b style={{ color: 'red' }}>Temp: {data.all_data.air_11.now_temp}</b> <br />
                        <b style={{ color: 'blue' }}>
                          Set Temp: {data.all_data.air_11.set_temp}{' '}
                        </b>{' '}
                        <br />
                        <b style={{ color: 'green' }}>Status: {data.all_data.air_11.mode}</b>
                        <br />
                        <b>Air: 7</b>
                      </p>
                    </div>

                    {/* Begin ENV Node */}

                    <div
                      className="text-block"
                      style={{
                        left: '39em',
                        bottom: '16em',
                        backgroundColor: 'rgb(36, 35, 35)',
                        opacity: '0.7',
                        color: 'rgb(255,255,255)',
                      }}
                    >
                      <p className="condensedlines">
                        <>Humid: {envSen1.humid} %</> <br />
                        <>Pressure: {envSen1.pressure}</> <br />
                        <>Noise: {envSen1.noise}</> <br />
                        <>Temp: {envSen1.temp}</>
                      </p>
                    </div>
                    {/* 
                    <div
                      className="text-block"
                      style={{
                        left: 600,
                        bottom: 350,
                        backgroundColor: 'rgb(36, 35, 35)',
                        opacity: '0.7',
                        color: 'rgb(255,255,255)',
                      }}
                    >
                      <p className="condensedlines">
                        <>Air qual: good</> <br />
                        <>Humid: 71.4 </> <br />
                        <>Light: normal</> <br />
                        <>Noise: normal</> <br />
                        <>Temp: 24.5</>
                      </p>
                    </div>

                    <div
                      className="text-block"
                      style={{
                        left: 1050,
                        bottom: 180,
                        backgroundColor: 'rgb(36, 35, 35)',
                        opacity: '0.7',
                        color: 'rgb(255,255,255)',
                      }}
                    >
                      <p className="condensedlines">
                        <>Air qual: good</> <br />
                        <>Humid: 71.4 </> <br />
                        <>Light: normal</> <br />
                        <>Noise: normal</> <br />
                        <>Temp: 24.5</>
                      </p>
                    </div> */}

                    {/* End ENV Node */}
                  </div>
                  {/* </center> */}
                </Col>
              </Row>
            </CardBody>
            {/* </Scrollbars> */}
          </Card>
        </Col>
        {/* <SynControl /> */}
      </Row>
      {/* <Popup isOpen={isCursor} target={name} />; */}
      {isSynACControlModal && <AcControl />}
      {ismouse && <Popup isOpen={ismouse} images={urlImages} />}
    </div>
  );
};

export { SynFloorPlan };
