import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardTitle, CardHeader, CardBody } from 'reactstrap';
import Select from 'react-select';
import { CurrentAutomationTab } from './CurrentAutomationTab.component';
import { MintelRooms, AutomationMintelRooms, AutomationDevices } from 'components/List/List';
import { ToggleButton, ACControl } from './ToggleButton.component';
import { automation } from 'api/services';
import toast, { Toaster } from 'react-hot-toast';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const notify = (text) => toast(text);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#00d1b2',
    color: 'white',
    '& .MuiButton-containedPrimary': {
      backgroundColor: '#00d1b2',
    },
  },
}));

const PMS = () => {
  const classes = useStyles();
  const [currentAutomation, setCurrentAutomation] = useState([]);
  const [selectedRoomName, setSelectedRoomName] = useState([MintelRooms[0]]);
  const [selectedRoomNameOfCurrentAutomation, setSelectedRoomNameOfCurrentAutomation] = useState(
    '201'
  );

  const [deviceStatesCheckin, setDeviceStatesCheckin] = useState({
    'Air Conditioner': { command: { state: 'on', mode: 'cool', fan: 'medium', temperature: 25 } },
    'Smart TV': { command: { state: 'on' } },
    'Exhaust Fan': { command: { state: 'on' } },
    Refrigerator: { command: { state: 'on' } },
    'Toilet Switch': { command: { state: 'on' } },
    'Corridor Switch': { command: { state: 'on' } },
    'Main Switch': { command: { state: 'on' } },
    'Window Switch': { command: { state: 'on' } },
    'Wardrobe Switch': { command: { state: 'on' } },
    'Bed Switch': { command: { state: 'on' } },
  });

  const [deviceStatesCheckout, setDeviceStatesCheckout] = useState({
    'Air Conditioner': { command: { state: 'off', mode: 'off', temperature: 25, fan: 'medium' } },
    'Smart TV': { command: { state: 'off' } },
    'Exhaust Fan': { command: { state: 'off' } },
    Refrigerator: { command: { state: 'off' } },
    'Toilet Switch': { command: { state: 'off' } },
    'Corridor Switch': { command: { state: 'off' } },
    'Main Switch': { command: { state: 'off' } },
    'Window Switch': { command: { state: 'off' } },
    'Wardrobe Switch': { command: { state: 'off' } },
    'Bed Switch': { command: { state: 'off' } },
  });

  const toggleDelete = async (id) => {
    let response = await automation.deleteAutomation(id);
    await console.log(response);
    await notify(`Delete successfully`);
    await queryAutomation();
  };

  const setNewDeviceState = (currentState, dev, pmstype) => {
    let newState = { ...currentState };
    newState[dev].command.state = currentState[dev].command.state === 'off' ? 'on' : 'off';
    if (pmstype === 'checkin') {
      setDeviceStatesCheckin(newState);
    } else {
      setDeviceStatesCheckout(newState);
    }
  };

  const setNewACState = (currentState, subcommand, value, pmstype) => {
    let newState = { ...currentState };
    newState['Air Conditioner'].command[subcommand] = value;
    if (pmstype === 'checkin') {
      setDeviceStatesCheckin(newState);
    } else {
      setDeviceStatesCheckout(newState);
    }
  };

  const postNewAutomation = async () => {
    if (selectedRoomName !== null) {
      ['check_in', 'check_out'].forEach((pmstype) => {
        selectedRoomName.forEach((rooms) => {
          rooms.value.forEach((room) => {
            automation
              .postAutomation(
                'event',
                { pms: { [room]: { [`is`]: pmstype } } },
                { condition_event: '', condition_value: '' },
                { [room]: pmstype === 'check_in' ? deviceStatesCheckin : deviceStatesCheckout }
              )
              .then(() => {
                queryAutomation();
                notify(`Set ${pmstype} configuration successfully`);
              });
          });
        });
      });
    } else {
      notify('Please complete the form.');
    }
  };

  const queryAutomation = () => {
    automation.getAutomation('trigger_type', 'event').then((array) => {
      if (array) {
        setCurrentAutomation(array.data.automations);
      }
    });
  };

  useEffect(() => {
    queryAutomation();
  }, []);

  return (
    <div
      style={{
        margin: '25px 0px 0px 0px',
        height: '100%',
        fontFamily: 'Montserrat',
      }}
    >
      <Row style={{ margin: '0px' }}>
        <Col lg="12" style={{ padding: '0' }}>
          <Card>
            <CardHeader>
              <CardTitle tag="h3" style={{ marginLeft: '15px' }}>
                Setting
              </CardTitle>
            </CardHeader>
            <CardBody className="overflow-auto">
              <Row style={{ margin: '0px', display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    margin: '10px 50px 10px 5px',
                    display: 'flex',
                    width: '1000px',
                    alignItems: 'center',
                  }}
                >
                  <h4 style={{ margin: '10px', fontSize: '18px' }}>Select Rooms</h4>
                  <div style={{ margin: '10px', width: '40%' }}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="repeatSelect"
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedRoomName}
                      onChange={(value) => setSelectedRoomName(value)}
                      options={MintelRooms}
                      placeholder={selectedRoomName}
                    />
                  </div>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.root}
                    onClick={() => postNewAutomation()}
                  >
                    Save
                  </Button>
                </div>
              </Row>
              <Row style={{ margin: '0px' }}>
                <Col lg="6" md="12" style={{ padding: '0px 10px 15px 10px' }}>
                  <h4 style={{ margin: '5px 5px 15px 5px', fontSize: '18px' }}>Check-in</h4>
                  {AutomationDevices.map((item, index) => (
                    <div key={index}>
                      <ToggleButton
                        defaultState={deviceStatesCheckin[item.value].command.state !== 'off'}
                        name={item.label}
                        check={'check_in'}
                        setSelected={() =>
                          setNewDeviceState(deviceStatesCheckin, item.value, 'checkin')
                        }
                        device_name={item.value}
                        borderBottom={item.borderBottom}
                      />
                      {item.value === 'Air Conditioner' &&
                      deviceStatesCheckin[item.value].command.state !== 'off' ? (
                        <ACControl
                          modeSelect={deviceStatesCheckin['Air Conditioner'].command.mode}
                          tempSelect={deviceStatesCheckin['Air Conditioner'].command.temperature}
                          fanSelect={deviceStatesCheckin['Air Conditioner'].command.fan}
                          setModeSelect={(mode) =>
                            setNewACState(deviceStatesCheckin, 'mode', mode, 'checkin')
                          }
                          setTempSelect={(temp) =>
                            setNewACState(
                              deviceStatesCheckin,
                              'temperature',
                              Number(temp),
                              'checkin'
                            )
                          }
                          setFanSelect={(fan) =>
                            setNewACState(deviceStatesCheckin, 'fan', fan, 'checkin')
                          }
                        />
                      ) : null}
                    </div>
                  ))}
                </Col>
                <Col lg="6" md="12" style={{ padding: '0px 10px 15px 10px' }}>
                  <h4 style={{ margin: '5px 5px 15px 5px', fontSize: '18px' }}>Check-out</h4>
                  {AutomationDevices.map((item, index) => (
                    <div key={index}>
                      <ToggleButton
                        defaultState={deviceStatesCheckout[item.value].command.state !== 'off'}
                        name={item.label}
                        check={'check_out'}
                        setSelected={() =>
                          setNewDeviceState(deviceStatesCheckout, item.value, 'checkout')
                        }
                        device_name={item.value}
                        borderBottom={item.borderBottom}
                      />
                      {item.value === 'Air Conditioner' &&
                      deviceStatesCheckout[item.value].command.state !== 'off' ? (
                        <ACControl
                          modeSelect={deviceStatesCheckout['Air Conditioner'].command.mode}
                          tempSelect={deviceStatesCheckout['Air Conditioner'].command.temperature}
                          fanSelect={deviceStatesCheckout['Air Conditioner'].command.fan}
                          setModeSelect={(mode) =>
                            setNewACState(deviceStatesCheckout, 'mode', mode, 'checkout')
                          }
                          setTempSelect={(temp) =>
                            setNewACState(
                              deviceStatesCheckout,
                              'temperature',
                              Number(temp),
                              'checkout'
                            )
                          }
                          setFanSelect={(fan) =>
                            setNewACState(deviceStatesCheckout, 'fan', fan, 'checkout')
                          }
                        />
                      ) : null}
                    </div>
                  ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h3" style={{ marginLeft: '15px' }}>
                Current PMS Setting{' '}
                {currentAutomation ? `(${Object.keys(currentAutomation).length})` : null}
              </CardTitle>
              <div style={{ marginLeft: '20px', marginTop: '0px', width: '150px' }}>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="repeatSelect"
                  closeMenuOnSelect={false}
                  value={selectedRoomNameOfCurrentAutomation}
                  onChange={(value) => setSelectedRoomNameOfCurrentAutomation(value.value[0])}
                  options={AutomationMintelRooms}
                  placeholder={selectedRoomNameOfCurrentAutomation}
                />
              </div>
            </CardHeader>
            <CardBody className="overflow-auto">
              <Row style={{ margin: '0px', maxHeight: '400px' }}>
                <Col
                  lg="12"
                  md="12"
                  style={{ padding: '0px', maxHeight: '650px', minHeight: '200px' }}
                >
                  {currentAutomation &&
                    currentAutomation
                      .map((PMSSetting, index) => (
                        <div key={index}>
                          {PMSSetting.trigger.trigger_type === 'event' &&
                          Object.keys(PMSSetting.trigger.trigger_event)[0] === 'pms' &&
                          Object.keys(PMSSetting.trigger.trigger_event.pms)[0] ===
                            `room_${selectedRoomNameOfCurrentAutomation}` ? (
                            <CurrentAutomationTab
                              key={index}
                              triggerEvent={
                                Object.values(PMSSetting.trigger.trigger_event.pms)[0].is
                              }
                              roomNumber={Object.keys(PMSSetting.trigger.trigger_event.pms)[0]}
                              action={PMSSetting.action}
                              automationId={PMSSetting.automation_id}
                              toggleDelete={(id) => toggleDelete(id)}
                            />
                          ) : null}
                        </div>
                      ))
                      .reverse()}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Toaster />
    </div>
  );
};

export { PMS };
