import React, { useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { MintelRooms } from 'components/List/List';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    '& > *': {
      margin: '0px 8px 8px 8px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: '#00d1b2',
    color: 'white',
    '& .MuiButton-containedPrimary': {
      backgroundColor: '#00d1b2',
    },
  },
}));

// const notify = (text) => toast(text);

const Header = ({ name, red }) => {
  return (
    <div style={{ margin: '0px 0px 10px 0px', display: 'flex', flexDirection: 'row' }}>
      <h3 style={{ fontSize: '18px', margin: '0px' }}>{name}</h3>
      <h3 style={{ fontSize: '18px', color: 'red', margin: '0px' }}>{red}</h3>
    </div>
  );
};

const SubHeader = ({ name, red }) => {
  return (
    <div style={{ margin: '0px', display: 'flex', flexDirection: 'row' }}>
      <p style={{ fontSize: '16px', margin: '0px' }}>{name}</p>
      <p style={{ fontSize: '16px', color: 'red', margin: '0px' }}>{red}</p>
    </div>
  );
};

const Device = () => {
  const classes = useStyles();
  const [selectedRoomNameTrigger, setSelectedRoomNameTrigger] = useState('');
  const [selectedRoomNameCondition, setSelectedRoomNameCondition] = useState('');
  const [selectedRoomNameAction, setSelectedRoomNameAction] = useState('');
  const [comparatorTrigger, setComparatorTrigger] = useState('');
  const [comparatorCondition, setComparatorCondition] = useState('');
  const [comparatorAction, setComparatorAction] = useState('');
  const [valueTrigger, setValueTrigger] = useState('');
  const [valueCondition, setValueCondition] = useState('');
  const [valueAction, setValueAction] = useState('');
  const [deviceTrigger, setDeviceTrigger] = useState('');
  const [deviceCondition, setDeviceCondition] = useState('');
  const [deviceAction, setDeviceAction] = useState('');

  const mintelDevices = [
    'Air Conditioner',
    'Smart TV',
    'Exhaust Fan',
    'Refrigerator',
    'Toilet Switch',
    'Corridor Switch',
    'Main Switch',
    'Window Switch',
    'Wardrobe Switch',
    'Bed Switch',
  ];

  // const setNewDeviceState = (currentState, dev, pmstype) => {
  //   let newState = { ...currentState };
  //   newState[dev].command.state = currentState[dev].command.state === 'off' ? 'on' : 'off';
  //   if (pmstype === 'checkin') {
  //     setDeviceStatesCheckin(newState);
  //   } else {
  //     setDeviceStatesCheckout(newState);
  //   }
  // };

  // const setNewACState = (currentState, subcommand, value, pmstype) => {
  //   let newState = { ...currentState };
  //   newState['Air Conditioner'].command[subcommand] = value;
  //   if (pmstype === 'checkin') {
  //     setDeviceStatesCheckin(newState);
  //   } else {
  //     setDeviceStatesCheckout(newState);
  //   }
  // };

  // const postNewAutomation = async () => {
  //   if (selectedRoomNameTrigger !== null) {
  //     ['check_in', 'check_out'].forEach((pmstype) => {
  //       selectedRoomNameTrigger.forEach((rooms) => {
  //         rooms.value.forEach((room) => {
  //           automation
  //             .postAutomation(
  //               'event',
  //               { pms: { [room]: { [`is`]: pmstype } } },
  //               { condition_event: '', condition_value: '' },
  //               { [room]: pmstype === 'check_in' ? deviceStatesCheckin : deviceStatesCheckout }
  //             )
  //             .then(() => {
  //               // queryAutomation();
  //               notify(`Set ${pmstype} configuration successfully`);
  //             });
  //         });
  //       });
  //     });
  //   } else {
  //     notify('Please complete the form.');
  //   }
  // };

  return (
    <div style={{ margin: '25px 0px 0px 0px' }}>
      <Row>
        <Col lg="12">
          <Header name={'Trigger'} red={'*'} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <SubHeader name={'Trigger object'} red={'*'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Room</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRoomNameTrigger}
                      onChange={(event) => setSelectedRoomNameTrigger(event.target.value)}
                    >
                      {MintelRooms.map((room) => (
                        <MenuItem value={room.value}>{room.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedRoomNameTrigger !== '' && (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Device</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={deviceTrigger}
                        onChange={(event) => setDeviceTrigger(event.target.value)}
                      >
                        {mintelDevices.map((device) => (
                          <MenuItem value={device}>{device}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Col>
                <Col lg="4">
                  <SubHeader name={'Comparator'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Comparator</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={comparatorTrigger}
                      onChange={(event) => setComparatorTrigger(event.target.value)}
                    >
                      <MenuItem value={'='}>{'='}</MenuItem>
                      <MenuItem value={'<'}>{'<'}</MenuItem>
                      <MenuItem value={'>'}>{'>'}</MenuItem>
                      <MenuItem value={'<='}>{'<='}</MenuItem>
                      <MenuItem value={'>='}>{'>='}</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg="4">
                  <SubHeader name={'Value'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Value</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={valueTrigger}
                      onChange={(event) => setValueTrigger(event.target.value)}
                    >
                      <MenuItem value={'on'}>{'on'}</MenuItem>
                      <MenuItem value={'off'}>{'off'}</MenuItem>
                      <MenuItem value={'other'}>{'other'}</MenuItem>
                    </Select>
                    {valueTrigger === 'other' && <TextField id="standard-basic" label="Standard" />}
                  </FormControl>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Header name={'Condition (optional)'} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <SubHeader name={'Condition object'} red={'*'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Room</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRoomNameCondition}
                      onChange={(event) => setSelectedRoomNameCondition(event.target.value)}
                    >
                      {MintelRooms.map((room) => (
                        <MenuItem value={room.value}>{room.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedRoomNameCondition !== '' && (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Device</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={deviceCondition}
                        onChange={(event) => setDeviceCondition(event.target.value)}
                      >
                        {mintelDevices.map((device) => (
                          <MenuItem value={device}>{device}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Col>
                <Col lg="4">
                  <SubHeader name={'Comparator'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Comparator</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={comparatorCondition}
                      onChange={(event) => setComparatorCondition(event.target.value)}
                    >
                      <MenuItem value={'='}>{'='}</MenuItem>
                      <MenuItem value={'<'}>{'<'}</MenuItem>
                      <MenuItem value={'>'}>{'>'}</MenuItem>
                      <MenuItem value={'<='}>{'<='}</MenuItem>
                      <MenuItem value={'>='}>{'>='}</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg="4">
                  <SubHeader name={'Value'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Value</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={valueCondition}
                      onChange={(event) => setValueCondition(event.target.value)}
                    >
                      <MenuItem value={'on'}>{'on'}</MenuItem>
                      <MenuItem value={'off'}>{'off'}</MenuItem>
                      <MenuItem value={'other'}>{'other'}</MenuItem>
                    </Select>
                    {valueCondition === 'other' && (
                      <TextField id="standard-basic" label="Standard" />
                    )}
                  </FormControl>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Header name={'Action'} red={'*'} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <SubHeader name={'Action object'} red={'*'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Room</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedRoomNameAction}
                      onChange={(event) => setSelectedRoomNameAction(event.target.value)}
                    >
                      {MintelRooms.map((room) => (
                        <MenuItem value={room.value}>{room.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedRoomNameAction !== '' && (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Device</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={deviceAction}
                        onChange={(event) => setDeviceAction(event.target.value)}
                      >
                        {mintelDevices.map((device) => (
                          <MenuItem value={device}>{device}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Col>
                <Col lg="4">
                  <SubHeader name={'Comparator'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Comparator</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={comparatorAction}
                      onChange={(event) => setComparatorAction(event.target.value)}
                    >
                      <MenuItem value={'='}>{'='}</MenuItem>
                      <MenuItem value={'<'}>{'<'}</MenuItem>
                      <MenuItem value={'>'}>{'>'}</MenuItem>
                      <MenuItem value={'<='}>{'<='}</MenuItem>
                      <MenuItem value={'>='}>{'>='}</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg="4">
                  <SubHeader name={'Value'} />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Action value</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={valueAction}
                      onChange={(event) => setValueAction(event.target.value)}
                    >
                      <MenuItem value={'on'}>{'on'}</MenuItem>
                      <MenuItem value={'off'}>{'off'}</MenuItem>
                      <MenuItem value={'other'}>{'other'}</MenuItem>
                    </Select>
                    {valueAction === 'other' && <TextField id="standard-basic" label="Standard" />}
                  </FormControl>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <Button variant="contained" size="medium" color="primary" className={classes.root}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export { Device };
