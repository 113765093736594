import axios from 'axios';
import { API_CONSTANTS } from '../../api';

async function getAllDevices(roomNumber, hotel) {
  const token = localStorage.getItem('token');

  const request = async (body) => {
    try {
      let responseJson = await axios.request(body);
      return responseJson;
    } catch (error) {
      console.error('[Services] getAllDevices Error:');
      console.error(error);

      var data = { requestError: error.message, response: error.response };
      return data;
    }
  };

  if (!hotel) {
    const requestOptions = {
      url: `${API_CONSTANTS.GET_ALL_DEVICES_URL}?room=${roomNumber}&building=mintel`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `token ${token}`,
      },
    };
    request(requestOptions);
  } else {
    const requestOptions = {
      url: `${API_CONSTANTS.GET_ALL_DEVICES_URL}?room=${roomNumber}&building=${hotel}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `token ${token}`,
      },
    };
    return await request(requestOptions);
  }
}

export const roomServices = {
  getAllDevices,
};
