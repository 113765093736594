import React, { createContext } from 'react';
import app from 'firebase/app';

import {
  deleteUserFirebaseServices,
  getUserIsUpdatedStateFirebaseServices,
  setUserIsUpdatedStateFirebaseServices,
} from './services/user.firebase.services';

import {
  getLineIdInRoomFirebaseServices,
  setLineIdInRoomFirebaseServices,
} from './services/room.firebase.services';

const FirebaseContext = createContext(null);

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const FirebaseProvider = ({ children }) => {
  let firebase = {
    app: null,
    db: null,
  };
  if (!app.apps.length) {
    let defaultApp = app.initializeApp(config);
    firebase = {
      app: defaultApp,
      db: defaultApp.database(),
      api: {
        deleteUserFirebaseServices,
        getUserIsUpdatedStateFirebaseServices,
        setUserIsUpdatedStateFirebaseServices,

        getLineIdInRoomFirebaseServices,
        setLineIdInRoomFirebaseServices,
      },
    };
  }
  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export { FirebaseContext, FirebaseProvider };
