export const DASHBOARD = {
  CHANGE_FILTER_ROOM_STATUS_LIST: 'CHANGE_FILTER_ROOM_STATUS_LIST',
  CHANGE_FILTER_COUNTRY_LIST: 'CHANGE_FILTER_COUNTRY_LIST',

  SELECTED_ROOM: 'SELECTED_ROOM',
  RESET_SELECTED_ROOM: 'RESET_SELECTED_ROOM',

  SHOW_ONE_ROOM_DETAILS_MODAL: 'SHOW_ONE_ROOM_DETAILS_MODAL',
  HIDE_ONE_ROOM_DETAILS_MODAL: 'HIDE_ONE_ROOM_DETAILS_MODAL',

  SHOW_ONE_ROOM_IOT_MODAL: 'SHOW_ONE_ROOM_IOT_MODAL',
  HIDE_ONE_ROOM_IOT_MODAL: 'HIDE_ONE_ROOM_IOT_MODAL',

  IS_CONTROL_IOT: 'IS_CONTROL_IOT',

  //Mouse Hover
  MOUSE_HOVER: 'MOUSE_HOVER',

  // Syn Devices control
  SYN_DEIVIES_CONTROL: 'SYN_DEIVIES_CONTROL',

  // Show SYN AC control
  SHOW_SYN_AC_CONTROL: 'SHOW_SYN_AC_CONTROL',

  // Hide SYN AC control
  HIDE_SYN_AC_CONTROL: 'HIDE_SYN_AC_CONTROL',

  // Plot Energy SYN
  PLOT_SYN_ENERGY: 'PLOT_SYN_ENERGY',

  // Show IoT modal
  SHOW_IOT_MODAL: 'SHOW_IOT_MODAL',

  // Modal Name
  MODAL_NAME: 'MODAL_NAME',
};
