import React, { Fragment, Children, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {
  EnergyCostBreakdownContainer,
  RealTimeContainer,
  EnergyContainer,
  EnergySavingRecommendation,
  ElectricityByHourContainer,
  DeviceOperationContainer,
  SolarPVRecommendation,
} from '../../../../../../containers';
import { energyActions } from '../../../../../energy/actions';
import { useDispatch } from 'react-redux';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(0.5),
  },
});

function TabContainer({ children, value: valueProp }) {
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const onChange = (e, value) => {
    dispatch(energyActions.energyTabSelect(value));
    setValue(value);
  };

  if (value === undefined) {
    setValue(valueProp);
  }

  return (
    <Fragment>
      <Tabs value={value} onChange={onChange} indicatorColor="primary">
        {Children.map(children, (child) => (
          <Tab label={child.props.label} style={{ outline: 'none', width: '120px' }} />
        ))}
      </Tabs>
      {Children.map(children, (child, index) => (index === value ? child : null))}
    </Fragment>
  );
}

TabContainer.defaultProps = {
  value: 0,
};

const TabContent = withStyles(styles)(({ classes, children }) => (
  <Typography component="div" className={classes.tabContent}>
    {children}
  </Typography>
));

const EnergyTabLayout = withStyles(styles)(() => {
  return (
    <div className="content">
      <TabContainer value={0}>
        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i className="nc-icon nc-globe text-warning" style={{ verticalAlign: 'middle' }} />{' '}
              Realtime
            </div>
          }
        >
          <RealTimeContainer />
        </TabContent>

        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i className="nc-icon nc-atom text-success" style={{ verticalAlign: 'middle' }} />{' '}
              Energy Performance
            </div>
          }
        >
          <EnergyContainer />
        </TabContent>

        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i className="nc-icon nc-atom text-success" style={{ verticalAlign: 'middle' }} />{' '}
              Cost Breakdown
            </div>
          }
        >
          <EnergyCostBreakdownContainer />
        </TabContent>

        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i className="nc-icon nc-bulb-63 text-danger" style={{ verticalAlign: 'middle' }} />{' '}
              Device Operation
            </div>
          }
        >
          <DeviceOperationContainer />
        </TabContent>

        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i className="nc-icon nc-bulb-63 text-danger" style={{ verticalAlign: 'middle' }} />{' '}
              Electricity by Hour
            </div>
          }
        >
          <ElectricityByHourContainer />
        </TabContent>

        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i
                className="nc-icon nc-spaceship text-warning"
                style={{ verticalAlign: 'middle' }}
              />{' '}
              Solar PV Recommendation
            </div>
          }
        >
          <SolarPVRecommendation />
        </TabContent>
        <TabContent
          label={
            <div style={{ fontFamily: 'Montserrat' }}>
              <i
                className="nc-icon nc-spaceship text-warning"
                style={{ verticalAlign: 'middle' }}
              />{' '}
              Energy Saving Recommendation
            </div>
          }
        >
          <EnergySavingRecommendation />
        </TabContent>
      </TabContainer>
    </div>
  );
});

export { EnergyTabLayout };
