import React from 'react';
import { AutomationComponent } from '../components';

const Automation = () => {

  return (
    <>
      <AutomationComponent />
    </>
  );
};

export { Automation };
