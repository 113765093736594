import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Timeline, Power, AttachMoney, Schedule, Event, WbSunny, Save } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import { energyActions } from '../../actions';
import {
  SynRealtimeContainer,
  MintelEnergyCostBreakdownContainer,
  MintelRealTimeContainer,
  MintelEnergyContainer,
  EnergySavingRecommendation,
  ElectricityByHour,
  MintelDeviceOperationContainer,
  SolarPVRecommendation,
} from '../';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={3}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}));

const EnergyTabLayout = () => {
  const dispatch = useDispatch();
  const hotelCode = localStorage.getItem('hotel_hotel_code');
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    dispatch(energyActions.clearEnergyData());
    setValue(newValue);
  };
  return (
    <div className="content">
      <div className={classes.root}>
        {/* <AppBar position="static" color="default"> */}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label="Realtime"
            icon={<Timeline />}
            {...a11yProps(0)}
            style={{ fontSize: 12, outline: 'none' }}
          />
          <Tab
            label="Energy Performance"
            icon={<Power />}
            {...a11yProps(1)}
            style={{ fontSize: 12, outline: 'none' }}
          />
          <Tab
            label="Cost Breakdown"
            icon={<AttachMoney />}
            {...a11yProps(2)}
            style={{ fontSize: 12, outline: 'none' }}
          />
          <Tab
            label="Device Operation"
            icon={<Event />}
            {...a11yProps(3)}
            style={{ fontSize: 12, outline: 'none' }}
          />
          <Tab
            label="Electricity by Hour"
            icon={<Schedule />}
            {...a11yProps(4)}
            style={{ fontSize: 12, outline: 'none' }}
          />
          <Tab
            label="Solar PV Recommendation"
            icon={<WbSunny />}
            {...a11yProps(5)}
            style={{ fontSize: 12, outline: 'none' }}
          />
          <Tab
            label="Energy Saving Recommendation"
            icon={<Save />}
            {...a11yProps(6)}
            style={{ fontSize: 12, outline: 'none' }}
          />

          {/* <Tab label="Realtime" {...a11yProps(0)} style={{ fontSize: 12 }} />
          <Tab label="Energy" {...a11yProps(1)} style={{ fontSize: 12 }} />
          <Tab label="Cost Breakdown" {...a11yProps(2)} style={{ fontSize: 12 }} />
          <Tab label="Device Operation" {...a11yProps(3)} style={{ fontSize: 12 }} />
          <Tab label="Electricity by Hour" {...a11yProps(4)} style={{ fontSize: 12 }} />
          <Tab label="Solar PV Recommendation" {...a11yProps(5)} style={{ fontSize: 12 }} />
          <Tab label="Energy Saving Recommendation" {...a11yProps(6)} style={{ fontSize: 12 }} /> */}
        </Tabs>
        {/* </AppBar> */}
        {hotelCode === 'MIN' && (
          <>
            <TabPanel value={value} index={0}>
              <MintelRealTimeContainer />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MintelEnergyContainer />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <MintelEnergyCostBreakdownContainer />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MintelDeviceOperationContainer />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ElectricityByHour />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <SolarPVRecommendation />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <EnergySavingRecommendation />
            </TabPanel>
          </>
        )}
        {hotelCode === 'SYN' && (
          <>
            <TabPanel value={value} index={0}>
              <SynRealtimeContainer />
            </TabPanel>
          </>
        )}
      </div>
    </div>
  );
};

export { EnergyTabLayout };
