import axios from 'axios'
import { API_CONSTANTS } from '../apiConstants'

function authHeader() {
  const accessToken = localStorage.getItem("token")
  if (accessToken) {
    return {
      Authorization: "Token " + accessToken,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}

export const c2d = (payload) => {
  return axios.post(API_CONSTANTS.C2D, payload, {
    headers: authHeader()
  })
}