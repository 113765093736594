import React from 'react';
// import { ActivityData, HistoryData, Info } from '../';

export const SmartTVComponents = (props) => {
  const currentTab = 'control';
  const openTV = true;
  function onClick1() {}

  const imagesPath = {
    open: '/assets/images/devices/icon-tv-open.png',
    close: '/assets/images/devices/icon-tv-close.png',
  };

  return (
    <>
      {props.menu}
      {/* Control tab */}
      {currentTab === 'control' && (
        <div className="iot-element" id="iot-control-tv">
          <div className="columns">
            <div
              className="column"
              style={{
                // border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div className="column">
                <img
                  alt="tv-icon"
                  id="icon-tv-close"
                  style={{
                    width: '200px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '7%',
                    cursor: 'pointer',
                  }}
                  src={openTV ? imagesPath.open : imagesPath.close}
                  onClick={(e) => onClick1(e)}
                />
              </div>
              <div className="column">
                <button
                  className={
                    openTV
                      ? 'button is-success device-controller'
                      : 'button is-unsuccess device-controller'
                  }
                  // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_b38c3e/data/gang 2", "type": "toggle", "deviceid": "78:0F:77:D6:44:01", "icons":["icon-tv-close","icon-tv-open"]}'
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={(e) => onClick1(e)}
                >
                  {/* {status.smart_tv_switch.status === 'on' ? 'ON' : 'OFF'} */}
                  {openTV ? 'ON' : 'OFF'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <Info image={image_link} header={props.header} detail={props.detail} />
      )}
      {currentTab === 'activity' && <ActivityData />}
      {currentTab === 'history' && (
        <HistoryData
          data={hist_plot}
          startDate={props.startDate}
          stopDate={props.stopDate}
          handleStartDate={props.handleStartDate}
          handleStopDate={props.handleStopDate}
        />
      )} */}
    </>
  );
};
