import React, { useContext, useState, useEffect } from 'react';
import { TdpkSolarPVComponents } from '../../../../components/TDPK';
import { FirebaseContext2 } from '../../../../../../firebase';

const TdpkSolarPVContainer = () => {
  const firebase = useContext(FirebaseContext2);
  const [solarPV, setSolarPV] = useState(null);

  function fetchData(didMount) {
    const main_path = 'hotel/mintel/energy/solar_pv_recommendation';

    if (didMount) {
      firebase.db.ref(main_path).off('value');
    } else {
      firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        setSolarPV(capt);
      });
    }
  }

  useEffect(() => {
    fetchData(false);
    return () => fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plotData = {
    series: [
      {
        name: 'LOAD',
        data: solarPV !== null ? Object.values(solarPV.power_consumption) : [],
        type: 'area',
      },
      {
        name: 'SOLAR',
        data: solarPV !== null ? Object.values(solarPV.solar_generation) : [],
        type: 'area',
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'area',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100],
        },
      },
      yaxis: {
        tickAmount: 9,
        title: {
          text: '(kW)',
        },
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      xaxis: {
        type: 'category',
        categories: solarPV !== null ? Object.keys(solarPV.power_consumption) : [],
      },
      title: {
        text: 'Irregular Data in Time Series',
        align: 'left',
        offsetX: 14,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: -10,
      },
    },
  };
  return (
    <div>
      <TdpkSolarPVComponents
        title={'Daily solar PV energy production (kW)'}
        plotData={plotData}
        solarPV={solarPV}
      />
    </div>
  );
};

export { TdpkSolarPVContainer };
