import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import {
  MenuTabComponents,
  ACComponents,
  EnvironmentSensorComponents,
  FrontSwitchComponents,
  FanComponents,
  BackSwitchComponents,
  SmartMeterComponents,
  SmartTVComponents,
  FridgeComponents,
} from '.';

export const ModalIoTLayout = (props) => {
  const [currentTab, setCurrentTab] = useState('control');

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={props.onCloseModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <Col md="6">
            <b className="modal-card-title" style={{ padding: 0, margin: 0 }}>
              {props.modalName === 'fan' && <>Exhaust Fan</>}
              {props.modalName === 'ac' && <>Air</>}
              {props.modalName === 'front_switch' && <>Front Switch</>}
              {props.modalName === 'back_switch' && <>Back Switch</>}
              {props.modalName === 'broadlinkenv' && <>Environment Sensor</>}
              {props.modalName === 'smart_meter' && <>Smart Meter</>}
              {props.modalName === 'smart_tv' && <>Smart TV</>}
              {props.modalName === 'fridge' && <>Fridge</>}
            </b>
          </Col>
          <Col md="6" style={{ textAlign: 'right' }}>
            <p className="modal-card-title" style={{ padding: 0, margin: 0, marginLeft: '50%' }}>
              {props.roomNo}
            </p>
          </Col>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {props.modalName === 'fan' && props.showIoTModal && (
            <FanComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}
          {props.modalName === 'front_switch' && props.showIoTModal && (
            <FrontSwitchComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}
          {props.modalName === 'ac' && props.showIoTModal && (
            <ACComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}

          {props.modalName === 'back_switch' && props.showIoTModal && (
            <BackSwitchComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}
          {props.modalName === 'broadlinkenv' && props.showIoTModal && (
            <EnvironmentSensorComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
            />
          )}
          {props.modalName === 'smart_meter' && props.showIoTModal && (
            <SmartMeterComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}
          {props.modalName === 'smart_tv' && props.showIoTModal && (
            <SmartTVComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}
          {props.modalName === 'fridge' && props.showIoTModal && (
            <FridgeComponents
              menu={<MenuTabComponents currentTab={(e) => setCurrentTab(e)} />}
              header={props.header}
              detail={props.detail}
              onchildClickActivity={props.handleClickDropdown}
              startDate={props.startDate}
              stopDate={props.stopDate}
              handleStartDate={props.handleStartDate}
              handleStopDate={props.handleStopDate}
              currentTab={currentTab}
            />
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth">
          <div className="column is-fullwidth">
            <button
              onClick={props.onCloseModal}
              style={{ padding: 0, textAlign: 'right', margin: 0 }}
            >
              CLOSE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};
