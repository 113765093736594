import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { NewMembers } from '../NewMembers/NewMembers';
import { ExistingMembers } from '../ExistingMembers/ExistingMembers'
import { FirebaseContext2 } from '../../../firebase'
// import { MembersComponent } from '../Members/Members';
// import { MaidActivityComponent } from '../MaidActivity/MaidActivity';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box p={3}>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      style={{ fontFamily: 'Montserrat' }}
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const MaidNav = () => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext2);
  const [value, setValue] = React.useState(0);
  const [newMembers, setNewMembers] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [lastUpdatedAt, setLastUpdatedAt] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async (didMount) => {
    const main_path = 'hotel/mintel/housekeeper/registered/waiting_for_approval/updated_at/timestamp';
    if (didMount) {
      await firebase.db.ref(main_path).off('value');
    } else {
      await firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        setLastUpdatedAt(capt);
      });
    }
  }

  const fetchAllMembers = async () => {
    try {
      const result = await axios.get(`https://altobotbackend.azurewebsites.net/api/v2.1/altobot_maid?hotel=MIN&approve=true`)
      if (result.data.users) {
        setAllMembers(result.data.users)
      }
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  useEffect(() => {
    fetchData(false)
    fetchAllMembers()
    return () => {
      fetchData(true)
    }
  }, [])

  const fetchPendingMembers = async () => {
    try {
      const result = await axios.get(`https://altobotbackend.azurewebsites.net/api/v2.1/altobot_maid?hotel=MIN&approve=false`)
      if (result.data.users) {
        setNewMembers(result.data.users)
      }
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  const acceptNewMember = async (line_id) => {
    if (window.confirm("Are you sure to accept this member?")) {
      try {
        await axios.put('https://altobotbackend.azurewebsites.net/api/v2.1/altobot_maid', {
          approve: true,
          line_id,
          hotel: 'MIN'
        })
        alert("Successfully")
        fetchAllMembers()
        fetchPendingMembers()
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }
  }

  const deleteNewMember = async (line_id) => {
    if (window.confirm("Are you sure to reject this member?")) {
      try {
        await axios.delete(`https://altobotbackend.azurewebsites.net/api/v2.1/altobot_maid?hotel=MIN&line_id=${line_id}`)
        alert("Successfully")
        fetchAllMembers()
        fetchPendingMembers()
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }
  }

  useEffect(() => {
    fetchPendingMembers()
  }, [lastUpdatedAt])

  return (
    <div className={classes.root} disabled style={{ position: 'relative' }}>
      {/* <AppBar position="static"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <LinkTab label="Current" href="/Current" {...a11yProps(0)} />
        <LinkTab label="Monthly" href="/Monthly" {...a11yProps(1)} />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <NewMembers newMembers={newMembers} acceptNewMember={acceptNewMember} deleteNewMember={deleteNewMember} />
        <ExistingMembers newMembers={allMembers} disableAccept deleteNewMember={deleteNewMember} />
        {/* <MembersComponent />
        <br />
        <MaidActivityComponent /> */}
      </TabPanel>
      <TabPanel value={value} index={1}></TabPanel>
    </div>
  );
};

export { MaidNav };
