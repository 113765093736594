import styled from 'styled-components';

export const CustomTable = styled.table`
  &&& {
    table,
    th,
    td {
      border: 0px solid black;
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      padding-left: 15px;
    }
    th {
      text-align: left;
    }
    table {
      width: 100%;
    }
  }
`;
