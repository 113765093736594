import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardControlActions } from '../../../../dashboard/actions';
import { List } from '../../../../../components/List/TabList';
import { FirebaseContext2 } from '../../../../../firebase';
import { Info, ActivityData, HistoryData } from '../../../components/Tabs';
import { energyActions } from '../../../../energy/actions';
import moment from 'moment';
import { roomActivitiesHistories } from '../../../../../api/services';
import { API_CONSTANTS } from '../../../../../api';
import { iotControlServices } from '../../../../../api/services';

const LightSwitchFrontControl = (props) => {
  //##--------------------------------Constant------------------------------------------------------------
  const dispatch = useDispatch();
  const isControlIot = useSelector((state) => state.dashboard.isControlIot);
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);

  const InitPlotData = useSelector((state) => state.historyEnergy.hist);
  // const dropdown = useSelector((state) => state.hotel.dropdown);
  const [currentTab, setCurrentTab] = useState('control');

  const getDevices = useSelector((state) => state.hotel.allDevices);
  const devices = getDevices ? getDevices.devices : null;

  const timeoutid = useRef();

  const firebase = useContext(FirebaseContext2);

  //##-------------------Switch--------------------------------
  const [openlight1, setOpenlight1] = useState(null);
  const [openlight2, setOpenlight2] = useState(null);
  const [openlight3, setOpenlight3] = useState(null);
  // const [plotHistData, setPlotHistData] = useState([]);
  // const [tmpHist, settmpHist] = useState(null);

  const [plotHistToilet, setPlotHistToilet] = useState([]);
  const [plotHistCorridor, setPlotHistCorridor] = useState([]);
  const [plotHistMain, setPlotHistMain] = useState([]);

  const [toiletHist, setToiletHist] = useState(null);
  const [corridorHist, setCorridorHist] = useState(null);
  const [mainHist, setMainHist] = useState(null);

  //##-------------------Switch Location-----------------------
  const [toilet, setToilet] = useState({
    state: null,
    time: null,
  });
  const [corridor, setCorridor] = useState({
    state: null,
    time: null,
  });
  const [main, setMain] = useState({
    state: null,
    time: null,
  });

  const onchildClickActivity = (event) => {
    props.onchildClickActivity(event);
  };
  const d = new Date();

  function GetMonth() {
    // eslint-disable-next-line
    let month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    return month[d.getMonth()];
  }

  //##----------------------------Fetch Data from firebase---------------------------
  async function fetchData(firebase, currentRoomNo, didMounted) {
    let fbpath =
      API_CONSTANTS.FIREBASE_MINTEL_REAL_TIME_PATH +
      'room_' +
      currentRoomNo +
      '/iot_devices/front_switch/relay';
    let toiletPath = fbpath + '/toilet_switch';
    let corridorPath = fbpath + '/corridor_switch';
    let mainPath = fbpath + '/main_switch';

    if (didMounted) {
      console.log('Back Light did mounted');
      await firebase.db.ref(toiletPath).off('value');
      await firebase.db.ref(corridorPath).off('value');
      await firebase.db.ref(mainPath).off('value');
    } else {
      await firebase.db.ref(toiletPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setToilet({
            state: capt.state,
            time: capt.timestamp,
          });
        }
      });

      await firebase.db.ref(corridorPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt !== null || undefined) {
          setCorridor({
            state: capt.state,
            time: capt.timestamp,
          });
        }
      });

      await firebase.db.ref(mainPath).on('value', function (snap) {
        let capt = snap.val();
        if (capt && capt.state && capt.timestamp !== null) {
          setMain({
            state: capt.state,
            time: capt.timestamp,
          });
        }
      });
    }
  }

  //##---------------------------Trigger API------------------------------------------
  async function triggerAPI(currentStatus, swLocation, currentRoomNo) {
    //##-------------------------Payload-----------------------------------

    const payload = {
      topic: 'devicecontrol',
      action: {
        [currentRoomNo]: {
          [swLocation]: {
            command: {
              state: currentStatus,
            },
          },
        },
      },
      RequestId: '12345',
    };
    console.log(payload.message, payload.device);
    iotControlServices.postIotApi(payload);
  }

  //##-------------------sync data from firebase only-----------------
  useEffect(() => {
    fetchData(firebase, currentRoomNo, false);
    return () => fetchData(firebase, currentRoomNo, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function histData(dev_id, nick_name, sub_dev) {
    try {
      let results = await roomActivitiesHistories.getActivitiesHistories(
        currentRoomNo,
        dev_id,
        sub_dev,
        nick_name,
        false,
        props.startDate,
        props.stopDate
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            let mapDataActivity = results.data.results;

            return mapDataActivity;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    if (toiletHist && corridorHist && mainHist !== null) {
      // let tmp1 = [];
      let toiletTmp = [];
      let corridorTmp = [];
      let mainTmp = [];

      // tmpHist.forEach((item) => {
      //   if (
      //     item.state !== null &&
      //     item.timestamp !== null &&
      //     item.state !== undefined &&
      //     item.timestamp !== undefined
      //   ) {
      //     tmp1.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
      //   }
      //   return setPlotHistData(tmp1);
      // });

      toiletHist.forEach((item) => {
        if (item.state && item.timestamp !== null) {
          toiletTmp.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        return setPlotHistToilet(toiletTmp);
      });

      corridorHist.forEach((item) => {
        if (item.state && item.timestamp !== null) {
          corridorTmp.push([
            Number(moment(item.timestamp).format('x')),
            item.state === 'on' ? 1 : 0,
          ]);
        }
        return setPlotHistCorridor(corridorTmp);
      });

      mainHist.forEach((item) => {
        if (item.state && item.timestamp !== null) {
          mainTmp.push([Number(moment(item.timestamp).format('x')), item.state === 'on' ? 1 : 0]);
        }
        return setPlotHistMain(mainTmp);
      });
    }
  }, [toiletHist, corridorHist, mainHist]);

  useEffect(() => {
    initSwitch(toilet.state, corridor.state, main.state);
  }, [toilet, corridor, main]);

  useEffect(() => {
    clearTimeout(timeoutid.current);

    devices.forEach((element) => {
      if (element['device_name'] === 'front_switch') {
        try {
          histData(element.device_id, 'front_switch', 0).then((results) => {
            return setToiletHist(results);
          });
          histData(element.device_id, 'front_switch', 1).then((results) => {
            return setCorridorHist(results);
          });
          histData(element.device_id, 'front_switch', 2).then((results) => {
            return setMainHist(results);
          });
        } catch {
          console.log('Fetch error');
        }
      }
    });
    // let tmp = [];

    let toiletTmp = [];
    let corridorTmp = [];
    let mainTmp = [];

    timeoutid.current = setTimeout(() => {
      // tmp.push(Number(moment(corridor.time).format('x')), corridor.state === 'on' ? 1 : 0);
      if (toilet.time !== null && corridor.time !== null && main.time !== null) {
        // switch (Number(dropdown)) {
        //   case 0:
        //     tmp.push(Number(moment(toilet.time).format('x')), toilet.state === 'on' ? 1 : 0);
        //     console.log('toilet');
        //     break;
        //   case 1:
        //     tmp.push(Number(moment(corridor.time).format('x')), corridor.state === 'on' ? 1 : 0);
        //     console.log('corridor');
        //     break;
        //   case 2:
        //     tmp.push(Number(moment(main.time).format('x')), main.state === 'on' ? 1 : 0);
        //     console.log('main');
        //     break;
        //   default:
        //     console.log('default');
        //     break;
        // }

        toiletTmp.push(Number(moment(toilet.time).format('x')), toilet.state === 'on' ? 1 : 0);
        corridorTmp.push(
          Number(moment(corridor.time).format('x')),
          corridor.state === 'on' ? 1 : 0
        );
        mainTmp.push(Number(moment(main.time).format('x')), main.state === 'on' ? 1 : 0);

        dispatch(
          energyActions.mintelHistoryData({
            hist: {
              front_switch: {
                toilet: toiletTmp,
                corridor: corridorTmp,
                main: mainTmp,
              },
              back_switch: [],
              ac: [],
              fan: [],
              fridge: { state: [], power: [] },
              smart_meter: { energy: [], power: [] },
              smart_tv: [],
            },
          })
        );
      }
    }, 2000);

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomNo, props.startDate, props.stopDate]);

  //##-----------------Sync status from firebase---------------------
  const initSwitch = (status1, status2, status3) => {
    if (status1 === 'on') {
      setOpenlight1(true);
    } else {
      setOpenlight1(false);
    }
    if (status2 === 'on') {
      setOpenlight2(true);
    } else {
      setOpenlight2(false);
    }
    if (status3 === 'on') {
      setOpenlight3(true);
    } else {
      setOpenlight3(false);
    }
  };

  //##-----------------------Toilet Switch---------------------------
  const onClick1 = async (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenlight1((prev) => !prev);
    const location = 'Toilet Switch';

    if (openlight1 !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openlight1 ? 'on' : 'off', location, currentRoomNo),
        200
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##-----------------------Corridor Switch-------------------------
  const onClick2 = async (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenlight2((prev) => !prev);
    const location = 'Corridor Switch';

    if (openlight2 !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openlight2 ? 'on' : 'off', location, currentRoomNo),
        200
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##-----------------------Main Switch------------------------------
  const onClick3 = async (event) => {
    clearTimeout(timeoutid.current);
    event.preventDefault();
    setOpenlight3((prev) => !prev);
    const location = 'Main Switch';
    if (openlight3 !== null) {
      timeoutid.current = setTimeout(
        () => triggerAPI(!openlight3 ? 'on' : 'off', location, currentRoomNo),
        200
      );
    }
    return () => clearTimeout(timeoutid.current);
  };

  //##-------------------Tab menu------------------------------
  const onClickTabMenu = (event, tabName) => {
    event.preventDefault();
    setCurrentTab(tabName);
  };

  //##------------------------ON Close Model--------------------------
  const onCloseModal = (event) => {
    event.preventDefault();
    dispatch(dashboardControlActions.showIotModal(false));
    dispatch(energyActions.clearHistoryData());
  };

  //##----------------------Image Path-------------
  const imagesPath = {
    open: '/assets/images/devices/icon-light-open.png',
    close: '/assets/images/devices/icon-light-close.png',
  };

  const image_link = 'https://images-na.ssl-images-amazon.com/images/I/41C6YYGB7IL._SL1000_.jpg';

  // console.log(TmpData);
  // console.log(dataPlotRealTime);

  // const hist_plot = {
  //   series: [
  //     {
  //       name: 'Front Switch',
  //       // data: plotHistData.concat(Main_mdb.plot),
  //       // data: InitPlotData.front_switch.concat(TmpData),
  //       // name: 'Switch',
  //       data:
  //         plotHistData !== undefined &&
  //         plotHistData !== null &&
  //         InitPlotData.front_switch !== undefined
  //           ? plotHistData.concat(InitPlotData.front_switch)
  //           : [],
  //       // data: dataPlotRealTime[0] !== NaN ? dataPlotRealTime : [],
  //     },
  //   ],
  //   options: {
  //     // annotations: {
  //     //   yaxis: [
  //     //     {
  //     //       // x: 0,
  //     //       y: 1,
  //     //       // borderColor: '#00E396',
  //     //       label: {
  //     //         // borderColor: '#00E396',
  //     //         style: {
  //     //           color: '#fff',
  //     //           background: '#00E396',
  //     //         },
  //     //         text: 'Light ON',
  //     //       },
  //     //     },
  //     //     {
  //     //       // x: 0,
  //     //       y: 0,
  //     //       // borderColor: '#e30062',
  //     //       label: {
  //     //         // borderColor: '#e30062',
  //     //         style: {
  //     //           color: '#fff',
  //     //           background: '#e30062',
  //     //         },
  //     //         text: 'Light OFF',
  //     //       },
  //     //     },
  //     //   ],
  //     // },
  //     // chart: {
  //     //   id: 'area-datetime',
  //     //   type: 'area',
  //     //   height: 350,
  //     //   zoom: {
  //     //     autoScaleYaxis: true,
  //     //   },
  //     // },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     markers: {
  //       size: 0,
  //       // style: 'hollow',
  //     },
  //     stroke: {
  //       curve: 'stepline',
  //       width: 2,
  //     },
  //     xaxis: {
  //       type: 'datetime',
  //       min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
  //       max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
  //       labels: {
  //         datetimeUTC: false,
  //       },
  //     },

  //     yaxis: {
  //       tickAmount: 1,
  //       labels: {
  //         formatter: function (value) {
  //           return value === 1 ? 'ON' : 'OFF';
  //         },
  //       },
  //       // max: 1.5,
  //       // show: false,
  //     },
  //     title: {
  //       text: 'Activity in 24 hrs',
  //       align: 'left',
  //     },
  //     tooltip: {
  //       x: {
  //         format: 'dd.MM.yyyy HH:mm',
  //       },
  //     },
  //   },
  // };

  const hist_plot_toilet = {
    series: [
      {
        name: 'Toilet Switch',
        data:
          plotHistToilet !== undefined &&
          plotHistToilet !== null &&
          InitPlotData.front_switch.toilet !== undefined
            ? plotHistToilet.concat(InitPlotData.front_switch.toilet)
            : [],
      },
    ],
    options: {
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },

      title: {
        text: 'Activity in 24 hrs of toilet switch',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  const hist_plot_corridor = {
    series: [
      {
        name: 'Corridor Switch',
        data:
          plotHistCorridor !== undefined &&
          plotHistCorridor !== null &&
          InitPlotData.front_switch.corridor !== undefined
            ? plotHistCorridor.concat(InitPlotData.front_switch.corridor)
            : [],
      },
    ],
    options: {
      noData: {
        text: 'No activity',
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },

      title: {
        text: 'Activity in 24 hrs of corridor switch',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  const hist_plot_main = {
    series: [
      {
        name: 'Main Switch',
        data:
          plotHistMain !== undefined &&
          plotHistMain !== null &&
          InitPlotData.front_switch.main !== undefined
            ? plotHistMain.concat(InitPlotData.front_switch.main)
            : [],
      },
    ],
    options: {
      noData: {
        text: 'No activity',
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        min: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 00:00:00`).getTime(),
        max: new Date(`${d.getDate()} ${GetMonth()} ${d.getFullYear()} 23:59:59`).getTime(),
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        tickAmount: 1,
        labels: {
          formatter: function (value) {
            return value === 1 ? 'ON' : 'OFF';
          },
        },
      },

      title: {
        text: 'Activity in 24 hrs of main switch',
        align: 'left',
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
    },
  };

  return (
    <div className="modal is-active" id="iot-modal">
      <div
        className="modal-background"
        onClick={(e) => onCloseModal(e)}
        id="modal-background-IOT"
      ></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ margin: 0, padding: '0px 20px 0px 20px' }}>
          <div className="room-name" style={{ width: '100%' }}>
            <p className="modal-card-title" style={{ marginTop: '1rem' }}>
              Front Switch
            </p>
          </div>
        </header>
        <section
          className="modal-card-body"
          style={{
            maxHeight: '100%',
            height: '600px',
          }}
        >
          {isControlIot && (
            <div id="ucount-w">
              <div id="modal-menu">
                <nav className="panel" style={{ boxShadow: 'none' }}>
                  <div className="tabs is-toggle is-fullwidth" style={{ marginBottom: '10px' }}>
                    <ul style={{ margin: '15px' }}>
                      <List
                        isSelected={currentTab === 'control'}
                        onClick={(e) => onClickTabMenu(e, 'control')}
                      >
                        CONTROL
                      </List>
                      <List
                        isSelected={currentTab === 'info'}
                        onClick={(e) => onClickTabMenu(e, 'info')}
                      >
                        INFO
                      </List>
                      <List
                        isSelected={currentTab === 'activity'}
                        onClick={(e) => onClickTabMenu(e, 'activity')}
                      >
                        ACTIVITY
                      </List>
                      <List
                        isSelected={currentTab === 'history'}
                        onClick={(e) => onClickTabMenu(e, 'history')}
                      >
                        HISTORY
                      </List>
                    </ul>
                  </div>

                  {/* Control tab */}
                  {currentTab === 'control' && (
                    <div className="iot-element" id="iot-control-light1">
                      <div className="columns">
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                          >
                            Toilet Switch
                          </div>
                          <div className="column">
                            <img
                              alt="front-switch"
                              id="icon-light-close"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '17%',
                                cursor: 'pointer',
                              }}
                              // Toilet Light
                              src={openlight1 ? imagesPath.open : imagesPath.close}
                              onClick={(e) => onClick1(e)}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            {/* TYPE: on-off --> change button to on off */}
                            <button
                              className={
                                // status.toilet_switch.status === 'on'
                                openlight1
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={(e) => onClick1(e)}
                            >
                              {/* {status.toilet_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openlight1 ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                          >
                            Corridor Switch
                          </div>
                          <div className="column">
                            <img
                              alt="front-switch-1"
                              id="icon-light-close1"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '17%',
                                cursor: 'pointer',
                              }}
                              // Corridor Switch
                              src={openlight2 ? imagesPath.open : imagesPath.close}
                              onClick={(e) => onClick2(e)}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            <button
                              // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_bdcd50/data/gang 1", "type": "on-off", "deviceid": "tasmota_BDCD50", "gang": 1, "icons": ["icon-light-close1", "icon-light-open1"]}'
                              className={
                                // status.corridor_switch.status === 'on'
                                openlight2
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={(e) => onClick2(e)}
                            >
                              {/* {status.corridor_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openlight2 ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                        <div
                          className="column"
                          style={{
                            // border: '1px #aaa solid',
                            borderRadius: '5px',
                            margin: '5px',
                          }}
                        >
                          <div
                            className="column"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                            }}
                          >
                            Main Switch
                          </div>
                          <div className="column">
                            <img
                              alt="front-switch-2"
                              id="icon-light-close2"
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '17%',
                                cursor: 'pointer',
                              }}
                              // src="/assets/images/devices/icon-light-close.png"
                              src={openlight3 ? imagesPath.open : imagesPath.close}
                              onClick={(e) => onClick3(e)}
                            />
                            <hr />
                          </div>
                          <div className="column">
                            <button
                              // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_bdcd50/data/gang 2", "type": "on-off", "deviceid": "tasmota_BDCD50", "gang": 2, "icons": ["icon-light-close2","icon-light-open2"]}'
                              className={
                                // status.main_switch.status === 'on'
                                openlight3
                                  ? 'button is-success device-controller'
                                  : 'button is-unsuccess device-controller'
                              }
                              style={{
                                width: '100px',
                                height: 'auto',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              onClick={(e) => onClick3(e)}
                            >
                              {/* {status.main_switch.status === 'on' ? 'ON' : 'OFF'} */}
                              {openlight3 ? 'ON' : 'OFF'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === 'info' && (
                    <Info image={image_link} detail={props.detail} header={props.header} />
                  )}
                  {currentTab === 'activity' && (
                    <ActivityData onClick={onchildClickActivity} subDevices={props.subDevices} />
                  )}
                  {currentTab === 'history' && (
                    <HistoryData
                      // data={hist_plot}
                      toiletPlot={hist_plot_toilet}
                      corridorPlot={hist_plot_corridor}
                      mainPlot={hist_plot_main}
                      onClick={onchildClickActivity}
                      startDate={props.startDate}
                      stopDate={props.stopDate}
                      handleStartDate={props.handleStartDate}
                      handleStopDate={props.handleStopDate}
                    />
                  )}
                </nav>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-fullwidth" style={{ padding: '10px 20px 10px 20px' }}>
          <div
            className="column is-fullwidth"
            style={{ padding: '0', width: '100%', textAlign: 'right' }}
          >
            <button
              onClick={(e) => onCloseModal(e)}
              className="button"
              style={{
                background: '#555',
                color: '#fff',
              }}
            >
              DONE
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export { LightSwitchFrontControl };
