import React from 'react';
import { EnergyTabLayout } from './LayoutTabs';

export const EnergyContainers = () => {
  return (
    <div className="content">
      <EnergyTabLayout />
    </div>
  );
};
