import React from 'react';

export const FrontSwitchComponents = (props) => {
  const currentTab = props.currentTab;
  const openlight1 = true;
  const openlight2 = true;
  const openlight3 = true;

  function onClick1() {}
  function onClick2() {}
  function onClick3() {}

  const imagesPath = {
    open: '/assets/images/devices/icon-light-open.png',
    close: '/assets/images/devices/icon-light-close.png',
  };
  return (
    <>
      {props.menu}
      {/* Control tab */}
      {currentTab === 'control' && (
        <div className="iot-element" id="iot-control-light1">
          <div className="columns">
            <div
              className="column"
              style={{
                // border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Toilet Switch
              </div>
              <div className="column">
                <img
                  alt="front-switch"
                  id="icon-light-close"
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '17%',
                    cursor: 'pointer',
                  }}
                  // Toilet Light
                  src={openlight1 ? imagesPath.open : imagesPath.close}
                  onClick={(e) => onClick1(e)}
                />
                <hr />
              </div>
              <div className="column">
                {/* TYPE: on-off --> change button to on off */}
                <button
                  className={
                    // status.toilet_switch.status === 'on'
                    openlight1
                      ? 'button is-success device-controller'
                      : 'button is-unsuccess device-controller'
                  }
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={(e) => onClick1(e)}
                >
                  {/* {status.toilet_switch.status === 'on' ? 'ON' : 'OFF'} */}
                  {openlight1 ? 'ON' : 'OFF'}
                </button>
              </div>
            </div>
            <div
              className="column"
              style={{
                // border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Corridor Switch
              </div>
              <div className="column">
                <img
                  alt="front-switch-1"
                  id="icon-light-close1"
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '17%',
                    cursor: 'pointer',
                  }}
                  // Corridor Switch
                  src={openlight2 ? imagesPath.open : imagesPath.close}
                  onClick={(e) => onClick2(e)}
                />
                <hr />
              </div>
              <div className="column">
                <button
                  // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_bdcd50/data/gang 1", "type": "on-off", "deviceid": "tasmota_BDCD50", "gang": 1, "icons": ["icon-light-close1", "icon-light-open1"]}'
                  className={
                    // status.corridor_switch.status === 'on'
                    openlight2
                      ? 'button is-success device-controller'
                      : 'button is-unsuccess device-controller'
                  }
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={(e) => onClick2(e)}
                >
                  {/* {status.corridor_switch.status === 'on' ? 'ON' : 'OFF'} */}
                  {openlight2 ? 'ON' : 'OFF'}
                </button>
              </div>
            </div>
            <div
              className="column"
              style={{
                // border: '1px #aaa solid',
                borderRadius: '5px',
                margin: '5px',
              }}
            >
              <div
                className="column"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Main Switch
              </div>
              <div className="column">
                <img
                  alt="front-switch-2"
                  id="icon-light-close2"
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '17%',
                    cursor: 'pointer',
                  }}
                  // src="/assets/images/devices/icon-light-close.png"
                  src={openlight3 ? imagesPath.open : imagesPath.close}
                  onClick={(e) => onClick3(e)}
                />
                <hr />
              </div>
              <div className="column">
                <button
                  // fbconnect='{"fbpath": "/hotel/mintel/building main/floor 1/room 108/tasmota_bdcd50/data/gang 2", "type": "on-off", "deviceid": "tasmota_BDCD50", "gang": 2, "icons": ["icon-light-close2","icon-light-open2"]}'
                  className={
                    // status.main_switch.status === 'on'
                    openlight3
                      ? 'button is-success device-controller'
                      : 'button is-unsuccess device-controller'
                  }
                  style={{
                    width: '100px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={(e) => onClick3(e)}
                >
                  {/* {status.main_switch.status === 'on' ? 'ON' : 'OFF'} */}
                  {openlight3 ? 'ON' : 'OFF'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentTab === 'info' && <>INFO</>}
      {currentTab === 'activity' && <>ACTIVITY</>}
      {currentTab === 'history' && <>HISTORY</>}

      {/* {currentTab === 'info' && (
        <Info image={image_link} detail={props.detail} header={props.header} />
      )}
      {currentTab === 'activity' && <ActivityData onClick={onchildClickActivity} />}
      {currentTab === 'history' && (
        <HistoryData
          data={hist_plot}
          onClick={onchildClickActivity}
          startDate={props.startDate}
          stopDate={props.stopDate}
          handleStartDate={props.handleStartDate}
          handleStopDate={props.handleStopDate}
        />
      )} */}
    </>
  );
};
