import React, { useContext, useMemo, useRef, useState } from 'react';
import { FirebaseContext2 } from '../../../../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { energyActions } from '../../../../actions';
import { modBus } from 'api/services';
import moment from 'moment';
import { TdpkRealtimeComponents } from '../../../../components/TDPK';

const TdpkRealtimeContainer = () => {
  const firebase = useContext(FirebaseContext2);
  const timeoutid = useRef();

  //##-----------------Global Variables Redux-----------------------
  const dispatch = useDispatch();
  const Main_mdb = useSelector((state) => state.energy.main_mdb);
  const Restaurant_mdb = useSelector((state) => state.energy.restaurant_mdb);
  const Laundry_mdb = useSelector((state) => state.energy.laundry_mdb);
  //##----------------Plot From API-------------------------
  const [histMain, setHistMain] = useState(null);
  const [histRest, setHistRest] = useState(null);
  const [histLaun, setHistLaun] = useState(null);
  //##-----------------Plot From Firebase-------------------
  const [plothistMain, setPlotHistMain] = useState([]);
  const [plothistRest, setPlotHistRest] = useState([]);
  const [plothistLaun, setPlotHistLaun] = useState([]);
  //##-----------------DatePicker---------------------------
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [fetchSampling, setFetchSampling] = useState(1);
  const [ranking, setRanking] = useState(null);
  const [mainMdb, setMainMdb] = useState(null);
  const [restaurantMdb, setrestaurantMdb] = useState(null);
  const [laundryMdb, setLaundryMdb] = useState(null);
  const [onlyEnergy, setOnlyEnergy] = useState(null);

  //##-----------------Handle Start Date------------------------------
  const handleStartDate = (event) => {
    setStartDate(event);
  };

  //##-----------------Handle Stop Date-------------------------------
  const handleStopDate = (event) => {
    setStopDate(event);
  };

  //##-----------------set sampling-----------------------------------
  const sampling = (event) => {
    setHistLaun(null);
    setHistRest(null);
    setHistMain(null);
    dispatch(energyActions.clearEnergyData());
    setFetchSampling(event);
  };

  //##--------------------------Fetch Data Function From Firebase----------------------------------
  async function fetchData(firebase, didMount) {
    // Constant URL
    let laundry_shop =
      'hotel/mintel/building main/laundry/iot_devices/9c:a5:25:ab:3a:b4:7/electric';
    let restaurant =
      'hotel/mintel/building main/restaurant/iot_devices/9c:a5:25:ab:3a:b4:8/electric';
    let main_hotel =
      'hotel/mintel/building main/main_energy/iot_devices/9c:a5:25:ab:3a:b4:9/electric';

    let hotel_ranking = 'hotel/mintel/energy/energy_performance/ranking';

    let only_energy_url = 'hotel/mintel/dashboard/energy_summary';

    if (didMount) {
      console.log('Firebase Did Mounted');
      await firebase.db.ref(main_hotel).off('value');

      await firebase.db.ref(restaurant).off('value');

      await firebase.db.ref(laundry_shop).off('value');

      await firebase.db.ref(hotel_ranking).off('value');

      await firebase.db.ref(only_energy_url).off('value');
    } else {
      // Fetch Main MDB
      await firebase.db.ref(main_hotel).on('value', function (snap) {
        let capt = snap.val();
        setMainMdb(capt);
      });

      // Fetch restaurant MDB
      await firebase.db.ref(restaurant).on('value', function (snap) {
        let capt = snap.val();
        setrestaurantMdb(capt);
      });

      // Fetch Laundry MDB
      await firebase.db.ref(laundry_shop).on('value', function (snap) {
        let capt = snap.val();
        setLaundryMdb(capt);
      });

      // Ranking
      await firebase.db.ref(hotel_ranking).on('value', function (snap) {
        let capt = snap.val();
        setRanking(capt);
      });

      // Only Energy
      await firebase.db.ref(only_energy_url).on('value', function (snap) {
        let capt = snap.val();
        setOnlyEnergy(capt);
      });
    }
  }

  //##--------------------Fetch History Data From API---------------------
  async function histData(dev_id, sub_dev, sampling_time) {
    try {
      let results = await modBus.getModBusHistory(
        dev_id,
        sub_dev,
        false,
        startDate,
        stopDate,
        sampling_time
      );
      if (results) {
        if (results['errors'] !== undefined) {
          // [DEBUG]
          let errorMsgDevice = results['errors'];
          // alert(errorMsg);
          console.error(errorMsgDevice);

          return null;
        } else {
          if (results.data) {
            return results.data.results;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  //##----------------Get month number to string---------------------------
  function GetMonth(start) {
    // eslint-disable-next-line
    let month = [];
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';
    if (start) {
      return month[startDate.getMonth()];
    } else {
      return month[stopDate.getMonth()];
    }
  }

  //##-------------------------- Fetching ----------------------------------
  useMemo(() => {
    fetchData(firebase, false);
    return () => fetchData(firebase, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //##---------------------------Historical Data----------------------------
  useMemo(() => {
    console.log('try to fetch');

    histData('9c:a5:25:ab:3a:b4:7', 0, fetchSampling).then((results) => {
      /// Laundry
      return setHistLaun(results);
    });
    histData('9c:a5:25:ab:3a:b4:8', 0, fetchSampling).then((results) => {
      /// Restaurant
      return setHistRest(results);
    });
    histData('9c:a5:25:ab:3a:b4:9', 3, fetchSampling).then((results) => {
      /// Main
      return setHistMain(results);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, stopDate, fetchSampling]);

  useMemo(() => {
    if (
      (histMain && histLaun && histRest) !== null &&
      (histMain && histLaun && histRest) !== undefined
    ) {
      let laundry = [];
      let main = [];
      let rest = [];
      histMain.forEach((item) => {
        if (item.power !== null && item.timestamp !== null) {
          main.push([Number(moment(item.timestamp).format('x')), Number(item.power)]);
        }
        setPlotHistMain(main);
      });
      histLaun.forEach((item) => {
        if (item.power !== null && item.timestamp !== null) {
          laundry.push([Number(moment(item.timestamp).format('x')), Number(item.power)]);
        }
        setPlotHistLaun(laundry);
      });
      histRest.forEach((item) => {
        if (item.power !== null && item.timestamp !== null) {
          rest.push([Number(moment(item.timestamp).format('x')), Number(item.power)]);
        }
        setPlotHistRest(rest);
      });
    }
  }, [histMain, histLaun, histRest]);

  //##------------------------- Dispatching --------------------------------
  useMemo(() => {
    clearTimeout(timeoutid.current);
    // seriesData(); /////////
    if ((histMain && histLaun && histRest) !== null) {
      if (mainMdb && restaurantMdb && laundryMdb !== null) {
        timeoutid.current = setTimeout(async () => {
          dispatch(
            energyActions.mintelEnergy({
              main_mdb: {
                plot: [
                  Number(moment(mainMdb.total.timestamp).format('x')),
                  Number(mainMdb.total.power.toFixed(2)),
                ],
                p_1: {
                  power: mainMdb.phase_1.power.toFixed(2),
                  current: mainMdb.phase_1.current.toFixed(2),
                  voltage: mainMdb.phase_1.voltage.toFixed(2),
                  power_reactive: mainMdb.phase_1.power_reactive.toFixed(2),
                  time: mainMdb.phase_1.timestamp,
                },
                p_2: {
                  power: mainMdb.phase_2.power.toFixed(2),
                  current: mainMdb.phase_2.current.toFixed(2),
                  voltage: mainMdb.phase_2.voltage.toFixed(2),
                  power_reactive: mainMdb.phase_2.power_reactive.toFixed(2),
                  time: mainMdb.phase_2.timestamp,
                },
                p_3: {
                  power: mainMdb.phase_3.power.toFixed(2),
                  current: mainMdb.phase_3.current.toFixed(2),
                  voltage: mainMdb.phase_3.voltage.toFixed(2),
                  power_reactive: mainMdb.phase_3.power_reactive.toFixed(2),
                  time: mainMdb.phase_3.timestamp,
                },
              },
              restaurant_mdb: {
                plot: [
                  Number(moment(restaurantMdb.total.timestamp).format('x')),
                  Number(restaurantMdb.total.power.toFixed(2)),
                ],
                p_1: {
                  power: restaurantMdb.phase_1.power.toFixed(2),
                  current: restaurantMdb.phase_1.current.toFixed(2),
                  voltage: restaurantMdb.phase_1.voltage.toFixed(2),
                  power_reactive: restaurantMdb.phase_1.power_reactive.toFixed(2),
                  time: restaurantMdb.phase_1.timestamp,
                },
                p_2: {
                  power: restaurantMdb.phase_2.power.toFixed(2),
                  current: restaurantMdb.phase_2.current.toFixed(2),
                  voltage: restaurantMdb.phase_2.voltage.toFixed(2),
                  power_reactive: restaurantMdb.phase_2.power_reactive.toFixed(2),
                  time: restaurantMdb.phase_2.timestamp,
                },
                p_3: {
                  power: restaurantMdb.phase_3.power.toFixed(2),
                  current: restaurantMdb.phase_3.current.toFixed(2),
                  voltage: restaurantMdb.phase_3.voltage.toFixed(2),
                  power_reactive: restaurantMdb.phase_3.power_reactive.toFixed(2),
                  time: restaurantMdb.phase_3.timestamp,
                },
              },
              laundry_mdb: {
                plot: [
                  Number(moment(laundryMdb.total.timestamp).format('x')),
                  Number(laundryMdb.total.power.toFixed(2)),
                ],
                p_1: {
                  power: laundryMdb.phase_1.power.toFixed(2),
                  current: laundryMdb.phase_1.current.toFixed(2),
                  voltage: laundryMdb.phase_1.voltage.toFixed(2),
                  power_reactive: laundryMdb.phase_1.power_reactive.toFixed(2),
                  time: laundryMdb.phase_1.timestamp,
                },
                p_2: {
                  power: laundryMdb.phase_2.power.toFixed(2),
                  current: laundryMdb.phase_2.current.toFixed(2),
                  voltage: laundryMdb.phase_2.voltage.toFixed(2),
                  power_reactive: laundryMdb.phase_2.power_reactive.toFixed(2),
                  time: laundryMdb.phase_2.timestamp,
                },
                p_3: {
                  power: laundryMdb.phase_3.power.toFixed(2),
                  current: laundryMdb.phase_3.current.toFixed(2),
                  voltage: laundryMdb.phase_3.voltage.toFixed(2),
                  power_reactive: laundryMdb.phase_3.power_reactive.toFixed(2),
                  time: laundryMdb.phase_3.timestamp,
                },
              },
            })
          );
        }, 2000);
      }
    }

    return () => clearTimeout(timeoutid.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMdb, restaurantMdb, laundryMdb, histMain, histLaun, histRest]);

  const main_graph = {
    series: [
      {
        name: 'Main Power (kW)',
        data: histMain !== null && plothistMain !== [] ? plothistMain.concat(Main_mdb.plot) : [],
      },
      {
        name: 'Restaurant Power (kW)',
        data:
          histRest !== null && plothistRest !== [] ? plothistRest.concat(Restaurant_mdb.plot) : [],
      },
      {
        name: 'Laundry Shop Power (kW)',
        data: histLaun !== null && plothistLaun !== [] ? plothistLaun.concat(Laundry_mdb.plot) : [],
      },
    ],
    options: {
      noData: {
        text: 'Loading...',
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      stroke: {
        width: 2,
        cruve: 'smooth',
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (val) {
            return Number(val).toFixed(2);
          },
        },
        title: {
          text: '(kW)',
        },
      },
      xaxis: {
        type: 'datetime',
        min: new Date(
          `${startDate.getDate()} ${GetMonth(true)} ${startDate.getFullYear()} 00:00:00`
        ).getTime(),
        max: new Date(
          `${stopDate.getDate() - 1} ${GetMonth(false)} ${stopDate.getFullYear()} 23:59:59`
        ).getTime(),
        labels: {
          datetimeUTC: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: 'dd.MM.yyyy HH:mm',
        },
      },
      title: {
        text: 'Real-Time Power',
        align: 'left',
      },
    },
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <TdpkRealtimeComponents
        main_graph={main_graph}
        main_mdb={mainMdb}
        restaurant_mdb={restaurantMdb}
        laundry_mdb={laundryMdb}
        startDate={startDate}
        stopDate={stopDate}
        handleStartDate={handleStartDate}
        handleStopDate={handleStopDate}
        sampling={sampling}
        ranking={ranking}
        onlyEnergy={onlyEnergy}
        fetchSampling={fetchSampling}
      />
    </div>
  );
};

export { TdpkRealtimeContainer };
