import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Row, Col } from 'reactstrap';

const Container = styled.div`
  margin: 5px;
  width: 74px;
  background-color: #e8e8e8;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 32px;
  position: relative;
`;

const Button = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#78E2C9' : '#707070')};
  color: white;
  padding: 6px 5px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: ${(props) => (props.selected ? '34px' : '2px')};
  transition: all 0.3s ease;
`;

export const ToggleButton = (props) => {
  const toggleSelected = () => {
    props.setSelected({ check: props.check, name: props.device_name, state: !props.defaultState });
  };
  return (
    <>
      <Row
        style={{
          minHeight: '43px',
          margin: '0px',
        }}
      >
        <Col lg="3">
          <Container onClick={toggleSelected}>
            <Button selected={props.defaultState}>{props.defaultState ? 'ON' : 'OFF'}</Button>
          </Container>
        </Col>

        <Col
          lg="8"
          style={{
            margin: '10px 0px 15px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => props.removeDevice(props.name)}
        >
          <div>{props.name}</div>
          <div data-tip="Remove this device" data-type="warning" place="top">
            <i className="fas fa-minus" style={{color:'red'}}/>
          </div>
        </Col>
        <ReactTooltip effect="solid" />
      </Row>
    </>
  );
};

export const ACControl = (props) => {
  return (
    <Row
      style={{
        height: '40px',
        width: '500px',
        marginLeft: '10px',
        marginTop: '15px',
        marginBottom: '20px',
      }}
    >
      <Col lg="3" md="4" sm="4" style={{ margin: '0px', bottom: '18px' }}>
        <div>
          <Row style={{ marginLeft: '5px' }}>
            <label>Mode</label>
          </Row>
          <Row style={{ margin: '0px' }}>
            <select
              name={'modeSelect'}
              value={props.modeSelect}
              onChange={(value) => props.setModeSelect(value.target.value)}
              style={{
                width: '130px',
                height: '40px',
                border: '1px solid #fff',
                borderRadius: '4px',
                color: 'gray',
              }}
            >
              <option value="cool">Cool</option>
              <option value="dry">Dry</option>
              <option value="fan">Fan</option>
            </select>
          </Row>
        </div>
      </Col>
      <Col lg="3" md="4" sm="4" style={{ margin: '0px', bottom: '18px' }}>
        {props.modeSelect !== 'off' ? (
          <div>
            <Row>
              <label>Temp</label>
            </Row>
            <Row>
              <select
                name={'tempSelect'}
                value={props.tempSelect}
                onChange={(value) => props.setTempSelect(value.target.value)}
                style={{
                  width: '130px',
                  height: '40px',
                  border: '1px solid #fff',
                  borderRadius: '4px',
                  color: 'gray',
                }}
              >
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
              </select>
            </Row>
          </div>
        ) : null}
      </Col>
      <Col lg="2" md="4" sm="4" style={{ bottom: '18px' }}>
        {props.modeSelect !== 'off' ? (
          <div>
            <Row>
              <label>Fan</label>
            </Row>
            <Row>
              <select
                name={'fanSelect'}
                value={props.fanSelect}
                onChange={(value) => props.setFanSelect(value.target.value)}
                style={{
                  width: '130px',
                  height: '40px',
                  border: '1px solid #fff',
                  borderRadius: '4px',
                  color: 'gray',
                }}
              >
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </Row>
          </div>
        ) : null}
      </Col>
    </Row>
  );
};
