import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
// import DatePicker from 'react-bootstrap-date-picker';
import { Row, Table } from 'react-bootstrap';

const DatePickerContainer = styled.div`
  margin: 10px;
  display: inline-flex;
`;

export const DatePickers = (props) => {
  return (
    <DatePickerContainer>
      <Row>
        <Table
          style={{
            fontSize: 14,
            backgroundColor: 'transparent',
            margin: '0px 0px 0px 10px',
            padding: 0,
            zIndex: 999,
          }}
        >
          <tbody style={{ margin: 0, padding: 0 }}>
            <tr>
              <td>
                <strong>Filter</strong>
              </td>
              <td>
                From{' '}
                <DatePicker
                  selected={props.daySelectStart}
                  onChange={(date) => props.handleStartDate(date)}
                />
              </td>
              <td>
                To{' '}
                <DatePicker
                  selected={props.daySelectStop}
                  onChange={(date) => props.handleStopDate(date)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        {/* <Col lg="2" style={{ height: 30, alignItems: 'center', fontSize: 14 }}>
          <strong>Filter</strong>
        </Col>
        <Col lg="5" sm="6" style={{ height: 30, alignItems: 'center', fontSize: 14 }}>
          From{' '}
          <DatePicker
            selected={props.daySelectStart}
            onChange={(date) => props.handleStartDate(date)}
          />
        </Col>
        <Col lg="5" sm="6" style={{ height: 30, alignItems: 'center', fontSize: 14 }}>
          To{' '}
          <DatePicker
            selected={props.daySelectStop}
            onChange={(date) => props.handleStopDate(date)}
          />
        </Col> */}
      </Row>
    </DatePickerContainer>
  );
};
