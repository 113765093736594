import React, { useContext, useState, useEffect } from 'react';
import { TdpkDeviceOperationComponents } from '../../../../components/TDPK';
import { FirebaseContext2 } from '../../../../../../firebase';

const TdpkDeviceOperationContainer = () => {
  const firebase = useContext(FirebaseContext2);
  const [operation, setOperation] = useState(null);
  const [hvac, setHvac] = useState(null);
  const [lighting, setLighting] = useState(null);
  const [pump, setPump] = useState(null);

  function fetchData(didMount) {
    const main_path = 'hotel/mintel/energy/operation_schedule';

    if (didMount) {
      firebase.db.ref(main_path).off('value');
    } else {
      firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        setOperation(capt);
      });
    }
  }

  useEffect(() => {
    fetchData(false);
    return () => {
      fetchData(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (operation !== null) {
      let tmp_hvac = [];
      let tmp_light = [];
      let tmp_pump = [];
      let devices = Object.values(operation.energy_consumption_by_system);
      devices.forEach((element) => {
        tmp_hvac.push(element['hvac']);
        tmp_light.push(element['lighting']);
        tmp_pump.push(element['pump']);
      });
      setHvac(tmp_hvac);
      setLighting(tmp_light);
      setPump(tmp_pump);
    }
  }, [operation]);

  let colorPalette = ['#00D8B6', '#008FFB', '#FEB019', '#FF4560', '#775DD0'];
  let plot_bar = {
    series: [
      {
        name: 'HVAC',
        data: hvac !== null ? hvac : [],
        // data: [],
      },
      {
        name: 'Lighting',
        data: lighting !== null ? lighting : [],
        // data: [],
      },
      {
        name: 'Pump',
        data: pump !== null ? pump : [],
        // data: [],
      },
    ],
    options: {
      chart: {
        stacked: true,
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
        },
      },
      colors: colorPalette,
      labels: operation !== null ? Object.keys(operation.energy_consumption_by_system) : [],
      xaxis: {
        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: 'kW',
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: '#78909c',
          },
        },
      },
      title: {
        text: 'Energy Consumption by System',
        align: 'center',
        style: {
          fontSize: '18px',
        },
      },
    },
  };

  return (
    <div className="content" style={{ marginTop: '20px' }}>
      <TdpkDeviceOperationComponents
        plot_bar={plot_bar}
        system={operation !== null ? operation.system : ''}
      />
    </div>
  );
};

export { TdpkDeviceOperationContainer };
