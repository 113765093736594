import { AUTH } from '../types';

// import { createErrorMessageFromJsonResponse } from '../../../utilities';

function requestLogout() {
  return {
    type: AUTH.LOGOUT_REQUEST,
  };
}

function successLogout(token) {
  return {
    type: AUTH.LOGOUT_SUCCESS,
    token,
  };
}

function failureLogout(error) {
  return {
    type: AUTH.LOGOUT_FAILURE,
    error,
  };
}

function logout() {
  return async (dispatch, getState) => {
    await dispatch(requestLogout());

    try {
      await localStorage.clear();
      await dispatch(successLogout());
      return true;
    } catch (error) {
      await dispatch(failureLogout(error.message));
      return false;
    }
  };
}

export const logoutActions = {
  requestLogout,
  successLogout,
  failureLogout,
  logout,
};
