import React, { useState, useEffect, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ElectricityForcastedGraph } from '../../../components/EnergyCostBreakdown/ElectricityForcastedGraph/ElectricityForcastedGraph';
import styled from 'styled-components';
import { Row, Col, Card } from 'reactstrap';
import { FirebaseContext2 } from '../../../../../firebase';

const Topic = styled.h5`
  margin: 0px;
  float: right;
`;

const Header = styled.h4`
  margin: 0px;
`;

const SolarPVRecommendation = () => {
  const firebase = useContext(FirebaseContext2);
  const [solarPV, setSolarPV] = useState(null);

  function fetchData(didMount) {
    const main_path = 'hotel/mintel/energy/solar_pv_recommendation';

    if (didMount) {
      firebase.db.ref(main_path).off('value');
    } else {
      firebase.db.ref(main_path).on('value', function (snap) {
        let capt = snap.val();
        setSolarPV(capt);
      });
    }
  }

  useEffect(() => {
    fetchData(false);
    return () => fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plotData = {
    series: [
      {
        name: 'LOAD',
        data: solarPV !== null ? Object.values(solarPV.power_consumption) : [],
        type: 'area',
      },
      {
        name: 'SOLAR',
        data: solarPV !== null ? Object.values(solarPV.solar_generation) : [],
        type: 'area',
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'area',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100],
        },
      },
      yaxis: {
        tickAmount: 9,
        title: {
          text: '(kW)',
        },
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      xaxis: {
        type: 'category',
        categories: solarPV !== null ? Object.keys(solarPV.power_consumption) : [],
      },
      title: {
        text: 'Irregular Data in Time Series',
        align: 'left',
        offsetX: 14,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: -10,
      },
    },
  };
  return (
    <div style={{ marginTop: '20px' }}>
      <Col lg="12" sm="12">
        <Card>
          <Row>
            <Col lg="12" md="12" sm="12">
              <ElectricityForcastedGraph
                title={'Daily solar PV energy production (kW)'}
                options={plotData.options}
                series={plotData.series}
              />
            </Col>
          </Row>
          <div style={{ margin: '20px', marginLeft: '40px' }}>
            <Row>
              <Header>Alto Tech Solution</Header>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Investment :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {solarPV !== null && solarPV.finance.investment.value}{' '}
                  {solarPV !== null && solarPV.finance.investment.unit}
                </p>
              </Col>
              <Col lg="3">
                <Topic>Investment Score :</Topic>
              </Col>
              <Col lg="3">
                <p>{solarPV !== null && solarPV.finance.investment_score}</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Save Monthly :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {solarPV !== null && solarPV.finance.save_monthly.value}{' '}
                  {solarPV !== null && solarPV.finance.save_monthly.unit}
                </p>
              </Col>
              <Col lg="3">
                <Topic>Save Yearly :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {solarPV !== null && solarPV.finance.save_yearly.value}{' '}
                  {solarPV !== null && solarPV.finance.save_yearly.unit}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Payback :</Topic>
              </Col>
              <Col lg="3">
                <p>
                  {solarPV !== null && solarPV.finance.payback.value}{' '}
                  {solarPV !== null && solarPV.finance.payback.unit}
                </p>
              </Col>
            </Row>

            {/* <Row>
              <Col lg="3">
                <Topic>Save :</Topic>
              </Col>
              <Col lg="3">
                <p>5,010 kWh or 20,041 Baht</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Budget :</Topic>
              </Col>
              <Col lg="3">
                <p>1,515,114 Baht</p>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <Topic>Breake Event Point :</Topic>
              </Col>
              <Col lg="3">
                <p>6 year 3 month</p>
              </Col>
            </Row>
          */}
          </div>
        </Card>
      </Col>
    </div>
  );
};

export { SolarPVRecommendation };
