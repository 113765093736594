import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardControlActions } from '../../dashboard/actions';
import Sketch from 'react-p5';
import './style.css';

function HotelFloorPlanMapCanvas(props) {
  const dispatch = useDispatch();
  const roomFilter = useSelector((state) => state.dashboard.filterRoomStatusList);
  const parentMapData = props.mapData;
  const IMAGE_KEY_LIST = ['repair', 'leak', 'permit', 'require', 'disconected', 'maid'];
  const currentRoomNo = useSelector((state) => state.dashboard.currentRoomNo);
  const [p5PreloadedImageObj, setP5PreloadedImageObj] = useState({});
  //##--------------------- P5 Preload ----------------------------
  const p5Preload = async (p5) => {
    let preloadedImageObj = {};

    //## Preload Image
    var src = [
      '/assets/images/icon-repair.png',
      '/assets/images/icon-leak-elec.png',
      '/assets/images/icon-non-permit.png',
      '/assets/images/icon-predictive.png',
      '/assets/images/icon-iot-disconected.png',
      '/assets/images/icon-maid.png',
    ];
    var _keys = IMAGE_KEY_LIST;
    for (var k in _keys) {
      preloadedImageObj[_keys[k]] = await p5.loadImage(src[k]);
      preloadedImageObj[_keys[k] + '_show'] = await p5.loadImage(src[k]);
    }
    setP5PreloadedImageObj(preloadedImageObj);
  };

  //##--------------------- P5 Setup ----------------------------

  const p5Setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight / 1.2).parent(canvasParentRef);
    p5.background(255);
    p5.frameRate(10);
  };

  const p5WindowResized = (p5) => {
    p5.resizeCanvas(window.innerWidth, window.innerHeight / 1.2);
  };

  //##--------------------- P5 Draw ----------------------------

  //## Draw One Room Block
  const drawRooms = (p5, inputMapData, images) => {
    let gw = window.innerWidth / inputMapData['xgrid'];
    let gh = window.innerHeight / inputMapData['ygrid'];

    let roomMap = {
      ...inputMapData.map,
    };

    for (var key in roomMap) {
      var d = roomMap[key];
      var is_show = false;
      // var devices_status = false;

      //##------------------Filter--------------------------------

      is_show = roomStatusFilter(roomFilter, d);
      // devices_status = deviceStatusFilter(roomFilter, d);

      //##----------------- Show All box --------------------------
      if (is_show) {
        var _x = d['posx'] * gw;
        var _y = d['posy'] * gh;

        //##-----------------------Box Color-------------------------------

        boxColor(d, p5);

        //##---------------------------Room Text---------------------------
        roomTextNum(p5, _x, _y, gw, gh, key);

        //##--------------------Icons in Room Num-------------------------
        iconsOnBox(d, p5, images, _x, _y, gw, gh);

        //##-----------------text filter---------------------------------
        textInSideBox(d, p5, _x, gw, _y, gh);
      }
    }
  };

  //## Main Draw Canvas
  const p5Draw = (p5) => {
    let gw = window.innerWidth / parentMapData['xgrid'];
    let gh = window.innerHeight / parentMapData['ygrid'];
    p5.cursor('default');
    /* pointer of rooms*/
    for (let i in parentMapData.map) {
      var _elem = parentMapData.map[i];

      var sx = _elem['posx'] * gw;
      var sy = _elem['posy'] * gh;
      var ex = (_elem['posx'] + 7 / 6) * gw;
      var ey = (_elem['posy'] + 7 / 6) * gh;
      document.body.style.cursor = '';
      if (p5.mouseX >= sx && p5.mouseX <= ex && p5.mouseY >= sy && p5.mouseY <= ey) {
        p5.cursor('pointer');
      }
    }

    try {
      var images = {};

      var _keys = IMAGE_KEY_LIST;
      for (var k in _keys) {
        images[_keys[k]] = p5PreloadedImageObj[_keys[k]];
        images[_keys[k] + '_show'] = p5PreloadedImageObj[_keys[k] + '_show'];
      }

      //##---------------Top icons------------------------
      p5.background(255);
      p5.image(
        images.permit_show,
        (25 / 1) * gw,
        (1 * p5.height) / 50,
        gw / 2,
        (images.permit_show.height * gw) / 2 / images.permit_show.width
      );
      p5.image(
        images.maid_show,
        (16 / 1) * gw,
        (1 * p5.height) / 50,
        gw / 2,
        (images.maid_show.height * gw) / 2 / images.maid_show.width
      );
      p5.image(
        images.require_show,
        (25 / 1) * gw,
        (2.5 * p5.height) / 40,
        gw / 2,
        (images.require_show.height * gw) / 2 / images.require_show.width
      );
      p5.image(
        images.leak_show,
        (20 / 1) * gw,
        (1 * p5.height) / 50,
        gw / 2,
        (images.leak_show.height * gw) / 2 / images.leak_show.width
      );
      p5.image(
        images.disconected_show,
        (20 / 1) * gw,
        (2.5 * p5.height) / 40,
        gw / 2,
        (images.disconected_show.height * gw) / 2 / images.disconected_show.width
      );
      p5.fill(100);
      p5.textAlign(p5.LEFT);
      p5.textSize(gw / 2.5);
      p5.text('Housekeeper', (17 / 1) * gw, (1.5 * p5.height) / 30);
      p5.text('Electric Leakage', (21 / 1) * gw, (1.5 * p5.height) / 30);
      p5.text('IoT Disconnected', (21 / 1) * gw, (2.75 * p5.height) / 30);
      p5.text('Non-Permit Room', (26 / 1) * gw, (1.5 * p5.height) / 30);
      p5.text('Require Maintenance', (26 / 1) * gw, (2.75 * p5.height) / 30);

      p5.fill(255, 255);
      p5.stroke(150);
      //## Draw All Rooms in canvas
      drawRooms(p5, parentMapData, images);
    } catch (error) {
      console.error(error.message);
    }
  };

  //##--------------------- P5 Event Handler ----------------------------

  const onMousePressed = async (p5) => {
    if (parentMapData) {
      let gw = window.innerWidth / parentMapData['xgrid'];
      let gh = window.innerHeight / parentMapData['ygrid'];
      for (let i in parentMapData.map) {
        var _elem = parentMapData.map[i];
        var sx = _elem['posx'] * gw;
        var sy = _elem['posy'] * gh;
        var ex = (_elem['posx'] + 7 / 5) * gw;
        var ey = (_elem['posy'] + 7 / 5) * gh;

        if (p5.mouseX >= sx && p5.mouseX <= ex && p5.mouseY >= sy && p5.mouseY <= ey) {
          //## [NEW]
          if (!currentRoomNo) {
            dispatch(dashboardControlActions.selectedRoom(_elem['room_number'], _elem));
          }
        }
      }
    }
  };

  return (
    <>
      {parentMapData && (
        <Sketch
          setup={p5Setup}
          draw={p5Draw}
          mousePressed={onMousePressed}
          preload={p5Preload}
          windowResized={p5WindowResized}
        />
      )}
    </>
  );
}

//##------------------Function-------------------------
const roomStatusFilter = (roomFilter, d) => {
  //##----------------- Filter Box ------------------------
  if (roomFilter.vc) {
    if (d['clean_status'] === 'vc') {
      return true;
    }
  }
  if (roomFilter.od) {
    if (d['clean_status'] === 'od') {
      return true;
    }
  }
  if (roomFilter.oo) {
    if (d['clean_status'] === 'oo') {
      return true;
    }
  }
  if (roomFilter.vd) {
    if (d['clean_status'] === 'vd') {
      return true;
    }
  }
  if (roomFilter.oc) {
    if (d['clean_status'] === 'oc') {
      return true;
    }
  }
  if (roomFilter.ac) {
    if (d['clean_status'] === 'ac') {
      return true;
    }
  }
};

// const deviceStatusFilter = (roomFilter, d) => {
//   if (roomFilter.non) {
//     if (d['non_permit_room']) {
//       return true;
//     }
//   }
//   if (roomFilter.iot) {
//     if (d['iot_disconnected']) {
//       return true;
//     }
//   }
//   if (roomFilter.leakage) {
//     if (d['electric_leakage']) {
//       return true;
//     }
//   }
//   if (roomFilter.maintenance) {
//     if (d['require_maintenance']) {
//       return true;
//     }
//   }
//   if (roomFilter.maid) {
//     if (d['maid_img_url'] !== undefined && d['maid_img_url'] !== '') {
//       return true;
//     }
//   }
// };

const textInSideBox = (d, p5, _x, gw, _y, gh) => {
  if (d['clean_status'] === 'vc') {
    p5.fill(0);
    p5.textAlign(p5.CENTER);
    p5.text('VC', _x + (2.85 * gw) / 5, _y + gh / 2 + gh / 4);
  }
  if (d['clean_status'] === 'oo') {
    p5.fill(255);
    p5.textAlign(p5.CENTER);
    p5.text('OO', _x + (2.85 * gw) / 5, _y + gh / 2 + gh / 4);
  }
  if (d['clean_status'] === 'vd') {
    p5.fill(255);
    p5.textAlign(p5.CENTER);
    p5.text('VD', _x + (2.85 * gw) / 5, _y + gh / 2 + gh / 4);
  }
  if (d['clean_status'] === 'od') {
    p5.fill(255);
    p5.textAlign(p5.CENTER);
    p5.text('OD', _x + (2.85 * gw) / 5, _y + gh / 2 + gh / 4);
  }
  if (d['clean_status'] === 'oc') {
    p5.fill(0);
    p5.textAlign(p5.CENTER);
    p5.text('OC', _x + (2.85 * gw) / 5, _y + gh / 2 + gh / 4);
  }
  if (d['clean_status'] === 'ac') {
    p5.fill(0);
    p5.textAlign(p5.CENTER);
    p5.text('AC', _x + (2.85 * gw) / 5, _y + gh / 2 + gh / 4);
  }
};

const iconsOnBox = (d, p5, images, _x, _y, gw, gh) => {
  if (d['require_maintenance']) {
    p5.image(
      images.require,
      _x + gw - 5,
      _y + gh / 2 + gh / 4,
      gw / 2,
      (images.require.height * gw) / 2 / images.require.width
    );
  }
  if (d['non_permit_room']) {
    p5.image(
      images.permit,
      _x - 10,
      _y + gh / 2 + gh / 4,
      gw / 2,
      (images.permit.height * gw) / 2 / images.permit.width
    );
  }
  if (d['maid_img_url'] !== undefined && d['maid_img_url'] !== '') {
    p5.image(
      images.maid,
      _x - 10,
      _y + gh / 2 + gh / 4,
      gw / 2,
      (images.maid.height * gw) / 2 / images.maid.width
    );
  }
  if (d['electric_leakage']) {
    p5.image(
      images.leak,
      _x + gw - 5,
      _y - gh / 2 + gh / 2,
      gw / 2,
      (images.leak.height * gw) / 2 / images.leak.width
    );
  }
  if (d['iot_disconnected']) {
    p5.image(
      images.disconected,
      _x - 10,
      _y - gh / 2 + gh / 2,
      gw / 2,
      (images.disconected.height * gw) / 2 / images.disconected.width
    );
  }
};

const boxColor = (d, p5) => {
  if (d['clean_status'] === 'vc') {
    p5.fill('#ffffff');
  } else if (d['clean_status'] === 'oo') {
    p5.fill('#fe3636');
  } else if (d['clean_status'] === 'vd') {
    p5.fill('#666666');
  } else if (d['clean_status'] === 'od') {
    p5.fill('#0180c0');
  } else if (d['clean_status'] === 'oc') {
    p5.fill('#7ffffe');
  } else if (d['clean_status'] === 'ac') {
    p5.fill('#fefb7f');
  }
};

const roomTextNum = (p5, _x, _y, gw, gh, key) => {
  p5.stroke(100);
  p5.rect(_x, _y, (7 * gw) / 6, (7 * gh) / 6, 3);
  p5.textAlign(p5.LEFT);
  p5.textSize(gw / 2.5); // Text Room number
  p5.fill(0);
  p5.text(key, _x, _y - gh / 10);
  p5.stroke(150);
};

export { HotelFloorPlanMapCanvas };
