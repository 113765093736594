import styled from 'styled-components';

export const Nav = styled.div``;

export const NavItem = styled.div`
  position: block;
  display: flex;
  top: 0;
  left: 0;
  height: 35px;
  width: 120px;
  border-radius: 5px 5px 0 0;
  background-color: white;
  border-top: 5px;
`;

export const NavLink = styled.div`
  margin: auto;
  font-size: 15px;
  border: 0;
  text-align: center;
`;

export const HeaderSpace = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const MultiInputSpace = styled.div`
  height: 15px;
  margin-bottom: 7px;
`;

